import React, { cloneElement, ReactElement, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { Nft, useRoom, BiddableNft } from "../contexts/roomContext";
import { useChain } from "../contexts/chainsContext";
import PendingImg from "../assets/pending.jpg";
import IconLink from "./Icons/IconLink";
import HTMLBlock from "./HTMLBlock";
import TxtBlock from "./TxtBlock";
import Modal from "./ui/Modal";
import { createFloor, getVolumeLabel } from "../utils";

interface NFTModalProps {
  children: ReactElement;
  nft: Nft;
}

const createImage = (nft?: BiddableNft, isFullScreen: boolean = false) => {
  if (nft?.assetType?.includes("text/plain")) {
    return (
      <TxtBlock
        nft={nft}
        width="100%"
        maxW={isFullScreen ? "100%" : "450px"}
        fontSize={isFullScreen ? "16px" : "12px"}
        height="100%"
      />
    );
  }
  if (nft?.assetType?.includes("text/html")) {
    return <HTMLBlock nft={nft} style={{ maxWidth: isFullScreen ? "100%" : "450px" }} />;
  }
  return (
    <img
      className={`object-contain w-full h-full ${isFullScreen ? 'max-h-[90vh]' : ''}`}
      src={nft?.image || nft?.thumbnail}
      alt={nft?.name}
      onError={(e) => {
        e.currentTarget.src = PendingImg;
      }}
    />
  );
};

export const NFTModal: React.FC<NFTModalProps> = ({ nft, children }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getChainDetails } = useChain();
  const { decimals, abbr, truncate } = useRoom();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const units = 10 ** decimals;

  const formatCompactNumber = (number: number): string => {
    const units = ["", "K", "M", "B", "T"];
    let magnitude = 0;

    while (number >= 1000 && magnitude < units.length - 1) {
      number /= 1000;
      magnitude++;
    }

    return number.toFixed(1) + units[magnitude];
  };

  const chainDetails = getChainDetails(nft?.blockchain || 'solana');
  const vol =
    nft?.collection?.volume / 10 ** (chainDetails?.decimals || 0);

  const formattedVolume = formatCompactNumber(vol);

  const modifiedAttributes = Array.isArray(nft?.attributes)
    ? nft.attributes.map((attr) => {
      if (typeof attr.value === 'string') {
        const modifiedValue = attr.value
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return { ...attr, value: modifiedValue };
      }
      return attr;
    })
    : [];

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <>
      {cloneElement(children, { onClick: onOpen })}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsFullScreen(false);
          onClose();
        }}
        size={isFullScreen ? "full" : "2xl"}
        title={
          !isFullScreen && (
            <div className="flex items-center justify-between w-full">
              <h3 className="text-xl font-bold">Details</h3>
              {nft?.collection?.name && (
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {nft.collection.name}
                </span>
              )}
            </div>
          )
        }
      >
        <div className={`space-y-6 ${isFullScreen ? 'h-screen' : ''}`}>
          <div className={`grid ${isFullScreen ? '' : 'md:grid-cols-2'} gap-6`}>
            <div className={`relative aspect-square bg-gray-100 dark:bg-gray-800 rounded-lg overflow-hidden ${isFullScreen ? 'h-screen' : ''}`}>
              {createImage(nft, isFullScreen)}
              <button
                onClick={toggleFullScreen}
                className="absolute top-4 right-4 p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-75 transition-all"
              >
                {isFullScreen ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                  </svg>
                )}
              </button>
            </div>

            {!isFullScreen && (
              <div className="space-y-6">
                <div>
                  <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
                    {nft.name}
                  </h2>
                  <p className="text-gray-600 dark:text-gray-400">
                    {nft.description || "No description available"}
                  </p>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                    <p className="text-sm text-gray-500 dark:text-gray-400">Floor</p>
                    <p className="text-lg font-semibold text-gray-900 dark:text-white">
                      {createFloor(nft)}
                    </p>
                  </div>

                  <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                    <p className="text-sm text-gray-500 dark:text-gray-400">Volume</p>
                    <p className="text-lg font-semibold text-gray-900 dark:text-white">
                      {nft &&
                        getVolumeLabel(
                          nft.collection.volume,
                          nft.collection?.floorPrice?.floorPrice?.decimals || 0,
                          abbr,
                          truncate
                        )}
                    </p>
                  </div>

                  <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                    <p className="text-sm text-gray-500 dark:text-gray-400">Owners</p>
                    <p className="text-lg font-semibold text-gray-900 dark:text-white">
                      {nft?.collection.owners}
                    </p>
                  </div>

                  <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                    <p className="text-sm text-gray-500 dark:text-gray-400">Total</p>
                    <p className="text-lg font-semibold text-gray-900 dark:text-white">
                      {nft?.collection.totalItems}
                    </p>
                  </div>
                </div>

                {nft?.marketplace?.url && (
                  <a
                    href={nft.marketplace.url}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex items-center text-blue-600 dark:text-blue-400 hover:underline"
                  >
                    See on Marketplace
                    <IconLink className="ml-2 h-4 w-4" />
                  </a>
                )}
              </div>
            )}
          </div>

          {!isFullScreen && modifiedAttributes.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                Attributes
              </h3>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {modifiedAttributes.map((attr) => (
                  <div
                    key={attr.trait_type}
                    className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg"
                  >
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {attr.trait_type}
                    </p>
                    <p className="text-base font-medium text-gray-900 dark:text-white">
                      {attr.value}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
