import { forwardRef, useEffect, useMemo, useState } from "react";
import { Button, Container, Flex, HStack, Heading, Image, Tooltip, Text, Box, BoxProps, Spinner, Checkbox } from "@chakra-ui/react";
import { BiddableNft, Nft, useRoom } from "../contexts/roomContext";
import { getNftCollectionMetadata } from "../services/nfts.service";
import PendingImg from "../assets/pending.jpg";
import { Link } from "react-router-dom";
import { count } from "console";
import React from "react";
import { filterCompleted, getIcon } from "../utils";
import { getRoomType } from "../utils/getRoomType";
import AvatarList from "./AvatarList";
import CountdownTimer from "./CountdownTimer";
import { useAppContext } from "../contexts/appContext";
import TokenGated from "./TokenGated";
import { Room } from "../types/room";
import NftCollection from "./NftCollection";
import BiddingGridItem from "./NftCollection/BiddingGridItem";
import BudgetCard from "./BudgetCard";
import NumItemsLimit from "./NumItemsLimit";
import { setBudget } from "../services/room.service";
import { ExpandableText } from "./ExpandableText";
import { useTheme } from "../contexts/ThemeContext";

const TopBoxListings = ({ count }: { count: number }) => {
  const { room } = useRoom();
  const { isDarkMode } = useTheme();
  const isOrdinals =
    room?.info?.isOrdinals || room.blockchain.name === "bitcoin";
  return (
    <div className={`flex items-center gap-2 px-4 py-2 rounded-lg ${isDarkMode ? 'bg-gray-800/30' : 'bg-gray-200/50'}`}>
      <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>Listed items:</span>
      <span className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{count}</span>
    </div>
  );
};

export default function LandingAuction({ roomId, step, setStep }: { roomId: string, step: number, setStep: (newStep: number) => void }) {
  const {
    units,
    abbr,
    bids,
    budgets,
    room,
    stepProgress,
    availableForBid,
    getAvailableForBid,
    biddingDiff,
    preferredIds,
    isSilentAuction,
    availableForBidLoading,
    canList,
    passed,
    listings,
    isAdmin,
    showContactModal,
    setShowContactModal,
    preview,
  } = useRoom();

  const budget = budgets?.budget;

  const { uid, displayName, agreements, email, initialized } = useAppContext();
  const isTokenGated = room?.info?.accessPolicy?.policy === "collection";
  const hasJoined = room?.users && room?.users.includes(uid || "") ? true : false;


  const part = filterCompleted(stepProgress);

  const roomChain = room?.blockchain?.name?.toLowerCase();
  const count = availableForBid?.length || 0;

  const toggleStep = (newStep: number) => {
    setStep(newStep);
  };

  const preferredNfts = useMemo(() => {
    return preferredIds
      ?.filter((id: string) => !passed.includes(id))
      .map((id: string) => availableForBid?.find((ele) => ele.itemId === id)!);
  }, [preferredIds, availableForBid, passed]);

  const availableNfts = useMemo(() => {
    return availableForBid?.filter((ele) => !passed.includes(ele.itemId));
  }, [canList, preferredIds, availableForBid, passed]);




  let data = availableNfts;

  if (isSilentAuction) {
    data = preferredNfts;
  }
  return (
    <StepperAuction
      roomId={roomId}
      uid={uid}
      budget={budget}
      units={units!}
      abbr={abbr!}

      count={count}
      part={part}
      step={step}
      room={room}
      data={data}
      passed={passed}
      preferredNfts={preferredNfts}
      availableForBidLoading={availableForBidLoading}
      isSilentAuction={isSilentAuction}
      canList={canList}
      hasJoined={hasJoined}
      toggleStep={toggleStep}
    />

    // <VStack className="box" width="100%" justifyItems="flex-start" zIndex={999999999}>
    //   <Heading as="h3" size="xs" color="gray.300" textAlign="left" width="100%">
    //     Preview for {isTicketGated ? "ticket" : "token"} gated
    //   </Heading>
    //   {!isTicketGated && (
    //     <>
    //       <Heading as="h4" size="xs" color="gray.300" textAlign="left" width="100%">
    //         To Access the room you need the following collections:
    //       </Heading>
    //       {renderCollections()}
    //     </>
    //   )}
    // </VStack>
  );
}

interface StepperProps {
  roomId: string;
  uid: any;
  budget: number;
  units: number;
  abbr: string;
  count: number;
  part: string[];
  step: number;
  room: any;
  data: any;
  passed: any,
  preferredNfts: any;
  availableForBidLoading: any;
  hasJoined: boolean;
  isSilentAuction: boolean;
  canList: boolean;

  toggleStep: (newStep: number) => void;
}

const StepperAuction = ({ roomId, uid, budget, units, abbr, count, part, step, room, data, passed, preferredNfts, availableForBidLoading, hasJoined, isSilentAuction, canList, toggleStep }: StepperProps) => {
  const { isDarkMode } = useTheme();
  let content;

  const containerClasses = `w-full max-w-6xl mx-auto p-4 ${isDarkMode
    ? 'bg-gray-900/50 backdrop-blur-sm text-white'
    : 'bg-white/80 backdrop-blur-sm text-gray-900 border border-gray-200'
    }`;

  const textSecondaryClasses = isDarkMode ? 'text-gray-400' : 'text-gray-600';
  const textPrimaryClasses = isDarkMode ? 'text-white' : 'text-gray-900';
  const headingClasses = `text-xl font-bold truncate mb-1 ${textPrimaryClasses}`;
  const descriptionClasses = `text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`;

  if (step === -1) {
    content = (
      <div className={containerClasses}>
        <div className="flex flex-col md:flex-row items-start gap-6">
          <div className="flex-1 min-w-0">
            <div className="flex items-center gap-3 mb-2">
              <span className={textSecondaryClasses}>{getRoomType(room.info.roomType)}</span>
              {getIcon(room?.blockchain?.name, "24px", "24px")}
            </div>
            <h2 className={headingClasses}>{room.info.name}</h2>
            {room.info.description && (
              <ExpandableText noOfLines={2}>
                <p className={descriptionClasses}>{room.info.description}</p>
              </ExpandableText>
            )}
          </div>

          <div className="flex flex-row md:items-center gap-4 w-full md:w-auto">
            <div className="flex-1 md:flex-initial">
              <CountdownTimer />
            </div>
            <div className="flex-1 md:flex-initial">
              <AvatarList avatars={room?.users} completed={part} />
            </div>
            <TopBoxListings count={count} />
          </div>
        </div>
      </div>
    );
  } else {
    // For step 0, 1, 2, etc.
    switch (step) {
      case 0:
        content = (
          <div className={containerClasses}>
            <div className="flex flex-col md:flex-row items-start gap-6">
              <div className="flex-1 min-w-0">
                <div className="flex items-center gap-3 mb-2">
                  <span className={textSecondaryClasses}>{getRoomType(room.info.roomType)}</span>
                  {getIcon(room?.blockchain?.name, "24px", "24px")}
                </div>
                <h2 className={headingClasses}>{room.info.name}</h2>
                {room.info.description && (
                  <ExpandableText noOfLines={2}>
                    <p className={descriptionClasses}>{room.info.description}</p>
                  </ExpandableText>
                )}
              </div>

              <div className="flex flex-row md:items-center gap-4 w-full md:w-auto">
                <div className="flex-1 md:flex-initial">
                  <CountdownTimer />
                </div>
                <div className="flex-1 md:flex-initial">
                  <AvatarList avatars={room?.users} completed={part} />
                </div>
                <TopBoxListings count={count} />
              </div>
            </div>

            <p className={`${descriptionClasses} mt-4 text-center`}>Smart auctions are different from regular auctions. Click "Get Started" to learn more.</p>
          </div>
        );
        break;
      case 1:
        content = (
          <div className={containerClasses}>
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-4">
              <div className="flex items-center gap-4">
                <div>
                  <div className="flex items-center gap-3 mb-1">
                    <span className={textSecondaryClasses}>Step 1</span>
                    <span className={textSecondaryClasses}>{step}/3</span>
                  </div>
                  <h2 className={headingClasses}>Explore Listed Items</h2>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <CountdownTimer />
                <AvatarList avatars={room?.users} completed={part} />
                <TopBoxListings count={count} />
              </div>
            </div>

            <p className={`${descriptionClasses} mb-4`}>Browse the items available in this auction, and click 'Hide' if you are not interested.</p>

            <div className="w-full">
              {availableForBidLoading ? (
                <div className="flex items-center justify-center gap-3 p-4">
                  <Spinner color={isDarkMode ? "#6565FF" : "#4A4AFF"} size="sm" />
                  <p className={descriptionClasses}>Loading items, please wait...</p>
                </div>
              ) : (
                <NftCollection
                  GridComponent={({ nft }: { nft: Nft | BiddableNft }) => (
                    <BiddingGridItem
                      nft={nft as BiddableNft}
                      isSeller={isSilentAuction && canList}
                      isPassed={passed ? passed.includes(nft?.itemId) : false}
                    />
                  )}
                  showGating
                  data={data || []}
                  indexOptions={["name", "collection.name"]}
                  groupBy="collection.name"
                  emptyMessage="Sorry no items to bid on yet!"
                  isLoading={availableForBidLoading}
                  collectionType="bidding"
                  defaultSort={{
                    key: "hasViewed",
                    direction: "asc",
                  }}
                  preferredNfts={preferredNfts}
                  hasJoined={hasJoined}
                />
              )}
            </div>
          </div>
        );
        break;
      case 2:
        content = (
          <div className={containerClasses}>
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-4">
              <div>
                <div className="flex items-center gap-3 mb-1">
                  <span className={textSecondaryClasses}>Step 2</span>
                  <span className={textSecondaryClasses}>{step}/3</span>
                </div>
                <h2 className={headingClasses}>Set Your Limits</h2>
              </div>

              <div className="flex items-center gap-4">
                <CountdownTimer />
                <AvatarList avatars={room?.users} completed={part} />
                <TopBoxListings count={count} />
              </div>
            </div>

            <p className={`${descriptionClasses} mb-4`}>Set a spending limit and specify the number of items you wish to receive; this will protect your bids and allow you to bid confidently without overspending.</p>

            <div className="grid md:grid-cols-2 gap-4">
              <div className={`rounded-lg p-4 ${isDarkMode ? 'bg-gray-800/30' : 'bg-gray-100'}`}>
                <BudgetCard
                  roomId={roomId}
                  uid={uid}
                  budget={budget}
                  units={units!}
                  abbr={abbr!}
                />
              </div>
              <div className={`rounded-lg p-4 ${isDarkMode ? 'bg-gray-800/30' : 'bg-gray-100'}`}>
                <NumItemsLimit />
              </div>
            </div>

            <div className="mt-4">
              <p className={`${descriptionClasses} mb-2`}>To learn more, please watch the video below:</p>
              <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                <iframe
                  className="w-full"
                  src="https://www.youtube.com/embed/UEGc1iqJyMQ?si=bV4Mo7cDiyvZobgu"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        );
        break;
      // Add cases for other steps if necessary
      default:
        content = null;
    }
  }

  return (
    <div className="w-full mx-auto my-4">
      <div className={`rounded-xl overflow-hidden ${isDarkMode ? 'bg-gray-900/50' : 'bg-white/80'} backdrop-blur-sm shadow-md`}>
        {content}

        {hasJoined && (
          <div className={`p-4 ${isDarkMode ? 'border-t border-gray-800' : 'border-t border-gray-200'}`}>
            <FooterButtons roomId={roomId} uid={uid} step={step} toggleStep={toggleStep} />
          </div>
        )}
        {!hasJoined && <TokenGated />}
      </div>
    </div>
  );
};

interface FooterButtonsProps {
  roomId: string;
  uid: string;
  step: number;
  toggleStep: (newStep: number) => void;
}

const FooterButtons = ({ roomId, uid, step, toggleStep }: FooterButtonsProps) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { isDarkMode } = useTheme();

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(e.target.checked);
    setBudget(roomId, uid, { skipLandingPage: e.target.checked });
  };

  const buttonBaseClasses = "px-6 py-2 rounded-lg transition-colors";
  const primaryButtonClasses = `${buttonBaseClasses} ${isDarkMode ? 'bg-[#6565FF] hover:bg-[#5151FF]' : 'bg-[#4A4AFF] hover:bg-[#3939FF] text-white'}`;
  const secondaryButtonClasses = `${buttonBaseClasses} ${isDarkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-gray-200 hover:bg-gray-300'}`;

  if (step === 0) {
    return (
      <div className="flex justify-between items-start w-full">
        <div className="space-y-4">
          <button
            className={secondaryButtonClasses}
            onClick={() => toggleStep(-1)}
          >
            Skip
          </button>
          <label className="flex items-center space-x-2 cursor-pointer">
            <input
              type="checkbox"
              checked={isCheckboxChecked}
              onChange={handleCheckboxChange}
              className={`rounded ${isDarkMode ? 'border-gray-600' : 'border-gray-400'}`}
            />
            <span className={isDarkMode ? 'text-sm text-gray-300' : 'text-sm text-gray-600'}>Don't show this again</span>
          </label>
        </div>
        <button
          className={primaryButtonClasses}
          onClick={() => toggleStep(step + 1)}
        >
          Get Started
        </button>
      </div>
    );
  }

  if (step === 1 || step === 2) {
    return (
      <div className="flex justify-between items-center w-full">
        <button
          className={secondaryButtonClasses}
          onClick={() => toggleStep(-1)}
        >
          Skip
        </button>
        <div className="flex space-x-2">
          <button
            className={primaryButtonClasses}
            onClick={() => toggleStep(Math.max(0, step - 1))}
          >
            Previous
          </button>
          <button
            className={primaryButtonClasses}
            onClick={() => toggleStep(step === 2 ? -1 : step + 1)}
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  return null;
};
