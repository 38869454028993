import { ReactElement, forwardRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Spinner, Avatar, Text, Box, Flex, BoxProps, Button } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import Firebase from "../../services/firebase";
import Bidding from "./Bidding";
import Listing from "./Listing";
import Finalize from "./Finalize";
import { useAppContext } from "../../contexts/appContext";
import CountdownTimer from "../../components/CountdownTimer";
import AdminBar from "../../components/AdminBar";
import { capitalizeFirstLetter, filterCompleted, getIcon } from "../../utils";
import ReactDOM from "react-dom";
// import PartyChat from "../../components/PartyChat";
import Chat from "../../components/Chat";
import IconClose from "../../components/Icons/IconClose";
import { useRoom } from "../../contexts/roomContext";
import { FaTimesCircle, FaTrash } from "react-icons/fa";
import { getRoomType } from "../../utils/getRoomType";
import AvatarList from "../../components/AvatarList";
import EmailVerification from "../EmailVerification";
import SignupForm from "../../components/SignupForm";
import useIsLive from "../../hooks/useIsLive";
import { AvatarItem } from "../../components/AvatarItem";
import { UserName } from "../../components/UserName";
import React from "react";
import { ref } from "yup";
import LandingAuction from "../../components/LandingAuction";

const LoadingArea = () => {
  return (
    <div className="flex flex-col items-center justify-center h-[50vh] w-full gap-4">
      <div className="w-10 h-10 border-4 border-primary border-t-transparent rounded-full animate-[spin_1s_linear_infinite]"></div>
      <p className="text-lg font-medium text-gray-800 dark:text-white">Loading room...</p>
    </div>
  );
};

const TopBoxListings = ({ count }: { count: number }) => {
  const { room } = useRoom();
  const isOrdinals =
    room?.info?.isOrdinals || room.blockchain.name === "bitcoin";
  return (
    <Flex
      alignItems={{ base: "center", lg: "flex-start" }}
      gap={"5px"}
      direction={{ base: "row", lg: "column" }}
      minW={"95px"}
    >
      <Text>Listed {isOrdinals ? "Ordinals" : "items"}:</Text>
      <Text as={"h4"} fontSize={{ base: "initial", lg: "2xl" }}>{count}</Text>
    </Flex>
  );
};

const RoomMessage = ({ icon: Icon, title, description }: { icon: any, title: string, description: string }) => {
  return (
    <div className="w-full mx-auto">
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-md p-8 space-y-6 transition-colors">
        <div className="flex flex-col items-center justify-center text-center space-y-6">
          <div className="flex items-center justify-center w-24 h-24 text-gray-400 dark:text-gray-500">
            <Icon className="w-full h-full" />
          </div>
          <div className="space-y-3">
            <h3 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">
              {title}
            </h3>
            <p className="text-gray-500 dark:text-gray-400 text-lg">
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function RoomDetails() {
  const {
    initializing,
    room,
    roomId,
    roomState,
    isAdmin,
    stepProgress,
    availableForBid,
    listings,
    isSilentAuction,
    canList,
    setIsPassing,
    budgets,
  } = useRoom();
  const { uid, displayName, agreements, email, initialized } = useAppContext();

  const skipLandingPage = budgets?.skipLandingPage;

  const isSeller = (isSilentAuction && canList)
  const [stepLanding, setStep] = useState(-1); // -1 so it's not in is passing mode since we have hidden the landing ui
  useEffect(() => {
    const shouldSetToMinusOne = isSeller || isAdmin || roomState === "no_trade_for_user" || roomState === "completed" || roomState === "listing" || roomState === "signing" || roomState === "archived" || roomState === "canceled" || roomState === "trade_not_found" || skipLandingPage;
    setStep(shouldSetToMinusOne ? -1 : 0);
  }, [isAdmin, roomState]);

  useEffect(() => {
    setIsPassing(false);
  }, [stepLanding]);

  useEffect(() => {
    document.body.classList.add("is-room");

    return () => {
      document.body.classList.remove("is-room");
    };
  }, []);

  if (initializing || !room || !room?.info) return <LoadingArea />;

  const isAi = room.info.roomType === "ai";

  // if (uid && !isAi && !email?.isVerified && email?.verificationSent)
  //   return <EmailVerification />;

  if (
    uid &&
    initialized &&
    !isAi &&
    (!displayName || !agreements?.consentTosAndPp)
  )
    return <SignupForm />;

  const status = room?.status;

  const returnStep = (step: string) => {
    switch (step) {
      case "listing":
        return <Listing />;
      case "bidding":
        return (
          <Bidding
            roomId={roomId!}
            uid={uid!}
            isAdmin={isAdmin}
            markupPercent={room?.info?.markupPercent}
          />
        );
      case "trade_not_found":
        return (
          <RoomMessage
            icon={FaTimesCircle}
            title="No Sale was Found"
            description="Sorry, a trade was not possible this time."
          />
        );
      case "canceled":
        return (
          <RoomMessage
            icon={FaTimesCircle}
            title="Trade Cancelled"
            description={`Sorry, the "${room.info.name}" room has been Cancelled.`}
          />
        );
      case "no_trade_for_user":
      case "signing":
      case "completed":
        return (
          <Finalize
            roomId={roomId!}
            room={room}
            uid={uid!}
            isAdmin={isAdmin}
            adminId={room?.info?.adminId}
          />
        );
      case "archived":
        return (
          <RoomMessage
            icon={FaTrash}
            title="Room Archived"
            description={`The room "${room.info.name}" has been Archived.`}
          />
        );
      default:
        return <LoadingArea />;
    }
  };

  const sidePanel = document.getElementById("side-panel");

  const openChat = () => {
    document.body.classList.add("chat-open");
  };

  const closeChat = () => {
    document.body.classList.remove("chat-open");
  };

  const part = filterCompleted(stepProgress);

  const roomChain = room?.blockchain?.name?.toLowerCase();
  const count = availableForBid?.length || 0;




  return (
    <>
      <Helmet>
        <title>{`Tulle | ${capitalizeFirstLetter(status || "")} in ${room.info.name
          }`}</title>
      </Helmet>
      {isAdmin && (
        <AdminBar
          roomId={roomId!}
          swapId={room.swapId?.swapId}
          status={status}
          tradeFound={room.tradeFound}
          chain={room?.blockchain?.name}
        />
      )}
      <audio id="sb_audio" autoPlay={true} />
      <div id="chat-toggle">
        <div className="bubble" onClick={openChat}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 30 30"
            xmlSpace="preserve"
          >
            <path
              d="M15,1.13c-8.28,0-15,5.63-15,12.59c0,3.47,1.67,6.61,4.38,8.88v5.34c0,0.51,0.41,0.93,0.93,0.93 c0.16,0,0.32-0.04,0.46-0.12l5.11-2.93c1.35,0.32,2.73,0.48,4.12,0.48c8.28,0,15-5.63,15-12.58S23.28,1.13,15,1.13L15,1.13z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
        <div className="close" onClick={closeChat}>
          <IconClose />
        </div>
      </div>
      {sidePanel && ReactDOM.createPortal(
        <div className="chat-side-panel">
          <Chat chatId={roomId!} isAdmin={isAdmin} isAiTrades={isAi} />
        </div>,
        sidePanel
      )}
      <Box className="party-page" w={"full"}>
        <LandingAuction roomId={roomId} step={-1} setStep={setStep} />
        {/* <LandingAuction roomId={roomId} step={stepLanding} setStep={setStep} /> 
        <div className="party-section"
          style={{ display: stepLanding === -1 ? 'block' : 'none' }}
        >
          {returnStep(roomState)}
        </div>
        */}
        <div className="party-section"

        >
          {returnStep(roomState)}
        </div>
      </Box>


    </>
  );
}
