import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAppContext } from "../contexts/appContext";
import SignInButton from "../components/SignInButton";
import PartnerGroup from "../components/PartnerGroup";
import { useChain } from "../contexts/chainsContext";

// Custom Chain Display Component
const ChainOptions = () => {
  const { chain, chains, setChain } = useChain();
  const [selectedChain, setSelectedChain] = useState<string>("solana");

  // Chain selection handler
  const handleChainSelect = (chainName: string) => {
    // Only update if the chain is available
    const chainToSelect = availableChains.find(c => c.value === chainName);
    if (chainToSelect?.available) {
      console.log(`Selecting chain: ${chainName}`);
      setSelectedChain(chainName);

      // Ensure chain is set properly in the context
      setChain(chainName);

      // Log the updated chain for debugging
      setTimeout(() => {
        console.log("Chain updated to:", chainName);
      }, 100);
    } else {
      console.log(`Chain ${chainName} is currently unavailable`);
    }
  };

  // Available chains
  const availableChains = [
    {
      name: "Solana",
      value: "solana",
      available: true,
      icon: (
        <svg viewBox="0 0 24 24" className="w-6 h-6">
          <path fill="currentColor" d="M17.6 6.31a.76.76 0 00-.53-.22H5.83a.19.19 0 00-.13.33l2.6 2.6a.76.76 0 00.53.22h11.24c.18 0 .27-.22.13-.33l-2.6-2.6zM17.6 14.08a.76.76 0 00-.53-.22H5.83a.19.19 0 00-.13.33l2.6 2.6a.76.76 0 00.53.22h11.24c.18 0 .27-.22.13-.33l-2.6-2.6zM5.83 10.19a.19.19 0 00-.13.33l2.6 2.6a.76.76 0 00.53.22h11.24c.18 0 .27-.22.13-.33l-2.6-2.6a.76.76 0 00-.53-.22H5.83z" />
        </svg>
      )
    }
  ];

  // Unavailable chains
  const unavailableChains = [
    {
      name: "Ethereum",
      value: "ethereum",
      available: false,
      icon: (
        <svg viewBox="0 0 24 24" className="w-6 h-6">
          <path fill="currentColor" d="M11.998 0v8.87l7.497 3.35z" />
          <path fill="currentColor" d="M11.998 0L4.5 12.22l7.498-3.35z" />
          <path fill="currentColor" d="M11.998 17.968v6.027L19.5 13.616z" />
          <path fill="currentColor" d="M11.998 23.995v-6.028L4.5 13.615z" />
          <path fill="currentColor" d="M11.998 16.573l7.497-4.353-7.497-3.348z" />
          <path fill="currentColor" d="M4.5 12.22l7.498 4.353v-7.701z" />
        </svg>
      )
    },
    {
      name: "Bitcoin",
      value: "bitcoin",
      available: false,
      icon: (
        <svg viewBox="0 0 24 24" className="w-6 h-6">
          <path fill="currentColor" d="M23.638 14.904c-1.602 6.43-8.113 10.34-14.542 8.736C2.67 22.05-1.244 15.525.362 9.105 1.962 2.67 8.475-1.243 14.9.358c6.43 1.605 10.342 8.115 8.738 14.548v-.002zm-6.35-4.613c.24-1.59-.974-2.45-2.64-3.03l.54-2.153-1.315-.33-.525 2.107c-.345-.087-.705-.167-1.064-.25l.526-2.127-1.32-.33-.54 2.165c-.285-.067-.565-.132-.84-.2l-1.815-.45-.35 1.4s.975.225.955.236c.535.136.63.486.615.766l-1.477 5.92c-.075.166-.24.406-.614.314.015.02-.96-.24-.96-.24l-.66 1.51 1.71.426.93.242-.54 2.19 1.32.327.54-2.17c.36.1.705.19 1.05.273l-.51 2.154 1.32.33.545-2.19c2.24.427 3.93.257 4.64-1.774.57-1.637-.03-2.58-1.217-3.196.854-.193 1.5-.76 1.68-1.93h.01zm-3.01 4.22c-.404 1.64-3.157.75-4.05.53l.72-2.9c.896.23 3.757.67 3.33 2.37zm.41-4.24c-.37 1.49-2.662.735-3.405.55l.654-2.64c.744.18 3.137.52 2.75 2.084v.006z" />
        </svg>
      )
    },
    {
      name: "Stacks",
      value: "stacks",
      available: false,
      icon: (
        <svg viewBox="0 0 24 24" className="w-6 h-6">
          <path fill="currentColor" d="M18.58 4.884v3.766H5.42V4.884h13.16zm0 5.187v3.766H5.42v-3.766h13.16zm0 5.18v3.764H5.42v-3.765h13.16z" />
        </svg>
      )
    },
  ];

  const allChains = [...availableChains, ...unavailableChains];

  return (
    <div className="w-full">
      <div className="grid grid-cols-2 gap-4">
        {allChains.map((chain) => (
          <div
            key={chain.name}
            className={`p-4 rounded-lg flex items-center gap-3 border transition-all ${chain.available
              ? `cursor-pointer ${selectedChain === chain.value
                ? "border-blue-500 dark:border-blue-400 bg-blue-50 dark:bg-blue-900/30"
                : "border-gray-200 dark:border-gray-700 bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"}`
              : "cursor-not-allowed bg-gray-100 dark:bg-gray-800/30 border-gray-200 dark:border-gray-700/30"
              }`}
            onClick={() => chain.available && handleChainSelect(chain.value)}
          >
            <div className={`w-10 h-10 flex items-center justify-center rounded-full ${chain.available
              ? `${selectedChain === chain.value
                ? "bg-blue-500 text-white dark:bg-blue-600"
                : "bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400"}`
              : "bg-gray-200 dark:bg-gray-700/50 text-gray-500 dark:text-gray-500"
              }`}>
              {chain.icon}
            </div>
            <div className="flex flex-col">
              <span className={`font-medium ${chain.available
                ? `text-gray-900 dark:text-white ${selectedChain === chain.value ? "font-semibold" : ""}`
                : "text-gray-500 dark:text-gray-400"
                }`}>
                {chain.name}
              </span>
              {!chain.available ? (
                <span className="text-xs text-red-500 dark:text-red-400">
                  Paused
                </span>
              ) : (
                <span className={`text-xs ${selectedChain === chain.value
                  ? "text-blue-600 dark:text-blue-400"
                  : "text-gray-500 dark:text-gray-400"}`}>
                  {selectedChain === chain.value ? "Selected" : "Click to select"}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default function Connect() {
  const { user, returnUrl } = useAppContext();

  if (user && returnUrl) {
    return <Navigate to={returnUrl} replace={true} />;
  }

  return (
    <>
      <Helmet>
        <title>Tulle | Connect Wallet</title>
      </Helmet>
      <div className="container mx-auto max-w-[600px] px-4 mt-20">
        <div className="flex flex-col text-center mb-8">
          <h2 className="text-4xl lg:text-5xl mb-4 font-bold text-gray-900 dark:text-white">
            Connect Wallet
          </h2>
          <p className="text-gray-600 dark:text-gray-300 max-w-lg mx-auto">
            Select a chain and connect your wallet to start using NeoSwap
          </p>
        </div>

        <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl shadow-sm overflow-hidden">
          <div className="p-6 md:p-8">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col items-center">
                <p className="mb-4 font-medium text-lg text-gray-900 dark:text-gray-200">
                  Select a chain and connect your wallet
                </p>
                <div className="w-full max-w-md mb-6">
                  <ChainOptions />
                </div>
                <div className="w-full flex justify-center">
                  <SignInButton version="connect-page" />
                </div>
              </div>

              <div className="flex items-start p-5 bg-blue-50 dark:bg-blue-900/20 border border-blue-100 dark:border-blue-800 rounded-lg mt-2">
                <svg className="w-6 h-6 text-blue-600 dark:text-blue-400 flex-shrink-0 mt-0.5" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                </svg>
                <p className="ml-3 text-sm text-gray-800 dark:text-gray-300">
                  This product is still in beta. If you encounter any problems, please use the feedback form on the left.
                </p>
              </div>
            </div>
          </div>
        </div>

        <PartnerGroup />
      </div>
    </>
  );
}
