import { Heading } from "@chakra-ui/react";
import LandingFooter from "./LandingFooter";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.svg";
import { Helmet } from "react-helmet-async";

export default function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <Helmet>
        <title>Tulle | Privacy Policy</title>
      </Helmet>
      <div className="container mx-auto px-4 py-8 mt-16">
        <div className="mb-8">

          <Heading as="h1" className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white">
            Privacy Policy
          </Heading>
        </div>
        <div className="prose prose-lg dark:prose-invert max-w-none">
          <p className="text-gray-600 dark:text-gray-400 mb-8">Last Updated: Feb 21, 2023</p>
          <p className="text-gray-700 dark:text-gray-300">
            Please read this Privacy Policy carefully, as it outlines the privacy practices surrounding the use and collection of your personal information in connection with your use of neoswap.xyz and participation in a NeoSwap event (collectively, the "Services"), owned and operated by NeoSwap Inc. (collectively, "NeoSwap," "we," "us," or "our").
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            By using the Services, you agree to the practices described in this Privacy Policy and any update posted here from time to time. To make sure you stay informed of all changes, you should check this Privacy Policy periodically. If you do not agree with our policies and practices, do not use our Services. Your continued use of the Services after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            Updates will be referenced by the "Last Updated" date shown above.
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            If you reside in California, you may have additional data rights. Please refer to the "California Privacy Rights" section below.
          </p>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mt-6">1. Data Collection</h3>
          <p className="text-gray-700 dark:text-gray-300">
            We collect certain information about you when you visit our App. This information may personally identify you or have the potential to personally identify you ("Personal Information"). This section describes the Personal Information we collect and the sources of that information.
          </p>
          <p className="text-gray-700 dark:text-gray-300 font-semibold">Information We Collect</p>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>Identifiers: </strong>We may collect your full name, email address, physical address, phone number, IP Address, device ID, third-party account information or other similar identifiers. We may also collect any other Personal Information that you provide to us, such as social media identifier or language preference.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>Internet or Other Electronic Network Activity Information:</strong> We may collect information regarding your interaction with the Services, including time of visit and geolocation data, pages visited, links clicked, emails opened, language preferences, and the pages that led or referred you to our Services. We may also collect information automatically from your device, such as device type, operating system name and version, device manufacturer and model, language, plug-ins, and add-ons.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>Geolocation Data: </strong>We may collect your physical location or infer your physical location based on your IP address, mailing address, or other network or voluntarily provided information.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>Public Information Observed from Blockchains: </strong>We collect data from activity that is publicly visible and/or accessible on blockchains. This may include blockchain addresses and information regarding purchases, sales, or transfers of Items, which may then be associated with other data you have provided to us.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>Information Collected from Third-Party Companies:</strong> We may receive Personal and/or Anonymous Data about you from companies that offer their products and/or services for use in conjunction with our Service or whose products and/or services may be linked from our Service. For example, third-party wallet providers provide us with your blockchain address and certain other information you choose to share with those wallets providers. We may add this to the data we have already collected from or about you through our Service.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>Information Collected by Our Servers:</strong> To provide the Services and make it more useful to you, we (or a third party service provider) collect information from you, including, but not limited to, your browser type, operating system, Internet Protocol ("IP") address, mobile device ID, blockchain address, wallet type, and date/time stamps.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>Log Files:</strong> As is true of most websites and applications, we gather certain information automatically and store it in log files. This information includes IP addresses, browser type, Internet service provider ("ISP"), referring/exit pages, operating system, date/time stamps, and clickstream data. We use this information to analyze trends, administer the Service, track users' movements around the Service, and better tailor our Services to our users' needs. For example, some of the information may be collected so that when you visit the Service, it will recognize you and the information can be used to personalize your experience.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>Cookies:</strong> Like many online services, we use cookies to collect information. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to analyze how users interact with our Service, make improvements to our product quality, and provide users with a more personalized experience.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>How We Respond to Do Not Track Signals:</strong> Our systems do not currently recognize "do not track" signals or other mechanisms that might enable Users to opt out of tracking on our site.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>Analytics Services:</strong> In addition to the tracking technologies we place like Cookies, other companies may set their own cookies or similar tools when you use the Services. This includes third-party analytics services ("Analytics Services") that we engage to help analyze how users use the Services. The information generated by the Cookies or other technologies about your use of the Services ("Analytics Information") is transmitted to the Analytics Services. The Analytics Services use Analytics Information to compile reports on user activity, which we may receive on an individual or aggregate basis. We use the information we get from Analytics Services to improve the Services. The Analytics Services may also transfer information to third parties where required to do so by law, or where such third parties process Analytics Information on their behalf. Each Analytics Services' ability to use and share Analytics Information is restricted by such Analytics Services' terms of use and privacy policy. By using our Service, you consent to the processing of data about you by Analytics Services in the manner and for the purposes set out above.
            </li>
          </ul>
          <p className="text-gray-700 dark:text-gray-300">
            You may refuse to provide, or request that we delete, certain categories of Personal Information we have about you. However, if our collection of the Personal Information is required by law or contract or is necessary for you to access or use the Services, we may be unable to provide the Services to you.
          </p>
          <p className="text-gray-700 dark:text-gray-300 font-semibold">Data Sources</p>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              We may automatically collect certain information about the mobile device, computer or other devices that you use to access the Services through commonly used information-gathering tools, such as cookies and web beacons (see our Cookies section below).
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              You provide some Personal Information to us directly. For example, you provide Identifiers, such as name, email address, phone number, and billing address, and Payment Information for us to process your order.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              We may generate information about you based on our relationship with you or your use of the Services. Such information may be aggregated or anonymized before we process it.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              Third parties or public sources may provide us with certain Personal Information. For example, we may collect Personal Information from publicly accessible social media profiles and posts, private third parties, law enforcement, or public sources.
            </li>
          </ul>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mt-6">2. Data Usage</h3>
          <p className="text-gray-700 dark:text-gray-300">
            This section describes the purposes for which we process your Personal Information. We process your Personal Information:
          </p>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>To perform our contractual obligations to you.</strong> This includes but is not limited to providing you access to the Services; to facilitate the creation of and security of your account; to identify you as a user in our system; display your username next to the Items currently or previously accessible in your third-party wallet, and next to Items on which you have interacted; processing trade requests; to provide customer support and respond to your requests and inquiries; to send you a welcome email to verify ownership of the email address provided when your account was created; send you administrative notifications, such as security, support, and maintenance advisories; to send you notifications related to actions on the Service, including notifications related to trades and auctions; to send you newsletters, promotional materials, and other notices related to our Services or third parties' goods and services; performing our contractual obligations to you under our Terms of Service; and providing you with access to the Services you purchase from us.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>For legitimate business interests which outweigh the general privacy rights of individual data subjects.</strong> This includes but is not limited to analyzing and improving our Services and business operations; ensuring the security and availability of the Services; to investigate and address conduct that may violate our Terms of Service; and monitoring use of the Services to prevent fraud or abuse.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>To comply with a legal obligation or requirement.</strong> We use and process your Personal Information when we believe doing so is necessary to comply with laws and regulations, pursuant to a judicial authorization, or to exercise or defend our legal rights or those of a third party.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              To act in any other way we may describe when you provide the Personal Data.
            </li>
          </ul>
          <p className="text-gray-700 dark:text-gray-300">
            With your consent. We process your Personal Information if we obtained your affirmative consent to such processing, such as to send you our newsletter and other marketing communications that you requested, or to respond to inquiries and questions that you initiated.
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            If we need to process your Personal Information for a purpose other than that for which the information was initially collected, we will provide you with information about that other purpose before we further process your Personal Information.
          </p>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mt-6">3. Data Sharing and Disclosure</h3>
          <p className="text-gray-700 dark:text-gray-300">
            We may share your Personal Information with third parties:
          </p>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>With your consent. </strong>We share your Personal Information if you affirmatively consent to it. If we are legally obligated to obtain your consent prior to sharing your Personal Information, we notify you of the specific purpose for which we are obtaining your consent, and we do not share beyond that purpose without notifying you of and obtaining additional consent for further disclosure.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>With third party service providers and agents. </strong>We share Personal Information with a limited number of service providers who process it on our behalf to provide or improve our business functions, and who have agreed to privacy restrictions similar to the ones in our Privacy Policy by making similar commitments.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>For legal disclosure.</strong> We may share your information with third parties when we reasonably believe disclosure is required or permitted in order to comply with a subpoena, court order or other applicable law, regulation or legal process.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>To protect us or others. </strong>We may share your information to the extent we believe that sharing such information is necessary to protect the rights, property, or safety of us, our products or services, our customers, or others.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>As aggregate information. </strong>We may share certain aggregated, non-personally identifying information internally or with others for purposes of research, reporting, and benchmarking.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>As a result of merger or sale of our business.</strong> We may share Personal Information if we are involved in a merger, sale, financing, liquidation, bankruptcy or acquisition of corporate entities or business units.
            </li>
          </ul>
          <p className="text-gray-700 dark:text-gray-300">
            We do not sell any Personal Information for any reason.
          </p>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mt-6">4. Data Access and Control</h3>
          <p className="text-gray-700 dark:text-gray-300">
            Users can alter their Personal Information via the following URL: https://neoswap.xyz/profile. In the event that further alterations to Personal Information are necessary, such requests may be submitted through the utilization of the designated 'Feedback' form available on the website.
          </p>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mt-6">5. Data Retention</h3>
          <p className="text-gray-700 dark:text-gray-300">
            We retain your Personal Information according to applicable laws or regulatory requirements and keep it as long as is necessary to fulfill the purposes described in this Privacy Policy or for which the personal data was collected.
          </p>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mt-6">6. Data Security</h3>
          <p className="text-gray-700 dark:text-gray-300">
            While no online service is 100% secure, we work to protect information about you against unauthorized access, use, alteration, or destruction, and take reasonable measures to do so. We monitor our Services for potential vulnerabilities and attacks, and we use a variety of security technologies and organizational procedures to help protect your personal data from unauthorized access, use, or disclosure. We also limit access to your Personal Information to those personnel with a need to know in order to perform job duties for us.
          </p>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mt-6">7. Children's Privacy</h3>
          <p className="text-gray-700 dark:text-gray-300">
            We do not collect Personal Information about or from persons under the age of thirteen. If you are under the age of thirteen you CANNOT use the App.
          </p>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mt-6">8. Third Party Links</h3>
          <p className="text-gray-700 dark:text-gray-300">
            We may provide content on the Services that links to third-party websites. For example, we may post links to our social media profiles so you can find us easily. We do not control, and are not responsible for, third parties' privacy practices and content. When you click on a link to a third-party site, the third party may collect your personal information, which will be governed by the third party's privacy policy. Please read the third party's privacy policy to learn about how they collect and process your personal information.
          </p>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mt-6">9. California Privacy Rights</h3>
          <p className="text-gray-700 dark:text-gray-300">
            This Section 9 applies only to individuals residing in the State of California who are considered "consumers" and from whom we collect "personal information" as those terms are defined in the California Consumer Privacy Act of 2018 (CCPA). We will review and update this Section 9 at least once every twelve (12) months to reflect changes in our business, legal or regulatory obligations, so please check this Section 9 periodically for changes.
          </p>
          <p className="text-gray-700 dark:text-gray-300 font-semibold">How We Collect, Use, and Disclose Your Data</p>
          <p className="text-gray-700 dark:text-gray-300">
            We collect the categories of Personal Information listed in the "Information We Collect" section above as well as Sensitive Personal Information. "Sensitive Personal Information" includes your, social security number, driver's license number, State ID number, passport number, account login, financial account number, debit and credit card numbers, your precise geolocation, your race, ethnic origin, religious and philosophical beliefs, union-membership status, contents of messages sent to and by you, your genetic data, your biometric information, heal information and sexual lifestyle and orientation. We use the information for the business or commercial purposes specified in the "Data Uses" section above. We disclose your information to the categories of third parties listed in the "Data Sharing and Disclosure" section above. We use cookies as described in our Cookies Policy.
          </p>
          <p className="text-gray-700 dark:text-gray-300 font-semibold">Your CCPA and CPRA Rights and Choices</p>
          <p className="text-gray-700 dark:text-gray-300">
            California consumers have the following rights regarding our use and disclosure of your personal information, subject to certain limitations in the CCPA and CPRA:
          </p>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>The right to know.</strong><em> </em>You may request, up to twice in a 12-month period, the following information about the personal information we have collected about you during the past 12 months:
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              the categories and specific pieces of personal information we have collected about you;
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              the categories of sources from which we collected the personal information;
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              the business or commercial purpose for which we collected the personal information;
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              the third parties with whom we shared the personal information; and
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              the categories of personal information about you that we disclosed for a business purpose, and the third parties to whom we disclosed that information for a business purpose.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>The right to delete.</strong><em> </em>You may request that we delete the personal information we have collected about you, subject to certain limitations under applicable law.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>The right to correct.</strong> You may request that we correct the personal information we have collected about you, subject to certain limitations under applicable law.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>The right to opt-out from the sharing of your personal information.</strong> You may request to opt out of any communication of your personal information to another party.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>The right to non-discrimination. </strong>The CCPA provides that you may not be discriminated against for exercising these rights.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>The right to limit our use and disclosure of your Sensitive Personal Information.</strong><em> </em>You may request that we limit or stop using and disclosing your Sensitive Personal Information except to the extent necessary to perform our services and provide our goods as would be reasonably expected by an average consumer who requests our goods or services or as otherwise permitted by law.
            </li>
          </ul>
          <p className="text-gray-700 dark:text-gray-300">
            You may exercise any of the rights above by submitting a request using the Contact Information provided below. We may need to verify your identity before responding to your request, such as by requesting government-issued identification or confirming your full name, email address or account access.
          </p>
          <p className="text-gray-700 dark:text-gray-300 font-semibold">How Long We Retain Your Data</p>
          <p className="text-gray-700 dark:text-gray-300">
            We will hold and use your personal information only as long as is reasonably necessary: (i) to provide the services or goods for which you provided the information, (ii) to exercise or fulfill any rights or obligations we have in contract or law, and (iii) to advertise our services or goods to you.
          </p>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mt-6">10. EU and EEA Users' Rights</h3>
          <p className="text-gray-700 dark:text-gray-300">
            If you are habitually located in the European Union or European Economic Area, you have the right to access, rectify, download or erase your information, as well as the right to restrict and object to certain processing of your information. While some of these rights apply generally, certain rights apply only in certain limited circumstances. We describe these rights below:
          </p>
          <p className="text-gray-700 dark:text-gray-300 font-semibold">
            You have the right to access your personal data and, if necessary, have it amended or deleted or restricted. In certain instances, you may have the right to the portability of your data. You can also ask us to not send marketing communications and not to use your personal data when we carry out profiling for direct marketing purposes. You can opt out of receiving email newsletters and other marketing communications by following the opt-out instructions provided to you in those emails. Transactional account messages will be unaffected if you opt-out from marketing communications.
          </p>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mt-6">11. Cookies Policy</h3>
          <p className="text-gray-700 dark:text-gray-300">
            Cookies are small text files that are stored in a web browser's memory. Cookies help us remember your preferences and that you have already logged in, and they help us analyze how you use the App so we can improve the Services. This Cookies Policy explains how we use cookies and other similar technologies to help us ensure that our Services function properly, prevent fraud and other harm, and analyze and improve the Services in accordance with our Privacy Policy. Any capitalized term used and not otherwise defined below has the meaning assigned to it in the Privacy Policy.
          </p>
          <p className="text-gray-700 dark:text-gray-300 font-semibold">How We Use Cookies</p>
          <p className="text-gray-700 dark:text-gray-300">
            Cookies help us personalize the Services and offer an effective and safe App. We update our cookies periodically, but we generally use cookies for the following purposes:
          </p>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>To operate the Services. </strong>We use cookies for functions such as authentication, fraud prevention and detection, and features and preferences.
            </li>
          </ul>
          <ul className="list-disc pl-8 text-gray-700 dark:text-gray-300">
            <li>
              <strong>To analyze and improve the Services.</strong><strong><em> </em></strong>We use cookies to understand how you use the Services so that we can improve your user experience.
            </li>
          </ul>
          <p className="text-gray-700 dark:text-gray-300 font-semibold">How You Can Manage Cookies</p>
          <p className="text-gray-700 dark:text-gray-300">
            Your web browser may allow you to control the cookies we and other websites set on your computer.
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            Please consult the help section of your web browser for more information on how to delete cookies. However, note that if you choose to delete or disable cookies, we may be unable to provide the App and the Services to you.
          </p>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mt-6">12. Contact Information</h3>
          <p className="text-gray-700 dark:text-gray-300">
            If you have any questions, comments, or concerns about this Privacy Policy or our services, please contact us:
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            NeoSwap Inc.
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            2505 Whispering Oaks Court
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            Midlothian, VA 23112
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            help@neoswap.ai
          </p>
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mt-6">13. Changes to this Privacy Policy</h3>
          <p className="text-gray-700 dark:text-gray-300">
            We will provide you with notice of changes to the way we process your Personal Information and will obtain your consent as required by applicable law. All changes are effective immediately upon posting a notice of such changes. Your continued access to or use of the Services following the posting of changes constitutes your acknowledgment of such changes. You can see when this Privacy Policy was last updated by checking the "Last Updated" date displayed at the foot of this Privacy Policy.
          </p>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
}
