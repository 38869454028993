import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Skeleton,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
} from "@chakra-ui/react";
import { useAppContext } from "../contexts/appContext";
import NeoSelect from "../components/NeoSelect";
import Firebase from "../services/firebase";
import {
  collection,
  getDoc,
  getDocs,
  query,
  where,
  doc,
} from "@firebase/firestore";
import { useChain } from "../contexts/chainsContext";
import NeoTable from "../components/Table/NeoTable";
import NeoTr from "../components/Table/NeoTr";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

type Option = {
  label: string;
  value: string;
};

const createEnd = (room: any) => {
  let time = room?.info?.startAt;

  if (room?.info?.partyDuration) {
    return time + room.info.partyDuration;
  }

  if (room?.info?.listingSeconds) {
    time += room.info.listingSeconds;
  }

  if (room?.info?.biddingSeconds) {
    time += room.info.biddingSeconds;
  }
  return time;
};

export default function MySwaps() {
  const { uid, admin } = useAppContext();
  const blockchain = uid?.split("-")[0];
  const { chain } = useChain();
  const [value, setValue] = useState<Option>({
    label: "In Progress & To sign",
    value: "in-progress-to-sign",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  const options: Option[] = [
    { label: "In Progress", value: "in-progress" },
    { label: "In Progress & Initializing", value: "in-progress-initializing" },
    { label: "In Progress & To sign", value: "in-progress-to-sign" },
    { label: "In Progress & Signed", value: "in-progress-signed" },
    { label: "Canceled", value: "canceled" },
    { label: "Completed", value: "completed" },
    { label: "All", value: "all" },
  ];

  if (admin) {
    options.push({ label: "All Pending Swaps", value: "all_swaps" });
  }

  const getSwaps = async () => {
    try {
      setIsLoading(true);
      const db = Firebase.getDBApp();
      let q = query(collection(db, "blockchain", blockchain!, "swaps"));

      if (value.value === "all_swaps") {
        q = query(q, where("state", "in", ["deployed", "pending"]));
      } else {
        q = query(q, where("users", "array-contains", uid!));
      }

      switch (value.value) {
        case 'in-progress':
          q = query(q, where("state", "in", ["deployed", "pending"]));
          break;

        case 'in-progress-initializing':
          q = query(q, where("state", "==", "pending"));
          break;

        case 'in-progress-to-sign':
          q = query(q, where("state", "==", "deployed"));
          break;

        case 'in-progress-signed':
          q = query(q, where("state", "==", "deployed"));
          break;

        case 'canceled':
          q = query(q, where("state", "==", "canceled"));
          break;

        case 'completed':
          q = query(q, where("state", "==", "closed"));
          break;

        case 'all':
          break;

        case 'all_swaps':
          break;

        default:
          break;
      }

      const swapsData = await getDocs(q);

      const swaps = swapsData.docs.map((doc) => ({
        swapId: doc.id,
        ...doc.data(),
      }));

      const data = await Promise.all(
        swaps.map(async (swap: any) => {
          const roomsCollection = swap?.roomType === "ai" ? "aiRooms" : "rooms";
          const ref = doc(
            db,
            roomsCollection,
            swap?.roomId || "not-found",
          );
          const room: any = await getDoc(ref);
          const roomData: any = room?.data();
          if (swap?.roomType === "ai") {
            roomData.info.startAt = roomData?.info?.createdAt?.seconds;
            roomData.info.duration = 900;
            roomData.info.roomType = "ai";
            roomData.status = roomData.state;
            roomData.url = "/ai-trades/" + room.id;
          } else {
            roomData.url = "/rooms/" + room.id;
          }

          return {
            ...roomData,
            swap,
            roomId: room?.id,
            swapId: swap?.swapId,
            completed: swap?.state === "closed",
          };
        })
      );

      setData(data);
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!chain) return;
    getSwaps();
  }, [chain, value]);

  const handleChange = (option: unknown) => {
    setValue(option as Option);
  };

  const filteredData = data.filter((ele) => {
    if (!ele.info) return false;
    // console.log("ele", ele);
    const swap = ele?.swap || {};
    switch (value.value) {
      case "in-progress":
        return swap.state === "pending" || swap.state === "deployed";
      case "in-progress-initializing":
        return swap.state === "pending";
      case "in-progress-to-sign":
        return (
          swap.state === "deployed" &&
          !ele.completed &&
          swap?.usersPaying?.includes(uid!)
        );
      case "in-progress-signed":
        return swap.state === "deployed" && ele.completed;
      case "canceled":
        return swap.state === "canceled";
      case "completed":
        return swap.state === "closed";
      case "all_swaps":
        return swap.state === "deployed";
      default:
        return true;
    }
  });

  return (
    <>
      <Helmet>
        <title>{`Tulle | My Swaps`}</title>
      </Helmet>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading className="page-title" as="h2" size="lg">
          My Swaps
        </Heading>
        <Box w={{ base: "50%", md: "20%" }}>
          <NeoSelect options={options} value={value} onChange={handleChange} />
        </Box>
      </Flex>
      <div className="box new-bg-transparent" >
        <Box flexGrow={1} overflow="hidden" overflowX="auto">
          {/* {isLoading &&
            Array.from(Array(10).keys()).map((ele) => {
              return <Skeleton key={ele} height="45px" mb={5} />;
            })} */}

          {isLoading ? (
            <div className="nft-loader">
              <Spinner color="#ed6cd8" />
              <p className="loadingtext"> Loading, please wait... </p>
            </div>
          ) : (
            <TableContainer>
              {filteredData.length > 0 ? (
                <NeoTable>
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Status</Th>
                      <Th>Type</Th>
                      <Th>Start Time</Th>
                      <Th>End Time</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredData.map((row: any) => {
                      return (
                        <NeoTr key={row.swapId} className=" new-bg-transparent">
                          <Td color="#6C60FF">
                            <Link to={row.url}>
                              {row.info.name}
                            </Link>
                          </Td>
                          <Td>{row.status}</Td>
                          <Td>{row?.info?.roomType?.toUpperCase()}</Td>
                          <Td>
                            {DateTime.fromSeconds(row.info.startAt)
                              .toUTC()
                              .startOf("minute")
                              .toLocaleString(DateTime.DATETIME_SHORT)}
                          </Td>
                          <Td>
                            {DateTime.fromSeconds(createEnd(row))
                              .toUTC()
                              .startOf("minute")
                              .toLocaleString(DateTime.DATETIME_SHORT)}
                          </Td>
                        </NeoTr>
                      );
                    })}
                  </Tbody>
                </NeoTable>
              ) : (
                <Box width="fit-content" mb="10%" mx="auto" mt="10%">
                  {" "}
                  There is nothing to Show{" "}
                </Box>
              )}
            </TableContainer>
          )}
        </Box>
      </div>
    </>
  );
}
