import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,

  Spinner,
  useToast,
  Input,
  FormLabel,
  Alert,
  AlertDescription,
  AlertIcon
} from "@chakra-ui/react";
import {
  doc

} from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { Helmet } from "react-helmet-async";

import { useAppContext } from "../contexts/appContext";
import Firebase from "../services/firebase";
import { DateTime } from "luxon";
import { getIcon } from "../utils";
import HandleTime from "../components/HandleTime";
import { httpsCallable } from "firebase/functions";
import { AddToCalendarButton } from "add-to-calendar-button-react";

import { Link } from "react-router-dom";
import { setWalletTicket } from "../services/room.service";

const auctions = [
  {
    id: "test",
    name: "Tensorian x Baxus x NeoSwap Smart Auction",
    startAt: 1702486800,
    chain: "solana",
    partyDuration: 86400
  }
];

export default function TicketDispenser() {

  const [checkValue, setTicketCheck] = useState<any>({});
  const [claiming, setClaim] = useState<boolean>(false);
  const { uid, token } = useAppContext();
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const handleChange = (event: any) => {
    setWalletAddress(event.target.value);
    // let v = val;
    // if (Number.isNaN(val)) v = 0;
    // setBudget(roomId, uid!, { numItemsLimitForBids: v });
  };

  async function ticketCheck() {
    const check = httpsCallable(Firebase.getFnsApp(), "ticket-check");
    if (!uid) {
      const userId = `solana-${walletAddress}`;
      return await check({ userId });
    } else {
      return await check();
    }
  }

  async function ticketClaim() {
    const claim = httpsCallable(Firebase.getFnsApp(), "ticket-claim", { timeout: 540000 });
    if (!uid) {
      const userId = `solana-${walletAddress}`;
      return await claim({ userId });
    } else {
      return await claim();
    }
  }

  const [walletAddress, setWalletAddress] = useState('');
  const getTicketCheck = async () => {
    setLoading(true)
    try {
      toast({
        title: "Please wait...",
        description: `We are scanning your wallet for claimable tickets`,
        status: "warning",
        duration: 3000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });

      let { data } = (await ticketCheck()) as { data: any };
      console.log(data)
      setTicketCheck(data)
      setLoading(false);

    } catch (e: any) {
      console.error("error fetching", e);
      setLoading(false);

    }

  };
  useEffect(() => {
    console.log(token)
    if (uid) {
      getTicketCheck();
    }
  }, [uid]);




  const saveWallet = () => {
    let v = walletAddress;
    if (v) {
      try {
        setWalletTicket(uid!, v);
        toast({
          title: "Address saved",
          description: "Thank you for submitting your address.  If you encounter any addition problems, please use the feedback form on the left.",
          status: "success",
          duration: 9000,
          isClosable: true,
          styleConfig: {
            zIndex: 999999,
          },
        });
      } catch (error) {
        console.log(error)
      }
    }
  };
  const claimTicket = async () => {
    setClaim(true);
    try {
      let { data } = (await ticketClaim()) as { data: any };
      if (data?.result == "success") {
        toast({
          title: "Ticket/s Claimed Successfully",
          description: `You have claimed ${data.claimed} ticket/s please check your wallet.`,
          status: "success",
          duration: 9000,
          isClosable: true,
          styleConfig: {
            zIndex: 999999,
          },
        });
        getTicketCheck()
      }
      if (data?.result == "error" && checkValue?.detail?.includes("not eligible")) {
        toast({
          title: "Not eligible",
          description: "One or more Tensorians are required to claim this ticket",
          status: "error",
          duration: 9000,
          isClosable: true,
          styleConfig: {
            zIndex: 999999,
          },
        });
      }
    } catch (e: any) {
      toast({
        title: "There was an issue",
        description: e.message + ". `Please use the feedback button on the left " || "Please Try Again!",
        status: "error",
        duration: 9000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });
      console.error("error fetching", e);
    }
    setClaim(false);

  }
  const ConnectedTicketUI = () => (
    <div className="flex-col  w-full justify-center items-center gap-16 inline-flex">
      <div className="self-stretch flex-col justify-center items-center gap-12 flex">
        <div className="self-stretch flex-col justify-center items-center gap-12 flex">
          <h1 className="text-center text-5xl sm:text-6xl font-bold gradient-text">
            Ticket Dispenser
          </h1>
        </div>
        <div className="text-center">
          <span className="text-gray-200 text-xl font-medium leading-snug">
            You can claim tickets for the following smart auctions
          </span>
        </div>

        {loading ? (
          <Flex
            p={10}
            alignItems={"center"}
            justifyContent={"center"}
            gap={4}
            flexDir="column"
          >
            <Spinner color="#ed6cd8" size={"xl"} emptyColor="#666666" />{" "}
            Loading events...
          </Flex>
        ) : (
          <div className="auction-list">
            {auctions.map((auction: any) => {
              const isLive = auction.startAt < Date.now() / 1000;
              const start = auction.startAt || 0;
              const end = start + (auction.partyDuration || 0);
              const DTstart = DateTime.fromSeconds(start);
              const DTend = DateTime.fromSeconds(end);

              return (
                <div key={auction.id} className="auction-list-item">
                  {getIcon(auction.chain)}
                  <h3>{auction.name}</h3>
                  {isLive && <span className="tag">LIVE</span>}
                  {!isLive && (
                    <div className="room-settings-text time-wrapper">
                      <span className="room-settings-text">Starting in</span>
                      <HandleTime time={auction.startAt} />
                    </div>
                  )}
                  {isLive && (
                    <div className="room-settings-text time-wrapper">
                      <span className="room-settings-text">Ending in</span>
                      <HandleTime time={end} />
                    </div>
                  )}

                  <div className="time-wrapper">
                    <p><span className="room-settings-text">Available to claim:</span> <span className="font-bold text-white">{checkValue?.available}</span></p>
                  </div>
                  <div className="time-wrapper">
                    <p><span className="room-settings-text">Remaining tickets:</span> <span className="font-bold text-white">{checkValue?.total - checkValue?.used} </span></p>
                  </div>


                  {checkValue?.result == "success" && checkValue?.available < checkValue?.total && (
                    <Button
                      className="bar-button"
                      backgroundColor="#6C60FF"
                      onClick={claimTicket}
                      isLoading={claiming}
                      loadingText={"Claiming..."}
                    >
                      Claim
                    </Button>

                  )}
                  {checkValue?.detail?.includes("All tickets claimed") && (
                    <Button disabled={true} >
                      Sold out
                    </Button>
                  )}
                  {checkValue?.result == "error" && checkValue?.detail?.includes("already claimed") && (
                    //   <Button disabled={true}>
                    //    Claimed
                    //  </Button>
                    <div>
                      {isLive && (
                        <Link to={`/rooms/${auction.id}`} className="button">
                          Join
                        </Link>
                      )}

                      {!isLive && (
                        <AddToCalendarButton
                          name={auction.name}
                          options={["Apple", "Google"]}
                          startDate={DTstart.toFormat("yyyy-MM-dd")}
                          endDate={DTend.toFormat("yyyy-MM-dd")}
                          startTime={DTstart.toFormat("HH:mm")}
                          endTime={DTend.toFormat("HH:mm")}
                          location={`${window.location.href}rooms/${auction.id}`}
                          timeZone="UTC"
                          uid={auction.id}
                          size="4|4|4"
                        ></AddToCalendarButton>
                      )}
                    </div>
                  )}

                  {checkValue?.result == "error" && checkValue?.detail?.includes("No eligible nfts") && (
                    <Button disabled={true}>
                      Not eligible
                    </Button>
                  )}
                  {checkValue?.result == "error" && checkValue?.detail?.includes("Eligible nfts exhausted") && (
                    <Button disabled={true}>
                      Already claimed
                    </Button>
                  )}
                </div>
              );
            })}
          </div>)}

        <Flex
          maxWidth={"800px"}
          p={10}
          alignItems={"center"}
          justifyContent={"center"}
          gap={4}
          flexDir="column"
        >
          <div className="text-center">
            <FormLabel htmlFor="first_name">If you encounter an error claiming your ticket(s), please enter your wallet address here and we will airdrop tickets to valid addresses before the event on a first come, first served basis.</FormLabel>

            <div className="spending-limit">

              <Input
                style={{ marginTop: "0px" }}
                placeholder="Paste your wallet address here..."
                type="text"
                name="name"
                id="name"
                mt={1}
                w="full"
                shadow="sm"
                rounded="md"
                value={walletAddress}
                onChange={handleChange}
              />

              <div className="btndiv">
                <Button
                  variant="unstyled"
                  className="save-wallet-btn rounded-tr-md rounded-br-md"
                  isDisabled={!walletAddress}
                  _disabled={{ opacity: 0.6 }}
                  onClick={saveWallet}
                >
                  Send Address
                </Button>

              </div>
            </div>
          </div>
        </Flex>

        <Flex
          maxWidth={"600px"}>
          <Alert
            status="info"
            className="info-alert"
            background={
              "linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"

            }
            borderRadius={"4px"}
          >
            <AlertIcon color={"#6C60FF"} />
            <AlertDescription>
              {"This product is still in beta.  If you encounter any problems, please use the feedback form on the left."}
            </AlertDescription>
          </Alert>
        </Flex>
      </div>

    </div>
  );
  const isObjectEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };
  const NotConnectedTicketUI = () => (
    <div className="flex-col  w-full justify-center items-center gap-16 inline-flex">
      <div className="self-stretch flex-col justify-center items-center gap-12 flex">
        <div className="self-stretch flex-col justify-center items-center gap-12 flex">
          <h1 className="text-center text-5xl sm:text-6xl font-bold gradient-text">
            Ticket Dispenser
          </h1>
        </div>
        <div className="text-center">
          <span className="text-gray-200 text-xl font-medium leading-snug">
            Please connect your wallet or paste your wallet address below to scan for claimable tickets.
          </span>
        </div>

        <Flex
          maxWidth={"800px"}
          p={10}
          alignItems={"center"}
          justifyContent={"center"}
          gap={4}
          flexDir="column"
        >
          <div className="spending-limit">

            <Input
              style={{ marginTop: "0px", width: "500px" }}
              placeholder="Paste your wallet address here..."
              type="text"
              name="name"
              mt={1}
              w="max"
              shadow="sm"
              rounded="md"
              value={walletAddress}
              onChange={handleChange}
            />

            <div className="btndiv">
              <Button
                variant="unstyled"
                className="save-wallet-btn rounded-tr-md rounded-br-md"
                isDisabled={walletAddress == ''}
                _disabled={{ opacity: 0.6 }}
                onClick={getTicketCheck}
              >
                Scan
              </Button>

            </div>
          </div>
        </Flex>
        {walletAddress &&
          <div>{loading ? (
            <Flex
              p={10}
              alignItems={"center"}
              justifyContent={"center"}
              gap={4}
              flexDir="column"
            >
              <Spinner color="#ed6cd8" size={"xl"} emptyColor="#666666" />{" "}
              Loading events...
            </Flex>
          ) : !isObjectEmpty(checkValue) && (
            <div className="auction-list">

              {auctions.map((auction: any) => {
                const isLive = auction.startAt < Date.now() / 1000;
                const start = auction.startAt || 0;
                const end = start + (auction.partyDuration || 0);
                const DTstart = DateTime.fromSeconds(start);
                const DTend = DateTime.fromSeconds(end);

                return (
                  <div key={auction.id} className="auction-list-item">
                    {getIcon(auction.chain)}
                    <h3>{auction.name}</h3>
                    {isLive && <span className="tag">LIVE</span>}
                    {!isLive && (
                      <div className="time-wrapper">
                        <p>Starting in</p>
                        <HandleTime time={auction.startAt} />
                      </div>
                    )}
                    {isLive && (
                      <div className="time-wrapper">
                        <p>Ending in</p>
                        <HandleTime time={auction.endsAt} />
                      </div>
                    )}

                    <div className="time-wrapper">
                      <p><span>Available to claim:</span> <span className="font-bold">{checkValue?.available}</span></p>
                    </div>
                    <div className="time-wrapper">
                      <p><span>Remaining tickets:</span> <span className="font-bold">{checkValue?.total - checkValue?.used} </span></p>
                    </div>


                    {checkValue?.result == "success" && checkValue?.available < checkValue?.total && (
                      <Button
                        className="bar-button"
                        backgroundColor="#6C60FF"
                        onClick={claimTicket}
                        isLoading={claiming}
                        loadingText={"Claiming..."}
                      >
                        Claim
                      </Button>

                    )}
                    {checkValue?.detail?.includes("All tickets claimed") && (
                      <Button disabled={true} >
                        Sold out
                      </Button>
                    )}
                    {checkValue?.result == "error" && checkValue?.detail?.includes("already claimed") && (
                      //   <Button disabled={true}>
                      //    Claimed
                      //  </Button>
                      <div>
                        {isLive && (
                          <Link to={`/rooms/${auction.id}`} className="button">
                            Join
                          </Link>
                        )}

                        {!isLive && (
                          <AddToCalendarButton
                            name={auction.name}
                            options={["Apple", "Google"]}
                            startDate={DTstart.toFormat("yyyy-MM-dd")}
                            endDate={DTend.toFormat("yyyy-MM-dd")}
                            startTime={DTstart.toFormat("HH:mm")}
                            endTime={DTend.toFormat("HH:mm")}
                            location={`${window.location.href}rooms/${auction.id}`}
                            timeZone="UTC"
                            uid={auction.id}
                            size="4|4|4"
                          ></AddToCalendarButton>
                        )}
                      </div>
                    )}

                    {checkValue?.result == "error" && checkValue?.detail?.includes("No eligible nfts") && (
                      <Button disabled={true}>
                        Not eligible
                      </Button>
                    )}
                    {checkValue?.result == "error" && checkValue?.detail?.includes("Eligible nfts exhausted") && (
                      <Button disabled={true}>
                        Already claimed
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>)}
          </div>
        }


        <Flex
          maxWidth={"600px"}>
          <Alert
            status="info"
            className="info-alert"
            background={
              "linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"

            }
            borderRadius={"4px"}
          >
            <AlertIcon color={"#6C60FF"} />
            <AlertDescription>
              {"This product is still in beta.  If you encounter any problems, please use the feedback form on the left."}
            </AlertDescription>
          </Alert>
        </Flex>

      </div>

    </div>
  );
  return (
    <>
      <Helmet>
        <title>Tulle | Ticket Dispenser</title>
      </Helmet>
      <Center>
        <Container maxW="5xl">
          <div className="flex flex-col-reverse lg:flex-row justify-center items-center gap-6   lg:justify-between pt-10">
            {uid &&
              <ConnectedTicketUI />
            }
            {!uid &&
              <NotConnectedTicketUI />
            }
          </div>
        </Container>
      </Center>
    </>
  );
}
