import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { memo } from "react";
import IconPurpleEye from "../Icons/IconPurpleEye";
import { Tooltip } from "react-tooltip";
import { CiUnlock } from "react-icons/ci";
import IconLock from "../Icons/IconLock";
import { BiddableNft, createPlaceLabel } from "../../contexts/roomContext";
import QuickActions from "./QuickActions";
import { createFloor, getReserveLabel, roundValue, formatButtonLabel } from "../../utils";
import BiddingError from "./BiddingError";
import IconTrash from "../Icons/IconTrash";

interface Props {
  nft: BiddableNft;
  finalMinute: boolean;
  min: number;
  max: number;
  budget: number;
  isHighBidder: boolean;
  isTied: boolean;
  hasBid: boolean;
  place: number;
  highestBidAmount: number;
  total: number;
  abbr: string;
  truncate: number;
  isSilentAuction: boolean;
  decimals: number;
  isSecretReserve: boolean;
  isBidReserveMet: boolean;
  incrementPct: number;
  incrementToken: number;
  startingBid: number;
  handleBid: (value: string | number | undefined, ignoreCheck?: boolean) => Promise<void>;
  floor: number;
  setAsIncrementAboveHighestBid: () => void;
  bidVal: number | string | undefined;
  canBid: boolean;
  onClose: () => void;
  cancel: () => Promise<void>;
  step: string;
  isOpen: boolean;
  setBidVal: (value: number | string) => void;
  bidMaxBudget: () => Promise<void>;
  error: string | undefined;
  setBudgetAndBid: (value: number) => Promise<void>;
  isOrdinals: boolean;
  suggestedPrice: string;
}

const BiddingTab = ({
  nft,
  finalMinute,
  min,
  max,
  budget,
  isHighBidder,
  isTied,
  hasBid,
  place,
  highestBidAmount,
  total,
  abbr,
  truncate,
  isSilentAuction,
  decimals,
  isSecretReserve,
  isBidReserveMet,
  incrementPct,
  incrementToken,
  startingBid,
  handleBid,
  floor,
  setAsIncrementAboveHighestBid,
  bidVal,
  canBid,
  onClose,
  cancel,
  step,
  isOpen,
  setBidVal,
  bidMaxBudget,
  error,
  setBudgetAndBid,
  isOrdinals,
  suggestedPrice,
}: Props) => {
  const renderPriceInput = (
    label: string,
    value: number | string | undefined,
    onChange: (value: string) => void,
    tooltip: string,
    min: number,
    max?: number
  ) => (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">{label}</span>
        <a
          data-tooltip-id="bidding-tooltip"
          data-tooltip-content={tooltip}
          data-tooltip-place="right"
        >
          <div className="h-4 w-4 text-gray-400">
            <IconPurpleEye />
          </div>
        </a>
      </div>
      <div className="relative">
        <input
          type="number"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          min={min}
          max={max}
          className="w-full rounded-lg border border-gray-300 bg-white px-4 py-2 text-gray-900 
            focus:border-primary focus:ring-2 focus:ring-primary dark:border-gray-600 
            dark:bg-gray-700 dark:text-white dark:focus:border-primary"
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-sm text-gray-500 dark:text-gray-400">{abbr}</span>
        </div>
      </div>
    </div>
  );

  const renderQuickSetButton = (
    label: string,
    onClick: () => void,
    description?: string
  ) => (
    <button
      onClick={onClick}
      className="flex w-full items-center justify-between rounded-lg border border-gray-200 
        bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 
        dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
    >
      <div className="flex flex-col items-start">
        <span>{label}</span>
        {description && (
          <span className="text-xs text-gray-500 dark:text-gray-400">{description}</span>
        )}
      </div>
    </button>
  );

  return (
    <div className="space-y-6">
      {/* Status Messages */}
      {error && (
        <div className="rounded-lg bg-red-50 p-4 dark:bg-red-900/20">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3 flex-1">
              <p className="text-sm text-red-700 dark:text-red-200">{error}</p>
              {error.includes("exceeds the available") && (
                <button
                  onClick={bidMaxBudget}
                  className="mt-2 inline-flex items-center rounded-lg bg-primary px-3 py-2 
                    text-sm font-semibold text-white shadow-sm hover:bg-primary-dark 
                    focus-visible:outline focus-visible:outline-2 
                    focus-visible:outline-offset-2 focus-visible:outline-primary"
                >
                  Increase Spending Limit
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {isHighBidder && !isTied && (
        <div className="rounded-lg bg-green-50 p-4 dark:bg-green-900/20">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-green-700 dark:text-green-200">
                You are the highest bidder!
              </p>
            </div>
          </div>
        </div>
      )}

      {isTied && (
        <div className="rounded-lg bg-yellow-50 p-4 dark:bg-yellow-900/20">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.485 2.495c.873-1.512 3.157-1.512 4.03 0l4.933 8.545c.873 1.512-.218 3.41-2.015 3.41H5.567c-1.797 0-2.888-1.898-2.015-3.41l4.933-8.545zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700 dark:text-yellow-200">
                You are tied for the highest bid!
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Bid Input Section */}
      <div className="space-y-6">
        {renderPriceInput(
          "Your Bid",
          bidVal,
          (value) => setBidVal(value),
          "Enter your bid amount",
          min,
          max
        )}

        {/* Quick Set Options */}
        <div className="space-y-2">
          <h4 className="text-sm font-medium text-gray-900 dark:text-white">
            Quick Set Options
          </h4>
          <div className="grid grid-cols-1 gap-2">
            {renderQuickSetButton(
              "Minimum Bid",
              () => handleBid(min),
              `Set bid to ${min} ${abbr}`
            )}
            {highestBidAmount > 0 && renderQuickSetButton(
              "Outbid Current Highest",
              setAsIncrementAboveHighestBid,
              `Increase bid above ${highestBidAmount / 10 ** decimals} ${abbr}`
            )}
            {renderQuickSetButton(
              "Maximum Budget",
              bidMaxBudget,
              `Set bid to your maximum budget of ${max / 10 ** decimals} ${abbr}`
            )}
          </div>
        </div>

        {/* Bid Information */}
        {hasBid && (
          <div className="space-y-2">
            <h4 className="text-sm font-medium text-gray-900 dark:text-white">
              Your Bid Status
            </h4>
            <div className="rounded-lg bg-gray-50 p-4 dark:bg-gray-800">
              <div className="flex justify-between">
                <span className="text-sm text-gray-500 dark:text-gray-400">Current Position</span>
                <span className="text-sm font-medium text-gray-900 dark:text-white">
                  {place} of {total}
                </span>
              </div>
              {!isSecretReserve && (
                <div className="mt-2 flex justify-between">
                  <span className="text-sm text-gray-500 dark:text-gray-400">Reserve Met</span>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">
                    {isBidReserveMet ? "Yes" : "No"}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(BiddingTab);
