import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '../contexts/ThemeContext';
import { FaTwitter, FaCopy } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import { Blink, useBlink } from '@dialectlabs/blinks';
import { useBlinkSolanaWalletAdapter } from '@dialectlabs/blinks/hooks/solana';
import '@dialectlabs/blinks/index.css';
import SignInButton from '../components/SignInButton';
import { useChain } from '../contexts/chainsContext';
import { useAppContext } from '../contexts/appContext';

// Override authentication requirement for this page
export const SKIP_AUTH_CHECK = true;

// Define constants to match the React Native version
const BlockchainIds = {
  SOLANA_MAINNET: 'solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp',
  SOLANA_DEVNET: 'solana:devnet',
  SOLANA_TESTNET: 'solana:testnet',
  ETHEREUM_MAINNET: 'ethereum:1',
  MONAD_DEVNET: 'monad:devnet'
};

interface BlinkViewerProps {
  blinkUrl: string;
  onUpdateUrl: (url: string) => void;
}

const BlinkViewer: React.FC<BlinkViewerProps> = ({ blinkUrl, onUpdateUrl }) => {
  const { isDarkMode } = useTheme();
  const [inputUrl, setInputUrl] = useState(blinkUrl);
  const [error, setError] = useState<string | null>(null);
  const { uid } = useAppContext();
  const { setChain } = useChain();
  const [detectedWallets, setDetectedWallets] = useState<string[]>([]);
  const [connectionError, setConnectionError] = useState<string | null>(null);

  // Add effect to detect installed wallet extensions
  useEffect(() => {
    const detectWallets = async () => {
      const detected: string[] = [];

      // Check for Phantom
      if (typeof window !== 'undefined' && 'phantom' in window && window.phantom?.solana?.isPhantom) {
        detected.push('phantom');
      }

      // Check for Solflare
      if (typeof window !== 'undefined' && 'solflare' in window && window.solflare?.isSolflare) {
        detected.push('solflare');
      }

      // Check for Backpack (typically exposed as 'backpack' or has a specific detection method)
      if (typeof window !== 'undefined' &&
        ('backpack' in window ||
          (window.solana?.isBackpack) ||
          document.querySelector('backpack-extension-injected'))) {
        detected.push('backpack');
      }

      console.log("Detected wallets:", detected);
      setDetectedWallets(detected);
    };

    detectWallets();
  }, []);

  // Get the Solana RPC URL
  const solanaRpcUrl = process.env.REACT_APP_SOLANA_NETWORK || 'https://api.mainnet-beta.solana.com';

  // Use the official Dialect hooks
  const { adapter } = useBlinkSolanaWalletAdapter(solanaRpcUrl);
  const { blink, isLoading } = useBlink({
    url: blinkUrl
  });

  // Set chain to Solana when component mounts
  useEffect(() => {
    setChain('solana');
  }, [setChain]);

  // Update error state based on blink loading status
  useEffect(() => {
    if (!blinkUrl) {
      setError('No blink URL provided');
      return;
    }

    if (!blink && !isLoading) {
      setError('Failed to load blink: The blink could not be loaded');
    } else {
      setError(null);
    }
  }, [blinkUrl, blink, isLoading]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success('Blink link copied to clipboard!');
  };

  const handleShareOnX = () => {
    const text = `Check out this Solana Blink! #Solana #Web3`;
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(window.location.href)}`, '_blank');
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleUpdateUrl();
    }
  };

  const handleUpdateUrl = () => {
    if (!inputUrl) {
      toast.error('Please enter a URL');
      return;
    }

    // Try to fix URL format if needed
    const correctedUrl = fixUrlFormat(inputUrl);

    if (!isValidUrl(correctedUrl)) {
      setError('Invalid URL format. Please enter a complete URL including http:// or https://');
      toast.error('Invalid URL format');
      return;
    }

    if (correctedUrl !== inputUrl) {
      setInputUrl(correctedUrl);
      toast.success('URL format corrected', { duration: 2000 });
    }

    if (correctedUrl !== blinkUrl) {
      onUpdateUrl(correctedUrl);
    }
  };

  // Helper to check and fix URL format
  const fixUrlFormat = (url: string): string => {
    let fixedUrl = url.trim();

    if (!fixedUrl.startsWith('http://') && !fixedUrl.startsWith('https://')) {
      fixedUrl = 'https://' + fixedUrl;
    }

    try {
      new URL(fixedUrl);
      return fixedUrl;
    } catch {
      return url;
    }
  };

  // Helper to validate URL format
  const isValidUrl = (url: string): boolean => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  return (
    <>
      <Helmet>
        <title>Star Atlas Blinks Viewer | Tulle</title>
        <meta name="description" content="View Solana blinks" />
        <meta name="blink-url" content={blinkUrl} />
      </Helmet>

      <Toaster position="top-right" />

      <div className="container mx-auto px-4 py-8 mt-16">
        <div className="mb-8 max-w-4xl mx-auto">
          <h1 className={`text-3xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Star Atlas Blinks Viewer
          </h1>
          <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2 mb-4">
            <input
              type="text"
              value={inputUrl}
              onChange={(e) => setInputUrl(e.target.value)}
              onKeyDown={handleInputKeyDown}
              className={`w-full p-2 rounded-lg border ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-900'}`}
              placeholder="Enter blink URL and press Enter..."
            />
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <LoadingSpinner />
          </div>
        ) : error ? (
          <div className="text-center py-8">
            <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg p-6 max-w-lg mx-auto">
              <div className="flex flex-col items-center">
                <svg className="w-12 h-12 text-red-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
                <h3 className="text-lg font-semibold text-red-700 dark:text-red-400 mb-2">Error Loading Blink</h3>
                <p className="text-red-600 dark:text-red-300 mb-4">{error}</p>
                <div className="bg-white dark:bg-gray-800 p-3 rounded border border-gray-200 dark:border-gray-700 w-full mb-4">
                  <p className="text-sm text-gray-600 dark:text-gray-400 mb-1">Example of a valid URL:</p>
                  <code className="text-xs bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded break-all">https://dial.to/donate</code>
                </div>
              </div>
            </div>
          </div>
        ) : !uid ? (
          <div className="bg-white dark:bg-gray-800 rounded-xl border border-gray-200 dark:border-gray-700 shadow-md overflow-hidden p-6 max-w-lg mx-auto">
            <div className="flex flex-col items-center text-center mb-4">
              <svg className="w-16 h-16 text-primary mb-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
              </svg>
              <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">Wallet Connection Required</h3>
              <p className="text-gray-600 dark:text-gray-300 max-w-md">
                Connect your wallet to interact with this blink.
              </p>
            </div>

            {connectionError && (
              <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg text-sm text-red-600 dark:text-red-400">
                <div className="flex items-center">
                  <svg className="w-5 h-5 mr-2 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                  {connectionError}
                </div>
              </div>
            )}

            <div className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-4 mb-6">
              <h4 className="font-medium text-blue-800 dark:text-blue-300 mb-2 text-sm">
                Available Wallets
              </h4>

              <div className="grid grid-cols-3 gap-2 mt-3">
                <a
                  href="https://phantom.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`flex flex-col items-center gap-2 p-3 rounded border ${detectedWallets.includes('phantom')
                    ? 'bg-white dark:bg-gray-700 border-blue-200 dark:border-blue-800 hover:bg-gray-50 dark:hover:bg-gray-600'
                    : 'bg-gray-100 dark:bg-gray-800 border-gray-200 dark:border-gray-700'} 
                    transition-colors text-sm`}
                >
                  <svg className="w-10 h-10 text-[#AB9FF2]" viewBox="0 0 200 180" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M89.1138 112.613C83.1715 121.719 73.2139 133.243 59.9641 133.243C53.7005 133.243 47.6777 130.665 47.6775 119.464C47.677 90.9369 86.6235 46.777 122.76 46.7764C143.317 46.776 151.509 61.0389 151.509 77.2361C151.509 98.0264 138.018 121.799 124.608 121.799C120.352 121.799 118.264 119.462 118.264 115.756C118.264 114.789 118.424 113.741 118.746 112.613C114.168 120.429 105.335 127.683 97.0638 127.683C91.0411 127.683 87.9898 123.895 87.9897 118.576C87.9897 116.642 88.3912 114.628 89.1138 112.613ZM115.936 68.7103C112.665 68.7161 110.435 71.4952 110.442 75.4598C110.449 79.4244 112.689 82.275 115.96 82.2693C119.152 82.2636 121.381 79.4052 121.374 75.4405C121.367 71.4759 119.128 68.7047 115.936 68.7103ZM133.287 68.6914C130.016 68.6972 127.786 71.4763 127.793 75.4409C127.8 79.4055 130.039 82.2561 133.311 82.2504C136.503 82.2448 138.732 79.3863 138.725 75.4216C138.718 71.457 136.479 68.6858 133.287 68.6914Z" fill="currentColor" />
                  </svg>
                  <span className="font-medium text-gray-700 dark:text-gray-200">Phantom</span>
                  {detectedWallets.includes('phantom') ? (
                    <span className="text-xs text-green-600 dark:text-green-400">Detected</span>
                  ) : (
                    <span className="text-xs text-gray-500 dark:text-gray-400">Not Found</span>
                  )}
                </a>
                <a
                  href="https://solflare.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`flex flex-col items-center gap-2 p-3 rounded border ${detectedWallets.includes('solflare')
                    ? 'bg-white dark:bg-gray-700 border-blue-200 dark:border-blue-800 hover:bg-gray-50 dark:hover:bg-gray-600'
                    : 'bg-gray-100 dark:bg-gray-800 border-gray-200 dark:border-gray-700'} 
                    transition-colors text-sm`}
                >
                  <img src="https://www.solflare.com/wp-content/uploads/2024/11/App-Icon.svg" alt="Solflare" className="w-8 h-8" />
                  <span className="font-medium text-gray-700 dark:text-gray-200">Solflare</span>
                  {detectedWallets.includes('solflare') ? (
                    <span className="text-xs text-green-600 dark:text-green-400">Detected</span>
                  ) : (
                    <span className="text-xs text-gray-500 dark:text-gray-400">Not Found</span>
                  )}
                </a>
                <a
                  href="https://backpack.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`flex flex-col items-center gap-2 p-3 rounded border ${detectedWallets.includes('backpack')
                    ? 'bg-white dark:bg-gray-700 border-blue-200 dark:border-blue-800 hover:bg-gray-50 dark:hover:bg-gray-600'
                    : 'bg-gray-100 dark:bg-gray-800 border-gray-200 dark:border-gray-700'} 
                    transition-colors text-sm`}
                >
                  <img src="https://backpack.app/icon/128x128.png" alt="Backpack" className="w-8 h-8" />
                  <span className="font-medium text-gray-700 dark:text-gray-200">Backpack</span>
                  {detectedWallets.includes('backpack') ? (
                    <span className="text-xs text-green-600 dark:text-green-400">Detected</span>
                  ) : (
                    <span className="text-xs text-gray-500 dark:text-gray-400">Not Found</span>
                  )}
                </a>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center my-6 gap-4">
              <SignInButton version="blink-page" />
            </div>

            <div className="text-xs text-gray-500 dark:text-gray-400 text-center">
              <p>After installing a wallet, refresh this page and click "Connect Wallet"</p>
            </div>
          </div>
        ) : blink ? (
          <div className="max-w-4xl mx-auto">
            <div className={`p-6 rounded-lg border ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} shadow-lg`}>
              <div className="blink-wrapper min-h-[400px] max-w-xl mx-auto">
                <div className="max-w-[500px] mx-auto">
                  {blink && adapter && (
                    <Blink
                      blink={blink}
                      adapter={adapter}
                      securityLevel="all"
                      stylePreset={isDarkMode ? "x-dark" : "default"}
                    />
                  )}
                </div>

                {/* Sharing options */}
                <div className="mt-6 pt-4 border-t border-gray-200 dark:border-gray-700 flex justify-center">
                  <div className="flex gap-2">
                    <button
                      onClick={handleShareOnX}
                      className="flex items-center justify-center gap-1 px-3 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors text-sm"
                    >
                      <FaTwitter />
                      <span>Share on X</span>
                    </button>
                    <button
                      onClick={handleCopyLink}
                      className={`flex items-center justify-center gap-1 px-3 py-2 rounded-lg text-sm ${isDarkMode
                        ? 'bg-gray-700 text-white hover:bg-gray-600'
                        : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                        } transition-colors`}
                    >
                      <FaCopy />
                      <span>Copy Link</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={`p-8 rounded-lg border ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-gray-50 border-gray-200'} text-center`}>
            <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              No blink data loaded. Enter a URL and click Update to get started.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

// Parse a blink action URL
const parseBlinkUrl = (actionParam: string | null): string => {
  const defaultBlinksUrl = "https://dial.to/donate";

  if (!actionParam) return defaultBlinksUrl;

  try {
    // If it's already a URL, validate it
    try {
      new URL(actionParam);
      return actionParam;
    } catch {
      return defaultBlinksUrl;
    }
  } catch {
    return defaultBlinksUrl;
  }
};

// Define the window interface properly
declare global {
  interface Window {
    // For Phantom
    phantom?: any;
    // For Solflare
    solflare?: any;
    // For Backpack
    backpack?: any;
    // Generic Solana property that might be provided by any wallet
    solana?: any;
  }
}

// This component is a wrapper that handles URL parameters
const BlinkPage: React.FC = () => {
  const [blinkUrl, setBlinkUrl] = useState<string>("");
  const { setChain } = useChain();

  // Set chain to Solana as soon as the page loads
  useEffect(() => {
    setChain('solana');
  }, [setChain]);

  useEffect(() => {
    // Get action parameter from URL
    const searchParams = new URLSearchParams(window.location.search);
    const actionParam = searchParams.get('action');

    // Use the action parameter or default URL
    const resolvedUrl = actionParam ? parseBlinkUrl(actionParam) : "https://dial.to/donate";
    setBlinkUrl(resolvedUrl);
  }, []);

  const handleUpdateUrl = (newUrl: string) => {
    setBlinkUrl(newUrl);

    // Update the URL in the browser with the simple format
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('action', newUrl);
    window.history.pushState(null, '', currentUrl.toString());
  };

  if (!blinkUrl) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return <BlinkViewer blinkUrl={blinkUrl} onUpdateUrl={handleUpdateUrl} />;
};

export default BlinkPage;
