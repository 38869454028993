import {
  Button,
  Box,
  Text,
  HStack,
  IconButton,
  Image,
  Heading,
  Spinner,
  VStack,
  Flex,
  Alert,
  AlertIcon,
  AlertDescription,
  Skeleton,
  useToast,
  ToastId,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { FaGift } from "react-icons/fa";
import { CiUnlock } from "react-icons/ci";
import IconLock from "../../components/Icons/IconLock";
import IconPurpleEye from "../../components/Icons/IconPurpleEye";
import { cancelBid, saveProgress } from "../../services/room.service";
import { useAppContext } from "../../contexts/appContext";
import SecretBidModal from "../../components/SecretBidModal";
import { setBudget as setBudgetService } from "../../services/room.service";
import {
  capitalizeFirstLetter,
  createFloor,
  filterCompleted,
  getReserveLabel,
  getTimeLeft,
  roundValue,
} from "../../utils";
import {
  BiddableNft,
  getHighestBid,
  Nft,
  Room,
  useRoom,
} from "../../contexts/roomContext";
import { Blockchain } from "../../components/AiTradesV2/types";
import { BiddingCarousel } from "../../components/BiddingCarousel";
import ListedNfts from "../../components/ListedNfts";
import { createColumnHelper } from "@tanstack/react-table";
import BidCell from "../../components/BiddingDataTable/BidCell";
import ReserveCell from "../../components/Table/ReserveCell";
import ImageCell from "../../components/Table/ImageCell";
import NftCollection from "../../components/NftCollection";
import BiddingGridItem from "../../components/NftCollection/BiddingGridItem";
import IconCurveArrow from "../../components/Icons/IconCurveArrow";
import ReservePriceForm from "../../components/ReservePriceForm/ReservePriceForm";
import { FaExclamationTriangle, FaTrashAlt } from "react-icons/fa";
import IconRefresh from "../../components/Icons/IconRefresh";
import PendingImg from "../../assets/pending.jpg";
import IconTrash from "../../components/Icons/IconTrash";
import IconEye from "../../components/Icons/IconEye";
import LastRun from "../../components/LastRun";
import BudgetCard from "../../components/BudgetCard";
import NumItemsLimit from "../../components/NumItemsLimit";
// import SmartAuctions from "../../components/SmartAuctions";
import SidebarListItem from "../../components/Sidebar/Parts/SidebarListItem";
import useNeoChain from "../../hooks/useNeoChain";
import { useChain } from "../../contexts/chainsContext";
import { Link } from "react-router-dom";
import useIsLive from "../../hooks/useIsLive";
import ContactMethodModal from "../../components/ContactMethodModal";
import useTimeLeft from "../../hooks/useTimeLeft";
import TestComponent from "../../components/TestComponent";
import TokenGated from "../../components/TokenGated";
import { XMarkIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { CgSearchFound } from "react-icons/cg";
import BiddingModal from "../../components/BiddingModal";

interface Props {
  roomId: string;
  uid: string;
  isAdmin: boolean;
  markupPercent?: number;
}

const columnHelper = createColumnHelper<any>();

const AuctionStatus = (props: any) => {
  const id = props.row.original.itemId;
  const { uid } = useAppContext();
  const { highestBids, bids } = useRoom();
  const bid = bids?.[id]?.bidPrice;
  const hasBid = typeof bid === "number";
  const { isHighBidder, highestBidAmount, isTied } = getHighestBid(
    highestBids,
    id,
    uid
  );

  return (
    <HStack>
      {isTied && <Text color="yellow">Tied</Text>}
      {isHighBidder && !isTied && <Text color="green">Highest bid</Text>}
      {!isHighBidder && highestBidAmount && hasBid && (
        <Text color="red">Outbid</Text>
      )}
    </HStack>
  );
};

const columns = [
  columnHelper.accessor("id", {
    cell: ImageCell,
    header: "",
  }),
  columnHelper.accessor("collectionName", {
    cell: (info) => {
      return info.row.original.collection.name;
    },
    header: "Collection Name",
  }),
  columnHelper.accessor("collectionFloorPrice", {
    cell: (info) => {
      return createFloor(info.row.original);
    },
    header: "Floor Price",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("collection.verified.spamScore", {
    header: "Spam Score",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("reservePrice", {
    cell: ReserveCell,
    header: "Reserve",
    meta: {
      isNumeric: true,
    },
  }),
  {
    cell: AuctionStatus,
    header: "Auction Status",
  },
  {
    header: "Set Bid Price",
    meta: {
      isNumeric: true,
    },
    cell: BidCell,
  },
];

const formatName = (name: string) => {
  // if name is longer than 10 characters, truncate it and add ellipsis
  if (name.length > 10) {
    return name.slice(0, 15) + "...";
  } else {
    return name;
  }
};

const Bids = ({ handleRemove }: { handleRemove: (id: string) => void }) => {
  const { uid } = useAppContext();
  const { bids, preview } = useRoom();
  const winning = preview?.results?.[uid!]?.get;
  const bidIds = Object.keys(bids);
  const losingIds = bidIds.filter((id) => !winning?.includes(id));
  const winningIds = bidIds.filter((id) => winning?.includes(id));
  return (
    <div>
      {bids && Object.keys(bids).length > 0 ? (
        <>
          {winningIds.length > 0 && (
            <>
              <div className="flex items-center gap-2 mt-4 mb-2 text-green-600 font-medium">
                <span className="w-2 h-2 rounded-full bg-green-500" />
                Winning
              </div>
              {Object.keys(bids)
                .filter((id) => winning.includes(id))
                .map((id) => {
                  return (
                    <BidItem
                      id={id}
                      key={id}
                      handleRemove={handleRemove}
                      bidAmount={bids[id].bidPrice}
                      isWinning
                      status="winning"
                    />
                  );
                })}
            </>
          )}

          {losingIds.length > 0 && (
            <>
              {" "}
              <div className="flex items-center gap-2 mt-4 mb-2 text-red-600 font-medium">
                <span className="w-2 h-2 rounded-full bg-red-500" />
                Not Winning
              </div>

              {winningIds.length == 0 && (
                <Alert
                  status="info"
                  className="info-alert"
                  background="linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"
                  borderRadius={"4px"}
                  mb={"12px"}
                >
                  <AlertIcon color={"#6C60FF"} />
                  <AlertDescription className="text-gray-700 dark:text-gray-200">
                    You are currently not winning any items. Consider bidding on more items or increasing your bids.
                  </AlertDescription>
                </Alert>
              )}
              {losingIds.map((id) => {
                return (
                  <BidItem
                    id={id}
                    key={id}
                    handleRemove={handleRemove}
                    bidAmount={bids[id].bidPrice}
                    status="notWinning"
                  />
                );
              })}
            </>
          )}
        </>
      ) : (
        <p className="no-bids-message text-gray-700 dark:text-gray-400">
          To get started, bid on some items and they will appear here.
        </p>
      )}
    </div>
  );
};

const TheListings = () => {
  const { uid } = useAppContext();
  const {
    preview,
    listings = {},
    getAvailableToList,
    availableToList,
    nftsLoading,
    ...rest
  } = useRoom();
  const listingIds = Object.keys(listings);

  const sellingIds: string[] = Object.keys(preview?.results || {}).reduce(
    (acc: string[], sellerId) => {
      if (sellerId !== uid) {
        acc.push(...preview.results[sellerId].get);
      }
      return acc;
    },
    []
  );
  const userSellingIds = listingIds.filter((id: string) =>
    sellingIds.includes(id)
  );
  const userUnsoldIds = listingIds.filter(
    (id: string) => !sellingIds.includes(id)
  );

  useEffect(() => {
    getAvailableToList();
  }, []);

  const getNftItem = (id: string, allowEditRemove?: boolean, status?: "selling" | "notSelling") => {
    const nft = availableToList?.find((ele) => ele.itemId == id);

    const decimals = nft && nft.collection?.floorPrice?.allPrices && nft.collection.floorPrice.allPrices.length > 0
      ? nft.collection.floorPrice.allPrices[0]?.decimals
      : 0;
    const abbr = nft && nft.collection?.floorPrice?.allPrices && nft.collection.floorPrice.allPrices.length > 0
      ? nft.collection.floorPrice.allPrices[0]?.symbol
      : '';

    if (!nft && !nftsLoading) return;
    return (
      <Skeleton isLoaded={!!nft} w={"100%"} h={!!nft ? "auto" : "40px"}>
        <SidebarListItem
          key={id}
          nft={nft}
          decimals={decimals}
          abbr={abbr}
          // handleRemove={handleRemove}
          handleRemove={() => { }}
          allowEditRemove={allowEditRemove}
          id={id}
          status={status}
        />
      </Skeleton>
    );
  };

  const timeLeft = useTimeLeft();
  const finalMinute = timeLeft < 60;

  return (
    <Flex direction={"column"} gap={"7px"}>
      <Alert
        status="info"
        className="info-alert"
        backgroundColor={finalMinute ? "#e34619" : ""}
        background={
          !finalMinute
            ? "linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"
            : ""
        }
        borderRadius={"4px"}
      >
        <AlertIcon color={"#6C60FF"} />
        <AlertDescription className="text-gray-700 dark:text-gray-200">
          {finalMinute
            ? "Please place your final bids before the timer runs out. An additional 60 seconds will be added to the auction for each new high bid."
            : "The solver will predict results every 60 seconds."}
        </AlertDescription>
      </Alert>
      <div className="update-status">
        <LastRun align="left" />
      </div>
      {listings && Object.keys(listings).length > 0 ? (
        <>
          {userSellingIds?.length > 0 && (
            <>
              <div className="flex items-center gap-2 mt-4 mb-2 text-green-700 dark:text-green-400 font-medium">
                <span className="w-2 h-2 rounded-full bg-green-500 dark:bg-green-400" />
                Selling
              </div>
              {userSellingIds.map((id) => (
                <div key={`selling-${id}`}>
                  {getNftItem(id, true, "selling")}
                </div>
              ))}
            </>
          )}

          {userUnsoldIds?.length > 0 && (
            <>
              <div className="flex items-center gap-2 mt-4 mb-2 text-red-700 dark:text-red-400 font-medium">
                <span className="w-2 h-2 rounded-full bg-red-500 dark:bg-red-400" />
                Not Selling
              </div>
              {userUnsoldIds.map((id) => (
                <div key={`unsold-${id}`}>
                  {getNftItem(id, true, "notSelling")}
                </div>
              ))}
            </>
          )}
        </>
      ) : (
        <p className="no-bids-message text-gray-700 dark:text-gray-400">
          To get started, bid on some items and they will appear here.
        </p>
      )}
    </Flex>
  );
};

const BidItem = ({
  id,
  handleRemove,
  bidAmount,
  isWinning,
  type,
  status,
}: {
  id: string;
  handleRemove: (id: string) => void;
  bidAmount: number;
  isWinning?: boolean;
  type?: "bid" | "winloss";
  status?: "winning" | "notWinning";
}) => {
  const { uid } = useAppContext();
  const {
    availableForBid,
    setModalId,
    decimals,
    abbr,
    truncate,
    room,
    highestBids,
    isAdmin,
    isSilentAuction,
    canList,
  } = useRoom();

  const nft = availableForBid?.find((nft) => nft.itemId === id);
  const reservePrice = nft?.reservePrice?.value;

  const [isEditingPrice, setIsEditingPrice] = useState(false);

  const { isHighBidder, highestBidAmount, isTied } = getHighestBid(
    highestBids,
    id,
    uid
  );

  const isReserveMet =
    room.info.secretReserve &&
    highestBidAmount >=
    (typeof reservePrice !== "undefined" ? reservePrice : 0);

  const isSecretReserve = room.info.secretReserve;
  const isSeller = isSilentAuction && canList;

  // Status color indicators with dark mode support
  const statusClasses = {
    winning: "border-l-4 border-green-500 dark:border-green-600",
    notWinning: "border-l-4 border-red-500 dark:border-red-600",
  };

  return (
    <div
      className={`flex items-center p-3 my-2 bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-shadow ${status ? statusClasses[status] : ""}`}
    >
      <Image
        className="min-w-[45px] w-[45px] h-[45px] object-cover cursor-pointer rounded-md overflow-hidden"
        src={nft?.thumbnail || nft?.image || ""}
        fallbackSrc={PendingImg}
        alt=""
        onClick={() => setModalId(nft?.itemId)}
      />
      <div className="flex-1 mx-3">
        <span className="flex flex-col">
          <h3
            className="text-sm font-medium cursor-pointer truncate text-gray-800 dark:text-gray-200"
            onClick={() => setModalId(nft?.itemId)}
          >
            {nft && formatName(nft.name)}
          </h3>
          {status && (
            <div className="flex items-center mt-1">
              <span
                className={`text-xs px-2 py-0.5 rounded-full font-medium ${status === "winning"
                  ? "bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400"
                  : "bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-400"
                  }`}
              >
                {status === "winning" ? "Winning" : "Not Winning"}
              </span>
            </div>
          )}
          <div className="flex items-center text-xs mt-1 text-gray-700 dark:text-gray-400">
            <p className="mr-1">Bid Amount:</p>
            <p className="font-medium">
              {getReserveLabel(bidAmount, decimals, abbr, truncate)}
            </p>
          </div>
        </span>
        <div className="mt-2">
          {!isAdmin && (
            <ReservePriceForm
              nfts={availableForBid}
              biddingId={id}
              isEditingPrice={isEditingPrice}
              setIsEditingPrice={setIsEditingPrice}
            />
          )}
        </div>
      </div>
      <div className="flex items-center">
        <IconButton
          className="text-gray-500 hover:text-red-500 dark:text-gray-500 dark:hover:text-red-400 transition-colors bg-transparent hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full p-2"
          onClick={() => handleRemove(id)}
          aria-label="Remove"
          icon={<IconTrash />}
        />
      </div>
    </div>
  );
};

const PassedItem = ({ id }: { id: string }) => {
  const { availableForBid, removePass, highestBids, room } = useRoom();
  const nft = availableForBid?.find((nft) => nft.itemId === id);
  const reservePrice = nft?.reservePrice?.value;
  const { uid } = useAppContext();

  const { truncate } = useRoom();

  const { isHighBidder, highestBidAmount, isTied } = getHighestBid(
    highestBids,
    id,
    uid
  );

  const isReserveMet =
    room.info.secretReserve &&
    highestBidAmount >=
    (typeof reservePrice !== "undefined" ? reservePrice : 0);

  const isSecretReserve = room.info.secretReserve;

  const handleRemovePassed = (id: string) => {
    removePass(id);
  };

  return (
    <div className="flex items-center p-3 my-2 bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-shadow">
      <Image
        className="min-w-[45px] w-[45px] h-[45px] object-cover rounded-md overflow-hidden"
        src={nft?.thumbnail || nft?.image || ""}
        fallbackSrc={PendingImg}
        alt=""
      />
      <div className="flex-1 mx-3">
        <h3 className="text-sm font-medium truncate text-gray-800 dark:text-gray-200">
          {nft && formatName(nft.name)}
        </h3>
      </div>
      <div className="flex items-center">
        <Button
          leftIcon={<IconEye />}
          variant="outline"
          className="px-3 py-1 text-sm border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
          onClick={() => handleRemovePassed(id)}
        >
          Restore
        </Button>
      </div>
    </div>
  );
};

const getChain = (roomChain: string, uid?: string) => {
  if (!uid) {
    return roomChain.toLowerCase();
  }
  return uid?.split("-")[0];
};

const SwitchButton = ({ roomChain }: { roomChain: string }) => {
  const { setChain, chain } = useChain();
  const chainFns = useNeoChain() as any;
  const { signIn } = chainFns[roomChain.toLowerCase() || "not_found"];
  const { signOut } = chainFns[chain?.name?.toLowerCase() || "not_found"];

  const handleSwitch = async () => {
    await signOut();
    setChain(roomChain);
    await signIn();
  };
  return (
    <Button
      bgColor="#6c60ff"
      color="white"
      size="sm"
      onClick={handleSwitch}
    >{`Join on ${capitalizeFirstLetter(roomChain)}`}</Button>
  );
};

// Update the SortOpt type definition
type SortOpt = {
  label: string;
  value: {
    key: string;
    direction: "asc" | "desc"; // Restrict direction to only "asc" or "desc"
  };
};

// Update the additionalSortOptions array to use the correct types
const additionalSortOptions: SortOpt[] = [
  {
    label: "Highest Bid",
    value: {
      key: "highestBid",
      direction: "desc" as const
    }
  },
  {
    label: "Lowest Bid",
    value: {
      key: "highestBid",
      direction: "asc" as const
    }
  },
  {
    label: "End Time",
    value: {
      key: "endTime",
      direction: "asc" as const
    }
  }
];

const Bidding = ({ roomId, uid, isAdmin: adminStatus, markupPercent }: Props) => {
  const isMountedRef = useRef(false);
  const toast = useToast();
  const {
    units,
    abbr,
    bids,
    budgets,
    room,
    availableForBid,
    getAvailableForBid,
    biddingDiff,
    preferredIds,
    isSilentAuction,
    availableForBidLoading,
    canList,
    passed,
    listings,
    showContactModal,
    setShowContactModal,
    preview,
    setModalId,
    highestBids,
    decimals,
    truncate,
  } = useRoom();

  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("name_asc");
  const [selectedCollection, setSelectedCollection] = useState("all");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isBiddingSidebarOpen, setIsBiddingSidebarOpen] = useState(false);
  const [filteredNfts, setFilteredNfts] = useState<BiddableNft[]>([]);

  // Get unique collections for filter dropdown
  const collections = ["all", ...new Set(availableForBid?.filter(nft => nft?.collection?.name).map(nft => nft.collection.name) || [])];

  // Get collection image helper
  const getCollectionImage = (collectionName: string) => {
    const firstNftInCollection = availableForBid?.find(nft => nft?.collection?.name === collectionName);
    return firstNftInCollection?.collection?.image || PendingImg;
  };

  useEffect(() => {
    // Filter out any undefined or null NFTs first
    let result = [...(availableForBid || [])].filter(nft => nft !== undefined && nft !== null);

    // Apply collection filter
    if (selectedCollection !== "all") {
      result = result.filter(nft => nft?.collection?.name === selectedCollection);
    }

    // Apply search filter
    if (searchQuery) {
      result = result.filter(nft =>
        (nft?.name || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
        (nft?.collection?.name || "").toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply sorting
    result.sort((a, b) => {
      switch (sortBy) {
        case "name_asc":
          return (a?.name || "").localeCompare(b?.name || "");
        case "name_desc":
          return (b?.name || "").localeCompare(a?.name || "");
        case "collection_asc":
          return (a?.collection?.name || "").localeCompare(b?.collection?.name || "");
        case "collection_desc":
          return (b?.collection?.name || "").localeCompare(a?.collection?.name || "");
        default:
          return 0;
      }
    });

    if (result.length > 0) {
      setFilteredNfts(result);
    }
  }, [availableForBid, searchQuery, sortBy, selectedCollection]);

  const winning = preview?.results?.[uid]?.get;
  const bidIds = bids ? Object.keys(bids) : null;
  const winningIds = bidIds && winning ? bidIds.filter((id) => winning?.includes(id)) : [];

  const isTicketGated = room?.info?.isTicketGated ? true : false;

  const hasJoined = room?.users && room?.users.includes(uid || "") ? true : false;

  const timeLeft = useTimeLeft();
  const isSeller = isSilentAuction && canList;

  const [currentTab, setCurrentTab] = useState(isSeller ? "listed" : "bids");
  const [secretBidModalOpen, setSecretBidModalOpen] = useState(false);

  useEffect(() => {
    if (isSeller || adminStatus) {
      setCurrentTab("listed");
    } else {
      setCurrentTab("bids");
    }
  }, [isSeller, adminStatus]);

  let IDsListed = listings ? Object.keys(listings) : [];
  if (adminStatus) {
    IDsListed = availableForBid?.map((nft) => nft.itemId);
  }
  // const IDsListed = listings ? Object.keys(listings) : [];

  const chain = getChain(room?.blockchain?.name, uid);

  const calulateIsLive = () => {
    return room?.info?.startAt < Date.now() / 1000;
  };

  const [isLive, setisLive] = useState(calulateIsLive());

  useEffect(() => {
    const timer = setTimeout(() => {
      setisLive(calulateIsLive());
    }, 1000);

    return () => clearTimeout(timer);
  });
  const budget = budgets?.budget;

  useEffect(() => {
    if (isMountedRef.current) return;
    getAvailableForBid();
    isMountedRef.current = true;
  }, [uid]);

  const handleRemove = (id: string) => {
    cancelBid(roomId, uid, id);
  };

  const goBack = () => {
    saveProgress(roomId, "listings", uid!, false);
  };

  const preferredNfts = useMemo(() => {
    return preferredIds
      ?.filter((id: string) => !passed.includes(id))
      .map((id: string) => availableForBid?.find((ele) => ele.itemId === id)!);
  }, [preferredIds, availableForBid, passed]);

  const availableNfts = useMemo(() => {
    return availableForBid?.filter((ele) => !passed.includes(ele.itemId));
  }, [canList, preferredIds, availableForBid, passed]);

  const roomChain = room?.blockchain?.name?.toLowerCase();
  const isCorrectChain = uid && uid?.includes(roomChain);
  let errorMsg;

  if (!uid || (!isCorrectChain && roomChain && chain !== roomChain)) {
    errorMsg = `This room is on ${capitalizeFirstLetter(
      room?.blockchain?.name
    )}. Please switch your wallet to ${capitalizeFirstLetter(
      roomChain
    )} to join.`;
  }

  const handleAcknowledgement = () => {
    sessionStorage.setItem(`${roomId}acknowledged`, "true");
    setSecretBidModalOpen(false);
  };

  const hasAcknowledged = sessionStorage.getItem(`${roomId}acknowledged`);

  const finalMinute = timeLeft < 60;

  useEffect(() => {
    if (finalMinute && !hasAcknowledged) {
      setSecretBidModalOpen(true);
    }
  }, [finalMinute, hasAcknowledged]);

  const isOrdinals =
    room?.info?.isOrdinals || room.blockchain.name === "bitcoin";

  const setBudget = async (roomId: string, uid: string, { budget }: { budget: number }) => {
    try {
      await setBudgetService(roomId, uid, { budget });
    } catch (error) {
      console.error("Error setting budget:", error);
    }
  };

  const { balance } = useAppContext();
  const { getChainDetails } = useChain();
  const tokenChain = getChainDetails(room.blockchain.name as Blockchain, room.info.token);

  const margin = (tokenChain.budgetMargin !== null && tokenChain.budgetMargin !== undefined)
    ? tokenChain.budgetMargin / 10 ** tokenChain.decimals
    : 0.05;

  const maxBudget = useMemo(() => {
    if (!balance || balance < margin) return 0;
    return roundValue(Number(balance - margin || 0), truncate, "down") * units;
  }, [balance, margin, units, truncate]);

  const showErrorToast = (title: string, description: string) => {
    toast({
      title,
      description,
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top",
    });
  };

  const handleMaxBudget = () => {
    if (maxBudget === 0) {
      showErrorToast(
        "Insufficient balance",
        `Sorry! You need a little more ${abbr}.`
      );
      return;
    }
    setBudget(roomId, uid!, { budget: maxBudget });
  };

  const handleBidButtonClick = (nftId: string) => {
    if (isSilentAuction || adminStatus) {
      setModalId(nftId);
    } else if (!uid) {
      showErrorToast(
        "Authentication Required",
        "Please connect your wallet to place a bid"
      );
    } else if (!room.users?.includes(uid)) {
      showErrorToast(
        "Join Required",
        "Please join the room to place a bid"
      );
    } else {
      setModalId(nftId);
    }
  };

  return (
    <>
      <Box pb="150px">
        {hasJoined && (
          <div>
            <div className="listing-area" style={{ marginBottom: "20px" }}>
              {isSilentAuction ? (
                <div className="room-section" style={{ flexGrow: 1 }}></div>
              ) : canList ? (
                <ListedNfts />
              ) : null}
            </div>
            <div className="flex flex-col gap-4 mb-6">
              <div className="flex items-center gap-2">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Set Your Limits
                </h2>
                <div className="flex-1 h-px bg-gray-200 dark:bg-gray-700"></div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Spending Limit */}
                <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-4">
                  <div className="flex items-center gap-2 mb-4">
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Spending Limit
                    </h3>
                    <div className="cursor-pointer text-gray-400 hover:text-gray-600 dark:hover:text-gray-300">
                      <IconPurpleEye />
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="relative flex-1">
                      <input
                        type="number"
                        value={budget ? roundValue(budget / units, truncate, "down") : ''}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (!val) {
                            setBudget(roomId, uid!, { budget: 0 });
                            return;
                          }
                          const numVal = Number(val);
                          if (numVal < 0) return;
                          if (numVal > maxBudget) return;
                          const adjustedVal = roundValue(numVal, truncate, "down") * units;
                          setBudget(roomId, uid!, { budget: adjustedVal });
                        }}
                        onBlur={(e) => {
                          const val = Number(e.target.value);
                          if (!val) {
                            setBudget(roomId, uid!, { budget: 0 });
                            return;
                          }
                          if (val > maxBudget) {
                            toast({
                              title: "Error Updating Spending Limit",
                              description: "Spending Limit is above your Max Spending Limit",
                              status: "error",
                              duration: 9000,
                              isClosable: true,
                            });
                            return;
                          }
                          if (val < 0) {
                            toast({
                              title: "Error Updating Spending Limit",
                              description: "Spending Limit cannot be negative",
                              status: "error",
                              duration: 9000,
                              isClosable: true,
                            });
                            return;
                          }
                          const adjustedVal = roundValue(val, truncate, "down") * units;
                          setBudget(roomId, uid!, { budget: adjustedVal });
                          toast({
                            title: "Spending Limit Updated",
                            description: "Spending Limit Set Successfully",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                          });
                        }}
                        min="0"
                        step="0.01"
                        className="w-full h-10 px-4 bg-gray-50 dark:bg-gray-700 
                                 text-gray-900 dark:text-white 
                                 border border-gray-200 dark:border-gray-600 
                                 rounded-l-lg
                                 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent
                                 placeholder-gray-400 dark:placeholder-gray-500"
                      />
                      <span className="absolute right-8 top-1/2 -translate-y-1/2 text-gray-400 dark:text-gray-500">
                        {abbr}
                      </span>
                      <div className="absolute right-2 top-1/2 -translate-y-1/2 flex flex-col">
                        <button
                          className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 h-4 flex items-center justify-center"
                          onClick={() => {
                            const currentVal = budget ? roundValue(budget / units, truncate, "down") : 0;
                            const newVal = roundValue(currentVal + 0.01, truncate, "down") * units;
                            if (newVal / units <= maxBudget) {
                              setBudget(roomId, uid!, { budget: newVal });
                            }
                          }}
                        >
                          ▲
                        </button>
                        <button
                          className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 h-4 flex items-center justify-center"
                          onClick={() => {
                            const currentVal = budget ? roundValue(budget / units, truncate, "down") : 0;
                            const newVal = roundValue(Math.max(0, currentVal - 0.01), truncate, "down") * units;
                            setBudget(roomId, uid!, { budget: newVal });
                          }}
                        >
                          ▼
                        </button>
                      </div>
                    </div>

                    <button
                      onClick={handleMaxBudget}
                      className="h-10 px-4 bg-primary hover:bg-primary-dark text-white
                               rounded-r-lg transition-colors duration-200"
                    >
                      Max
                    </button>
                  </div>
                </div>

                {/* Item Limit */}
                <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-4">
                  <NumItemsLimit />
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2 mb-4">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                Bid on Every Item You Like
              </h2>
              <div className="flex-1 h-px bg-gray-200 dark:bg-gray-700"></div>
            </div>
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-6">
              Your limits protect you, and you don't need to have the highest bid to win
            </p>

            {!isSilentAuction && preferredIds?.length > 0 && (
              <Box className="featured-items" mb={8}>
                <h2>
                  <span>Featured {isOrdinals ? "Ordinals" : "items"}</span>
                </h2>
                {biddingDiff && (
                  <div className="refresh-button" onClick={getAvailableForBid}>
                    <IconRefresh />
                  </div>
                )}
                <div className="grid-view">
                  <BiddingCarousel data={preferredNfts} />
                </div>
              </Box>
            )}
            <div className="bidding-wrapper">
              {errorMsg && (
                <div className="manage-bids">
                  <div className="tab-content">
                    {errorMsg && (
                      <VStack
                        justifyContent="flex-start"
                        alignItems="center"
                        className="box"
                        textAlign="center"
                        backgroundColor="rgba(0,0,0,.1)"
                        m="20px"
                        p="60px"
                      >
                        <Text fontSize="16px" mb="25px">
                          {errorMsg}
                        </Text>
                        <SwitchButton roomChain={roomChain} />
                      </VStack>
                    )}
                  </div>
                </div>
              )}
              {uid && !errorMsg && (
                <></>
              )}
            </div>

            {/* Main Content with Sidebar */}
            <div className="flex gap-6 relative">
              {/* Collections Sidebar - Desktop */}
              <aside className="hidden lg:block w-64 flex-shrink-0">
                <div className="sticky top-20">
                  <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-4">
                    <div className="mb-4">
                      <input
                        type="text"
                        placeholder="Search items..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-full h-10 px-4 rounded-lg 
                          border border-gray-200 dark:border-gray-700
                          bg-white dark:bg-gray-700 
                          text-gray-900 dark:text-white
                          focus:ring-2 focus:ring-primary focus:border-transparent"
                      />
                    </div>

                    <div className="space-y-2 overflow-y-auto max-h-[calc(100vh-180px)]">
                      <h3 className="font-semibold text-gray-900 dark:text-white mb-2">Collections</h3>
                      <button
                        onClick={() => setSelectedCollection("all")}
                        className={`w-full text-left px-3 py-2 rounded-lg transition-colors ${selectedCollection === "all"
                          ? "bg-primary text-white"
                          : "text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                          }`}
                      >
                        All Collections
                      </button>
                      {collections
                        .filter(c => c !== "all")
                        .map(collection => (
                          <button
                            key={collection}
                            onClick={() => setSelectedCollection(collection)}
                            className={`w-full text-left px-3 py-2 rounded-lg transition-colors flex items-center gap-3 ${selectedCollection === collection
                              ? "bg-primary text-white"
                              : "text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                              }`}
                          >
                            <div className="w-8 h-8 flex-shrink-0 rounded-full overflow-hidden bg-gray-100 dark:bg-gray-700">
                              <img
                                src={getCollectionImage(collection)}
                                alt={collection}
                                className="w-full h-full object-cover"
                                onError={(e) => {
                                  (e.target as HTMLImageElement).src = PendingImg;
                                }}
                              />
                            </div>
                            <span className="truncate text-gray-800 dark:text-gray-200">
                              {collection}
                            </span>
                          </button>
                        ))}
                    </div>
                  </div>
                </div>
              </aside>

              {/* Collections Sidebar - Mobile */}
              <div className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity lg:hidden
                ${isSidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                onClick={() => setIsSidebarOpen(false)}
              >
                <div className={`fixed inset-y-0 left-0 w-64 bg-white dark:bg-gray-800 transform transition-transform
                  ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
                  onClick={e => e.stopPropagation()}
                >
                  {/* Mobile sidebar content - same as desktop */}
                  <div className="p-4">
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="font-semibold text-gray-900 dark:text-white">Collections</h3>
                      <button onClick={() => setIsSidebarOpen(false)} className="text-gray-500">
                        <XMarkIcon className="w-6 h-6" />
                      </button>
                    </div>
                    {/* Collection buttons - same as desktop */}
                    <div className="space-y-2 overflow-y-auto max-h-[calc(100vh-180px)]">
                      <button
                        onClick={() => {
                          setSelectedCollection("all");
                          setIsSidebarOpen(false);
                        }}
                        className={`w-full text-left px-3 py-2 rounded-lg transition-colors ${selectedCollection === "all"
                          ? "bg-primary text-white"
                          : "text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                          }`}
                      >
                        All Collections
                      </button>
                      {collections
                        .filter(c => c !== "all")
                        .map(collection => (
                          <button
                            key={collection}
                            onClick={() => {
                              setSelectedCollection(collection);
                              setIsSidebarOpen(false);
                            }}
                            className={`w-full text-left px-3 py-2 rounded-lg transition-colors flex items-center gap-3 ${selectedCollection === collection
                              ? "bg-primary text-white"
                              : "text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                              }`}
                          >
                            <div className="w-8 h-8 flex-shrink-0 rounded-full overflow-hidden bg-gray-100 dark:bg-gray-700">
                              <img
                                src={getCollectionImage(collection)}
                                alt={collection}
                                className="w-full h-full object-cover"
                                onError={(e) => {
                                  (e.target as HTMLImageElement).src = PendingImg;
                                }}
                              />
                            </div>
                            <span className="truncate text-gray-800 dark:text-gray-200">
                              {collection}
                            </span>
                          </button>
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Main NFT Grid */}
              <main className="flex-1 min-w-0">
                <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
                  <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center gap-4">
                      {/* Collections filter button - Always visible */}
                      <button
                        className="px-4 py-2 rounded-lg bg-gray-100 dark:bg-gray-700
                          text-gray-800 dark:text-gray-200
                          hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors flex items-center gap-2"
                        onClick={() => setIsSidebarOpen(true)}
                      >
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        Collections
                      </button>

                      {/* Bidding Sidebar Toggle Button - Mobile Only */}
                      <button
                        className="lg:hidden px-4 py-2 rounded-lg bg-gray-100 dark:bg-gray-700
                          text-gray-800 dark:text-gray-200
                          hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors flex items-center gap-2"
                        onClick={() => setIsBiddingSidebarOpen(true)}
                      >
                        <FaGift className="w-5 h-5" />
                        {currentTab === "bids" ? "Currently Bidding" :
                          currentTab === "passed" ? "Hidden Items" : "Listed Items"}
                      </button>

                      <select
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                        className="w-[180px] px-4 py-2 rounded-lg
                          border border-gray-200 dark:border-gray-700
                          bg-white dark:bg-gray-700 text-gray-900 dark:text-white
                          focus:ring-2 focus:ring-primary focus:border-transparent"
                      >
                        <option value="name_asc">Name (A-Z)</option>
                        <option value="name_desc">Name (Z-A)</option>
                        <option value="collection_asc">Collection (A-Z)</option>
                        <option value="collection_desc">Collection (Z-A)</option>
                      </select>
                    </div>
                  </div>

                  {biddingDiff && !availableForBidLoading && !isSeller && (
                    <div className="mb-6 animate-pulse">
                      <div className="flex items-center justify-between p-4 bg-primary/5 border border-primary/20 rounded-lg">
                        <div className="flex items-center gap-3">
                          <div className="flex items-center justify-center w-8 h-8 rounded-full bg-primary/10">
                            <FaExclamationTriangle className="w-4 h-4 text-primary" />
                          </div>
                          <div>
                            <h3 className="text-base font-medium text-gray-900 dark:text-white">
                              New Items Available!
                            </h3>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                              New items have been listed. Refresh to see the latest additions.
                            </p>
                          </div>
                        </div>
                        <button
                          onClick={getAvailableForBid}
                          className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary-dark rounded-lg transition-colors"
                        >
                          <IconRefresh className="w-4 h-4" />
                          Refresh Items
                        </button>
                      </div>
                    </div>
                  )}

                  {availableForBidLoading ? (
                    <div className="flex flex-col items-center justify-center min-h-[50vh] w-full gap-4">
                      <div className="w-10 h-10 border-4 border-primary border-t-transparent rounded-full animate-[spin_1s_linear_infinite]"></div>
                      <p className="text-lg font-medium text-gray-800 dark:text-white">Loading {isOrdinals ? "Ordinals" : "items"}...</p>
                    </div>
                  ) : filteredNfts.length === 0 ? (
                    <div className="flex justify-center items-center min-h-[200px]">
                      <p className="text-lg text-gray-500 dark:text-gray-400">
                        {`Sorry you don't have any ${isOrdinals ? "Ordinals" : "items"} to bid on`}
                      </p>
                    </div>
                  ) : (
                    <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-8 gap-3">
                      {filteredNfts.map((nft) => {
                        const bid = bids?.[nft.itemId];
                        const { isHighBidder, highestBidAmount, isTied } = getHighestBid(
                          highestBids,
                          nft.itemId,
                          uid
                        );

                        return (
                          <div key={nft.itemId}
                            className={`bg-gray-50 dark:bg-gray-700/50 rounded-lg 
                              shadow-sm hover:shadow-md transition-shadow
                              ${bid ? 'ring-1 ring-primary' : ''}`}
                          >
                            <div className="relative pt-[100%]">
                              <img
                                src={nft.thumbnail || nft.image}
                                alt={nft.name}
                                className="absolute inset-0 w-full h-full object-cover rounded-t-lg"
                                onError={(e) => {
                                  e.currentTarget.src = PendingImg;
                                }}
                              />
                            </div>

                            <div className="p-2">
                              <div className="mb-1">
                                <h3 className="font-medium text-gray-900 dark:text-white text-xs truncate">
                                  {nft.name}
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400 text-[11px] truncate">
                                  {nft.collection?.name || "Unknown Collection"}
                                </p>
                              </div>

                              <div className="flex flex-col gap-1">
                                <div className="flex justify-between items-center px-2 py-0.5 bg-gray-100 dark:bg-gray-600 rounded">
                                  <span className="text-gray-600 dark:text-gray-300 text-[11px]">Floor</span>
                                  <span className="font-medium text-gray-900 dark:text-white text-[11px]">
                                    {createFloor(nft)}
                                  </span>
                                </div>

                                {bid && (
                                  <div className="flex justify-between items-center px-2 py-0.5 bg-primary/10 rounded">
                                    <span className="text-primary text-[11px]">Your Bid</span>
                                    <span className="font-medium text-primary text-[11px]">
                                      {bid.bidPrice / (10 ** decimals)} {abbr}
                                    </span>
                                  </div>
                                )}

                                {highestBidAmount > 0 && (
                                  <div className="flex justify-between items-center px-2 py-0.5 bg-gray-100 dark:bg-gray-600 rounded">
                                    <span className="text-gray-600 dark:text-gray-300 text-[11px]">Highest</span>
                                    <span className="font-medium text-gray-900 dark:text-white text-[11px]">
                                      {highestBidAmount / (10 ** decimals)} {abbr}
                                    </span>
                                  </div>
                                )}

                                {isHighBidder && (
                                  <div className="px-2 py-0.5 bg-green-100 dark:bg-green-900/20 rounded">
                                    <span className="text-green-600 dark:text-green-400 text-[11px]">
                                      {isTied ? "Tied" : "Highest bid"}
                                    </span>
                                  </div>
                                )}
                              </div>

                              <div className="mt-2">
                                <button
                                  onClick={() => handleBidButtonClick(nft.itemId)}
                                  className={`w-full px-2 py-1 text-[11px] flex items-center justify-center gap-1
                                    ${bid
                                      ? 'bg-primary hover:bg-primary-dark text-white'
                                      : 'border border-primary text-primary hover:bg-primary hover:text-white'
                                    } rounded transition-colors`}
                                >
                                  {bid ? (
                                    <>
                                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
                                        <polyline fill="none" stroke="currentColor" strokeWidth="2" points="6 13 10.2 16.6 18 7" />
                                      </svg>
                                      View
                                    </>
                                  ) : (
                                    <>
                                      <CgSearchFound size={12} />
                                      Place Bid
                                    </>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </main>

              {/* Currently Bidding Sidebar - Desktop */}
              <aside className="hidden lg:block w-80 flex-shrink-0">
                <div className="sticky top-20">
                  <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-4">
                    {/* Tabs Navigation */}
                    <div className="flex border-b border-gray-200 dark:border-gray-700 mb-4">
                      {(!isSeller && !adminStatus) || room?.info?.roomType === "smartAuction" ? (
                        <button
                          onClick={() => setCurrentTab("bids")}
                          className={`px-4 py-2 text-sm font-medium border-b-2 transition-colors
                            ${currentTab === "bids"
                              ? "border-primary text-primary"
                              : "border-transparent text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            }`}
                        >
                          {finalMinute ? "Final Bids" : "Currently Bidding"}
                        </button>
                      ) : null}

                      {((!isSeller && !adminStatus) || room?.info?.roomType === "smartAuction") && isLive ? (
                        <button
                          onClick={() => setCurrentTab("passed")}
                          className={`px-4 py-2 text-sm font-medium border-b-2 transition-colors
                            ${currentTab === "passed"
                              ? "border-primary text-primary"
                              : "border-transparent text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            }`}
                        >
                          Hidden Items
                        </button>
                      ) : null}

                      {(adminStatus || isSeller || room?.info?.roomType === "smartAuction") && (
                        <button
                          onClick={() => setCurrentTab("listed")}
                          className={`px-4 py-2 text-sm font-medium border-b-2 transition-colors
                            ${currentTab === "listed"
                              ? "border-primary text-primary"
                              : "border-transparent text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            }`}
                        >
                          Listed Items
                        </button>
                      )}
                    </div>

                    {/* Currently Bidding Tab */}
                    {currentTab === "bids" && (
                      <>
                        <div className="flex items-center justify-between mb-4">
                          <h3 className="font-semibold text-gray-900 dark:text-white">Currently Bidding</h3>
                          {Object.keys(bids || {}).length > 0 && (
                            <button
                              onClick={() => handleRemove(Object.keys(bids)[0])}
                              className="text-sm text-red-500 hover:text-red-600 transition-colors"
                            >
                              Remove All
                            </button>
                          )}
                        </div>

                        <Alert
                          status="info"
                          className="info-alert mb-4"
                          backgroundColor={finalMinute ? "#e34619" : ""}
                          background={
                            !finalMinute
                              ? "linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"
                              : ""
                          }
                          borderRadius={"4px"}
                        >
                          <AlertIcon color={"#6C60FF"} />
                          <AlertDescription className="text-gray-700 dark:text-gray-200">
                            {finalMinute
                              ? "Please place your final bids before the timer runs out. An additional 60 seconds will be added to the auction for each new high bid."
                              : "The solver will predict results every 60 seconds."}
                          </AlertDescription>
                        </Alert>

                        <div className="update-status mb-4">
                          <LastRun />
                        </div>

                        {bids ? (
                          <Bids handleRemove={handleRemove} />
                        ) : (
                          <p className="text-gray-500 dark:text-gray-400 text-center py-4">
                            To get started, bid on some items and they will appear here.
                          </p>
                        )}
                      </>
                    )}

                    {/* Hidden Items Tab */}
                    {currentTab === "passed" && (
                      <div className="space-y-4">
                        <div className="flex items-center justify-between mb-4">
                          <h3 className="font-semibold text-gray-900 dark:text-white">Hidden Items</h3>
                        </div>
                        {passed.length > 0 ? (
                          <div className="space-y-2">
                            {passed.map((id) => (
                              <PassedItem id={id} key={id} />
                            ))}
                          </div>
                        ) : (
                          <p className="text-gray-500 dark:text-gray-400 text-center py-4">
                            There are no hidden items.
                          </p>
                        )}
                      </div>
                    )}

                    {/* Listed Items Tab */}
                    {currentTab === "listed" && (
                      <div className="space-y-4">
                        <div className="flex items-center justify-between mb-4">
                          <h3 className="font-semibold text-gray-900 dark:text-white">Listed Items</h3>
                          {canList && isSilentAuction && (
                            <button
                              onClick={goBack}
                              className="text-sm text-primary hover:text-primary-dark transition-colors"
                            >
                              Edit Listings
                            </button>
                          )}
                        </div>
                        <TheListings />
                      </div>
                    )}
                  </div>
                </div>
              </aside>

              {/* Currently Bidding Sidebar - Mobile */}
              <div className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity lg:hidden
                ${isBiddingSidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                onClick={() => setIsBiddingSidebarOpen(false)}
              >
                <div className={`fixed inset-y-0 right-0 w-80 bg-white dark:bg-gray-800 transform transition-transform
                  ${isBiddingSidebarOpen ? 'translate-x-0' : 'translate-x-full'}`}
                  onClick={e => e.stopPropagation()}
                >
                  <div className="p-4 h-full flex flex-col">
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="font-semibold text-gray-900 dark:text-white">
                        {currentTab === "bids" ? "Currently Bidding" :
                          currentTab === "passed" ? "Hidden Items" : "Listed Items"}
                      </h3>
                      <button
                        onClick={() => setIsBiddingSidebarOpen(false)}
                        className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                      >
                        <XMarkIcon className="w-6 h-6" />
                      </button>
                    </div>

                    {/* Tabs Navigation */}
                    <div className="flex border-b border-gray-200 dark:border-gray-700 mb-4">
                      {(!isSeller && !adminStatus) || room?.info?.roomType === "smartAuction" ? (
                        <button
                          onClick={() => setCurrentTab("bids")}
                          className={`px-4 py-2 text-sm font-medium border-b-2 transition-colors
                            ${currentTab === "bids"
                              ? "border-primary text-primary"
                              : "border-transparent text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            }`}
                        >
                          {finalMinute ? "Final Bids" : "Currently Bidding"}
                        </button>
                      ) : null}

                      {((!isSeller && !adminStatus) || room?.info?.roomType === "smartAuction") && isLive ? (
                        <button
                          onClick={() => setCurrentTab("passed")}
                          className={`px-4 py-2 text-sm font-medium border-b-2 transition-colors
                            ${currentTab === "passed"
                              ? "border-primary text-primary"
                              : "border-transparent text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            }`}
                        >
                          Hidden Items
                        </button>
                      ) : null}

                      {(adminStatus || isSeller || room?.info?.roomType === "smartAuction") && (
                        <button
                          onClick={() => setCurrentTab("listed")}
                          className={`px-4 py-2 text-sm font-medium border-b-2 transition-colors
                            ${currentTab === "listed"
                              ? "border-primary text-primary"
                              : "border-transparent text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            }`}
                        >
                          Listed Items
                        </button>
                      )}
                    </div>

                    <div className="flex-1 overflow-y-auto">
                      {/* Currently Bidding Tab */}
                      {currentTab === "bids" && (
                        <>
                          <div className="flex items-center justify-between mb-4">
                            <h3 className="font-semibold text-gray-900 dark:text-white">Currently Bidding</h3>
                            {Object.keys(bids || {}).length > 0 && (
                              <button
                                onClick={() => handleRemove(Object.keys(bids)[0])}
                                className="text-sm text-red-500 hover:text-red-600 transition-colors"
                              >
                                Remove All
                              </button>
                            )}
                          </div>

                          <Alert
                            status="info"
                            className="info-alert mb-4"
                            backgroundColor={finalMinute ? "#e34619" : ""}
                            background={
                              !finalMinute
                                ? "linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"
                                : ""
                            }
                            borderRadius={"4px"}
                          >
                            <AlertIcon color={"#6C60FF"} />
                            <AlertDescription className="text-gray-700 dark:text-gray-200">
                              {finalMinute
                                ? "Please place your final bids before the timer runs out. An additional 60 seconds will be added to the auction for each new high bid."
                                : "The solver will predict results every 60 seconds."}
                            </AlertDescription>
                          </Alert>

                          <div className="update-status mb-4">
                            <LastRun />
                          </div>

                          {bids ? (
                            <Bids handleRemove={handleRemove} />
                          ) : (
                            <p className="text-gray-500 dark:text-gray-400 text-center py-4">
                              To get started, bid on some items and they will appear here.
                            </p>
                          )}
                        </>
                      )}

                      {/* Hidden Items Tab */}
                      {currentTab === "passed" && (
                        <div className="space-y-4">
                          <div className="flex items-center justify-between mb-4">
                            <h3 className="font-semibold text-gray-900 dark:text-white">Hidden Items</h3>
                          </div>
                          {passed.length > 0 ? (
                            <div className="space-y-2">
                              {passed.map((id) => (
                                <PassedItem id={id} key={id} />
                              ))}
                            </div>
                          ) : (
                            <p className="text-gray-500 dark:text-gray-400 text-center py-4">
                              There are no hidden items.
                            </p>
                          )}
                        </div>
                      )}

                      {/* Listed Items Tab */}
                      {currentTab === "listed" && (
                        <div className="space-y-4">
                          <div className="flex items-center justify-between mb-4">
                            <h3 className="font-semibold text-gray-900 dark:text-white">Listed Items</h3>
                            {canList && isSilentAuction && (
                              <button
                                onClick={goBack}
                                className="text-sm text-primary hover:text-primary-dark transition-colors"
                              >
                                Edit Listings
                              </button>
                            )}
                          </div>
                          <TheListings />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <SecretBidModal
          open={secretBidModalOpen}
          handleClose={() => handleAcknowledgement()}
          timeLeft={timeLeft || 0}
        />
      </Box>

      <ContactMethodModal
        open={showContactModal ?? false}
        handleClose={() => setShowContactModal(false)}
      />

      <BiddingModal nfts={filteredNfts} />
    </>
  );
};

export default Bidding;
