interface IconCloseProps {
  className?: string;
}

export default function IconClose({ className }: IconCloseProps) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
      className={className}
    >
      <path d="M28.35,6.32L19.67,15l8.67,8.68c1.29,1.29,1.29,3.38,0,4.67c-0.64,0.64-1.49,0.97-2.33,0.97 c-0.85,0-1.69-0.32-2.34-0.97L15,19.67l-8.68,8.68c-0.64,0.64-1.49,0.97-2.34,0.97c-0.85,0-1.69-0.32-2.34-0.97 c-1.29-1.29-1.29-3.38,0-4.67L10.33,15L1.65,6.32c-1.29-1.29-1.29-3.38,0-4.67c1.29-1.29,3.38-1.29,4.67,0L15,10.33l8.68-8.68 c1.29-1.29,3.38-1.29,4.67,0C29.64,2.94,29.64,5.03,28.35,6.32z" fill="currentColor" />
    </svg>
  );
}
