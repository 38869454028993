import { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { Link } from "react-router-dom";
import partyIcon from "../assets/party.svg";
import PendingImg from "../assets/pending.jpg";
import { Nft, useRoom } from "../contexts/roomContext";
import { confetti } from "../services/confetti";
import Firebase from "../services/firebase";
import { getNftDetails } from "../services/nfts.service";
import { roundValue } from "../utils";
import HTMLBlock from "./HTMLBlock";
import IconArrow from "./Icons/IconArrow";
import IconClose from "./Icons/IconClose";
import { NFTModal } from "./NFTModal";
import RatingButton from "./RatingButton";
import TxtBlock from "./TxtBlock";
import UserRevenue from "./UserRevenue";

interface Props {
  resultData: any;
  uid: string;
  userRevenue: string | undefined;
  roomChain: string;
}

const Participant = ({ id }: { id: string }) => {
  const db = Firebase.getDBApp();
  const [data] = useDocument(doc(db, "users", id), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const user = data?.data();
  const displayName = user?.displayName;
  const avatar = user?.avatar;

  return (
    <li className="flex items-center gap-2">
      {!displayName ? (
        <div className="animate-spin h-5 w-5 border-2 border-blue-500 rounded-full border-t-transparent" />
      ) : (
        <>
          <img src={avatar} className="w-8 h-8 rounded-full" loading="lazy" alt={displayName} />
          <span className="truncate">{displayName}</span>
        </>
      )}
    </li>
  );
};

const createImage = (nft: Nft) => {
  const img = nft?.thumbnail || nft?.image;

  if (nft?.assetType?.includes("text/plain")) {
    return <TxtBlock nft={nft} boxSize="64px" maxW="64px" fontSize="4px" />;
  }
  if (nft?.assetType?.includes("text/html")) {
    return <HTMLBlock nft={nft} style={{ maxWidth: "64px" }} />;
  }
  return (
    <div className="group relative">
      <img
        className="object-cover w-20 h-20 rounded-xl transition-transform group-hover:scale-105"
        src={img}
        alt={nft.name}
        onError={(e) => {
          e.currentTarget.src = PendingImg;
        }}
      />
      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
        <div className="bg-black bg-opacity-50 rounded-full p-2">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 4v3m4-3v3m0-6v3" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default function TradeResult({
  resultData,
  uid,
  userRevenue,
  roomChain,
}: Props) {
  const { decimals, abbr, roomState, roomId, truncate } = useRoom();
  const [nfts, setNfts] = useState<{ [id: string]: Nft }>();
  const [showModal, setShowModal] = useState(false);
  const [isGiveLoading, setIsGiveLoading] = useState(true);
  const [isGetLoading, setIsGetLoading] = useState(true);

  const get = resultData?.results?.[uid]?.get || [];
  const give = resultData?.results?.[uid]?.give || [];
  const token = resultData?.results?.[uid]?.token;

  const allNfts = [...get, ...give];

  const getNfts = async () => {
    try {
      const { data } = (await getNftDetails(allNfts)) as { data: Nft[] };
      const updates = data.reduce((acc: { [id: string]: Nft }, nft) => {
        acc[nft?.itemId] = nft;
        return acc;
      }, {});
      setNfts(updates);
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    let key = `completed-room-${roomId}`,
      alreadyShown = localStorage.getItem(key);

    if (!alreadyShown && roomState === "completed") {
      setShowModal(true);
      confetti.fire();
      localStorage.setItem(key, "true");
    }
  }, [roomState, roomId]);

  const closeCompleteModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (allNfts.length > 0) {
      getNfts();
    }
  }, [JSON.stringify(allNfts)]);

  useEffect(() => {
    if (give?.length > 0 || token < 0) {
      setIsGiveLoading(false);
    }
    if (get?.length > 0 || token > 0) {
      setIsGetLoading(false);
    }
  }, [give, get]);

  return (
    <div className="w-full max-w-4xl mx-auto px-4">
      {userRevenue !== undefined ? (
        <UserRevenue userRevenue={userRevenue} />
      ) : null}
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-8 space-y-8 transition-colors">
        <div className="space-y-10">
          {(token < 0 || give.length > 0) && (
            <div className="space-y-4">
              <div className="flex items-center gap-3 pb-2 border-b border-gray-100 dark:border-gray-700">
                <h4 className="text-lg font-semibold text-red-600 dark:text-red-400">You Give</h4>
                <div className="w-5 h-5 text-red-600 dark:text-red-400 transform rotate-180">
                  <IconArrow />
                </div>
              </div>
              <div className="flex flex-wrap gap-4 p-4 bg-gray-50 dark:bg-gray-900/50 rounded-xl">
                {token < 0 && (
                  <div className={`transition-all duration-300 ${!isGiveLoading ? 'opacity-100 translate-y-0' : 'opacity-50 translate-y-2'}`}>
                    <div className="bg-white dark:bg-gray-800 shadow-sm hover:shadow-md transition-shadow border border-gray-200 dark:border-gray-700 rounded-xl p-4 min-w-[90px]">
                      <div className="text-center font-medium text-gray-900 dark:text-gray-100">
                        {roundValue((token * -1) / 10 ** decimals, truncate)}
                      </div>
                      <div className="text-sm text-gray-500 dark:text-gray-400 text-center mt-1">
                        {abbr}
                      </div>
                    </div>
                  </div>
                )}
                {give.map((id: string) => {
                  const nft = nfts?.[id];
                  if (!nft) return null;
                  return (
                    <div key={id} className={`transition-all duration-300 ${!isGiveLoading ? 'opacity-100 translate-y-0' : 'opacity-50 translate-y-2'}`}>
                      <div className="group bg-white dark:bg-gray-800 shadow-sm hover:shadow-md transition-all border border-gray-200 dark:border-gray-700 rounded-xl p-3 w-[90px] h-[90px] flex items-center justify-center overflow-hidden cursor-pointer">
                        <NFTModal nft={nft}>{createImage(nft)}</NFTModal>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {(token > 0 || get.length > 0) && (
            <div className="space-y-4">
              <div className="flex items-center gap-3 pb-2 border-b border-gray-100 dark:border-gray-700">
                <h4 className="text-lg font-semibold text-green-600 dark:text-green-400">You Get</h4>
                <div className="w-5 h-5 text-green-600 dark:text-green-400">
                  <IconArrow />
                </div>
              </div>
              <div className="flex flex-wrap gap-4 p-4 bg-gray-50 dark:bg-gray-900/50 rounded-xl">
                {token > 0 && (
                  <div className={`transition-all duration-300 ${!isGetLoading ? 'opacity-100 translate-y-0' : 'opacity-50 translate-y-2'}`}>
                    <div className="bg-white dark:bg-gray-800 shadow-sm hover:shadow-md transition-shadow border border-gray-200 dark:border-gray-700 rounded-xl p-4 min-w-[90px]">
                      <div className="text-center font-medium text-gray-900 dark:text-gray-100">
                        {roundValue(token / 10 ** decimals, truncate)}
                      </div>
                      <div className="text-sm text-gray-500 dark:text-gray-400 text-center mt-1">
                        {abbr}
                      </div>
                    </div>
                  </div>
                )}
                {get.map((id: string) => {
                  const nft = nfts?.[id];
                  if (!nft) return null;
                  return (
                    <div key={id} className={`transition-all duration-300 ${!isGetLoading ? 'opacity-100 translate-y-0' : 'opacity-50 translate-y-2'}`}>
                      <div className="group bg-white dark:bg-gray-800 shadow-sm hover:shadow-md transition-all border border-gray-200 dark:border-gray-700 rounded-xl p-3 w-[90px] h-[90px] flex items-center justify-center overflow-hidden cursor-pointer">
                        <NFTModal nft={nft}>
                          {createImage(nft)}
                        </NFTModal>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {!token && get.length === 0 && give.length === 0 && (
            <div className="text-center py-16 space-y-4">
              <div className="flex justify-center text-gray-400 dark:text-gray-500">
                <IconClose className="w-16 h-16" />
              </div>
              <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-200">
                No sale found for you this time
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                Join another event and try again!
              </p>
            </div>
          )}
        </div>

        {!token && get.length === 0 && give.length === 0 ? (
          <div></div>
        ) : (
          <div className="mt-12 flex justify-center">
            <RatingButton roomId={roomId} uid={uid} />
          </div>
        )}
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-2xl p-8 max-w-md mx-4 text-center space-y-6 transform transition-all animate-fade-in shadow-xl">
            <img src={partyIcon} alt="Congrats!" className="w-24 h-24 mx-auto" />
            <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
              Woohoo! The trade is complete!
            </h2>
            <p className="text-gray-600 dark:text-gray-300">
              Great news, the trade you were in has been signed by everyone and
              is now complete! Click below to see the trade.
            </p>
            <button
              onClick={closeCompleteModal}
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-8 py-3 rounded-xl transition-colors"
            >
              Show Trade
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
