import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tooltip,
  Icon,
  Button,
  Checkbox,
  Link,
  useToast,
} from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAppContext } from "../contexts/appContext";
import { useUA } from "../contexts/userTracking";
import {
  updateInfo,
  updateTerms,
} from "../services/user.service";
import { hasError } from "../utils";
import { addCollectionSwapOffer } from "../services/room.service";

const schema = Yup.object().shape({
  displayName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter a display name"),
  email: Yup.string().email("Please enter a valid email").required("Please enter an email"),
  telegram: Yup.string(),
  discord: Yup.string(),
  tacChecked: Yup.boolean().oneOf(
    [true],
    "Please accept the terms and conditions"
  ),
});

export default function SignupForm({
  handleDone,
  csOfferData,
  setStep,
  settings,
  showToast,
  onClose,
}: {
  handleDone?: () => void;
  csOfferData?: any;
  setStep?: (step: number) => void;
  settings?: any;
  showToast?: boolean;
  onClose?: () => void;
}) {
  const toast = useToast();
  const {
    uid,
    email,
    telegram,
    discord,
    user,
    displayName,
    agreements,
    updateUserProfile,
    sendEmailVerification,
    updateState,
  } = useAppContext();

  const { gaCreateProfile, gaVerifyEmail, gaSignedSwap } = useUA();

  const initialValues = {
    email: user?.email || email?.value || "",
    telegram: telegram?.value || "",
    discord: discord?.value || "",
    displayName: user?.displayName || displayName || "",
    tacChecked: agreements?.consentTosAndPp || false,
    notificationsChecked: agreements?.consentNotifications || false,
  };

  const handleError = (e: any) => {
    let message = "Please Try Again!";

    if (e.code === "auth/requires-recent-login") {
      message = "Please re-login to update your email!";
    }

    if (e.code === "auth/email-already-in-use") {
      message = "Email already in use!";
    }

    if (e.code === "auth/too-many-requests") {
      message = "Too many requests, please try again later!";
    }

    toast({
      title: "Error updating your email!",
      description: message,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleRetry = async (values: typeof initialValues) => {
    try {
      await updateTerms(uid!, {
        consentTosAndPp: values.tacChecked,
        consentNotifications: values.notificationsChecked,
      });
      await updateInfo(uid!, "email", {
        value: values.email,
        isVerified: true,
        verificationSent: true,
      });
      updateState({
        displayName: values.displayName,
        email: {
          value: values.email,
          isVerified: true,
          verificationSent: true,
        },
        agreements: {
          consentTosAndPp: values.tacChecked,
          consentNotifications: values.notificationsChecked,
        },
      });
      gaCreateProfile();
    } catch (e: any) {
      handleError(e);
    }
  };

  const handleSubmit = async (values: typeof initialValues, props: any) => {
    try {
      gaVerifyEmail();
      gaSignedSwap();

      await updateUserProfile({ displayName: values.displayName });
      await updateTerms(uid!, {
        consentTosAndPp: values.tacChecked,
        consentNotifications: values.notificationsChecked,
      });

      if (values.email) {
        await updateInfo(uid!, "email", {
          value: values.email,
          verificationSent: true,
        });
        updateState({
          email: {
            value: values.email,
          },
        });
      }
      if (values.telegram) {
        await updateInfo(uid!, "telegram", {
          value: values.telegram,
        });
        updateState({
          telegram: {
            value: values.telegram,
          },
        });
      }
      if (values.discord) {
        await updateInfo(uid!, "discord", {
          value: values.discord,
        });
        updateState({
          discord: {
            value: values.discord,
          },
        });
      }
      if (csOfferData) {
        addCollectionSwapOffer(csOfferData);
      }
      updateState({
        displayName: values.displayName,
        agreements: {
          consentTosAndPp: values.tacChecked,
          consentNotifications: values.notificationsChecked,
        },
      });
      gaCreateProfile();
      if (showToast) {
        toast({
          title: "Offer saved!",
          description: "Saved",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
      if (setStep) {
        setStep(2)
      }
      if (handleDone) {
        handleDone();
      }
    } catch (e: any) {
      if (e.code === "auth/email-already-in-use") {
        return handleRetry(values);
      }
      handleError(e);
    } finally {
      props.setSubmitting(false);
    }
  };

  return (
    <div className="w-full">
      <div className="flex justify-center">
        <div className="w-full max-w-2xl px-4 py-8 mt-20">
          {settings?.heading ? (
            <h2 className="text-xl font-semibold text-center mb-2 mt-6 text-gray-900 dark:text-white">
              {settings.heading}
            </h2>
          ) : (
            <h2 className="text-4xl font-bold text-center mb-3 mt-3 text-gray-900 dark:text-white">
              What should we call you?
            </h2>
          )}
          {settings?.subheadingWarning && (
            <p className="text-center text-red-500 dark:text-red-400 mb-2">
              {settings.subheadingWarning}
            </p>
          )}
          {!settings?.hideChangeLater && (
            <p className="text-lg text-center mb-2 text-gray-600 dark:text-gray-300">
              You can always change this later
            </p>
          )}

          <div className="bg-white dark:bg-gray-800/50 rounded-xl shadow-sm p-6 md:p-8">
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={schema}
            >
              {({ errors, touched, values, handleChange, ...props }) => (
                <form onSubmit={props.handleSubmit} className="space-y-6">
                  <FormControl
                    isRequired
                    isInvalid={hasError(errors, touched, "displayName")}
                  >
                    <FormLabel htmlFor="displayName" className="!text-gray-700 dark:!text-gray-300">
                      Display Name
                    </FormLabel>
                    <Input
                      id="displayName"
                      name="displayName"
                      onChange={handleChange}
                      value={values.displayName}
                      bg="white"
                      borderColor="gray.300"
                      color="gray.900"
                      _hover={{ borderColor: "gray.400" }}
                      _dark={{
                        bg: "gray.800",
                        borderColor: "gray.600",
                        color: "white",
                        _hover: { borderColor: "gray.500" }
                      }}
                      _focus={{
                        borderColor: "blue.500",
                        boxShadow: "0 0 0 1px rgba(59, 130, 246, 0.5)"
                      }}
                    />
                    <FormErrorMessage>{errors.displayName}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={hasError(errors, touched, "email")}
                    isRequired
                  >
                    <div className="flex items-center gap-2">
                      <FormLabel htmlFor="email" className="!text-gray-700 dark:!text-gray-300">
                        Email
                      </FormLabel>
                      <Tooltip label="The email is needed so we can notify you when smart contracts are ready to sign and your sign transaction status. We will not share your email nor spam you.">
                        <Icon as={InfoIcon} className="!text-gray-400" />
                      </Tooltip>
                    </div>
                    <Input
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      bg="white"
                      borderColor="gray.300"
                      color="gray.900"
                      _hover={{ borderColor: "gray.400" }}
                      _dark={{
                        bg: "gray.800",
                        borderColor: "gray.600",
                        color: "white",
                        _hover: { borderColor: "gray.500" }
                      }}
                      _focus={{
                        borderColor: "blue.500",
                        boxShadow: "0 0 0 1px rgba(59, 130, 246, 0.5)"
                      }}
                    />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={hasError(errors, touched, "telegram")}
                  >
                    <FormLabel htmlFor="telegram" className="!text-gray-700 dark:!text-gray-300">
                      Telegram
                    </FormLabel>
                    <Input
                      id="telegram"
                      name="telegram"
                      onChange={handleChange}
                      value={values.telegram}
                      bg="white"
                      borderColor="gray.300"
                      color="gray.900"
                      _hover={{ borderColor: "gray.400" }}
                      _dark={{
                        bg: "gray.800",
                        borderColor: "gray.600",
                        color: "white",
                        _hover: { borderColor: "gray.500" }
                      }}
                      _focus={{
                        borderColor: "blue.500",
                        boxShadow: "0 0 0 1px rgba(59, 130, 246, 0.5)"
                      }}
                    />
                    <FormErrorMessage>{errors.telegram}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={hasError(errors, touched, "discord")}
                  >
                    <FormLabel htmlFor="discord" className="!text-gray-700 dark:!text-gray-300">
                      Discord
                    </FormLabel>
                    <Input
                      id="discord"
                      name="discord"
                      onChange={handleChange}
                      value={values.discord}
                      bg="white"
                      borderColor="gray.300"
                      color="gray.900"
                      _hover={{ borderColor: "gray.400" }}
                      _dark={{
                        bg: "gray.800",
                        borderColor: "gray.600",
                        color: "white",
                        _hover: { borderColor: "gray.500" }
                      }}
                      _focus={{
                        borderColor: "blue.500",
                        boxShadow: "0 0 0 1px rgba(59, 130, 246, 0.5)"
                      }}
                    />
                    <FormErrorMessage>{errors.discord}</FormErrorMessage>
                  </FormControl>

                  <FormControl isRequired>
                    <div className="flex items-center gap-2">
                      <Checkbox
                        id="tacChecked"
                        name="tacChecked"
                        isChecked={values.tacChecked}
                        onChange={handleChange}
                        colorScheme="blue"
                        borderColor="gray.400"
                        _dark={{ borderColor: "gray.500" }}
                      />
                      <FormLabel className="!text-xs !text-gray-600 dark:!text-gray-400 !m-0">
                        I have read and agree to the{" "}
                        <Link
                          href="https://neoswap.cc/terms-and-conditions"
                          target="_blank"
                          className="!text-blue-500 hover:!text-blue-600 dark:!text-blue-400 dark:hover:!text-blue-300"
                        >
                          terms and conditions
                        </Link>
                      </FormLabel>
                    </div>
                  </FormControl>

                  <FormControl>
                    <div className="flex items-center gap-2">
                      <Checkbox
                        id="notificationsChecked"
                        name="notificationsChecked"
                        isChecked={values.notificationsChecked}
                        onChange={handleChange}
                        colorScheme="blue"
                        borderColor="gray.400"
                        _dark={{ borderColor: "gray.500" }}
                      />
                      <FormLabel className="!text-xs !text-gray-600 dark:!text-gray-400 !m-0">
                        Please add me to the email list to receive NeoSwap news & updates and partners.
                      </FormLabel>
                    </div>
                  </FormControl>

                  <div className="flex gap-4 w-full">
                    {settings?.canClose && (
                      <Button
                        type="button"
                        onClick={() => {
                          if (onClose) onClose();
                          else if (handleDone) handleDone();
                        }}
                        bg="white"
                        color="gray.900"
                        border="1px"
                        borderColor="gray.300"
                        _hover={{ bg: "gray.50" }}
                        _dark={{
                          bg: "gray.700",
                          color: "white",
                          borderColor: "gray.600",
                          _hover: { bg: "gray.600" }
                        }}
                        flex="1"
                      >
                        Close
                      </Button>
                    )}
                    <Button
                      type="submit"
                      isLoading={props.isSubmitting}
                      bg="blue.600"
                      color="white"
                      _hover={{ bg: "blue.700" }}
                      _dark={{
                        bg: "blue.600",
                        _hover: { bg: "blue.700" }
                      }}
                      flex="1"
                    >
                      Save
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
