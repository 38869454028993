import { ChangeEvent } from "react";
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAppContext } from "../../contexts/appContext";
import firebase from "../../services/firebase";
import { updateInfo } from "../../services/user.service";

export default function TelegramInput() {
  const { uid } = useAppContext();
  const [data] = useDocument(
    doc(firebase.getDBApp(), `users`, uid! || "not_found", "contact", "telegram")
  );
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await updateInfo(uid!, "telegram", { value: e.target.value });
  };
  const value = data?.data()?.value as string;

  return (
    <div className="w-full">
      <label
        htmlFor="telegram"
        className="block text-sm font-medium mb-1 text-gray-900 dark:text-white"
      >
        Telegram
      </label>
      <div className="flex">
        <span className="bg-gray-300 dark:bg-gray-700 px-3 py-2 rounded-l text-gray-900 dark:text-white flex items-center">
          @
        </span>
        <input
          id="telegram"
          value={value || ""}
          onChange={handleChange}
          className="flex-1 bg-gray-200 dark:bg-gray-800 rounded-r p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
        />
      </div>
    </div>
  );
}
