import { Helmet } from "react-helmet-async";
import { Formik, FormikProps, useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";
import { uniqBy } from "lodash";
import toast, { Toaster } from "react-hot-toast";
import * as Yup from "yup";
import { DateTime } from "luxon";
import { create, getAdmin } from "../services/room.service";
import { useAppContext } from "../contexts/appContext";
import { hasError } from "../utils";
import { useChain } from "../contexts/chainsContext";
import { useUA } from "../contexts/userTracking";
import { useEffect, useState } from "react";
import { loadNfts } from "../services/nfts.service";
import { Nft } from "../contexts/roomContext";
import NeoSelect from "../components/NeoSelect";
import IconSmartAuction from "../components/Icons/IconSmartAuction";
import IconSmartSilentAuction from "../components/Icons/IconSmartSilentAuction";
import IconQuickDuration from '../components/Icons/IconQuickDuration';
import IconStandardDuration from '../components/Icons/IconStandardDuration';
import IconCustomDuration from '../components/Icons/IconCustomDuration';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .when('creationType', {
      is: 'quick',
      then: Yup.string().notRequired(),
      otherwise: Yup.string()
        .required("Name is required")
        .default(() => `Room - ${DateTime.now().toFormat('MM/dd/yyyy HH:mm')}`),
    }),
  chain: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please select a chain!"),

  itemsPreference: Yup.object().when("roomType", {
    is: "smartSilentAuction",
    then: Yup.object().shape({
      preferredUsers: Yup.array()
        .min(1, "Preferred User Address is required")
        .required("Preferred User Address is required"),
    }),
    otherwise: Yup.object(),
  }),
});

type CollectionOptions = {
  label: string;
  value: string;
}[];

type RoomCreationType = 'quick' | 'standard' | 'custom';

type FormValues = {
  durationUnits: string;
  adminId: string;
  name: string;
  roomType: string;
  description: string;
  startAt: number;
  partyDuration: number;
  markupPercent: number;
  minReservePrice: number;
  maxReservePrice: number;
  incrementPct: number;
  incrementToken: number;
  maxUsers: number;
  numNftsToList: number;
  chain: string;
  allowedCollections: CollectionOptions;
  accessPolicy: {
    policy: string;
    collections: CollectionOptions | null;
  };
  isTicketGated: boolean;
  itemsPreference: {
    preferAdmin: boolean;
    preferredItems: CollectionOptions;
    preferredUsers: CollectionOptions;
  };
  addToEvents: boolean;
  secretReserve: boolean;
  isPromoted: boolean;
  token: string | null;
  creationType: RoomCreationType;
  showAdvancedSettings: boolean;
};

const multiplier = (durationUnits: string) => {
  switch (durationUnits) {
    case "minutes":
      return 60;
    case "hours":
      return 3600;
    case "days":
      return 86400;
    default:
      return 1;
  }
};

const TimePicker = () => {
  const { values, setValues } = useFormikContext();
  const vals = values as unknown as FormValues;
  return (
    <select
      className="bg-transparent w-full outline-none text-gray-900 dark:text-white"
      value={vals.durationUnits}
      name="durationUnits"
      onChange={(e) => {
        const val = e.target.value;
        const prevMultiplier = multiplier(vals.durationUnits);
        const dur = vals.partyDuration / prevMultiplier;
        setValues({
          ...vals,
          durationUnits: val,
          partyDuration: dur * multiplier(e.target.value),
        });
      }}
    >
      <option value="minutes">Minutes</option>
      <option value="hours">Hours</option>
      <option value="days">Days</option>
    </select>
  );
};

export default function CreateRoom() {
  const [collectionOptions, setCollectionOptions] = useState<string[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { gaCreateParty } = useUA();
  const { chain, chains } = useChain();
  const { uid, rights } = useAppContext();
  const navigate = useNavigate();
  const roomChain = uid?.split("-")[0];
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const cleanValues = (values: Partial<FormValues>) => {
    const defaultRoomName = `Room - ${DateTime.now().toFormat('MM/dd/yyyy HH:mm')}`;
    return {
      ...values,
      name: values.name || defaultRoomName,
      incrementPct: values.incrementPct ? values.incrementPct / 100 : 0,
      incrementToken:
        chain && values.incrementToken
          ? values.incrementToken * 10 ** chain?.decimals
          : 0,
      allowedCollections: values.allowedCollections?.map((c) => c.value),
      itemsPreference: {
        ...values.itemsPreference,
        preferredItems:
          values.itemsPreference?.preferredItems?.map((c) => c.value.trim()) ||
          null,
        preferredUsers:
          values.itemsPreference?.preferredUsers?.map((c) => c.value.trim()) ||
          null,
      },
      minReservePrice: values.minReservePrice
        ? values.minReservePrice * 10 ** (chain?.decimals ?? 0)
        : 0,
      maxReservePrice: values.maxReservePrice
        ? values.maxReservePrice * 10 ** (chain?.decimals ?? 0)
        : 0,
      accessPolicy: {
        policy: values.accessPolicy?.policy,
        collections:
          values.accessPolicy?.collections?.map((c) => c.value) || null,
      },
    };
  };

  useEffect(() => {
    const getCollectionOptions = async () => {
      try {
        let { data } = (await loadNfts(uid!)) as { data: any };
        let opts = data.map((nft: Nft) => {
          return {
            value: nft.collection.collectionId,
            label: nft.collection.name,
          };
        });
        opts = uniqBy(opts, "value");
        setCollectionOptions(opts);
      } catch (e: any) {
        console.error("error fetching collection options", e);
      }
    };
    getCollectionOptions();
  }, []);

  useEffect(() => {
    const checkAdmin = async () => {
      if (uid) {
        const adminData = await getAdmin(uid);
        setIsAdmin(adminData != null);
      }
    };

    checkAdmin();
  }, [uid]);

  const initialValues = {
    adminId: "",
    name: "",
    roomType: "smartAuction",
    description: "",
    startAt: Math.floor(DateTime.now().toUTC().toSeconds()),
    partyDuration: 86400, // Default to 1 day in seconds
    durationUnits: "days",
    incrementPct: (chain && chain?.incrementPct * 100) || 0,
    incrementToken: (chain && chain?.incrementToken / 10 ** chain?.decimals) || 0,
    markupPercent: 5,
    minReservePrice: 0,
    maxReservePrice: 1,
    maxUsers: 30,
    numNftsToList: 7,
    chain: roomChain,
    allowedCollections: [],
    accessPolicy: {
      policy: "open",
      collections: [],
    },
    itemsPreference: {
      preferAdmin: false,
      preferredItems: [],
      preferredUsers: [],
    },
    addToEvents: false,
    isPromoted: false,
    isTicketGated: false,
    secretReserve: false,
    token: "native",
    creationType: 'standard' as RoomCreationType,
    showAdvancedSettings: false,
  };

  const canUseButton = rights.hasOwnProperty("create-room");

  return (
    <>
      <Helmet>
        <title>{`Tulle | Create Room On Chain`}</title>
      </Helmet>
      <Toaster position="top-right" />
      <div className="p-6 mt-20 ">
        <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">Create a Room</h2>

        <Formik
          initialValues={{
            ...initialValues,
            adminId: uid!,
            secretReserve: true,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values: typeof initialValues) => {
            if (showConfirmation) {
              const { chain, durationUnits, creationType, showAdvancedSettings, ...data } = values;

              try {
                if (!chain) throw new Error("No chain selected");
                const res = await create(cleanValues(data), chain);
                gaCreateParty(res);
                navigate(`/rooms/${res.id}`, { replace: true });
              } catch (e: any) {
                console.log("ERROR", e, data);
                toast.error(e?.message || "Please report an issue through the feedback button.", {
                  duration: 9000,
                });
              }
            } else {
              setShowConfirmation(true);
            }
          }}
        >
          {(props: FormikProps<typeof initialValues>) => {
            const selectedChain = chains?.find(
              (c) => c.name.toLowerCase() === props.values.chain
            );

            const handleRoomTypeChange = (type: RoomCreationType) => {
              const defaultRoomName = `Room - ${DateTime.now().toFormat('MM/dd/yyyy HH:mm')}`;
              let newValues = { ...props.values, creationType: type };

              // Set duration based on room type
              switch (type) {
                case 'quick':
                  newValues = {
                    ...props.values,
                    creationType: type,
                    partyDuration: 3600, // 1 hour in seconds
                    durationUnits: "hours",
                    name: defaultRoomName // Set default name for quick rooms too
                  };
                  break;
                case 'standard':
                  newValues = {
                    ...props.values,
                    creationType: type,
                    partyDuration: 86400, // 24 hours in seconds
                    durationUnits: "days",
                    name: defaultRoomName
                  };
                  break;
                case 'custom':
                  newValues = {
                    ...props.values,
                    creationType: type,
                    partyDuration: props.values.partyDuration || 86400, // Keep current duration or default to 1 day
                    durationUnits: props.values.durationUnits || "days",
                    name: defaultRoomName
                  };
                  break;
              }

              props.setValues(newValues);
            };

            const renderRoomTypeSelection = () => (
              <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg mb-6">
                <h4 className="mb-4 text-gray-900 dark:text-white">Room Duration Preset</h4>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <label className="relative cursor-pointer">
                    <input
                      type="radio"
                      name="creationType"
                      value="quick"
                      className="sr-only peer"
                      checked={props.values.creationType === 'quick'}
                      onChange={() => handleRoomTypeChange('quick')}
                    />
                    <div className="flex flex-col items-center p-4 border border-gray-300 dark:border-gray-700 rounded-lg peer-checked:border-primary peer-checked:bg-primary/10 hover:border-primary transition-colors bg-gray-50 dark:bg-gray-800">
                      <div className="w-12 h-12 flex items-center justify-center text-gray-900 dark:text-white">
                        <div className="w-8 h-8">
                          <IconQuickDuration />
                        </div>
                      </div>
                      <span className="mt-2 text-sm font-medium text-gray-900 dark:text-white">Quick Room</span>
                      <span className="text-xs text-gray-500 dark:text-gray-400 mt-1">1 Hour Duration</span>
                    </div>
                  </label>

                  <label className="relative cursor-pointer">
                    <input
                      type="radio"
                      name="creationType"
                      value="standard"
                      className="sr-only peer"
                      checked={props.values.creationType === 'standard'}
                      onChange={() => handleRoomTypeChange('standard')}
                    />
                    <div className="flex flex-col items-center p-4 border border-gray-300 dark:border-gray-700 rounded-lg peer-checked:border-primary peer-checked:bg-primary/10 hover:border-primary transition-colors bg-gray-50 dark:bg-gray-800">
                      <div className="w-12 h-12 flex items-center justify-center text-gray-900 dark:text-white">
                        <div className="w-8 h-8">
                          <IconStandardDuration />
                        </div>
                      </div>
                      <span className="mt-2 text-sm font-medium text-gray-900 dark:text-white">Standard Room</span>
                      <span className="text-xs text-gray-500 dark:text-gray-400 mt-1">1 Day Duration</span>
                    </div>
                  </label>

                  <label className="relative cursor-pointer">
                    <input
                      type="radio"
                      name="creationType"
                      value="custom"
                      className="sr-only peer"
                      checked={props.values.creationType === 'custom'}
                      onChange={() => handleRoomTypeChange('custom')}
                    />
                    <div className="flex flex-col items-center p-4 border border-gray-300 dark:border-gray-700 rounded-lg peer-checked:border-primary peer-checked:bg-primary/10 hover:border-primary transition-colors bg-gray-50 dark:bg-gray-800">
                      <div className="w-12 h-12 flex items-center justify-center text-gray-900 dark:text-white">
                        <div className="w-8 h-8">
                          <IconCustomDuration />
                        </div>
                      </div>
                      <span className="mt-2 text-sm font-medium text-gray-900 dark:text-white">Custom Duration</span>
                      <span className="text-xs text-gray-500 dark:text-gray-400 mt-1">Set your own duration</span>
                    </div>
                  </label>
                </div>
              </div>
            );

            const renderRoomTypeOptions = () => (
              <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg mb-6">
                <h4 className="mb-4 text-gray-900 dark:text-white">Room Type</h4>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <label className="relative cursor-pointer">
                    <input
                      type="radio"
                      name="roomType"
                      value="smartAuction"
                      className="sr-only peer"
                      checked={props.values.roomType === "smartAuction"}
                      onChange={() => {
                        props.setValues({
                          ...props.values,
                          maxUsers: 30,
                          roomType: "smartAuction",
                        });
                      }}
                      required
                    />
                    <div className="flex flex-col items-center p-4 border border-gray-300 dark:border-gray-700 rounded-lg peer-checked:border-primary peer-checked:bg-primary/10 hover:border-primary transition-colors bg-gray-50 dark:bg-gray-800">
                      <div className="w-12 h-12 flex items-center justify-center text-gray-900 dark:text-white">
                        <div className="w-8 h-8">
                          <IconSmartAuction />
                        </div>
                      </div>
                      <span className="mt-2 text-sm text-gray-900 dark:text-white">Smart Swap</span>
                    </div>
                  </label>

                  <label className="relative cursor-pointer">
                    <input
                      type="radio"
                      name="roomType"
                      value="smartSilentAuction"
                      className="sr-only peer"
                      checked={props.values.roomType === "smartSilentAuction"}
                      onChange={() => {
                        props.setValues({
                          ...props.values,
                          maxUsers: 250,
                          roomType: "smartSilentAuction",
                        });
                      }}
                      required
                    />
                    <div className="flex flex-col items-center p-4 border border-gray-300 dark:border-gray-700 rounded-lg peer-checked:border-primary peer-checked:bg-primary/10 hover:border-primary transition-colors bg-gray-50 dark:bg-gray-800">
                      <div className="w-12 h-12 flex items-center justify-center text-gray-900 dark:text-white">
                        <div className="w-8 h-8">
                          <IconSmartSilentAuction />
                        </div>
                      </div>
                      <span className="mt-2 text-sm text-gray-900 dark:text-white">Smart Auction</span>
                    </div>
                  </label>
                </div>
              </div>
            );

            const renderBasicSettings = () => (
              <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg mb-6">
                <h4 className="mb-4 text-gray-900 dark:text-white">Basic Settings</h4>
                <div className="space-y-6">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                      Room Name (Optional)
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder={`Room - ${DateTime.now().toFormat('MM/dd/yyyy HH:mm')}`}
                      className="w-full bg-gray-200 dark:bg-gray-900 rounded p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      value={props.values.name}
                    />
                  </div>

                  <div>
                    <label htmlFor="roomType" className="block text-sm font-medium mb-2 text-gray-900 dark:text-white">
                      Room Type
                    </label>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <label className="relative cursor-pointer">
                        <input
                          type="radio"
                          name="roomType"
                          value="smartAuction"
                          className="sr-only peer"
                          checked={props.values.roomType === "smartAuction"}
                          onChange={() => {
                            props.setValues({
                              ...props.values,
                              maxUsers: 30,
                              roomType: "smartAuction",
                            });
                          }}
                          required
                        />
                        <div className="flex flex-col items-center p-4 border border-gray-300 dark:border-gray-700 rounded-lg peer-checked:border-primary peer-checked:bg-primary/10 hover:border-primary transition-colors bg-gray-50 dark:bg-gray-800">
                          <div className="w-12 h-12 flex items-center justify-center text-gray-900 dark:text-white">
                            <div className="w-8 h-8">
                              <IconSmartAuction />
                            </div>
                          </div>
                          <span className="mt-2 text-sm text-gray-900 dark:text-white">Smart Swap</span>
                        </div>
                      </label>

                      <label className="relative cursor-pointer">
                        <input
                          type="radio"
                          name="roomType"
                          value="smartSilentAuction"
                          className="sr-only peer"
                          checked={props.values.roomType === "smartSilentAuction"}
                          onChange={() => {
                            props.setValues({
                              ...props.values,
                              maxUsers: 250,
                              roomType: "smartSilentAuction",
                            });
                          }}
                          required
                        />
                        <div className="flex flex-col items-center p-4 border border-gray-300 dark:border-gray-700 rounded-lg peer-checked:border-primary peer-checked:bg-primary/10 hover:border-primary transition-colors bg-gray-50 dark:bg-gray-800">
                          <div className="w-12 h-12 flex items-center justify-center text-gray-900 dark:text-white">
                            <div className="w-8 h-8">
                              <IconSmartSilentAuction />
                            </div>
                          </div>
                          <span className="mt-2 text-sm text-gray-900 dark:text-white">Smart Auction</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  {props.values.roomType === "smartSilentAuction" && (
                    <div>
                      <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                        Preferred Users (Required)
                      </label>
                      <NeoSelect
                        isMulti
                        isCreatable
                        onChange={(values: any) => {
                          props.setFieldValue("itemsPreference.preferredUsers", values);
                        }}
                        value={props.values.itemsPreference.preferredUsers}
                      />
                      {hasError(props.errors, props.touched, "itemsPreference.preferredUsers") && (
                        <p className="mt-1 text-red-500 text-sm">
                          {hasError(props.errors, props.touched, "itemsPreference.preferredUsers")}
                        </p>
                      )}
                    </div>
                  )}

                  {props.values.creationType === 'custom' && (
                    <div>
                      <label htmlFor="partyDuration" className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                        Event Duration
                      </label>
                      <div className="flex">
                        <input
                          type="number"
                          name="partyDuration"
                          id="partyDuration"
                          className="flex-1 bg-gray-200 dark:bg-gray-900 rounded-l p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
                          onChange={(e) => {
                            props.setFieldValue(
                              "partyDuration",
                              Number(e.target.value || 1) *
                              multiplier(props.values.durationUnits)
                            );
                          }}
                          value={
                            props.values.partyDuration /
                            multiplier(props.values.durationUnits)
                          }
                          required
                        />
                        <div className="bg-gray-300 dark:bg-gray-700 px-3 py-2 rounded-r text-gray-900 dark:text-white">
                          <TimePicker />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );

            const renderAdvancedSettings = () => (
              <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg mb-6">
                <div className="flex justify-between items-center mb-4">
                  <h4 className="text-gray-900 dark:text-white">Advanced Settings</h4>
                  <button
                    type="button"
                    className="text-primary text-sm hover:text-primary-hover"
                    onClick={() => props.setFieldValue('showAdvancedSettings', !props.values.showAdvancedSettings)}
                  >
                    {props.values.showAdvancedSettings ? 'Hide' : 'Show'}
                  </button>
                </div>

                {props.values.showAdvancedSettings && (
                  <div className="space-y-6">
                    <div>
                      <label htmlFor="chain" className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                        Chain
                      </label>
                      <input
                        type="text"
                        name="chain"
                        id="chain"
                        className="w-full bg-gray-200 dark:bg-gray-900 rounded p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
                        value={props.values.chain}
                        disabled
                      />
                    </div>

                    {Object.keys(chain?.tokens || {}).length > 0 && (
                      <div>
                        <label htmlFor="token" className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                          Room Token
                        </label>
                        <select
                          id="token"
                          name="token"
                          className="w-full bg-gray-200 dark:bg-gray-900 rounded p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
                          onChange={(e) => {
                            const found = chain?.tokens?.[e.target.value];
                            if (!found) return;
                            const { incrementToken, incrementPct, decimals } = found;
                            props.setValues({
                              ...props.values,
                              token: e.target.value,
                              incrementPct: incrementPct ? incrementPct * 100 : 0,
                              incrementToken: incrementToken ? incrementToken / 10 ** decimals : 0,
                            });
                          }}
                          value={props.values.token || ""}
                        >
                          {chain &&
                            chain.tokens &&
                            Object.keys(chain.tokens || {}).map((chainTokenKey) => {
                              if (!chain.tokens) return;
                              const chainToken = chain.tokens[chainTokenKey];
                              return (
                                <option key={chainTokenKey} value={chainTokenKey}>
                                  {chainToken.name} ({chainToken.abbr})
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    )}

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="incrementPct" className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                          Increment Percentage
                        </label>
                        <div className="flex">
                          <input
                            type="number"
                            name="incrementPct"
                            id="incrementPct"
                            className="flex-1 bg-gray-200 dark:bg-gray-900 rounded-l p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
                            onChange={props.handleChange}
                            value={props.values.incrementPct}
                          />
                          <span className="bg-gray-300 dark:bg-gray-700 px-3 py-2 rounded-r text-gray-900 dark:text-white">%</span>
                        </div>
                      </div>

                      <div>
                        <label htmlFor="incrementToken" className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                          Increment Token
                        </label>
                        <div className="flex">
                          <input
                            type="number"
                            name="incrementToken"
                            id="incrementToken"
                            className="flex-1 bg-gray-200 dark:bg-gray-900 rounded-l p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
                            onChange={props.handleChange}
                            value={props.values.incrementToken}
                          />
                          <span className="bg-gray-300 dark:bg-gray-700 px-3 py-2 rounded-r text-gray-900 dark:text-white">
                            {selectedChain?.abbr}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label htmlFor="description" className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                        Description
                      </label>
                      <textarea
                        name="description"
                        id="description"
                        rows={3}
                        className="w-full bg-gray-200 dark:bg-gray-900 rounded p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
                        onChange={props.handleChange}
                        value={props.values.description}
                      />
                    </div>

                    <div>
                      <label htmlFor="maxUsers" className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                        Max Users
                      </label>
                      <input
                        type="number"
                        name="maxUsers"
                        id="maxUsers"
                        className="w-full bg-gray-200 dark:bg-gray-900 rounded p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
                        onChange={props.handleChange}
                        value={props.values.maxUsers}
                      />
                    </div>

                    <div>
                      <label htmlFor="numNftsToList" className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                        Max Number of Items to List
                      </label>
                      <input
                        type="number"
                        name="numNftsToList"
                        id="numNftsToList"
                        className="w-full bg-gray-200 dark:bg-gray-900 rounded p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
                        onChange={props.handleChange}
                        value={props.values.numNftsToList}
                      />
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="minReservePrice" className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                          Minimum Reserve Price
                        </label>
                        <div className="flex">
                          <input
                            type="number"
                            name="minReservePrice"
                            id="minReservePrice"
                            className="flex-1 bg-gray-200 dark:bg-gray-900 rounded-l p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
                            onChange={props.handleChange}
                            value={props.values.minReservePrice}
                          />
                          <span className="bg-gray-300 dark:bg-gray-700 px-3 py-2 rounded-r text-gray-900 dark:text-white">
                            {selectedChain?.abbr}
                          </span>
                        </div>
                      </div>

                      <div>
                        <label htmlFor="maxReservePrice" className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                          Maximum Reserve Price
                        </label>
                        <div className="flex">
                          <input
                            type="number"
                            name="maxReservePrice"
                            id="maxReservePrice"
                            className="flex-1 bg-gray-200 dark:bg-gray-900 rounded-l p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
                            onChange={props.handleChange}
                            value={props.values.maxReservePrice}
                          />
                          <span className="bg-gray-300 dark:bg-gray-700 px-3 py-2 rounded-r text-gray-900 dark:text-white">
                            {selectedChain?.abbr}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label htmlFor="allowedCollections" className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                        Allowed Collections
                      </label>
                      <div className="mt-1">
                        <NeoSelect
                          isMulti
                          options={collectionOptions}
                          onChange={(values: any) => {
                            props.setFieldValue("allowedCollections", values);
                          }}
                          value={props.values.allowedCollections}
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">Access Policy</label>
                      <div className="space-y-2">
                        <label className="flex items-center gap-2">
                          <input
                            type="radio"
                            name="accessPolicy.policy"
                            value="open"
                            checked={props.values.accessPolicy.policy === "open"}
                            onChange={() => {
                              props.setFieldValue("accessPolicy", {
                                policy: "open",
                                collections: [],
                              });
                            }}
                            className="w-4 h-4"
                          />
                          <span className="text-gray-900 dark:text-white">Open</span>
                        </label>
                        <label className="flex items-center gap-2">
                          <input
                            type="radio"
                            name="accessPolicy.policy"
                            value="holders"
                            checked={props.values.accessPolicy.policy === "holders"}
                            onChange={() => {
                              props.setFieldValue("accessPolicy", {
                                policy: "holders",
                                collections: [],
                              });
                            }}
                            className="w-4 h-4"
                          />
                          <span className="text-gray-900 dark:text-white">Holders Only</span>
                        </label>
                      </div>
                      {props.values.accessPolicy.policy === "holders" && (
                        <div className="mt-2">
                          <NeoSelect
                            isMulti
                            options={collectionOptions}
                            onChange={(values: any) => {
                              props.setFieldValue("accessPolicy.collections", values);
                            }}
                            value={props.values.accessPolicy.collections}
                          />
                        </div>
                      )}
                    </div>

                    {props.values.roomType === "smartSilentAuction" && (
                      <div>
                        <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">Items Preference</label>
                        <div className="space-y-4">
                          <label className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              name="itemsPreference.preferAdmin"
                              checked={props.values.itemsPreference.preferAdmin}
                              onChange={props.handleChange}
                              className="w-4 h-4 rounded border-gray-300"
                            />
                            <span className="text-gray-900 dark:text-white">Prefer Admin</span>
                          </label>

                          <div>
                            <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                              Preferred Items
                            </label>
                            <NeoSelect
                              isMulti
                              isCreatable
                              onChange={(values: any) => {
                                props.setFieldValue("itemsPreference.preferredItems", values);
                              }}
                              value={props.values.itemsPreference.preferredItems}
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium mb-1 text-gray-900 dark:text-white">
                              Preferred Users
                            </label>
                            <NeoSelect
                              isMulti
                              isCreatable
                              onChange={(values: any) => {
                                props.setFieldValue("itemsPreference.preferredUsers", values);
                              }}
                              value={props.values.itemsPreference.preferredUsers}
                            />
                            {hasError(props.errors, props.touched, "itemsPreference.preferredUsers") && (
                              <p className="mt-1 text-red-500 text-sm">
                                {hasError(props.errors, props.touched, "itemsPreference.preferredUsers")}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="space-y-2">
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          id="secretReserve"
                          name="secretReserve"
                          checked={props.values?.secretReserve}
                          onChange={props.handleChange}
                          className="w-4 h-4 rounded border-gray-300"
                        />
                        <span className="text-gray-900 dark:text-white">Hide reserve prices until met</span>
                      </label>

                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          id="isTicketGated"
                          name="isTicketGated"
                          checked={props.values?.isTicketGated}
                          onChange={props.handleChange}
                          className="w-4 h-4 rounded border-gray-300"
                        />
                        <span className="text-gray-900 dark:text-white">Ticket room</span>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            );

            const renderQuickRoom = () => (
              <>
                {renderBasicSettings()}
                {renderAdvancedSettings()}
                <div className="text-center py-8">
                  <p className="text-gray-500 dark:text-gray-400 mb-6">
                    Quick room will be created with 1 hour duration.
                  </p>
                  <button
                    type="button"
                    className={`py-2 px-8 rounded font-medium ${canUseButton
                      ? "bg-primary hover:bg-primary-hover text-white"
                      : "bg-gray-600 cursor-not-allowed text-gray-300"
                      }`}
                    onClick={() => setShowConfirmation(true)}
                    disabled={!canUseButton || props.isSubmitting}
                  >
                    {props.isSubmitting ? "Creating..." : "Create Now"}
                  </button>
                </div>
              </>
            );

            const renderStandardRoom = () => (
              <>
                {renderBasicSettings()}
                {renderAdvancedSettings()}
                <div className="text-center py-8">
                  <p className="text-gray-500 dark:text-gray-400 mb-6">
                    Room will be created with 1 day duration.
                  </p>
                  <button
                    type="button"
                    className={`py-2 px-8 rounded font-medium ${canUseButton
                      ? "bg-primary hover:bg-primary-hover text-white"
                      : "bg-gray-600 cursor-not-allowed text-gray-300"
                      }`}
                    onClick={() => setShowConfirmation(true)}
                    disabled={!canUseButton || props.isSubmitting}
                  >
                    {props.isSubmitting ? "Creating..." : "Create Now"}
                  </button>
                </div>
              </>
            );

            const renderCustomRoom = () => (
              <>
                {renderBasicSettings()}
                {renderAdvancedSettings()}
                <div className="text-center py-8">
                  <p className="text-gray-500 dark:text-gray-400 mb-6">
                    Room will be created with {props.values.partyDuration / multiplier(props.values.durationUnits)} {props.values.durationUnits} duration.
                  </p>
                  <button
                    type="button"
                    className={`py-2 px-8 rounded font-medium ${canUseButton
                      ? "bg-primary hover:bg-primary-hover text-white"
                      : "bg-gray-600 cursor-not-allowed text-gray-300"
                      }`}
                    onClick={() => setShowConfirmation(true)}
                    disabled={!canUseButton || props.isSubmitting}
                  >
                    {props.isSubmitting ? "Creating..." : "Create Now"}
                  </button>
                </div>
              </>
            );

            return (
              <form onSubmit={props.handleSubmit} className="space-y-6">
                {renderRoomTypeSelection()}

                {props.values.creationType === 'quick' && renderQuickRoom()}
                {props.values.creationType === 'standard' && renderStandardRoom()}
                {props.values.creationType === 'custom' && renderCustomRoom()}

                {showConfirmation && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg max-w-lg w-full mx-4">
                      <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">Confirm Room Settings</h3>
                      <div className="space-y-2 mb-6 text-gray-900 dark:text-white">
                        <p><strong>Room Type:</strong> {props.values.creationType}</p>
                        <p><strong>Chain:</strong> {props.values.chain}</p>
                        {props.values.name && <p><strong>Name:</strong> {props.values.name}</p>}
                        <p><strong>Duration:</strong> {props.values.partyDuration / multiplier(props.values.durationUnits)} {props.values.durationUnits}</p>
                      </div>
                      <div className="flex justify-end gap-4">
                        <button
                          type="button"
                          className="px-4 py-2 text-gray-600 hover:text-gray-800"
                          onClick={() => setShowConfirmation(false)}
                        >
                          Edit
                        </button>
                        <button
                          type="submit"
                          className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-hover"
                          disabled={props.isSubmitting}
                        >
                          {props.isSubmitting ? "Creating..." : "Create Room"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

