import { config, colorMap } from "./config";
import { getUrlContentType } from "../../utils";
import { useEffect, useRef, useState } from "react";
import { FaDownload, FaSearchPlus, FaTimes } from "react-icons/fa";

// interface Props {
//   data?: any;
// }

// https://codesandbox.io/s/2jl1d

// Other coin icons can be found in /public/coins/
let activeCoins = {
  SOL: {
    path: "/coins/solana.svg",
    image: null,
  },
  MATIC: {
    path: "/coins/polygon.svg",
    image: null,
  },
  STX: {
    path: "/coins/stacks.svg",
    image: null,
  },
  ETH: {
    path: "/coins/ethereum.svg",
    image: null,
  },
  BTC: {
    path: "/coins/bitcoin.svg",
    image: null,
  },
  USDC: {
    path: "/coins/usdc.svg",
    image: null,
  },
  DEFAULT: {
    path: "/coins/coin.svg",
    image: null,
  },
};

export default function TradeGif({ data }) {
  const [isZoomed, setIsZoomed] = useState(false);
  const isMountedRef = useRef(false);
  const exportGif = useRef();

  useEffect(() => {
    if (!isMountedRef.current) {
      new window.p5(animationSketch, "c1");
      isMountedRef.current = true;
    }
  }, []);

  const edgesVis = data.edges.map((edge) => {
    return { from: edge.source, to: edge.target, ...edge };
  });

  const nodesVis = data.nodes.map((node) => node);

  const nodePositions = {};
  let nodePositionsArray = [];
  const nodeColors = {};
  const curvePoints = {};
  const edgeImages = {};
  const edgeTypes = {};
  const edgeProgress = {};
  let cnv, context;
  let paused = false;
  let neoswapLogo;
  let fallbackImage;
  let rykerFont;
  // let interFont;

  async function init(p5) {
    // create a network
    const container = document.getElementById("vis");
    console.log("container", container);
    const data = {
      nodes: nodesVis,
      edges: edgesVis,
    };
    console.log(data);
    const options = {
      width: "80%",
      height: "80%",
      layout: {
        randomSeed: config.randomSeed || undefined,
      },
    };
    console.log("init", data, options);
    const network = new window.vis.Network(container, data, options);

    for (let i = 0; i < data.nodes.length; i++) {
      let node = data.nodes[i];
      let pos = network.getPosition(node.id);
      nodePositions[node.id] = {
        x: pos.x,
        y: pos.y,
      };
      nodeColors[node.id] = colorMap[i % colorMap.length];
      // nodes.push(new Node(p5, node.id, new p5.Vector(pos.x,pos.y), colorMap[i % colorMap.length], ellipseR, node));
      // nodesByID[node.id] = nodes[nodes.length - 1];
    }

    let { minX, maxX, minY, maxY } = findMaxNodePositions();
    console.log("minX", minX, "maxX", maxX, "minY", minY, "maxY", maxY);
    if (config.showUsernames) {
      minX *= 1.2;
      maxX *= 1.2;
      minY *= 1.2;
      maxY *= 1.2;
    }
    Object.keys(nodePositions).forEach(function (key, index) {
      nodePositions[key] = {
        x: p5.map(
          nodePositions[key].x,
          minX,
          maxX,
          -p5.width / 2 + 20,
          p5.width / 2 - 20
        ),
        y: p5.map(
          nodePositions[key].y,
          minY,
          maxY,
          -p5.height / 2 + 20,
          p5.height / 2 - 20
        ),
      };
    });

    console.log("nodePositions", nodePositions);

    nodePositionsArray = Object.entries(nodePositions).map(([k, v]) => {
      return {
        x: config.width / 2 + v.x,
        y: config.height / 2 + v.y,
        key: k,
      };
    });

    for (let i = 0; i < data.edges.length; i++) {
      let edge = data.edges[i];

      edgeImages[edge.source + "++" + edge.target] = [];
      edgeTypes[edge.source + "++" + edge.target] = [];
      edgeProgress[edge.source + "++" + edge.target] = [];

      const startProgress = Math.random();

      for (let j = 0; j < Math.min(edge.nfts.length, 3); j++) {
        edgeProgress[edge.source + "++" + edge.target].push(
          startProgress - j * 0.2
        );
        let nft = edge.nfts[j];
        let media;
        let type = "";
        if (nft.image) {
          try {
            type = await getUrlContentType(nft.image);
            console.log("TYPE: ", type);
            if (type.includes("video") || type.includes("gif")) {
              media = await p5.createVideo(nft.image);
              console.log("VIDEO LOADED FOR: ", nft.image);
              media.volume(0);
              media.loop();
              media.hide();
            } else {
              media = await p5.loadImage(nft.image);
              console.log("IMAGE LOADED FOR: ", nft.image);
            }
          } catch (e) {
            console.log(e);
            media = fallbackImage;
          }
        } else {
          media = fallbackImage;
        }

        edgeImages[edge.source + "++" + edge.target].push(media);
        edgeTypes[edge.source + "++" + edge.target].push(type);
      }

      for (let j = 0; j < edge.tokens.length; j++) {
        if (edge.tokens[j].amount === 0) continue;
        edgeProgress[edge.source + "++" + edge.target].push(
          startProgress - edge.nfts.length * 0.2 - j * 0.2
        );
      }
    }

    console.log("initial progresses: ", edgeProgress);

    network.destroy();
  }

  const drawCurve = (p5, head, tail, headCol, tailCol, curve, curveDir) => {
    let points = [];
    let midPoint = p5.createVector(
      (head.x + tail.x) / 2,
      (head.y + tail.y) / 2
    );
    let sub = p5.createVector(tail.x - head.x, tail.y - head.y);
    let bezierCPoint = midPoint.add(sub.rotate(90).mult(0.17));

    let point = head.copy();
    for (let t = 0; t <= 1; t += config.curvePrecision) {
      points.push(point.copy());
      let x1 = p5.bezierPoint(
        head.x,
        bezierCPoint.x,
        bezierCPoint.x,
        tail.x,
        t
      );
      let y1 = p5.bezierPoint(
        head.y,
        bezierCPoint.y,
        bezierCPoint.y,
        tail.y,
        t
      );
      let pointB = p5.createVector(x1, y1);
      p5.stroke(p5.lerpColor(p5.color(headCol), p5.color(tailCol), t));
      p5.line(point.x, point.y, pointB.x, pointB.y);
      point = pointB.copy();
    }
    points.push(point.copy());
    return points;
  };

  const angleBetween = (p5, tail, head) => {
    let a = p5.atan2(tail.y - head.y, tail.x - head.x);
    if (a < 0) {
      a += p5.TWO_PI;
    }
    return a;
  };

  const findMaxNodePositions = () => {
    let minX = 0;
    let minY = 0;
    let maxX = 0;
    let maxY = 0;
    Object.keys(nodePositions).forEach(function (key, index) {
      if (nodePositions[key].x < minX) {
        minX = nodePositions[key].x;
      }
      if (nodePositions[key].x > maxX) {
        maxX = nodePositions[key].x;
      }
      if (nodePositions[key].y < minY) {
        minY = nodePositions[key].y;
      }
      if (nodePositions[key].y > maxY) {
        maxY = nodePositions[key].y;
      }
    });
    return {
      minX: minX,
      minY: minY,
      maxX: maxX,
      maxY: maxY,
    };
  };

  const setPaused = (_paused) => (paused = _paused);

  let bottom;

  const drawBottom = (p5) => {
    bottom.fill(255);
    bottom.strokeWeight(config.linesThickness);
    bottom.stroke(bottom.color(config.backgroundColor));

    for (let i = 0; i < data.edges.length; i++) {
      let edge = data.edges[i];
      let source = nodePositions[edge.source];
      let target = nodePositions[edge.target];
      let offset = 5;
      // let dist = p5.dist(source.x, source.y, target.x, target.y);
      bottom.push();
      bottom.translate(config.width / 2, config.height / 2);
      curvePoints[edge.source + "++" + edge.target] = drawCurve(
        bottom,
        bottom.createVector(source.x, source.y),
        bottom.createVector(target.x, target.y),
        nodeColors[edge.source],
        nodeColors[edge.target],
        20,
        1
      );
      if (config.showArrows) {
        let offset = config.linesThickness * 3.5;
        bottom.stroke(bottom.color(config.backgroundColor));
        let trianglePoint =
          curvePoints[edge.source + "++" + edge.target][
            curvePoints[edge.source + "++" + edge.target].length -
              Math.floor(10)
          ];
        let trianglePrePoint =
          curvePoints[edge.source + "++" + edge.target][
            curvePoints[edge.source + "++" + edge.target].length -
              Math.floor(10) -
              1
          ];
        console.log(nodeColors[edge.target], nodeColors[edge.source]);
        bottom.fill(
          bottom.lerpColor(
            bottom.color(nodeColors[edge.target]),
            bottom.color(nodeColors[edge.source]),
            0.02
          )
        );
        bottom.push(); //start new drawing state
        let vec = bottom.createVector(
          trianglePoint.x - trianglePrePoint.x,
          trianglePoint.y - trianglePrePoint.y
        );
        let angle = Math.atan2(
          trianglePrePoint.y - trianglePoint.y,
          trianglePrePoint.x - trianglePoint.x
        ); //gets the angle of the line
        console.log("ANGLE: ", angle);

        bottom.translate(trianglePoint.x, trianglePoint.y); //translates to the destination vertex
        bottom.rotate(vec.heading() - bottom.radians(0)); //rotates the arrow point
        // p5.rect(0,0,offset,offset); //draws the arrow point as a rectangle
        bottom.triangle(0, 0, -offset, -offset, -offset, offset); //draws the arrow point as a triangle
        // p5.rotate(angle - p5.HALF_PI); //rotates the arrow point
        bottom.pop();
      }
      bottom.pop();
    }

    for (let i = 0; i < data.nodes.length; i++) {
      let node = data.nodes[i];
      let pos = nodePositions[node.id];
      bottom.push();
      bottom.translate(config.width / 2, config.height / 2);
      bottom.fill(nodeColors[node.id]);
      // p5.ellipse(pos.x, pos.y, 20, 20);
      bottom.pop();
    }
  };

  const animationSketch = (p5) => {
    p5.preload = () => {
      neoswapLogo = p5.loadImage(config.logo);
      fallbackImage = p5.loadImage("/fallback.jpg");
      // rykerFont = p5.loadFont("/fonts/RykerBold/font.woff", () => {
      //   console.log("loaded rykerFont");
      // });
      // interFont = p5.loadFont("/fonts/Inter/Inter-Bold", () => {
      //   console.log("loaded inter");
      // });

      Object.entries(activeCoins).forEach(([key, coin]) => {
        coin.image = p5.loadImage(coin.path);
      });
    };

    p5.setup = async () => {
      // Calculate canvas size based on container width while maintaining aspect ratio
      const container = document.getElementById("c1");
      const containerWidth = container.offsetWidth;
      const size = Math.min(containerWidth, 512); // Cap at 512px
      cnv = p5.createCanvas(size, size);
      cnv.mousePressed(() => {
        mousePressed(p5.mouseX, p5.mouseY);
      });
      context = cnv.drawingContext;
      //context.font = `12px Rykerbold sans-serif`;
      context.font = `12px InterBold`;
      cnv.mouseOver(() => {
        if (config.pauseOnHover) setPaused(true);
        // mouseOver(p5.mouseX, p5.mouseY);
      });
      cnv.mouseOut(() => {
        if (config.pauseOnHover) setPaused(false);
      });
      p5.frameRate(config.frameRate);
      p5.ellipseMode(p5.CENTER);
      p5.rectMode(p5.CENTER);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.textSize(12);
      // p5.textFont(rykerFont);
      p5.imageMode(p5.CENTER);
      p5.angleMode(p5.DEGREES);

      await init(p5);
      bottom = p5.createGraphics(config.width, config.height);
      console.log(bottom);
      drawBottom(p5);

      // if (config.exportGif) {
      //   p5.createLoop({
      //     duration: config.gifLength,
      //     framesPerSecond: 30,
      //     gif: {
      //       open: false,
      //       download: true,
      //       render: false,
      //       options: {
      //         quality: 30,
      //         workers: 10,
      //         startLoop: 1,
      //       },
      //     },
      //   });
      // }
      console.log("<><><>><>", p5);
      exportGif.current = () =>
        p5.createLoop({
          duration: config.gifLength,
          framesPerSecond: 30,
          gif: {
            open: false,
            download: true,
            render: false,
            options: {
              quality: 30,
              workers: 10,
              startLoop: 1,
            },
          },
          gifOnFinishRender: (blob) =>
            console.log("callback on finish rendering", {
              blob,
            }),
        });

      neoswapLogo = p5.loadImage(config.logo);
      console.log("SETUP", neoswapLogo);
    };

    p5.draw = async () => {
      p5.imageMode(p5.CENTER);
      
      // Create linear gradient background
      const ctx = p5.drawingContext;
      const gradient = ctx.createLinearGradient(0, 0, 0, config.height);
      gradient.addColorStop(0, config.backgroundColor1);
      gradient.addColorStop(1, config.backgroundColor2);
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, config.width, config.height);

      p5.push();
      p5.translate(config.width / 2, config.height / 2);
      p5.image(bottom, 0, 0);
      p5.pop();
      // p5.fill(255);
      // p5.textSize(20);
      // p5.text(parseInt(p5.frameRate()), 20, p5.height-20);

      for (let i = 0; i < data.nodes.length; i++) {
        let node = data.nodes[i];
        let pos = nodePositions[node.id];
        p5.push();
        p5.translate(config.width / 2, config.height / 2);
        p5.strokeWeight(2);
        p5.stroke(p5.color(config.textStrokeColor));
        if (
          p5.dist(
            p5.mouseX - p5.width / 2,
            p5.mouseY - p5.height / 2,
            pos.x,
            pos.y
          ) < 20 ||
          config.showUsernames
        ) {
          // White semi-transparent background for rectangle
          p5.fill(255, 255, 255, 255);
          p5.rect(
            pos.x,
            pos.y,
            context.measureText(node.data.username).width + 15,
            20,
            10
          );
          
          // Text color from config
          p5.fill(p5.color(config.textColor));
          p5.noStroke();
          p5.textSize(12);
          p5.text(node.data.username, pos.x, pos.y);
        }
        p5.pop();
      }
      p5.strokeWeight(1);

      for (let i = 0; i < data.edges.length; i++) {
        let edge = data.edges[i];
        let source = nodePositions[edge.source];
        let target = nodePositions[edge.target];
        let points = curvePoints[edge.source + "++" + edge.target];
        // p5.map(edgeProgress[edge.source+"++"+edge.target], 0, 1, 0, points.length-1)
        let nftImages = edgeImages[edge.source + "++" + edge.target];

        p5.push();
        p5.translate(config.width / 2, config.height / 2);

        p5.fill(255);

        if (nftImages && nftImages.length > 0) {
          for (let i = 0; i < Math.min(nftImages.length, 3); i++) {
            let progress = edgeProgress[edge.source + "++" + edge.target][i];
            const sizeMult = p5.map(progress, 0, 1, 0.01, p5.PI);
            let pos =
              points[
                Math.floor(
                  p5.map(Math.max(0, progress), 0, 1, 0, points.length - 1)
                )
              ];
            let img = nftImages[i];
            let odd = i % 2;

            p5.image(
              fallbackImage,
              pos.x,
              pos.y,
              Math.sin(sizeMult) * 30,
              Math.sin(sizeMult) * 30
            );

            p5.image(
              img,
              pos.x,
              pos.y,
              Math.sin(sizeMult) * 30,
              Math.sin(sizeMult) * 30
            );

            if (!paused)
              edgeProgress[edge.source + "++" + edge.target][i] += Math.max(
                0.005,
                config.speed *
                  (p5.map(
                    Math.sin(
                      p5.map(
                        edgeProgress[edge.source + "++" + edge.target][i],
                        0,
                        1,
                        p5.PI,
                        p5.TWO_PI
                      )
                    )
                  ),
                  0,
                  -1,
                  1,
                  config.speedMultiplier)
              );
            if (edgeProgress[edge.source + "++" + edge.target][i] >= 1)
              edgeProgress[edge.source + "++" + edge.target][i] = 0;
          }
        }

        let ts = 0;
        for (let j = 0; j < edge.tokens.length; j++) {
          if (edge.tokens[j].amount === 0) continue;
          let progress =
            edgeProgress[edge.source + "++" + edge.target][
              (nftImages?.length || 0) + ts
            ];
          // console.log("progress: ",progress);
          const sizeMult = p5.map(progress, 0, 1, 0.01, p5.PI);
          let pos =
            points[
              Math.floor(
                p5.map(
                  Math.min(1, Math.max(0, progress)),
                  0,
                  1,
                  0,
                  points.length - 1
                )
              )
            ];

          let coin;

          switch (edge.tokens[j].name) {
            case "SOL":
              coin = activeCoins.SOL.image;
              break;

            case "MATIC":
              coin = activeCoins.MATIC.image;
              break;

            case "STX":
              coin = activeCoins.STX.image;
              break;

            case "ETH":
              coin = activeCoins.ETH.image;
              break;
            case "BTC":
              coin = activeCoins.BTC.image;
              break;

            case "USDC":
              coin = activeCoins.USDC.image;
              break;

            default:
              coin = activeCoins.DEFAULT.image;
          }

          p5.image(
            coin,
            pos.x,
            pos.y,
            Math.sin(sizeMult) * 30,
            Math.sin(sizeMult) * 30
          );

          if (!paused)
            edgeProgress[edge.source + "++" + edge.target][
              (nftImages?.length || 0) + ts
            ] += Math.max(
              0.005,
              config.speed *
                (p5.map(
                  Math.sin(
                    p5.map(
                      edgeProgress[edge.source + "++" + edge.target][
                        (nftImages?.length || 0) + ts
                      ],
                      0,
                      1,
                      p5.PI,
                      p5.TWO_PI
                    )
                  )
                ),
                0,
                -1,
                1,
                config.speedMultiplier)
            );
          if (
            edgeProgress[edge.source + "++" + edge.target][
              (nftImages?.length || 0) + ts
            ] >= 1
          )
            edgeProgress[edge.source + "++" + edge.target][
              (nftImages?.length || 0) + ts
            ] = 0;

          ts++;
        }

        p5.pop();
      }

      p5.imageMode(p5.CORNER);

      if (config.showLogo) p5.image(neoswapLogo, 20, 20, 65, 30);
    };
  };

  const handleExport = () => {
    exportGif.current();
  };

  const handleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const mousePressed = (x, y) => {
    for (let i = 0; i < nodePositionsArray.length; i++) {
      const pos = nodePositionsArray[i];
      if (Math.abs(pos.x - x) < 20 && Math.abs(pos.y - y) < 20) {
        config.onNodeClick(data.nodes[pos.key]);
      }
    }
  };

  // const mouseOver = (x,y) =>{
  //   for (let i = 0; i < nodePositionsArray.length; i++) {
  //     const pos = nodePositionsArray[i];
  //     if(Math.abs(pos.x - x) < 20 && Math.abs(pos.y - y) < 20){
  //       config.onNodeHover(data.nodes[pos.key]);
  //     }
  //   }
  // }

  return (
    <div className={` w-full h-full ${isZoomed ? 'absolute inset-0 z-[9999999] bg-black/90 ' : 'relative'}`}>
      <div className={`relative w-full h-full ${isZoomed ? 'flex items-center justify-center p-8' : ''}`}>
        <button 
          className="absolute top-4 right-16 p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors z-[9999999] text-white"
          onClick={handleExport}
        >
          <FaDownload className="w-5 h-5" />
        </button>
        <button 
          className="absolute top-4 right-4 p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors z-[9999999] text-white"
          onClick={handleZoom}
        >
          {isZoomed ? <FaTimes className="w-5 h-5" /> : <FaSearchPlus className="w-5 h-5" />}
        </button>
        <div className={`${isZoomed ? 'w-full max-w-[1200px] mx-auto ' : ''}`}>
          <div id="vis" className="hidden"></div>
          <div id="c1" className="w-full aspect-square max-w-[512px] mx-auto"></div>
        </div>
      </div>
    </div>
  );
}
