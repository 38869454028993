import { useCallback, useState, useEffect } from "react";
import { useAppContext } from "../contexts/appContext";
import { getAbbr, getTruncate, roundValue, trucPublicKey } from "../utils";
import { useChain } from "../contexts/chainsContext";
import { useUA } from "../contexts/userTracking";
import useNeoChain from "../hooks/useNeoChain";
import { Link } from "react-router-dom";
import { FaCopy } from "react-icons/fa";
import { BiLogOut, BiRefresh } from "react-icons/bi";
import IconSolana from "./Icons/IconSolana";
import toast from "react-hot-toast";

export default function SignInButton({ version }: { version?: string }) {
  const { gaConnectWalletClick } = useUA();
  const { chain, setChain } = useChain();
  const chainFns = useNeoChain() as any;
  const { uid, publicKey, avatar, balance, activeChainBalance, tokenBalances, getTokenBalance, isFetchingBalance } = useAppContext();
  const { signIn, connecting, signOut } = chainFns?.[chain?.name?.toLowerCase() || "not_found"];
  const [copied, setCopied] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isWalletSelected, setIsWalletSelected] = useState(false);
  const [connectionError, setConnectionError] = useState<string | null>(null);
  const abbr = getAbbr(tokenBalances, activeChainBalance);
  const truncate = getTruncate(tokenBalances, activeChainBalance);

  const copyAddress = useCallback(async () => {
    if (publicKey) {
      await navigator.clipboard.writeText(publicKey);
      setCopied(true);
      setTimeout(() => setCopied(false), 400);
    }
  }, [publicKey]);

  const refreshBalance = async () => {
    if (uid) {
      await getTokenBalance(uid);
    }
  };

  const handleSignIn = useCallback(async () => {
    try {
      setConnectionError(null);
      gaConnectWalletClick();

      // Log current chain state
      console.log("SignInButton: Current chain:", chain?.name);

      // Special case for blink-page, always use Solana
      if (version === "blink-page") {
        console.log("SignInButton: On blink page, forcing Solana chain");
        setChain('solana');
        await new Promise(resolve => setTimeout(resolve, 100)); // Small delay to ensure chain is set
        await signIn();
        return;
      }

      // If we have a chain name, ensure we're on that chain before connecting
      if (chain?.name) {
        const chainToUse = chain.name.toLowerCase();
        console.log(`SignInButton: Setting chain to ${chainToUse} before connecting`);
        setChain(chainToUse);
        await new Promise(resolve => setTimeout(resolve, 100)); // Small delay to ensure chain is set
        await signIn();
      } else {
        console.log("SignInButton: No chain selected, using current chain");
        await signIn();
      }
    } catch (error: any) {
      console.error("Error during sign in:", error);
      let errorMessage = "Failed to connect wallet. Please try again.";

      if (error?.message?.includes("User rejected")) {
        errorMessage = "Connection cancelled by user.";
      } else if (error?.message?.includes("timeout")) {
        errorMessage = "Connection timed out. Please try again.";
      } else if (error?.message?.includes("not found")) {
        errorMessage = "Wallet not found. Please install a supported wallet.";
      }

      setConnectionError(errorMessage);
      toast.error(errorMessage);
    }
  }, [chain, setChain, signIn, gaConnectWalletClick, version]);

  // Add effect to detect wallet selection
  useEffect(() => {
    const detectWalletSelection = () => {
      const hasWallet = window.phantom?.solana?.isPhantom ||
        window.solflare?.isSolflare ||
        window.backpack?.isBackpack;
      setIsWalletSelected(!!hasWallet);
      if (!hasWallet) {
        setConnectionError("No supported wallet found. Please install Phantom, Solflare, or Backpack.");
      }
    };

    detectWalletSelection();
    window.addEventListener('load', detectWalletSelection);
    return () => window.removeEventListener('load', detectWalletSelection);
  }, []);

  if (uid)
    return (
      <div className="flex items-center gap-2">
        <div className="relative">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg dark:bg-gray-800/30 bg-gray-200/50`}
          >
            <img
              src={avatar}
              alt="Profile"
              className="w-8 h-8 rounded-full object-cover ring-2 ring-primary/20"
            />
            <div className="flex items-center gap-2 text-light-text dark:text-dark-text font-medium">
              <span className={`${isFetchingBalance ? 'opacity-50' : ''}`}>
                {isFetchingBalance ? "..." : roundValue(balance ? balance : 0, truncate)}
              </span>
              {chain?.name === "Solana" && <IconSolana className="w-4 h-4" />}
            </div>
          </button>

          {isMenuOpen && (
            <div className="absolute right-0 mt-2 w-56 bg-light-bg-secondary dark:bg-dark-bg-secondary rounded-xl 
                          shadow-lg border border-light-border dark:border-dark-border backdrop-blur-lg">
              <div className="p-3 border-b border-light-border dark:border-dark-border">
                <button
                  onClick={copyAddress}
                  className="w-full px-3 py-2 text-left rounded-lg hover:bg-light-menu-hover dark:hover:bg-dark-menu-hover 
                           flex items-center gap-2 text-light-text dark:text-dark-text"
                >
                  <FaCopy className="w-4 h-4 opacity-60" />
                  <span className="font-medium">{trucPublicKey(publicKey || "")}</span>
                  {copied && <span className="ml-auto text-sm text-primary">Copied!</span>}
                </button>
              </div>

              <div className="py-2">
                {[
                  //{ to: "/my-items", label: "My Items" },
                  // { to: "/my-swaps", label: "My Swaps" },
                  //{ to: "/rooms", label: "My Rooms" },
                  { to: "/profile", label: "Edit Profile" }
                  //{ to: "/points", label: "My Points" },
                ].map((item) => (
                  <Link
                    key={item.to}
                    to={item.to}
                    className="block px-3 py-2 hover:bg-light-menu-hover dark:hover:bg-dark-menu-hover 
                             text-light-text dark:text-dark-text transition-colors"
                  >
                    {item.label}
                  </Link>
                ))}
              </div>

              <div className="p-2 border-t border-light-border dark:border-dark-border">
                <button
                  onClick={signOut}
                  className="w-full px-3 py-2 text-left text-red-500 hover:bg-light-menu-hover dark:hover:bg-dark-menu-hover 
                           rounded-lg flex items-center gap-2 transition-colors"
                >
                  <BiLogOut className="w-4 h-4" />
                  <span>Sign Out</span>
                </button>
              </div>
            </div>
          )}
        </div>

        <button
          onClick={refreshBalance}
          disabled={isFetchingBalance}
          className="p-2 rounded-lg hover:bg-light-menu-hover dark:hover:bg-dark-menu-hover 
                   transition-colors disabled:opacity-50"
        >
          <BiRefresh className={`w-5 h-5 text-light-text dark:text-dark-text ${isFetchingBalance ? 'animate-spin' : ''}`} />
        </button>
      </div>
    );

  return (
    <button
      disabled={connecting}
      onClick={handleSignIn}
      className={`${version === "connect-page" || version === "blink-page"
        ? 'px-6 py-3 text-base'
        : 'px-6 py-2 text-sm'}
                 bg-primary hover:bg-primary-hover text-white rounded-lg
                 transition-all duration-200 disabled:opacity-50 font-medium
                 hover:shadow-md hover:scale-[1.02] flex items-center gap-2 justify-center`}
    >
      {connecting ? (
        <>
          <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <span>Connecting...</span>
        </>
      ) : (
        <>
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 18V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3H19C20.1 3 21 3.9 21 5V6H12C10.89 6 10 6.9 10 8V16C10 17.1 10.89 18 12 18H21ZM12 16H22V8H12V16ZM16 13.5C15.17 13.5 14.5 12.83 14.5 12C14.5 11.17 15.17 10.5 16 10.5C16.83 10.5 17.5 11.17 17.5 12C17.5 12.83 16.83 13.5 16 13.5Z" fill="currentColor" />
          </svg>
          <span>{isWalletSelected ? "Connect Wallet" : "Select Wallet"}</span>
        </>
      )}
      {connectionError && (
        <div className="absolute top-full left-0 right-0 mt-2 p-2 bg-red-100 dark:bg-red-900/20 text-red-600 dark:text-red-400 text-xs rounded-lg">
          {connectionError}
        </div>
      )}
    </button>
  );
}
