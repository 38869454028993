import { ChangeEvent } from "react";
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAppContext } from "../../contexts/appContext";
import firebase from "../../services/firebase";
import { updateInfo } from "../../services/user.service";

export default function DiscordInput() {
  const { uid } = useAppContext();
  const [data] = useDocument(
    doc(firebase.getDBApp(), `users`, uid! || "not_found", "contact", "discord")
  );
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await updateInfo(uid!, "discord", { value: e.target.value });
  };
  const value = data?.data()?.value as string;

  return (
    <div className="w-full">
      <label
        htmlFor="discord"
        className="block text-sm font-medium mb-1 text-gray-900 dark:text-white"
      >
        Discord
      </label>
      <input
        id="discord"
        value={value || ""}
        onChange={handleChange}
        className="w-full bg-gray-200 dark:bg-gray-800 rounded p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
      />
    </div>
  );
}
