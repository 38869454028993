import { cloneElement } from "react";
import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { createFloor, getVolumeLabel } from "../utils";
import IconLink from "./Icons/IconLink";
import { NftMetadata } from "ns-types";
import { ReactElement } from "react";
import TxtBlock from "./TxtBlock";
import HTMLBlock from "./HTMLBlock";
import PendingImg from "../assets/pending.jpg";

interface DetailsPanelProps {
  nft: NftMetadata;
  abbr: string;
  truncate: number;
  className?: string;
}
export function DetailsPanel({
  nft,
  abbr,
  truncate,
  className,
}: DetailsPanelProps) {
  const modifiedAttributes = Array.isArray(nft?.attributes)
    ? nft.attributes.map(
      (attr: { value: string | number | boolean; trait_type: string }) => {
        if (typeof attr.value === 'string') {
          const modifiedValue = attr.value
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          return { ...attr, value: modifiedValue };
        }
        return attr;
      }
    )
    : [];

  return (
    <div className={`listing-modal-details ${className ? className : ""}`}>
      <div className="nft-details-tab">
        {nft?.collection?.name || nft?.collection?.twitter ? (
          <>
            {nft.collection.twitter ? (
              <h4>
                <a target="_blank" href={nft.collection.twitter}>
                  {nft.collection.twitter}
                </a>
              </h4>
            ) : (
              <h4>{nft.collection.name}</h4>
            )}
          </>
        ) : null}
        <h3>{nft?.name}</h3>

        <div className="the-nft-details">
          <div className="nft-details-item">
            <p>Floor</p>
            <span>{createFloor(nft)}</span>
          </div>

          <div className="nft-details-item">
            <p>Volume</p>
            <span>
              {nft &&
                getVolumeLabel(
                  nft?.collection?.volume,
                  nft?.collection?.floorPrice?.floorPrice?.decimals || 0,
                  abbr,
                  truncate
                )}
            </span>
          </div>

          <div className="nft-details-item">
            <p>Owners</p>
            <span>{nft?.collection?.owners}</span>
          </div>

          <div className="nft-details-item">
            <p>Total</p>
            <span>{nft?.collection?.totalItems}</span>
          </div>

          {nft?.marketplace.url ? (
            <div className="last-link">
              <span>
                <a href={nft?.marketplace.url} target="_blank" rel="noreferrer">
                  See on Marketplace
                  <div>
                    <IconLink />
                  </div>
                </a>
              </span>
            </div>
          ) : null}
        </div>

        <Box
          className="mb-5"
          style={{ maxHeight: "410px", overflowY: "scroll" }}
        >
          <h1 className="titleAtributes">Attributes</h1>
          {nft?.attributes ? (
            <Table>
              <Thead className="border-b font-medium dark:border-neutral-500">
                <Tr>
                  <Th pl={"0"} className="px-6 py-4">
                    Type
                  </Th>
                  <Th className="px-6 py-4">Value</Th>
                </Tr>
              </Thead>
              <Tbody>
                {modifiedAttributes?.map(
                  (attr: { trait_type: string; value: string | number | boolean }) => (
                    <Tr
                      key={attr.trait_type}
                      className="border-b-2 dark:border-neutral-500"
                    >
                      <Td pl={"0"} className="whitespace-nowrap px-6 py-4">
                        {attr.trait_type}
                      </Td>
                      <Td className="whitespace-nowrap px-6 py-4">
                        {String(attr.value)}
                      </Td>
                    </Tr>
                  )
                )}
              </Tbody>
            </Table>
          ) : (
            <h1>--</h1>
          )}
        </Box>

        {nft.userDescription ? (
          <>
            <h5>Additional Info Provided by Seller</h5>
            <p>{nft.userDescription}</p>
          </>
        ) : null}

        <h5>Details</h5>
        <p>
          {nft?.description ? nft?.description : "This Item has no description."}
        </p>
      </div>
    </div>
  );
}

interface Props {
  target: ReactElement;
  title: string;
  nft: NftMetadata;
  abbr: string;
  truncate: number;
}

const createImage = (nft?: NftMetadata) => {
  const img = nft?.image || nft?.thumbnail;
  if (nft?.assetType?.includes("text/plain")) {
    return (
      <TxtBlock
        nft={nft}
        width="100%"
        maxW="450px"
        fontSize="12px"
        height="100%"
      />
    );
  }
  if (nft?.assetType?.includes("text/html")) {
    return <HTMLBlock nft={nft} style={{ maxWidth: "450px" }} />;
  }
  return <Image objectFit="cover" src={img} fallbackSrc={PendingImg} />;
};

export default function DetailsModal({
  target,
  title,
  nft,
  abbr,
  truncate,
}: Props) {
  const { isOpen, onClose, onToggle } = useDisclosure();
  return (
    <>
      {nft && cloneElement(target, { onClick: onToggle })}
      <Modal isOpen={isOpen} onClose={onClose} size={"5xl"} trapFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="listing-modal-body wip">
              <Flex
                direction={{ base: "column", md: "row" }}
                gap="20px"
                justifyContent={"space-between"}
                className="image-arrows"
              >
                <div className={`asset-container flex-1`}>
                  {createImage(nft)}
                </div>
                <DetailsPanel
                  nft={nft}
                  truncate={truncate}
                  abbr={abbr}
                  className={"flex-1"}
                />
              </Flex>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
