import { useEffect, useState, useCallback } from "react";
import {
  Button,
  IconButton,
  Link,
  Text,
  useDisclosure,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  // ModalHeader,
  ModalCloseButton,
  ModalBody,
  // ModalFooter,
  // Input,
  useMediaQuery,
  // Box,
  Center,
} from "@chakra-ui/react";

import BottomBar from "./BottomBar";
import { useRoom } from "../contexts/roomContext";
import useNeoChain from "../hooks/useNeoChain";
import SwapModal from "./SwapModal";
import IconRefresh from "./Icons/IconRefresh";
import { AvatarModal } from "./AvatarList";
import { groupBy, uniq } from "lodash";
import { useAppContext } from "../contexts/appContext";
import { getFeedback, initializeSwap } from "../services/room.service";
import { useChain } from "../contexts/chainsContext";
import { getAiInfo } from "../services/user.service";
import IconWaiting from "./Icons/IconWaiting";
import IconTick from "./Icons/IconTick";
import { useUA } from "../contexts/userTracking";
import DeclineModal from "./DeclineModal";
import AcceptModal from "./AcceptModal";
import SignupForm from "./SignupForm";
// import { EmailVerificationMessage } from "../pages/EmailVerification";

// Icons as components for better maintainability
const RefreshIcon = () => (
  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
  </svg>
);

const TickIcon = () => (
  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
  </svg>
);

const WaitingIcon = () => (
  <svg className="w-4 h-4 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
);

const VerifyModal = ({
  isOpen,
  onClose,
  handleInitialize,
}: {
  isOpen: boolean;
  onClose: () => void;
  handleInitialize: (ignore?: boolean) => void;
}) => {
  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? 'block' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black/50" onClick={onClose} />
      <div className="fixed inset-x-0 bottom-0 z-50 p-4 mx-auto max-w-2xl bg-white dark:bg-dark-bg-secondary rounded-t-xl">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
          <span className="sr-only">Close</span>
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="flex justify-center">
          <SignupForm handleDone={() => { onClose(); handleInitialize(true); }} />
        </div>
      </div>
    </div>
  );
};

export default function RoomContractButtons() {
  const { gaDeploySwap } = useUA();
  const [initializing, setInitializing] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const { uid, agreements, displayName, user } = useAppContext();
  const { roomId, room, getSwapState, swapState } = useRoom();
  const chainFns = useNeoChain() as any;
  const roomChain = room?.blockchain?.name?.toLowerCase();
  const [hasFeedback, setHasFeedback] = useState(false);
  const [canRetry, setCanRetry] = useState(false);
  const [feedback, setFeedback] = useState<any>({});
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const { connected, connect, connecting, wallet } =
    chainFns[roomChain || "not_found"];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThanSm] = useMediaQuery("(min-width: 30em)");
  const { chain } = useChain();
  const {
    isOpen: isDeclineModalOpen,
    onOpen: onDeclineModalOpen,
    onClose: onDeclineModalClose,
  } = useDisclosure();

  const {
    isOpen: verifyModalOpen,
    onOpen: onVerifyModalOpen,
    onClose: onVerifyModalClose,
  } = useDisclosure();

  const handleDeclineSubmit = (reason: string) => {
    // Do something with the reason, e.g., send it to the backend
    console.log("Decline reason:", reason);
  };

  const hasSwaps = !!room?.userSwaps?.[uid!];
  const [swapInfo, setSwapInfo] = useState({
    total: 0,
    signed: 0,
  });

  const handleDecline = () => {
    onDeclineModalOpen(); // Open the decline modal
  };

  const handleAccept = () => {
    setAcceptModalOpen(true); // Open the decline modal
  };

  const handleRefreshSwapState = async () => {
    try {
      setRefreshing(true);
      await getSwapState();
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    handleRefreshSwapState();
  }, []);

  const handleInitialize = async (ignore?: boolean) => {
    try {
      if (!ignore && (!agreements?.consentTosAndPp || !displayName))
        return onVerifyModalOpen();
      setInitializing(true);
      await initializeSwap(roomId);
      await handleRefreshSwapState();
      gaDeploySwap();
    } finally {
      setInitializing(false);
    }
  };
  useEffect(() => {
    if (swapState) {
      const userSwaps = swapState?.userStatus?.filter((u) => u.user === uid);
      const swapStatus = swapState?.userStatus?.filter(
        (u) => u.userState === "signed" && u.user === uid
      );

      setSwapInfo({
        total: userSwaps?.length,
        signed: swapStatus?.length,
      });
    }
  }, [swapState]);

  useEffect(() => {
    const checkIfRated = async () => {
      if (roomId && uid) {
        const userFeedback = await getFeedback(roomId, uid);
        if (userFeedback) {
          setHasFeedback(true);
          setFeedback(userFeedback);
        }
      }
    };
    checkIfRated();
  }, []);

  const grouped = groupBy(swapState?.userStatus, "user");

  const completed = Object.keys(grouped || {}).reduce(
    (acc: string[], id: string) => {
      if (grouped[id].every((ele) => ele.userState === "signed")) {
        acc.push(id);
      }
      return acc;
    },
    []
  );

  const hasDeclinedKey = (obj: any) => {
    for (const key in obj) {
      if (key.startsWith("decline")) {
        return true;
      }
    }
    return false;
  };

  const avatars = uniq(swapState?.userStatus?.map((u) => u.user));

  const checkRetry = async () => {
    try {
      const res = await getAiInfo(uid!);
      if (
        !res.numDeclined ||
        res.numDeclined < (chain?.ai?.maxNumDeclined || 0) - 1
      ) {
        setCanRetry(true);
      }
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  useEffect(() => {
    checkRetry();
  }, []);

  // Base button classes
  const buttonClasses = {
    base: "px-4 py-2 rounded-lg font-medium transition-colors duration-200 flex items-center gap-2",
    primary: "bg-[#6565FF] hover:bg-[#5151FF] text-white",
    secondary: "bg-red-500 hover:bg-red-600 text-white",
    link: "text-[#6565FF] hover:text-[#5151FF]"
  };

  if (!hasSwaps && room?.info?.roomType === "ai") {
    if (!room.users.includes(uid || "")) return null;
    return (
      <>
        <VerifyModal
          isOpen={verifyModalOpen}
          onClose={onVerifyModalClose}
          handleInitialize={handleInitialize}
        />
        <footer className="fixed bottom-0 left-0 right-0 border-t border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-900 shadow-lg">
          <div className="container mx-auto px-4 py-3">
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <h3 className="text-base font-medium text-gray-900 dark:text-gray-100">
                  Deploy swap to accept offer and sign
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Not satisfied with the trade? Share your feedback with us{" "}
                  <a
                    href="https://neoswap.cc/feedback"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#6565FF] hover:text-[#5151FF]"
                  >
                    here
                  </a>
                </p>
              </div>

              <div className="flex gap-2">
                {hasFeedback && hasDeclinedKey(feedback.feedback) ? (
                  <button
                    className={`${buttonClasses.base} ${buttonClasses.secondary}`}
                    disabled
                  >
                    Declined
                  </button>
                ) : (
                  <>
                    <button
                      onClick={handleDecline}
                      className={`${buttonClasses.base} ${buttonClasses.secondary}`}
                    >
                      {canRetry ? "Decline & Try Again" : "Decline"}
                    </button>
                    <button
                      onClick={() => handleAccept()}
                      className={`${buttonClasses.base} ${buttonClasses.primary}`}
                      disabled={initializing}
                    >
                      {initializing ? (
                        <>
                          <WaitingIcon />
                          <span>Initializing...</span>
                        </>
                      ) : (
                        "Accept"
                      )}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </footer>

        {uid && (
          <>
            <DeclineModal
              isOpen={isDeclineModalOpen}
              onClose={onDeclineModalClose}
              onSubmit={handleDeclineSubmit}
              roomId={roomId}
              uid={uid}
              canRetry={canRetry}
            />
            <AcceptModal
              isOpen={acceptModalOpen}
              onClose={() => setAcceptModalOpen(false)}
              onSubmit={handleDeclineSubmit}
              handleInitialize={() => handleInitialize()}
              roomId={roomId}
              uid={uid}
            />
          </>
        )}
      </>
    );
  }

  if (!hasSwaps) return null;

  return (
    <>
      <AvatarModal
        isOpen={isOpen}
        onClose={onClose}
        avatars={avatars}
        completed={completed}
        title={`Global Sign Status: ${completed?.length} out of ${avatars?.length} signed`}
      />
      <footer className="fixed bottom-0 left-0 right-0 border-t border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-900 shadow-lg">
        <div className="container mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <button
                onClick={handleRefreshSwapState}
                disabled={refreshing}
                className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
              >
                <RefreshIcon />
              </button>

              <div className="flex flex-col">
                {swapState && (
                  <>
                    {swapInfo.signed === swapInfo.total ? (
                      <div className="flex items-center gap-2 text-green-500">
                        <TickIcon />
                        <span className="text-sm font-medium">
                          {swapInfo.total > 1
                            ? `You have signed ${swapInfo.signed} / ${swapInfo.total} swaps`
                            : "You have signed your swap"}
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
                        <WaitingIcon />
                        <span className="text-sm font-medium">
                          {swapInfo.signed < 1
                            ? "Please sign"
                            : `You have signed ${swapInfo.signed} / ${swapInfo.total} swaps`}
                        </span>
                      </div>
                    )}
                    <p className="text-xs text-gray-600 dark:text-gray-400">
                      Not satisfied? Share your feedback{" "}
                      <a
                        href="https://neoswap.cc/feedback"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#6565FF] hover:text-[#5151FF]"
                      >
                        here
                      </a>
                    </p>
                  </>
                )}
              </div>
            </div>

            <div className="flex items-center gap-3">
              <button
                onClick={onOpen}
                className={`${buttonClasses.base} ${buttonClasses.link}`}
              >
                View status
              </button>
              {connected ? (
                <SwapModal
                  handleRefreshSwapState={handleRefreshSwapState}
                  hasSwaps={hasSwaps}
                  roomId={roomId}
                  roomChain={roomChain}
                />
              ) : (
                <button
                  onClick={connect}
                  disabled={connecting}
                  className={`${buttonClasses.base} ${buttonClasses.primary}`}
                >
                  {connecting ? (
                    <>
                      <WaitingIcon />
                      <span>Connecting...</span>
                    </>
                  ) : (
                    !wallet ? "Choose Wallet" : "Connect"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
