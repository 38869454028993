import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useAppContext } from "../contexts/appContext";
import { loadNfts } from "../services/nfts.service";
import { Nft } from "../contexts/roomContext";
import MyNftCard from "../components/NftCollection/MyNftCard";

import { Squares2X2Icon as ViewGridIcon, ListBulletIcon as ViewListIcon, XMarkIcon } from "@heroicons/react/24/outline";



const sortOptions = [
  { value: "name_asc", label: "Name (A-Z)" },
  { value: "name_desc", label: "Name (Z-A)" },
  { value: "floor_asc", label: "Floor Price (Low to High)" },
  { value: "floor_desc", label: "Floor Price (High to Low)" },
  { value: "collection_asc", label: "Collection (A-Z)" },
  { value: "collection_desc", label: "Collection (Z-A)" },
];

// Add type for floor price calculation
const getFloorPrice = (nft: Nft): number => {
  return typeof nft.collection?.floorPrice === 'number'
    ? nft.collection.floorPrice
    : 0;
};

export default function MyItems() {
  const { uid } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [nfts, setNfts] = useState<Nft[]>([]);
  const [filteredNfts, setFilteredNfts] = useState<Nft[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("name_asc");
  const [selectedCollection, setSelectedCollection] = useState("all");
  const [isGridView, setIsGridView] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const getNfts = async () => {
      setIsLoading(true);
      try {
        const { data } = (await loadNfts(uid!)) as { data: Nft[] };
        const validNfts = data.filter(nft => nft && nft.collection);
        setNfts(validNfts);
        setFilteredNfts(validNfts);
      } catch (error) {
        console.error("Error loading NFTs:", error);
        setNfts([]);
        setFilteredNfts([]);
      }
      setIsLoading(false);
    };
    if (uid) getNfts();
  }, [uid]);

  // Get unique collections for filter dropdown
  const collections = ["all", ...new Set(nfts.map(nft => nft.collection.name))];

  // Update the getCollectionImage function to use collection.image directly
  const getCollectionImage = (collectionName: string) => {
    const firstNftInCollection = nfts.find(nft => nft.collection.name === collectionName);
    return firstNftInCollection?.collection?.image;
  };

  useEffect(() => {
    let result = [...nfts];

    // Apply collection filter
    if (selectedCollection !== "all") {
      result = result.filter(nft => nft.collection.name === selectedCollection);
    }

    // Apply search filter
    if (searchQuery) {
      result = result.filter(nft =>
        nft.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        nft.collection.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply sorting with type-safe comparisons
    result.sort((a, b) => {
      switch (sortBy) {
        case "name_asc":
          return a.name.localeCompare(b.name);
        case "name_desc":
          return b.name.localeCompare(a.name);
        case "floor_asc":
          return getFloorPrice(a) - getFloorPrice(b);
        case "floor_desc":
          return getFloorPrice(b) - getFloorPrice(a);
        case "collection_asc":
          return a.collection.name.localeCompare(b.collection.name);
        case "collection_desc":
          return b.collection.name.localeCompare(a.collection.name);
        default:
          return 0;
      }
    });

    setFilteredNfts(result);
  }, [nfts, searchQuery, sortBy, selectedCollection]);

  return (
    <div className="page-content bg-white dark:bg-gray-900 min-h-screen">
      <Helmet>
        <title>Tulle | My Items</title>
      </Helmet>

      <div className="p-4 sm:p-8">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-8">
          My Items
        </h2>

        <div className="relative flex flex-col lg:flex-row gap-6">
          {/* Sidebar */}
          <aside className="hidden lg:block w-72 flex-shrink-0">
            <div className="sticky top-20">
              <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-4">
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Search collections..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full h-10 px-4 rounded-lg 
                      border border-gray-200 dark:border-gray-700
                      bg-white dark:bg-gray-700 
                      text-gray-900 dark:text-white
                      focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>

                <div className="space-y-2 overflow-y-auto max-h-[calc(100vh-180px)]">
                  <button
                    onClick={() => setSelectedCollection("all")}
                    className={`w-full text-left px-3 py-2 rounded-lg transition-colors ${selectedCollection === "all"
                      ? "bg-primary text-white"
                      : "hover:bg-gray-100 dark:hover:bg-gray-700"
                      }`}
                  >
                    All Collections
                  </button>
                  {collections
                    .filter(c => c !== "all")
                    .map(collection => (
                      <button
                        key={collection}
                        onClick={() => setSelectedCollection(collection)}
                        className={`w-full text-left px-3 py-2 rounded-lg transition-colors flex items-center gap-3 ${selectedCollection === collection
                          ? "bg-primary text-white"
                          : "hover:bg-gray-100 dark:hover:bg-gray-700"
                          }`}
                      >
                        <div className="w-8 h-8 flex-shrink-0 rounded-full overflow-hidden bg-gray-100 dark:bg-gray-700">
                          <img
                            src={getCollectionImage(collection)}
                            alt={collection}
                            className="w-full h-full object-cover"
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = 'https://via.placeholder.com/32';
                            }}
                          />
                        </div>
                        <span className="truncate">{collection}</span>
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </aside>

          {/* Main content area */}
          <main className="flex-1">
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg">
              {isLoading ? (
                <div className="flex flex-col items-center justify-center p-12">
                  <div className="w-10 h-10 border-4 border-primary border-t-transparent rounded-full animate-spin"></div>
                  <p className="mt-4 text-gray-600 dark:text-gray-400">Loading your NFTs...</p>
                </div>
              ) : (
                <div className="p-6">
                  <div className="flex justify-between items-center mb-6">
                    {/* Mobile filter button */}
                    <button
                      className="lg:hidden px-4 py-2 rounded-lg bg-gray-100 dark:bg-gray-700
                        hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                      onClick={() => setIsSidebarOpen(true)}
                    >
                      Collections
                    </button>

                    <div className="flex items-center gap-4">
                      <div className="flex gap-2">
                        <button
                          onClick={() => setIsGridView(true)}
                          className={`p-2 rounded-lg transition-colors ${isGridView
                            ? "bg-primary text-white"
                            : "bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300"
                            }`}
                        >
                          <ViewGridIcon className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => setIsGridView(false)}
                          className={`p-2 rounded-lg transition-colors ${!isGridView
                            ? "bg-primary text-white"
                            : "bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300"
                            }`}
                        >
                          <ViewListIcon className="w-5 h-5" />
                        </button>
                      </div>

                      <div className="w-[200px]">
                        <select
                          value={sortBy}
                          onChange={(e) => setSortBy(e.target.value)}
                          className="w-full h-10 px-4 rounded-lg
                            border border-gray-200 dark:border-gray-700
                            bg-white dark:bg-gray-700
                            text-gray-900 dark:text-white
                            focus:ring-2 focus:ring-primary focus:border-transparent
                            appearance-none bg-no-repeat bg-right
                            bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQgNmw0IDQgNC00IiBzdHJva2U9IiM2QjcyODAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+')]"
                        >
                          {sortOptions.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  {filteredNfts.length === 0 ? (
                    <div className="flex justify-center items-center min-h-[200px]">
                      <p className="text-lg text-gray-500 dark:text-gray-400">
                        No NFTs found matching your criteria
                      </p>
                    </div>
                  ) : isGridView ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                      {filteredNfts.map((nft) => (
                        <MyNftCard
                          key={nft.itemId || `${nft.collection.name}-${nft.name}`}
                          nft={nft}
                          viewMode="grid"
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col gap-3">
                      {filteredNfts.map((nft) => (
                        <MyNftCard
                          key={nft.itemId || `${nft.collection.name}-${nft.name}`}
                          nft={nft}
                          viewMode="list"
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </main>

          {/* Mobile sidebar */}
          <div
            className={`lg:hidden fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity ${isSidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
              }`}
            onClick={() => setIsSidebarOpen(false)}
          >
            <div
              className={`absolute top-0 left-0 w-64 h-full bg-white dark:bg-gray-800 transform transition-transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                }`}
              onClick={e => e.stopPropagation()}
            >
              <div className="p-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold">Collections</h3>
                  <button
                    onClick={() => setIsSidebarOpen(false)}
                    className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <XMarkIcon className="w-5 h-5" />
                  </button>
                </div>

                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Search collections..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full h-10 px-4 rounded-lg 
                      border border-gray-200 dark:border-gray-700
                      bg-white dark:bg-gray-700 
                      text-gray-900 dark:text-white
                      focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>

                <div className="space-y-2">
                  <button
                    onClick={() => {
                      setSelectedCollection("all");
                      setIsSidebarOpen(false);
                    }}
                    className={`w-full text-left px-3 py-2 rounded-lg transition-colors ${selectedCollection === "all"
                      ? "bg-primary text-white"
                      : "hover:bg-gray-100 dark:hover:bg-gray-700"
                      }`}
                  >
                    All Collections
                  </button>
                  {collections
                    .filter(c => c !== "all")
                    .map(collection => (
                      <button
                        key={collection}
                        onClick={() => {
                          setSelectedCollection(collection);
                          setIsSidebarOpen(false);
                        }}
                        className={`w-full text-left px-3 py-2 rounded-lg transition-colors flex items-center gap-3 ${selectedCollection === collection
                          ? "bg-primary text-white"
                          : "hover:bg-gray-100 dark:hover:bg-gray-700"
                          }`}
                      >
                        <div className="w-8 h-8 flex-shrink-0 rounded-full overflow-hidden bg-gray-100 dark:bg-gray-700">
                          <img
                            src={getCollectionImage(collection)}
                            alt={collection}
                            className="w-full h-full object-cover"
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = 'https://via.placeholder.com/32';
                            }}
                          />
                        </div>
                        <span className="truncate">{collection}</span>
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
