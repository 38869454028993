import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { Firestore, getFirestore, initializeFirestore } from "firebase/firestore";
import { Functions, getFunctions } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import axios from "axios";

class Firebase {
  environment = {
    production: true,
    language: "en",
    api: process.env.REACT_APP_API_URL,
  };

  app!: FirebaseApp;
  auth!: Auth;
  db!: Firestore;
  fns!: Functions;

  initApp = new Promise(async (resolve, reject) => {
    try {
      let firebaseConfig;
      
      if (process.env.REACT_APP_AUTO_INIT === "true") {
        const res = await axios.get(`/__/firebase/init.json`);
        firebaseConfig = res.data;
      } else {
        firebaseConfig = {
          apiKey: process.env.REACT_APP_API_KEY,
          authDomain: process.env.REACT_APP_AUTH_DOMAIN,
          databaseURL: process.env.REACT_APP_DATABASE_URL,
          projectId: process.env.REACT_APP_PROJECT_ID,
          storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
          messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
          appId: process.env.REACT_APP_APP_ID,
          measurementId: process.env.REACT_APP_MEASUREMENT_ID,
        };
      }

      // Initialize Firebase app
      this.app = initializeApp(firebaseConfig);

      // Initialize AppCheck only in production
      if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_CAPTCHA_SITE_KEY) {
        try {
          initializeAppCheck(this.app, {
            provider: new ReCaptchaV3Provider(process.env.REACT_APP_CAPTCHA_SITE_KEY),
            isTokenAutoRefreshEnabled: true,
          });
        } catch (error) {
          console.warn('AppCheck initialization failed:', error);
        }
      } else if (process.env.NODE_ENV === 'development') {
        // @ts-ignore
        window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
      }

      // Initialize other Firebase services
      this.auth = getAuth(this.app);
      this.db = getFirestore(this.app);
      this.fns = getFunctions(this.app);

      resolve(this.app);
    } catch (error) {
      console.error('Firebase initialization error:', error);
      reject(error);
    }
  });

  getAuthApp = () => {
    return this.auth;
  };

  getDBApp = () => {
    return this.db;
  };
  
  getFnsApp = () => {
    return this.fns;
  };
}

export default new Firebase();