import { useEffect, useRef, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import PendingImg from "../../assets/pending.jpg";
import { useAppContext } from "../../contexts/appContext";
import { useRoom } from "../../contexts/roomContext";
import useNeoChain from "../../hooks/useNeoChain";

interface Props {
  nfts: any;
  saveProgress: () => void;
  handleRemove: (id: string) => void;
  removeAll: () => void;
  solving?: boolean;
  finishLabel: string;
}

const BottomNft = ({
  id,
  handleRemove,
}: {
  id: string;
  handleRemove: (id: string) => void;
}) => {
  const { availableForBid } = useRoom();
  const nft = availableForBid?.find((nft) => nft.itemId === id);

  return (
    <div className="relative">
      <button
        className="absolute -right-2.5 -top-2.5 flex items-center justify-center w-6 h-6 rounded-full bg-[#051a28] text-white hover:bg-gray-800 transition-colors"
        onClick={() => handleRemove(id)}
        aria-label="remove"
      >
        <FaTrashAlt className="w-3 h-3" />
      </button>
      <img
        className="min-w-[64px] w-16 h-16 object-cover rounded"
        src={nft?.thumbnail || nft?.image || ""}
        onError={(e) => {
          e.currentTarget.src = PendingImg;
        }}
        alt=""
      />
    </div>
  );
};

export default function BottomBar({
  nfts,
  saveProgress,
  handleRemove,
  removeAll,
  finishLabel,
}: Props) {
  const { uid } = useAppContext();
  const { room } = useRoom();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const chainFns = useNeoChain() as any;
  const roomChain = room?.blockchain?.name?.toLowerCase();
  const { cancel } = chainFns[roomChain || "not_found"];

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            {/* Background overlay */}
            <div
              className="fixed inset-0 transition-opacity bg-black bg-opacity-50"
              onClick={closeModal}
            ></div>

            {/* Modal content */}
            <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                      Your Bids
                    </h3>
                    <button
                      onClick={closeModal}
                      className="absolute right-4 top-4 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 transition-colors"
                    >
                      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    <div className="mt-4">
                      <div className="flex flex-wrap gap-5">
                        {nfts?.sort().map((id: string) => (
                          <BottomNft key={id} id={id} handleRemove={handleRemove} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={closeModal}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-800 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Bottom Bar */}
      <div className="fixed bottom-0 left-0 w-full backdrop-blur-md border-t dark:border-white/10 border-black/10 dark:bg-gray-800/80 bg-white/80 py-2 px-6 z-50">
        <div className="w-full max-w-7xl mx-auto flex justify-end">
          <div className="flex items-center gap-4">
            {uid === "ethereum-0xd54DA03AF0Fd56f57820f94f4245F51ED349493f" && cancel && (
              <button
                onClick={cancel}
                className="px-3 py-1.5 text-sm bg-gray-100/80 dark:bg-white/10 text-gray-800 dark:text-gray-200 rounded-lg hover:bg-gray-200/80 dark:hover:bg-white/15 transition-colors"
              >
                Cancel Swap
              </button>
            )}
            <button
              onClick={saveProgress}
              className="px-3 py-1.5 text-sm bg-[#6C60FF] hover:bg-[#5B50EF] text-white rounded-lg transition-colors font-medium shadow-sm"
            >
              {finishLabel}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
