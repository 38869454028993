import react, { useState } from "react";
import { Image, IconButton } from "@chakra-ui/react";

import {
  getReserveLabel,
  createInitialPrice,
  formatName,
} from "../../../utils";

import ReservePriceForm from "../../ReservePriceForm/ReservePriceForm";

import PendingImg from "../../../assets/pending.jpg";
import IconTrash from "../../../components/Icons/IconTrash";
import { useRoom, Nft } from "../../../contexts/roomContext";

interface PropTypes {
  nft?: Nft;
  decimals: number;
  abbr?: string;
  handleRemove: (id: string) => void;
  id: string;
  allowEditRemove?: boolean;
  status?: "selling" | "notSelling";
}

export default function SidebarListItem({
  nft,
  decimals,
  abbr,
  handleRemove,
  id,
  allowEditRemove = true,
  status,
}: PropTypes) {
  const { listings, availableToList, truncate, setModalId } = useRoom();
  const listing = listings?.[id];
  const [listedPrice, setListedPrice] = useState<number | string>(
    createInitialPrice(nft, decimals, listing)
  );

  const [isEditingPrice, setIsEditingPrice] = useState(false);

  // Status color indicators with dark mode support
  const statusClasses = {
    selling: "border-l-4 border-green-500 dark:border-green-600",
    notSelling: "border-l-4 border-amber-500 dark:border-amber-600",
  };

  return (
    <div
      className={`flex items-center p-3 my-2 bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-shadow ${status ? statusClasses[status] : ""}`}
      key={id}
    >
      <Image
        className="min-w-[45px] w-[45px] h-[45px] object-cover cursor-pointer rounded-md overflow-hidden"
        src={nft?.thumbnail || nft?.image || ""}
        fallbackSrc={PendingImg}
        alt=""
        onClick={() => setModalId(id)}
      />
      <div className="flex-1 mx-3">
        <span className="flex flex-col">
          <h3
            className="text-sm font-medium cursor-pointer truncate text-gray-900 dark:text-gray-200"
            onClick={() => setModalId(id)}
          >
            {nft && formatName(nft.name)}
          </h3>
          {status && (
            <div className="flex items-center mt-1">
              <span
                className={`text-xs px-2 py-0.5 rounded-full font-medium ${status === "selling"
                  ? "bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400"
                  : "bg-amber-100 text-amber-800 dark:bg-amber-900/30 dark:text-amber-400"
                  }`}
              >
                {status === "selling" ? "Selling" : "Not Selling"}
              </span>
            </div>
          )}
          {/* <div className="flex justify-between text-xs text-gray-600 dark:text-gray-400">
            <p>Reserve</p>
            <p className="font-medium">
              {decimals &&
                abbr &&
                getReserveLabel(
                  listings?.[id]?.listingPrice,
                  decimals,
                  abbr,
                  truncate
                )}
            </p>
          </div> */}
        </span>
        {allowEditRemove && (
          <div className="mt-2">
            {nft ? (
              <ReservePriceForm
                nfts={availableToList}
                biddingId={nft.itemId}
                type="listing"
                listedPrice={listings?.[id]?.listingPrice}
                startingBid={listings?.[id]?.startingBid}
                setListedPrice={setListedPrice}
                isEditingPrice={isEditingPrice}
                setIsEditingPrice={(status: boolean) =>
                  setIsEditingPrice(status)
                }
              />
            ) : null}
          </div>
        )}
      </div>
      {allowEditRemove && (
        <div className="flex items-center">
          {nft ? (
            <IconButton
              className="text-gray-600 hover:text-red-500 dark:text-gray-400 dark:hover:text-red-400 transition-colors bg-transparent hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full p-2"
              onClick={() => handleRemove(nft.itemId)}
              aria-label="Remove"
              icon={<IconTrash />}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}
