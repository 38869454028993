import { Button } from "@chakra-ui/react";
import IconEyeSlash from "../Icons/IconEyeSlash";

const PassButton = ({
  hasBid,
  handlePass,
}: {
  hasBid: boolean;
  handlePass: any;
}) => {
  return (
    <>
      <Button
        variant="outline"
        isDisabled={hasBid}
        onClick={handlePass}
        rounded="md"
        borderColor="gray.300"
        color="gray.700"
        backgroundColor="white"
        _hover={{ backgroundColor: "gray.100" }}
        _dark={{
          borderColor: "gray.600",
          color: "gray.200",
          backgroundColor: "gray.800",
          _hover: { backgroundColor: "gray.700" }
        }}
        leftIcon={<IconEyeSlash />}
      >
        Hide
      </Button>
    </>
  );
};

export default PassButton;
