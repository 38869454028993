import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useToast } from "@chakra-ui/react";
import { Tooltip } from "react-tooltip";
import PendingImg from "../../assets/pending.jpg";
import { addListing, removeListing } from "../../services/room.service";
import { createFloor, roundValue, getVolumeLabel } from "../../utils";
import { useUA } from "../../contexts/userTracking";
import { Nft, useRoom } from "../../contexts/roomContext";
import { useAppContext } from "../../contexts/appContext";
import IconPurpleEye from "../Icons/IconPurpleEye";
import IconLink from "../Icons/IconLink";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import TxtBlock from "../TxtBlock";
import HTMLBlock from "../HTMLBlock";
import TransactionModal from "../ui/TransactionModal";

interface Props {
  listing?: {
    reservePrice: number;
    listingPrice: number;
    startingBid: number;
    userDescription?: string;
  };
  nfts: Nft[];
}

const createImage = (nft?: Nft) => {
  const img = nft?.image || nft?.thumbnail;
  if (nft?.assetType?.includes("text/plain")) {
    return (
      <TxtBlock
        nft={nft}
        width="100%"
        maxW="450px"
        fontSize="12px"
        height="100%"
      />
    );
  }
  if (nft?.assetType?.includes("text/html")) {
    return <HTMLBlock nft={nft} style={{ maxWidth: "450px" }} />;
  }
  return (
    <img
      src={img}
      alt={nft?.name}
      className="w-full h-full object-cover"
      onError={(e) => {
        e.currentTarget.src = PendingImg;
      }}
    />
  );
};

const ListModal = ({ nfts }: Props) => {
  const { gaAddNft, gaSetReservePrice } = useUA();
  const { uid } = useAppContext();
  const {
    roomId,
    room,
    abbr,
    step,
    decimals,
    handleNext,
    handlePrev,
    availableToList,
    listings,
    setModalId,
    modalId,
    truncate,
  } = useRoom();

  const toast = useToast();
  const nft = nfts?.find((nft) => nft.itemId === modalId);
  const itemIndex = nfts?.findIndex((nft) => nft?.itemId === modalId) + 1;
  const listing = listings?.[modalId || ""];
  const availableToListCount = availableToList?.filter(
    (item) => item.locks.length === 0
  ).length;

  const [currentTab, setCurrentTab] = useState("listing");
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [nftDetails, setNftDetails] = useState<string | undefined>(
    listing?.userDescription
  );
  const [listedPrice, setListedPrice] = useState<number | string>(() => {
    let price = 0;
    if (listing?.listingPrice) {
      price = roundValue(listing.listingPrice / (10 ** (decimals || 0)), truncate);
    } else if (listing?.reservePrice) {
      price = roundValue(listing.reservePrice / (10 ** (decimals || 0)), truncate);
    } else if (room?.info?.minReservePrice) {
      price = room.info.minReservePrice / (10 ** (decimals || 0));
    }
    return isNaN(price) ? 0 : price;
  });
  const [startingBid, setStartingBid] = useState<number | string>(() => {
    let bid = 0;
    if (listing?.startingBid) {
      bid = roundValue(listing.startingBid / (10 ** (decimals || 0)), truncate);
    }
    return isNaN(bid) ? 0 : bid;
  });
  const [reserveAndStartingMatch, setReserveAndStartingMatch] = useState(true);

  const canList = Object.values(listings || {}).length <= room?.info?.numNftsToList;
  const isListed = typeof listing?.listingPrice === "number";
  const min = 0;
  const max = room?.info?.maxReservePrice;

  const handleCheckError = (value: string | number) => {
    console.log("Listingnft?.itemId")
    console.log(nft?.itemId)
    const val = Number(value);
    if (val > max || val < min) {
      if (val > max) setError("Price too high for the room!");
      if (val < min) setError("Price too low for the room!");
    } else {
      setError(null);
    }
  };

  const handleAddListing = (val?: string | number) => {

    const roundedVal = roundValue(Number(listedPrice), truncate);
    if (!canList) {
      return toast({
        title: "You've reached your limit",
        description:
          "You've listed the maximum number of Items for this room. Please remove one to list another.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
    if (!nft?.itemId) return;
    const listedNum = Number(val || roundedVal);
    if (!listedNum && listedNum !== 0) {
      return setError("Please enter a valid reserve price");
    }
    if (listedNum > max || listedNum < min) {
      return handleCheckError(listedNum);
    }
    setError(null);
    const minAmount = room?.info?.minReservePrice / 10 ** decimals;
    const maxAmount = room?.info?.maxReservePrice / 10 ** decimals;
    if (listedNum === 0 && !confirm) {
      if (listedNum < minAmount) {
        alert(`The minimum reserve price is ${minAmount} ${abbr}`);
      } else {
        return setConfirm(true);
      }
    } else {
      if (listedNum > maxAmount) {
        alert(`The maximum reserve price is ${maxAmount} ${abbr}`);
        return;
      }
      if (listedNum < minAmount) {
        alert(`The minimum reserve price is ${minAmount} ${abbr}`);
        return;
      }
      if (listedNum < Number(startingBid)) {
        alert("Starting bid must be equal or less than reserve price");
        return;
      }
      setListedPrice(listedNum);
      let listingPrice = listedNum * 10 ** decimals;
      const royaltyRate = nft?.royalty?.rate || 0;
      let adjustedReservePrice = listingPrice / (1 + (room.info.markupPercent / 100) + royaltyRate);
      addListing(roomId, uid!, nft.itemId, {
        reservePrice: adjustedReservePrice,
        listingPrice: listingPrice,
        startingBid: reserveAndStartingMatch ? listingPrice : Number(startingBid) * 10 ** decimals,
        userDescription: nftDetails || null,
      });
      gaAddNft(roomId, nft.itemId);
      gaSetReservePrice(roomId, nft.itemId, listingPrice);
      setConfirm(false);
      return toast({
        title: "Success!",
        description: "Your listing has been updated.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleRemove = async () => {
    if (!nft?.itemId) return;
    await removeListing(roomId, uid!, nft?.itemId);
  };

  const handleKeyDown = (e: any) => {
    if (e?.key === "Enter") {
      handleAddListing();
    }
  };

  const handleQuickSet = (markdown?: number) => {
    if (markdown === undefined) {
      setListedPrice(0);
      handleAddListing(0);
      return;
    }

    if (markdown === 100) {
      if (Number(listedPrice) > 0) {
        setListedPrice(0);
        setConfirm(true);
        return;
      }
      setListedPrice(0);
      handleAddListing(0);
      return;
    }

    if (!nft?.collection?.floorPrice?.floorPrice?.valueConverted) return;

    const collectionFloorPrice =
      nft.collection.floorPrice.floorPrice.valueConverted /
      10 ** nft.collection.floorPrice.floorPrice.decimals;

    const markdownValue = markdown !== undefined ? markdown : 0;
    const markdownFactor =
      markdownValue === 0 ? 0 : markdownValue === 100 ? 1 : markdownValue / 100;

    const newPrice = roundValue(
      collectionFloorPrice - collectionFloorPrice * markdownFactor,
      truncate
    );

    setListedPrice(newPrice);
    handleAddListing(newPrice);
  };

  const tab = (name: string, slug: string) => {
    return (
      <button
        className={`px-5 py-2.5 text-lg font-bold cursor-pointer transition-colors
          ${currentTab === slug ? 'bg-[#052032]' : 'hover:bg-gray-700/50'}`}
        onClick={() => setCurrentTab(slug)}
      >
        {name}
      </button>
    );
  };

  const modifiedAttributes = Array.isArray(nft?.attributes)
    ? nft.attributes.map((attr) => {
      if (typeof attr.value === 'string') {
        const modifiedValue = attr.value
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return { ...attr, value: modifiedValue };
      }
      return attr;
    })
    : [];

  const handleNftDetailsChange = (e: any) => {
    setNftDetails(e.target.value);
  };

  const renderPriceInput = (
    label: string,
    value: number | string,
    onChange: (value: string) => void,
    tooltip: string,
    min: number,
    max?: number,
    disabled?: boolean
  ) => (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">{label}</span>
        <a
          data-tooltip-id="listing-tooltip"
          data-tooltip-content={tooltip}
          data-tooltip-place="right"
        >
          <div className="h-4 w-4 text-gray-400">
            <IconPurpleEye />
          </div>
        </a>
      </div>
      <div className="relative">
        <input
          type="number"
          value={isNaN(value as number) ? 0 : value}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={() => handleAddListing()}
          disabled={disabled}
          min={min}
          max={max}
          className={`w-full rounded-lg border border-gray-300 bg-white px-4 py-2 text-gray-900 
            focus:border-primary focus:ring-2 focus:ring-primary dark:border-gray-600 
            dark:bg-gray-700 dark:text-white dark:focus:border-primary ${disabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-sm text-gray-500 dark:text-gray-400">{abbr}</span>
        </div>
      </div>
    </div>
  );

  const renderQuickSetButton = (
    label: string,
    discount: number | undefined,
    onClick: () => void
  ) => (
    <button
      onClick={onClick}
      className="flex w-full items-center justify-between rounded-lg border border-gray-200 
        bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 
        dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
    >
      <span>{label}</span>
      {discount !== undefined && (
        <span className="text-primary">-{discount}%</span>
      )}
    </button>
  );

  return (
    <TransactionModal
      isOpen={!!nft}
      onClose={() => setModalId(undefined)}
      size="4xl"
      className="dark:bg-gray-900 max-h-[90vh] overflow-hidden"
      headerClassName="dark:bg-gray-800/50"
      contentClassName="dark:bg-gray-900/50"
      title="List Item"
      footer={
        <div className="flex justify-between w-full">
          <button
            type="button"
            onClick={() => setModalId(undefined)}
            className="rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm 
              font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-600 
              dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
          >
            Cancel
          </button>
          {isListed ? (
            <button
              className="rounded-lg bg-red-600 px-4 py-2 text-sm font-medium text-white 
                hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 
                focus:ring-offset-2"
              onClick={handleRemove}
            >
              Remove
            </button>
          ) : (
            <button
              className="rounded-lg bg-primary px-4 py-2 text-sm font-medium text-white 
                hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary 
                focus:ring-offset-2"
              onClick={() => handleAddListing()}
            >
              List
            </button>
          )}
        </div>
      }
    >
      <div className="flex gap-6 h-[calc(90vh-12rem)] overflow-hidden">
        {/* Left side - Image and Navigation */}
        <div className="w-1/3 flex-shrink-0 flex flex-col">
          <div className="relative flex-1 overflow-hidden">
            <div className={`h-full rounded-lg ${isListed ? 'ring-2 ring-primary' : ''}`}>
              {createImage(nft)}
            </div>
          </div>

          {!confirm && (
            <div className="mt-4 flex items-center justify-between">
              <button
                className="flex items-center gap-2 rounded-lg border border-gray-300 
                  bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 
                  disabled:opacity-50 dark:border-gray-600 dark:bg-gray-700 
                  dark:text-white dark:hover:bg-gray-600"
                onClick={() => handlePrev(nfts)}
                disabled={nfts.length < 2}
              >
                <FaArrowLeft className="h-4 w-4" />
                <span>Previous</span>
              </button>

              <span className="text-sm text-gray-500 dark:text-gray-400">
                Viewing [{itemIndex}/{availableToListCount}]
              </span>

              <button
                className="flex items-center gap-2 rounded-lg border border-gray-300 
                  bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 
                  disabled:opacity-50 dark:border-gray-600 dark:bg-gray-700 
                  dark:text-white dark:hover:bg-gray-600"
                onClick={() => handleNext(nfts)}
                disabled={nfts.length < 2}
              >
                <span>Next</span>
                <FaArrowRight className="h-4 w-4" />
              </button>
            </div>
          )}
        </div>

        {/* Right side - Content */}
        <div className="w-2/3 flex-1 overflow-y-auto pr-2">
          <div className="flex flex-col gap-4">
            <div className="flex border-b border-gray-200 dark:border-gray-700">
              {tab("Details", "details")}
              {tab("Listing", "listing")}
            </div>

            {currentTab === "listing" ? (
              confirm ? (
                <div className="rounded-lg bg-gray-50 p-6 dark:bg-gray-800">
                  <div className="text-center">
                    <p className="mb-4 text-lg font-medium text-gray-900 dark:text-white">
                      Are you sure you want to list this Item for free?
                    </p>
                    <div className="flex justify-center gap-4">
                      <button
                        className="rounded-lg border border-gray-300 bg-white px-4 py-2 
                          text-sm font-medium text-gray-700 hover:bg-gray-50 
                          dark:border-gray-600 dark:bg-gray-700 dark:text-white 
                          dark:hover:bg-gray-600"
                        onClick={() => setConfirm(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="rounded-lg bg-primary px-4 py-2 text-sm font-medium 
                          text-white hover:bg-primary-dark focus:outline-none focus:ring-2 
                          focus:ring-primary focus:ring-offset-2"
                        onClick={() => handleQuickSet()}
                        autoFocus
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="space-y-6">
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    {renderPriceInput(
                      "Reserve Price",
                      listedPrice,
                      (value) => {
                        handleCheckError(value);
                        setListedPrice(value);
                      },
                      "The absolute minimum price that you would be willing to sell the item for",
                      min,
                      max
                    )}

                    {renderPriceInput(
                      "Starting Bid",
                      reserveAndStartingMatch ? listedPrice : startingBid || 0,
                      (value) => {
                        handleCheckError(value);
                        setStartingBid(value);
                      },
                      "The price at which bidding should start (must be equal or less than the reserve)",
                      0,
                      undefined,
                      reserveAndStartingMatch
                    )}
                  </div>

                  <div className="space-y-2">
                    <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                      Quick Set Options
                    </h4>
                    <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                      {renderQuickSetButton("Floor Price", 0, () => handleQuickSet(0))}
                      {renderQuickSetButton("Clearance", 50, () => handleQuickSet(50))}
                      {renderQuickSetButton("Fire Sale", 75, () => handleQuickSet(75))}
                      {renderQuickSetButton("Free", 100, () => handleQuickSet(100))}
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-900 dark:text-white">
                      Additional Information (optional)
                    </label>
                    <textarea
                      value={nftDetails}
                      onChange={handleNftDetailsChange}
                      placeholder="Enter details about your Item"
                      onBlur={() => handleAddListing()}
                      className="w-full rounded-lg border border-gray-300 bg-white px-4 py-2 
                        text-gray-900 focus:border-primary focus:ring-2 focus:ring-primary 
                        dark:border-gray-600 dark:bg-gray-700 dark:text-white 
                        dark:focus:border-primary"
                      rows={4}
                    />
                  </div>
                </div>
              )
            ) : (
              <div className="space-y-6">
                <div className="space-y-4">
                  {nft?.collection.name || nft?.collection.twitter ? (
                    <div className="space-y-1">
                      <h4 className="text-lg font-medium text-gray-900 dark:text-white">
                        {nft?.collection.twitter ? (
                          <a
                            href={nft.collection.twitter}
                            target="_blank"
                            rel="noreferrer"
                            className="hover:text-primary"
                          >
                            {nft.collection.twitter}
                          </a>
                        ) : (
                          <span>{nft?.collection.name}</span>
                        )}
                      </h4>
                    </div>
                  ) : null}

                  <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                    {nft?.name}
                  </h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="rounded-lg bg-gray-50 p-4 dark:bg-gray-800">
                      <p className="text-sm text-gray-500 dark:text-gray-400">Floor</p>
                      <p className="mt-1 text-lg font-medium text-gray-900 dark:text-white">
                        {createFloor(nft)}
                      </p>
                    </div>

                    <div className="rounded-lg bg-gray-50 p-4 dark:bg-gray-800">
                      <p className="text-sm text-gray-500 dark:text-gray-400">Volume</p>
                      <p className="mt-1 text-lg font-medium text-gray-900 dark:text-white">
                        {nft &&
                          getVolumeLabel(
                            nft.collection.volume,
                            nft?.collection?.floorPrice?.floorPrice?.decimals || 0,
                            abbr,
                            truncate
                          )}
                      </p>
                    </div>
                  </div>

                  {nft?.attributes && (
                    <div className="space-y-2">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                        Attributes
                      </h4>
                      <div className="rounded-lg border border-gray-200 dark:border-gray-700">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                          <thead>
                            <tr>
                              <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">
                                Type
                              </th>
                              <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">
                                Value
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                            {modifiedAttributes?.map((attr) => (
                              <tr key={attr.trait_type}>
                                <td className="px-4 py-2 text-sm text-gray-900 dark:text-white">
                                  {attr.trait_type}
                                </td>
                                <td className="px-4 py-2 text-sm text-gray-900 dark:text-white">
                                  {attr.value}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  <div className="space-y-2">
                    <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                      Description
                    </h4>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {nft?.description || "This Item has no description."}
                    </p>
                  </div>

                  {nftDetails && (
                    <div className="space-y-2">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                        Additional Info Provided by Seller
                      </h4>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {nftDetails}
                      </p>
                    </div>
                  )}

                  {nft?.marketplace?.url && (
                    <div className="pt-4">
                      <a
                        href={nft.marketplace.url}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex items-center gap-2 text-primary hover:text-primary-dark"
                      >
                        See on Marketplace
                        <IconLink className="h-4 w-4" />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </TransactionModal>
  );
};

export default memo(ListModal);
