export default function IconStandardDuration() {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            xmlSpace="preserve"
        >
            <path
                d="M15,0C6.7,0,0,6.7,0,15s6.7,15,15,15s15-6.7,15-15S23.3,0,15,0z M15,27.3C8.2,27.3,2.7,21.8,2.7,15S8.2,2.7,15,2.7
        S27.3,8.2,27.3,15C27.3,21.8,21.8,27.3,15,27.3z"
                fill="currentColor"
            />
            <path
                d="M15,7.5c-0.8,0-1.4,0.6-1.4,1.4v6.1c0,0.4,0.2,0.8,0.5,1.1l4.3,4.3c0.5,0.5,1.4,0.5,1.9,0s0.5-1.4,0-1.9l-3.9-3.9V8.9
        C16.4,8.1,15.8,7.5,15,7.5z"
                fill="currentColor"
            />
            <path
                d="M15,4.8c0.8,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4s-1.4,0.6-1.4,1.4S14.2,4.8,15,4.8z"
                fill="currentColor"
            />
            <path
                d="M15,25.2c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S15.8,25.2,15,25.2z"
                fill="currentColor"
            />
            <path
                d="M25.2,15c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4S25.2,14.2,25.2,15z"
                fill="currentColor"
            />
            <path
                d="M2,15c0,0.8,0.6,1.4,1.4,1.4S4.8,15.8,4.8,15s-0.6-1.4-1.4-1.4S2,14.2,2,15z"
                fill="currentColor"
            />
        </svg>
    );
} 