import {
  Button,
  Stack,
  useDisclosure,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  useToast,
  Alert,
  AlertDescription,
  AlertIcon,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../contexts/appContext";
import useNeoChain from "../hooks/useNeoChain";
import { addTxId, getSwaps, updateSwapField, generateAiRoom, getAiRoom } from "../services/room.service";
import { InfoIcon, WarningIcon } from "@chakra-ui/icons";
import { useUA } from "../contexts/userTracking";
import AiTradesButton from "./AiTradesV2/Button";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AiRoom } from "../components/AiTradesV2/types";
import { acceptAiOffer } from "../pages/AiOffers";

export type UserSwap = {
  blockchain: "solana" | "ethereum" | "stacks";
  type: string;
  order: number;
  description: string;
  config: any;
  txId?: string;
  swapId?: string;
};

const DepositItem = ({
  swap,
  fetchSwaps,
  canSign,
  deposit,
  roomChain,
  setShowGenerateAgain,
  onSigned,
  alreadySigned,
}: {
  swap: UserSwap;
  fetchSwaps: () => void;
  canSign: boolean;
  deposit: (args: any) => Promise<any>;
  roomChain: string;
  setShowGenerateAgain?: (value: boolean) => void;
  onSigned?: () => void;
  alreadySigned?: boolean;
}) => {
  const toast = useToast();
  const { gaSignSwap } = useUA();
  const [depositing, setDepositing] = useState(false);
  const { getTokenBalance, uid } = useAppContext();
  const chainFns = useNeoChain() as any;
  const chain = chainFns[roomChain || "not_found"];

  const errorThatShoudGenerateAgain = chain?.errorThatShoudGenerateAgain;

  const handleDeposit = async () => {
    try {
      setDepositing(true);
      console.log(swap.config);
      const res = await deposit(swap.config);
      console.log("RES", res);
      const { hashes: txIds, programId, signedPsbt } = res;
      if (signedPsbt) {
        console.log("WE RECEIVED A SIGNED PSBT", signedPsbt);
        await updateSwapField(
          swap.config.path,
          swap.config.propName,
          signedPsbt
        );
        await getTokenBalance(uid!);
      } else {
        if (!txIds) return setDepositing(false);
        if (txIds) {
          await Promise.all(
            txIds.map(async (txId: string) => {
              const data = {
                ...swap,
                txId,
                status: "pending",
              } as any;
              if (programId) {
                data.programId = programId;
              }
              gaSignSwap(txId);
              console.log("DATA", data);
              return await addTxId(roomChain, txId, data);
            })
          );
        }
        await getTokenBalance(uid!);
        setDepositing(false);
        if (onSigned) {
          onSigned();
        }
      }
    } catch (e: any) {
      setDepositing(false);
      if (errorThatShoudGenerateAgain(e)) {
        if (setShowGenerateAgain) {
          setShowGenerateAgain(true);
        }
      }
      toast({
        title: "Error Purchasing!",
        description:
          e?.message || "Please report a bug through the feedback button.",
        status: "error",
        duration: 9000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });
    } finally {
      fetchSwaps();
    }
  };

  const btnLabel = alreadySigned ? "Signed" : "Sign";

  return (
    <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4 space-y-4">
      <div className="text-gray-700 dark:text-gray-200" dangerouslySetInnerHTML={{ __html: swap.description }} />
      <div className="flex justify-end">
        <Button
          onClick={handleDeposit}
          isLoading={depositing}
          loadingText="...Purchasing"
          isDisabled={!canSign}
          className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition-colors disabled:opacity-50"
        >
          {btnLabel}
        </Button>
      </div>
    </div>
  );
};

const TextElement = (props: any) => {
  const [value, setValue] = useState("");

  return (
    <form
      onSubmit={async (e: any) => {
        e.preventDefault();
        await updateSwapField(props.path, props.propName, value);
        props.fetchSwaps();
      }}
      className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4 space-y-4"
    >
      <div className="space-y-2">
        <p className="text-gray-700 dark:text-gray-200" dangerouslySetInnerHTML={{ __html: props.label }} />
        <Input
          placeholder={props.placeholder}
          onChange={(e: any) => setValue(e.target.value)}
          required
          className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
        />
      </div>
      <div className="flex justify-end">
        <Button
          type="submit"
          className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition-colors"
        >
          Save
        </Button>
      </div>
    </form>
  );
};

const SwapForm = ({
  swap,
  fetchSwaps,
}: {
  swap: UserSwap;
  fetchSwaps: () => void;
}) => {
  const toast = useToast();
  const isMountedRef = useRef(false);

  const handleCopy = async (event: any) => {
    const txt = typeof event === "object" ? event?.target?.innerText : event;
    if (!txt) return;

    await navigator.clipboard.writeText(txt);
    toast({
      title: "Copied to clipboard!",
      status: "success",
      duration: 9000,
      isClosable: true,
      styleConfig: {
        zIndex: 999999,
      },
    });
  };

  useEffect(() => {
    if (isMountedRef.current) return;
    const ordinal = document.getElementById(`${swap.swapId}-ordinal`);
    const utxo = document.getElementById(`${swap.swapId}-utxo`);
    const btcAddr = document.getElementById(`${swap.swapId}-btc`);
    if (ordinal) {
      ordinal.style.cursor = "pointer";
      const btn = document.createElement("button");
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        const txt = ordinal.getElementsByTagName("a")[0].innerHTML;
        handleCopy(txt);
      });
      btn.classList.add("ml-2", "px-2", "py-1", "text-sm", "bg-blue-600", "text-white", "rounded", "hover:bg-blue-700");
      btn.id = `copy-${swap.swapId}-ordinal`;
      btn.innerText = "Copy";
      ordinal.appendChild(btn);
    }

    if (utxo) {
      utxo.style.cursor = "pointer";
    }
    utxo?.addEventListener("click", handleCopy);

    if (btcAddr) {
      btcAddr.style.cursor = "pointer";
    }
    btcAddr?.addEventListener("click", handleCopy);
    isMountedRef.current = true;
  }, []);

  return swap?.config?.map((ele: any, index: number) => {
    if (ele.type === "text") {
      return <TextElement key={index} fetchSwaps={fetchSwaps} {...ele} />;
    }
    return null;
  });
};

export default function SwapModal({
  roomId,
  aiRoomId,
  hasSwaps,
  handleRefreshSwapState,
  roomChain,
  aiTrades,
  setHasSigned,
}: {
  roomId?: string;
  aiRoomId?: string;
  hasSwaps: boolean;
  handleRefreshSwapState: () => Promise<void>;
  roomChain: string;
  aiTrades?: boolean;
  setHasSigned?: () => void;
}) {
  const { uid } = useAppContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [swaps, setSwaps] = useState<UserSwap[]>([]);
  const [signedSwaps, setSignedSwaps] = useState<{ [key: string]: boolean }>({});
  const [showGenerateAgain, setShowGenerateAgain] = useState(false);
  const [generating, setGenerating] = useState(false);
  const navigate = useNavigate();

  const handleItemSigned = (order: number) => {
    setSignedSwaps(prev => ({ ...prev, [order]: true }));

    if (swaps) {
      const allSigned = swaps.every(swap => signedSwaps[swap.order] || swap.order === order);
      if (allSigned && setHasSigned) {
        setHasSigned();
      }
    }
  };

  const chainFns = useNeoChain() as any;
  const { deposit, connected, connect } = chainFns[roomChain || "not_found"];

  const fetchSwaps = async () => {
    try {
      const [{ data }] = await Promise.all([
        (await getSwaps({ roomId, aiRoomId, userId: uid })) as any,
        handleRefreshSwapState(),
      ]);
      const results: UserSwap[] = JSON.parse(data) || [];
      setSwaps(results.sort((a: UserSwap, b: UserSwap) => a.order - b.order));
    } finally {
      console.log("done fetching swaps");
    }
  };

  const handleGenerateAgain = async () => {
    setGenerating(true);
    const roomDoc = await getAiRoom(aiRoomId!);
    const room = roomDoc.data() as AiRoom;
    const acceptedOffer = room.acceptedOffer;

    const res = await generateAiRoom({ userId: uid!, campaign: room.campaign, token: room.info.token });
    if (!res.success) {
      throw new Error("Failed to fetch data");
    }

    const newRoomDoc = await getAiRoom(res.aiRoomId);
    const newRoom = newRoomDoc.data() as AiRoom;

    const newOfferId = Object.keys(newRoom.offers).find((key) => {
      const offer = newRoom.offers[key];
      return offer.name === acceptedOffer?.name && offer.subname === acceptedOffer?.subname;
    });

    await acceptAiOffer({ walletId: uid!, aiRoomId: res.aiRoomId, offerId: newOfferId! })

    navigate(`/ai-trades/${res.aiRoomId}`, { replace: true });

    setGenerating(false);
  };

  const { isFetching, isRefetching, data } = useQuery({
    queryKey: ["nfts", roomId, aiRoomId, uid],
    queryFn: async () => {
      const { data } = await getSwaps({ roomId, aiRoomId, userId: uid });
      return data;
    },
    staleTime: 5000,
    refetchOnWindowFocus: false,
    refetchInterval: 10000,
    onSuccess: (data: string) => {
      const results: UserSwap[] = JSON.parse(data) || [];
      setSwaps(results.sort((a: UserSwap, b: UserSwap) => a.order - b.order));
      handleRefreshSwapState();
    },
  });

  useEffect(() => {
    if (!connected) {
      connect();
    }
  }, []);

  return (
    <>
      {aiTrades && connected && (
        <AiTradesButton
          handleClick={() => {
            onOpen();
            fetchSwaps();
          }}
          isDisabled={!hasSwaps}
          className="w-full md:w-52 flex items-center justify-center px-10 md:px-4 py-6 mx:py-4 bg-gradient-to-l from-sky-500 via-blue-600 to-indigo-500 rounded-md text-base font-bold text-center text-white capitalize"
        >
          Buy Pack
        </AiTradesButton>
      )}
      {!connected && (
        <Button
          onClick={connect}
          isDisabled={!hasSwaps}
          className="w-full md:w-auto px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors disabled:opacity-50"
        >
          Connect Wallet
        </Button>
      )}
      {!aiTrades && connected && (
        <Button
          onClick={() => {
            onOpen();
            fetchSwaps();
          }}
          isDisabled={!hasSwaps}
          className="w-full md:w-auto px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors disabled:opacity-50"
        >
          Sign
        </Button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="3xl"
        id="swap-modal"
        isCentered={true}
        scrollBehavior="inside"
        trapFocus={false}
      >
        <ModalOverlay className="bg-black/60" />
        <ModalContent className="bg-white dark:bg-gray-900 rounded-xl shadow-xl">
          <ModalHeader className="border-b border-gray-200 dark:border-gray-700">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Your Transactions</h2>
            <Alert
              status="info"
              className="mt-4 bg-gradient-to-r from-red-500/10 to-transparent dark:from-red-900/20 rounded-lg"
            >
              <AlertIcon className="text-blue-600 dark:text-blue-400" />
              <AlertDescription className="text-sm text-gray-700 dark:text-gray-300">
                Warning: Do not refresh the page while the transaction is executing. This transaction is complex and may require multiple attempts to complete.
              </AlertDescription>
            </Alert>
          </ModalHeader>
          <ModalCloseButton className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200" />
          <ModalBody className="p-6">
            <div className="space-y-6">
              {isFetching && !isRefetching && (
                <div className="text-center py-8">
                  <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-2">Loading, please wait...</h2>
                  <p className="text-gray-500 dark:text-gray-400">We are fetching on-chain data, this may take a moment.</p>
                </div>
              )}
              <Skeleton isLoaded={!!data} className="min-h-[200px]">
                <Stack spacing="4">
                  {swaps?.map((swap, idx) => {
                    const isFirstSwap = idx === 0;
                    const prevSwap = swaps?.[idx - 1];
                    const isPrevSwapErrorOrMessage = prevSwap && (prevSwap.type === "error" || prevSwap.type === "message");
                    const isPrevSwapSigned = idx > 0 && signedSwaps[swaps[idx - 1].order];
                    const isCurrentSwapSigned = signedSwaps[swap.order];

                    let canSign = false;
                    if (isFirstSwap) {
                      canSign = !isCurrentSwapSigned;
                    } else if (isPrevSwapErrorOrMessage || isPrevSwapSigned) {
                      canSign = !isCurrentSwapSigned;
                    }

                    return (
                      <div key={idx} className="bg-gray-50 dark:bg-gray-800/40 rounded-lg p-4">
                        <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                          Transaction {idx + 1}
                        </h2>
                        {swap.type === "deposit" || swap.type === "tx" || swap.type === "signature" ? (
                          <DepositItem
                            swap={swap}
                            fetchSwaps={fetchSwaps}
                            canSign={canSign}
                            deposit={deposit}
                            roomChain={roomChain}
                            setShowGenerateAgain={setShowGenerateAgain}
                            onSigned={() => handleItemSigned(swap.order)}
                            alreadySigned={isCurrentSwapSigned}
                          />
                        ) : swap.type === "input" ? (
                          <SwapForm
                            swap={swap}
                            fetchSwaps={fetchSwaps}
                          />
                        ) : swap.type === "error" || swap.type === "message" ? (
                          <div className="flex items-start space-x-3 p-4 bg-gray-100 dark:bg-gray-700/50 rounded-lg">
                            {swap.type === "error" ? (
                              <WarningIcon className="h-5 w-5 text-red-500 dark:text-red-400 flex-shrink-0" />
                            ) : (
                              <InfoIcon className="h-5 w-5 text-blue-500 dark:text-blue-400 flex-shrink-0" />
                            )}
                            <div
                              className="flex-1 text-gray-700 dark:text-gray-200"
                              dangerouslySetInnerHTML={{
                                __html: swap.description,
                              }}
                            />
                          </div>
                        ) : (
                          "Swap Type unhandled"
                        )}
                      </div>
                    );
                  })}
                </Stack>
                {showGenerateAgain && (
                  <div className="flex justify-center mt-6">
                    <Button
                      onClick={handleGenerateAgain}
                      isLoading={generating}
                      loadingText="Generating..."
                      isDisabled={generating}
                      className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg transition-colors disabled:opacity-50"
                    >
                      Generate Again
                    </Button>
                  </div>
                )}
              </Skeleton>
            </div>
          </ModalBody>
          <ModalFooter className="border-t border-gray-200 dark:border-gray-700" />
        </ModalContent>
      </Modal>
    </>
  );
}
