import { useCallback, useEffect, useState, useRef } from "react";
import { Room, useRoom } from "../contexts/roomContext";

export default function useTimeLeft() {
  const { room, timeDiff } = useRoom();
  const [timeLeft, setTimeLeft] = useState(() => {
    const startAt = room?.info?.startAt || 0;
    const partyDuration = room?.info?.partyDuration || 0;
    const end = startAt + partyDuration;
    const now = new Date().getTime() / 1000 + (timeDiff || 0);
    return end - now;
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const startAt = room?.info?.startAt || 0;
      const partyDuration = room?.info?.partyDuration || 0;
      const end = startAt + partyDuration;
      const now = new Date().getTime() / 1000 + (timeDiff || 0);
      setTimeLeft(end - now);
    }, 1000);

    return () => clearInterval(timer);
  }, [room?.info?.startAt, room?.info?.partyDuration, timeDiff]);

  return timeLeft;
}