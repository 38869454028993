import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAppContext } from "../contexts/appContext";
import { updateInfo, updateTerms } from "../services/user.service";
import { hasError } from "../utils";
import DiscordInput from "./Inputs/DiscordInput";
import TwitterInput from "./Inputs/TwitterInput";
import TelegramInput from "./Inputs/TelegramInput";

const schema = Yup.object().shape({
  displayName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter a display name"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required!"), // pass your error message string
});

export default function ProfileForm() {
  const {
    uid,
    email,
    user,
    displayName,
    avatar,
    updateEmail,
    updateUserProfile,
  } = useAppContext();

  const initialValues = {
    email: user?.email || email?.value || "",
    displayName: user?.displayName || displayName || "",
    tacChecked: false,
    notificationsChecked: false,
  };

  const handleError = (e: any) => {
    let message = "Please Try Again!";

    if (e.code === "auth/requires-recent-login") {
      message = "Please re-login to update your email!";
    }

    if (e.code === "auth/email-already-in-use") {
      message = "Email already in use!";
    }

    if (e.code === "auth/too-many-requests") {
      message = "Too many requests, please try again later!";
    }

    // Using browser toasts instead of Chakra toast
    alert(`Error updating your email! ${message}`);
  };

  const handleSubmit = async (values: typeof initialValues, props: any) => {
    try {
      await updateUserProfile({ displayName: values.displayName });

      await updateEmail(values.email);

      await updateInfo(uid!, "email", { value: values.email });

      await updateTerms(uid!, {
        consentTosAndPp: values.tacChecked,
        consentNotifications: values.notificationsChecked,
      });

      // Using browser toasts instead of Chakra toast
      alert("Profile Saved Successfully!");
    } catch (e) {
      handleError(e);
    } finally {
      props.setSubmitting(false);
    }
  };

  return (
    <div className="w-full mt-12">
      <div className="flex justify-center">
        <div className="w-full md:w-3/4 p-10">
          <div className="w-full md:max-w-md mx-auto">
            <div className="new-bg-transparent p-6 rounded-lg">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schema}
              >
                {({ errors, touched, values, handleChange, ...props }) => {
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="flex flex-col justify-center items-center gap-6">
                        {/* Avatar */}
                        <div className="w-24 h-24 rounded-full overflow-hidden bg-gray-200 dark:bg-gray-700">
                          {avatar ? (
                            <img
                              src={avatar}
                              alt="Profile"
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <div className="w-full h-full flex items-center justify-center text-gray-400">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                              </svg>
                            </div>
                          )}
                        </div>

                        {/* Display Name Field */}
                        <div className="w-full">
                          <label
                            htmlFor="displayName"
                            className="block text-sm font-medium mb-1 text-gray-900 dark:text-white"
                          >
                            Display Name<span className="text-red-500">*</span>
                          </label>
                          <input
                            id="displayName"
                            name="displayName"
                            type="text"
                            className="w-full bg-gray-200 dark:bg-gray-800 rounded p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
                            onChange={handleChange}
                            value={values.displayName}
                          />
                          {hasError(errors, touched, "displayName") && (
                            <p className="mt-1 text-red-500 text-sm">
                              {errors.displayName}
                            </p>
                          )}
                        </div>

                        {/* Email Field */}
                        <div className="w-full">
                          <div className="flex items-center">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium mb-1 text-gray-900 dark:text-white"
                            >
                              Email<span className="text-red-500">*</span>
                            </label>
                            <div className="relative ml-1 group">
                              <InformationCircleIcon className="h-4 w-4 text-gray-500 dark:text-gray-400 cursor-help" />
                              <div className="absolute bottom-full left-0 mb-2 w-64 p-2 bg-gray-800 text-white text-xs rounded shadow opacity-0 group-hover:opacity-100 transition z-10">
                                The email is needed so we can notify you when smart contracts are ready to sign and your sign transaction status. We will not share your email nor spam you.
                              </div>
                            </div>
                          </div>
                          <input
                            id="email"
                            name="email"
                            type="email"
                            className="w-full bg-gray-200 dark:bg-gray-800 rounded p-2 outline-none focus:ring-2 focus:ring-primary text-gray-900 dark:text-white"
                            onChange={handleChange}
                            value={values.email}
                          />
                          {hasError(errors, touched, "email") && (
                            <p className="mt-1 text-red-500 text-sm">
                              {errors.email}
                            </p>
                          )}
                        </div>

                        {/* Social Inputs */}
                        <TwitterInput />
                        <TelegramInput />
                        <DiscordInput />

                        {/* Submit Button */}
                        <button
                          type="submit"
                          className="w-full py-2 px-4 bg-primary hover:bg-primary-hover text-white rounded font-medium disabled:bg-gray-400 disabled:cursor-not-allowed transition-colors"
                          disabled={props.isSubmitting}
                        >
                          {props.isSubmitting ? (
                            <div className="flex items-center justify-center">
                              <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                              Updating...
                            </div>
                          ) : (
                            "Update"
                          )}
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
