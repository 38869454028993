import { Button } from "@chakra-ui/react";
import { CiUnlock } from "react-icons/ci";
import IconLock from "../Icons/IconLock";
import { getReserveLabel, roundValue } from "../../utils";
import { BiddableNft, HighestBids } from "../../contexts/roomContext";

interface Props {
  uid: string;
  nft: BiddableNft;
  highestBids: HighestBids;
  abbr: string;
  truncate: number;
  isSecretReserve: boolean;
  isSeller: boolean;
  isAdmin: boolean;
  handleBid: (amt: number) => void;
}

export default function TopBids({
  uid,
  nft,
  highestBids,
  abbr,
  truncate,
  isSecretReserve,
  isSeller,
  isAdmin,
  handleBid,
}: Props) {
  const id = nft.itemId;
  return (
    <div className="listing-modal-details top-bids">
      <h4 className="text-gray-900 dark:text-white font-semibold text-lg mb-2">Top Bids</h4>
      <p className="text-gray-700 dark:text-gray-300 mb-4">
        Set your spending limit and bid on everything you like. Don't worry
        about overspending, the NeoSwap algorithm will take care of the rest.
      </p>
      {Object.keys(highestBids).length > 0 ? (
        <ul className="space-y-3">
          {[
            ...Object.keys(highestBids[id || ""] || {}).sort(
              (aUserId, bUserId) => {
                return highestBids[id!][bUserId] - highestBids[id!][aUserId];
              }
            ),
          ]
            .slice(0, 5)
            .map((userId, idx) => {
              let amt = getReserveLabel(
                highestBids[id!][userId],
                nft?.reservePrice?.decimals || 1,
                abbr,
                truncate
              );

              const bidItemAmount =
                nft && nft.reservePrice && nft.reservePrice.decimals
                  ? roundValue(
                    highestBids[id!][userId] /
                    10 ** nft?.reservePrice?.decimals,
                    truncate
                  )
                  : 0;

              const bidItemReserve =
                nft &&
                  nft.reservePrice &&
                  nft.reservePrice.value &&
                  nft.reservePrice.decimals
                  ? roundValue(
                    nft.reservePrice.value /
                    10 ** nft?.reservePrice?.decimals,
                    truncate
                  )
                  : 0;

              const isReserveMet =
                bidItemAmount >=
                (typeof bidItemReserve !== "undefined" ? bidItemReserve : 0);

              return (
                <li
                  key={userId}
                  className="flex items-center gap-2 flex-wrap text-gray-800 dark:text-gray-200 py-2 border-b border-gray-100 dark:border-gray-700 last:border-0"
                >
                  <span className="font-semibold">{idx + 1}.</span>
                  <span>{userId === uid ? "You Bid: " : "User Bid: "}</span>
                  <span className="font-medium text-primary">{amt}</span>
                  {isSecretReserve && (
                    <span
                      style={{
                        background: isReserveMet ? "#40cd56" : "white",
                        color: isReserveMet ? "white" : "#0c416a",
                        padding: "0px 5px",
                        borderRadius: "5px",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px",
                        gap: "5px",
                        marginLeft: "10px",
                      }}
                    >
                      {/* {isSecretReserve ? (
                        isReserveMet ? (
                          <CiUnlock />
                        ) : (
                          <IconLock />
                        )
                      ) : null}
                      <p style={{ margin: "0px" }}>
                        {isReserveMet ? "Reserve Met" : "Reserve Not Met"}
                      </p> */}
                    </span>
                  )}
                  {userId !== uid && !isSeller && !isAdmin ? (
                    <Button
                      onClick={() => handleBid(bidItemAmount)}
                      variant="outline"
                      borderColor="#6C60FF"
                      color="#6C60FF"
                      borderRadius={6}
                      size="sm"
                      ml="auto"
                    >
                      Match
                    </Button>
                  ) : null}
                </li>
              );
            })}
        </ul>
      ) : (
        <div className="no-bids text-gray-700 dark:text-gray-300 text-center py-6">
          No bids yet. Be the first to bid on this Item!
        </div>
      )}
    </div>
  );
}
