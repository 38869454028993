import { Helmet } from "react-helmet-async";
import {
  Avatar,
  Button,
  Heading,
  HStack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Skeleton,
  useToast,
  IconProps,
  // Image,
  // Tooltip,
  // Box,
  Tag,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { collection, doc } from "firebase/firestore";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import Firebase from "../services/firebase";
import {
  capitalizeFirstLetter,
  getFormattedId,
  roundValue,
  trucPublicKey,
} from "../utils";
import ListingsTable from "../components/ListingsTable";
import { useAppContext } from "../contexts/appContext";
import { joinRoom } from "../services/room.service";
// import SignInButton from "../components/SignInButton";
import { useRoom } from "../contexts/roomContext";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useUA } from "../contexts/userTracking";
import TradeGif from "../components/TradeGif";
import dateFormat from "dateformat";
import { useChain } from "../contexts/chainsContext";
import IconSolana from "../components/Icons/IconSolana";
import IconStacks from "../components/Icons/IconStacks";
import IconEthereum from "../components/Icons/IconEthereum";
import useNeoChain from "../hooks/useNeoChain";
import { DateTime } from "luxon";
import HandleTime from "../components/HandleTime";
import TokenGated from "../components/TokenGated";

const icons: { [chain: string]: (props: IconProps) => ReactElement } = {
  solana: IconSolana,
  stacks: IconStacks,
  ethereum: IconEthereum,
};

// Test Cases
// import solana1 from '../data/solana1.json';
// import stacks1 from '../data/stacks1.json';
// import stacks2 from '../data/stacks2.json';

type JoinRoomRes = {
  data: {
    result: string;
    allowed: boolean;
    joined: boolean;
    detail: string;
  };
};

const PartyHeader = ({
  id,
  name,
  status,
  inRoom,
  canJoin,
  isCorrectChain,
  roomChain,
}: {
  id: string;
  name: string;
  status?: string;
  inRoom?: boolean;
  canJoin: boolean;
  isCorrectChain: boolean;
  roomChain: string;
}) => {
  const toast = useToast();
  const [joining, setJoining] = useState(false);
  const { gaJoinParty } = useUA();
  const navigate = useNavigate();
  const { uid } = useAppContext();
  const { room, canList } = useRoom();

  const handleNav = async () => {
    try {
      if (uid) {
        setJoining(true);
        const { data } = (await joinRoom(id, true)) as JoinRoomRes;
        if (!data.allowed) throw new Error(data.detail);
        if (canJoin && !inRoom) {
          gaJoinParty(id);
        }
        navigate(`/rooms/${id}`);
      }
    } catch (e: any) {
      toast({
        title: "Unable to Join Event",
        description: e?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setJoining(false);
    }
  };

  const start = room?.info?.startAt || 0;
  const end = start + (room?.info?.partyDuration || 0);
  const DTstart = DateTime.fromSeconds(start);
  const DTend = DateTime.fromSeconds(end);
  const isLive = start < Date.now() / 1000;

  const returnLabel = () => {
    if (status === "finalize") return "Event Completed";
    if (!uid) return "Sign In";
    if (!isCorrectChain) return `Sign in to ${roomChain}`;
    if (!isLive) return "Event not started yet";
    return canJoin ? "Join Event" : "Unable to Join";
  };

  return (
    <HStack className="party-title" justify="space-between" width="100%">
      <h2>{name}</h2>
      <HStack>
        {/* {!uid ? <SignInButton /> : <></>} */}
        {!uid && <Tag variant="outline">Please Connect Wallet to Join</Tag>}
        {uid && (canList || isLive) ? (
          <Button
            onClick={handleNav}
            bgColor="#6c60ff"
            color="white"
            isDisabled={!canJoin}
            isLoading={joining}
            loadingText="Joining..."
            size="sm"
          >
            {returnLabel()}
          </Button>
        ) : (
          <></>
        )}
        {uid && !isLive ? (
          <>
            <AddToCalendarButton
              name={name}
              options={["Apple", "Google"]}
              startDate={DTstart.toFormat("yyyy-MM-dd")}
              endDate={DTend.toFormat("yyyy-MM-dd")}
              startTime={DTstart.toFormat("HH:mm")}
              endTime={DTend.toFormat("HH:mm")}
              location={window.location.href}
              timeZone="UTC"
              uid={id}
              size="4|4|4"
              styleLight="--btn-background: #6C60FF;
               --btn-border: #6C60FF;
               --btn-text: #fffff;
                   --btn-background-hover: #062438"
            ></AddToCalendarButton>

            <div className="time-wrapper">
              <p id="start">Starting in</p>
              <HandleTime time={start} />
            </div>
          </>
        ) : (
          <></>
        )}
      </HStack>
    </HStack>
  );
};

const StatsItems = ({
  roomChain,
  roomType,
  listings,
  participants,
}: {
  roomChain: string;
  roomType: string;
  listings: any;
  participants: number;
}) => {
  const { decimals, abbr, truncate } = useRoom();
  const stats = generateListingStats(listings);
  const units = 10 ** decimals;
  const avgReservePrice =
    decimals && stats?.avgReservePrice
      ? roundValue((stats?.avgReservePrice || 0) / units, truncate)
      : 0;
  const ChainIcon = icons[roomChain];

  console.log(truncate);
  return (
    <StatGroup className="party-stats box" width="100%">
      <Stat>
        <StatLabel>Chain</StatLabel>
        {roomChain && (
          <>
            <StatNumber>
              <ChainIcon />
            </StatNumber>
          </>
        )}
      </Stat>
      <Stat>
        <StatLabel>Items Listed</StatLabel>
        <StatNumber>{stats?.totalListings}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Average Reserve Price</StatLabel>
        <StatNumber>
          {!avgReservePrice ? "-" : avgReservePrice} {abbr}
        </StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Participants</StatLabel>
        <StatNumber>{participants}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Event Type</StatLabel>
        <StatNumber>{roomType.toUpperCase()}</StatNumber>
      </Stat>
    </StatGroup>
  );
};

const ParticipantListItem = ({ uid }: { uid: string }) => {
  const [data, loading] = useDocument(doc(Firebase.getDBApp(), "users", uid));
  const user = data?.data();
  const [_chain, publicKey] = uid.split("-");
  const displayName = user?.displayName || trucPublicKey(publicKey);
  const avatar = user?.avatar;
  return (
    <Skeleton isLoaded={!loading}>
      <HStack
        className="participant-list-item"
        width="100%"
        justifyContent="flex-start"
      >
        <Avatar src={avatar} />
        <Text>{displayName}</Text>
      </HStack>
    </Skeleton>
  );
};

const ParticipantList = ({ participants }: { participants: string[] }) => {
  return (
    <div className="box participant-list">
      <HStack
        width="100%"
        justifyContent="space-between"
        fontWeight="700"
        marginBottom="30px"
        paddingTop="7px"
      >
        <Heading size="sm">Participants</Heading>
        <Text>{Object.keys(participants).length}</Text>
      </HStack>
      {participants
        .sort((a, b) => a.localeCompare(b))
        .map((uid: string) => {
          return <ParticipantListItem key={uid} uid={uid} />;
        })}
    </div>
  );
};

const average = (array: number[]) =>
  array.reduce((a, b) => a + b, 0) / array.length;

const generateListingStats = (
  listings?: { [nftId: string]: { reservePrice: number } }[]
) => {
  if (!listings) return null;
  const participantCount = listings.length;
  const reservePrices = listings.reduce<number[]>((acc, ele) => {
    const prices = Object.values(ele).map((price) => price.reservePrice);
    return [...acc, ...prices];
  }, []);
  return {
    participantCount,
    avgReservePrice: average(reservePrices),
    totalListings: reservePrices.length,
  };
};

const SwitchButton = ({ roomChain }: { roomChain: string }) => {
  const { setChain, chain } = useChain();
  const chainFns = useNeoChain() as any;
  const { signIn } = chainFns[roomChain.toLowerCase() || "not_found"];
  const { signOut } = chainFns[chain?.name?.toLowerCase() || "not_found"];

  const handleSwitch = async () => {
    await signOut();
    setChain(roomChain);
    await signIn();
  };
  return (
    <Button
      bgColor="#6c60ff"
      color="white"
      size="sm"
      onClick={handleSwitch}
    >{`Join on ${capitalizeFirstLetter(roomChain)}`}</Button>
  );
};

export default function PartyPreview() {
  const toast = useToast();
  const isMountedRef = useRef(false);
  const navigate = useNavigate();
  const { uid } = useAppContext();
  const { room, result, roomState, swap } = useRoom();
  const { roomId } = useParams();
  const { setChain, chain } = useChain();

  const db = Firebase.getDBApp();
  const [listingData] = useCollection(
    collection(db, "rooms", roomId!, "listings")
  );
  const [canJoin, setCanJoin] = useState(false);

  const listings = listingData?.docs?.map((doc) => doc.data());
  const swapId = room?.swapId?.swapId;
  const gifUrl = room?.gifUrl;
  const roomChain = room?.blockchain?.name?.toLowerCase();

  const gifData = result?.gifData;
  // const gifData = stacks2;

  // const { signOut } = useNeoChain(chain?.name);

  const isCorrectChain =
    uid && uid?.includes(room?.blockchain?.name.toLowerCase());

  useEffect(() => {
    if (!room?.users) return;
    if (room?.users?.includes(uid || "")) {
      navigate(`/rooms/${roomId}`, { replace: true });
    }
  }, [room?.users]);

  useEffect(() => {
    const checkCanJoin = async () => {
      try {
        const { data } = (await joinRoom(roomId!)) as JoinRoomRes;
        setCanJoin(data?.allowed);
        if (!data.allowed) throw new Error(data.detail);
      } catch (e: any) {
        toast({
          title: "Unable to Join Event",
          description: e.message,
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    if (!uid) return;
    checkCanJoin();
  }, [roomId, uid]);

  useEffect(() => {
    if (isMountedRef.current === true) return;
    if (!isCorrectChain && !uid && roomChain) {
      setChain(roomChain);
    }

    if (roomChain) {
      isMountedRef.current = true;
    }
  }, [roomChain, uid, isCorrectChain]);

  let errorMsg;

  if (!isCorrectChain && roomChain && chain?.name.toLowerCase() !== roomChain) {
    errorMsg = `This room is on ${capitalizeFirstLetter(
      room?.blockchain?.name
    )}. Please switch your wallet to ${capitalizeFirstLetter(
      roomChain
    )} to join.`;
  }

  return (
    <>
      <Helmet>
        <title>{`Tulle | ${room?.info?.name} Preview`}</title>
        <meta name="description" content={`Previewing ${room?.info?.name}`} />
      </Helmet>

      <VStack width="100%" spacing="5">
        <div className="party-header">
          <HStack justifyContent="flex-start" width="100%">
            <Breadcrumb className="breadcrumb">
              <BreadcrumbItem>
                <BreadcrumbLink as={Link} to="/">
                  Home
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink as={Link} to="/rooms">
                  Rooms
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage className="breadcrumb-current">
                <span className="breadcrumb-text">
                  <BreadcrumbLink>{room?.info?.name}</BreadcrumbLink>
                </span>
              </BreadcrumbItem>
            </Breadcrumb>
          </HStack>
          <PartyHeader
            id={roomId!}
            name={room?.info?.name}
            status={room?.status}
            inRoom={room?.users?.includes(uid || "")}
            canJoin={canJoin}
            isCorrectChain={!!isCorrectChain}
            roomChain={capitalizeFirstLetter(roomChain || "")}
          />
          {errorMsg && (
            <HStack
              width="100%"
              justifyContent="flex-start"
              alignItems="center"
              className="box"
            >
              <Text color="red">{errorMsg}</Text>{" "}
              <SwitchButton roomChain={roomChain} />
            </HStack>
          )}
          <TokenGated />
          <StatsItems
            roomChain={roomChain}
            listings={listings}
            roomType={room?.info?.roomType || ""}
            participants={room?.users?.length}
          />

          {(gifUrl || gifData) && (
            <div className="trade-info box">
              <TradeGif gifUrl={gifUrl} gifData={gifData} />
              <div className="trade-details">
                {swapId && (
                  <h3>
                    Trade {getFormattedId(swapId)}
                    <span className="tag">Complete</span>
                  </h3>
                )}
                <p className="description">
                  This trade was completed in the "{room.info.name}" room.
                </p>
                <ul>
                  <li className="chain">
                    <span>Chain:</span> {roomChain}
                  </li>
                  <li>
                    <span>Status:</span> Complete
                  </li>
                  <li>
                    <span>Created:</span>{" "}
                    {dateFormat(room.createdAt.seconds * 1000, "mmmm dS yyyy")}
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        <HStack className="party-preview" width="100%" alignItems="flex-start">
          <div className="box listing-table">
            {roomChain && listings && listings?.length > 0 ? (
              <ListingsTable listings={listings} />
            ) : (
              <p className="empty-listings">No Listings Yet</p>
            )}
          </div>
          {room?.users?.length > 0 ? (
            <ParticipantList participants={room.users} />
          ) : (
            <div className="box participant-list">
              <p className="empty-list">No Participants Yet</p>
            </div>
          )}
        </HStack>
      </VStack>
    </>
  );
}

// var joinRoom= this.fns.httpsCallable('room-joinRoom');
// joinRoom({roomId}) // to check if user can join
// joinRoom({roomId, join: true}); // to join
// reposnse:
// {
//   result: "success"/"error"
//   allowed: true/false,
//   joined: true/false,
//   detail: "...",
// }
