import React from 'react';

interface TransactionModalProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    subtitle?: string;
    size?: 'sm' | 'md' | 'lg' | '2xl' | '3xl' | '4xl' | '5xl' | 'full';
    children: React.ReactNode;
    footer?: React.ReactNode;
    className?: string;
    headerClassName?: string;
    contentClassName?: string;
}

const TransactionModal: React.FC<TransactionModalProps> = ({
    isOpen,
    onClose,
    title,
    subtitle,
    size = 'md',
    children,
    footer,
    className = '',
    headerClassName = '',
    contentClassName = ''
}) => {
    if (!isOpen) return null;

    const sizeClasses = {
        sm: 'max-w-md',
        md: 'max-w-lg',
        lg: 'max-w-xl',
        '2xl': 'max-w-2xl',
        '3xl': 'max-w-3xl',
        '4xl': 'max-w-4xl',
        '5xl': 'max-w-5xl',
        'full': 'max-w-full'
    }[size];

    return (
        <div className="fixed inset-0 z-[100]">
            {/* Backdrop */}
            <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose} />

            {/* Modal Container */}
            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4">
                    {/* Modal Content */}
                    <div
                        className={`relative w-full ${sizeClasses} transform overflow-hidden rounded-xl 
                            bg-white shadow-2xl transition-all dark:bg-[#051A28] ${className}`}
                    >
                        {/* Close Button */}
                        <button
                            onClick={onClose}
                            className="absolute right-4 top-4 z-10 rounded-lg p-1 text-gray-400 
                                hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400"
                        >
                            <span className="sr-only">Close</span>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                        {/* Header */}
                        {(title || subtitle) && (
                            <div className={`px-6 py-4 ${headerClassName}`}>
                                {title && (
                                    <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                                        {title}
                                    </h3>
                                )}
                                {subtitle && (
                                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                                        {subtitle}
                                    </p>
                                )}
                            </div>
                        )}

                        {/* Body */}
                        <div className={`px-6 py-4 ${contentClassName}`}>
                            {children}
                        </div>

                        {/* Footer */}
                        {footer && (
                            <div className="sticky bottom-0 border-t border-gray-200 bg-gray-50 
                                px-6 py-4 dark:border-gray-700/50 dark:bg-gray-800/50">
                                {footer}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionModal; 