import { CheckIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useEffect, useRef, useState } from "react";
import { serverTimestamp } from "firebase/firestore";
import {
  initializeSwap,
  solveTrade,
  updateRoom,
  cancelTrades,
} from "../services/room.service";
import ReactDOM from "react-dom";
import usePolygon from "../hooks/usePolygon";
import { useUA } from "../contexts/userTracking";
import { useRoom } from "../contexts/roomContext";

interface Props {
  status: string;
  roomId: string;
  swapId?: string;
  tradeFound: boolean;
  chain: string;
}

interface SolanaInitializeProps {
  roomId: string;
  swapId?: string;
  tradeFound: boolean;
}

export default function AdminBar({
  status,
  roomId,
  swapId,
  tradeFound,
  chain,
}: Props) {
  const { gaArchiveParty } = useUA();
  const topBar = document.getElementById("top-bar");
  const [archiving, setArchiving] = useState(false);
  const [solving, setSolving] = useState(false);
  const [modalType, setModalType] = useState("");
  const [isCanceling, setIsCanceling] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef(null);

  const hasSubscribedToBuyDown = localStorage.getItem("buydown-waitlist-subscribed");

  useEffect(() => {
    if (!hasSubscribedToBuyDown) {
      document.body.classList.add("bodywithadmin");
    }
    document.body.classList.add("admin-active");

    return () => {
      document.body.classList.remove("admin-active");
    };
  });

  const { room } = useRoom();

  const handleSolve = async () => {
    try {
      setIsOpen(false);
      setSolving(true);
      await updateRoom(roomId, { status: "solving" });
      const res = (await solveTrade(roomId)) as any;
      if (res?.data?.result === "error") {
        throw new Error(res?.data?.detail);
      }
      setIsOpen(false);
    } catch (e: any) {
      // Handle error with a custom toast implementation
      console.error("Error Calling the Solver:", e.message || "Please Try Again!");
    } finally {
      setSolving(false);
    }
  };

  const handleArchive = async () => {
    try {
      setArchiving(true);
      await updateRoom(roomId, {
        status: status === "archived" ? "listing" : "archived",
        archivedAt: status === "archived" ? null : serverTimestamp(),
      });
      if (status !== "archived") {
        gaArchiveParty(roomId);
      }
    } catch (e) {
      // Handle error with a custom toast implementation
      console.error("Error Archiving the Room:", "Please Try Again!");
    } finally {
      setArchiving(false);
    }
  };

  const handleCancel = async () => {
    try {
      setIsCanceling(true);
      setIsOpen(false);
      const res = (await cancelTrades(roomId)) as any;
      if (res?.data?.result === "error") {
        throw new Error(res?.data?.detail);
      }
      setIsOpen(false);
    } catch (e: any) {
      // Handle error with a custom toast implementation
      console.error("Error Canceling the Trades:", e.message || "Please Try Again!");
    } finally {
      setSolving(false);
      setIsCanceling(false);
    }
  };

  const handleCancelAndSolve = (action: string) => {
    setModalType(action);
    setIsOpen(true);
  };

  return (
    <>
      {/* Alert Dialog */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full mx-4">
            <h2 className="text-lg font-bold mb-4 text-gray-900 dark:text-white">
              Are you sure you want to proceed?
            </h2>
            <p className="mb-6 text-gray-700 dark:text-gray-300">
              You are about to{" "}
              {modalType === "cancel"
                ? "cancel the active swaps"
                : "find swaps and proceed to signing stage"}
              . This action can't be undone. Are you sure you want to proceed?
            </p>
            <div className="flex justify-end gap-4">
              <button
                ref={cancelRef}
                onClick={() => setIsOpen(false)}
                className="px-4 py-2 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
              >
                No
              </button>
              <button
                onClick={modalType === "cancel" ? handleCancel : handleSolve}
                className="px-4 py-2 rounded-md bg-red-500 text-white hover:bg-red-600 transition-colors"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {ReactDOM.createPortal(
        <div className="fixed top-0 left-0 right-0 z-[999] bg-white dark:bg-[#1c2944] h-[70px] flex items-center shadow-md border-b border-gray-200 dark:border-gray-800">
          <div className="container mx-auto px-4 flex items-center gap-8">
            <h2 className="text-gray-900 dark:text-white font-bold">Host Controls:</h2>
            <div className="flex gap-8 flex-1">
              <button
                className={`flex items-center px-6 py-2 rounded-md text-white text-sm font-semibold transition-colors
                  ${solving || tradeFound ? 'bg-green-600/50 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'}`}
                onClick={() => handleCancelAndSolve("solve")}
                disabled={solving || tradeFound}
              >
                {solving ? 'Solving...' : 'Solve Trade'}
                <CheckIcon className="ml-2 h-4 w-4" />
              </button>

              <button
                className={`px-6 py-2 rounded-md text-white text-sm font-semibold transition-colors
                  ${archiving ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-90'}
                  ${status === "archived" ? 'bg-green-500' : 'bg-orange-500'}`}
                onClick={handleArchive}
                disabled={archiving}
              >
                {archiving ? 'Loading...' : status === "archived" ? "Activate Room" : "Archive Room"}
              </button>

              <button
                className={`px-6 py-2 rounded-md text-white text-sm font-semibold transition-colors
                  ${isCanceling ? 'bg-red-600/50 cursor-not-allowed' : 'bg-red-500 hover:bg-red-600'}`}
                onClick={() => handleCancelAndSolve("cancel")}
                disabled={isCanceling}
              >
                {isCanceling ? 'Canceling...' : 'Cancel Swaps'}
              </button>
            </div>
          </div>
        </div>,
        topBar!
      )}
    </>
  );
}
