import { FaDiscord, FaTwitter } from "react-icons/fa";

export default function LandingFooter() {
  return (
    <footer className="bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-800">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col items-center space-y-4">
          <div className="flex space-x-6">
            <a
              href="https://x.com/tulle_ai"
              target="_blank"
              rel="noreferrer"
              className="text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white transition-colors"
            >
              <FaTwitter className="w-8 h-8" />
            </a>
            <a
              href="https://discord.gg/4mfqbBkGRS"
              target="_blank"
              rel="noreferrer"
              className="text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white transition-colors"
            >
              <FaDiscord className="w-8 h-8" />
            </a>
          </div>
          <p className="text-gray-600 dark:text-gray-400 text-sm">
            Copyright &copy; {new Date().getFullYear()} Tulle
          </p>
        </div>
      </div>
    </footer>
  );
}
