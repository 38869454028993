import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { UserbackProvider } from '@userback/react';
import { HelmetProvider } from 'react-helmet-async';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ChakraProvider } from '@chakra-ui/react';
import reportWebVitals from './reportWebVitals';
import Router from './router';
import theme from './theme';
import Firebase from './services/firebase';
import AppProvider from './contexts/appContext';
import ErrorBoundary from './components/ErrorBoundary';
import UserAnalyticsProvider from './contexts/userTracking';
import ChainsProvider from './contexts/chainsContext';
import NeoSwapWalletProvider from './contexts/walletContext';
import WalletPassthroughProvider from './components/PacksWidget/contexts/WalletPassthroughContext';
import { isNil } from 'lodash'

import 'react-tooltip/dist/react-tooltip.css';
import './css/app.scss';
import './tailwind-output.css';
import { ThemeProvider } from './contexts/ThemeContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App = ({ dehydratedState }: { dehydratedState?: any }) => {
  const [isFirebaseInitialized, setIsFirebaseInitialized] = useState(false);

  useEffect(() => {
    document.addEventListener(
      'scroll',
      (e: Event) => {
        let nav = document.getElementById('nav-bar');

        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
          if (nav) {
            nav.classList.add('fixed');
          }
        } else {
          if (nav) {
            nav.classList.remove('fixed');
          }
        }
      },
      { passive: true }
    );
  }, []);

  useEffect(() => {
    const getReferralId = () => {
      const queryParams = new URLSearchParams(window.location.search);
      return queryParams.get('r');
    };

    const ref = getReferralId();
    const hasCurrentRef = localStorage.getItem('buddyLink');

    if (ref && isNil(hasCurrentRef)) {
      localStorage.setItem('buddyLink', ref);
    }
  }, []);

  useEffect(() => {
    const initializeFirebase = async () => {
      try {
        await Firebase.initApp;
        setIsFirebaseInitialized(true);
      } catch (error) {
        console.error('Failed to initialize Firebase:', error);
      }
    };

    initializeFirebase();
  }, []);

  if (!isFirebaseInitialized) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <React.StrictMode>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={dehydratedState}>
            <BrowserRouter>
              <HelmetProvider>
                <ChakraProvider theme={theme}>
                  <ThemeProvider>
                    <UserAnalyticsProvider>
                      <ChainsProvider>
                        <NeoSwapWalletProvider>
                          <WalletPassthroughProvider>
                            <AppProvider>
                              <Router />
                            </AppProvider>
                          </WalletPassthroughProvider>
                        </NeoSwapWalletProvider>
                      </ChainsProvider>
                    </UserAnalyticsProvider>
                  </ThemeProvider>
                </ChakraProvider>
              </HelmetProvider>
            </BrowserRouter>
          </Hydrate>
        </QueryClientProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

root.render(<App />);

reportWebVitals();
