import { memo } from "react";
import { BiddableNft } from "../../contexts/roomContext";
import IconLink from "../Icons/IconLink";
import { createFloor, getVolumeLabel } from "../../utils";
import { ExpandableText } from "../ExpandableText";

interface Props {
  nft: BiddableNft;
  abbr: string;
  truncate: number;
  isOrdinals: boolean;
  suggestedPrice: string;
}

const DetailsTab = ({ nft, abbr, truncate, isOrdinals, suggestedPrice }: Props) => {
  const modifiedAttributes = Array.isArray(nft?.attributes)
    ? nft.attributes.map((attr) => {
      if (typeof attr.value === 'string') {
        const modifiedValue = attr.value
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return { ...attr, value: modifiedValue };
      }
      return attr;
    })
    : [];

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        {nft?.collection.name || nft?.collection.twitter ? (
          <div className="space-y-1">
            <h4 className="text-lg font-medium text-gray-900 dark:text-white">
              {nft?.collection.twitter ? (
                <a
                  href={nft.collection.twitter}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-primary"
                >
                  {nft.collection.twitter}
                </a>
              ) : (
                <span>{nft?.collection.name}</span>
              )}
            </h4>
          </div>
        ) : null}

        <h3 className="text-xl font-bold text-gray-900 dark:text-white">
          {nft?.name}
        </h3>

        <div className="grid grid-cols-2 gap-4">
          {!isOrdinals && (
            <div className="rounded-lg bg-gray-50 p-4 dark:bg-gray-800">
              <p className="text-sm text-gray-500 dark:text-gray-400">{suggestedPrice}</p>
              <p className="mt-1 text-lg font-medium text-gray-900 dark:text-white">
                {createFloor(nft)}
              </p>
            </div>
          )}

          <div className="rounded-lg bg-gray-50 p-4 dark:bg-gray-800">
            <p className="text-sm text-gray-500 dark:text-gray-400">Volume</p>
            <p className="mt-1 text-lg font-medium text-gray-900 dark:text-white">
              {nft &&
                getVolumeLabel(
                  nft.collection.volume,
                  nft.reservePrice?.decimals || 0,
                  abbr,
                  truncate
                )}
            </p>
          </div>

          <div className="rounded-lg bg-gray-50 p-4 dark:bg-gray-800">
            <p className="text-sm text-gray-500 dark:text-gray-400">Owners</p>
            <p className="mt-1 text-lg font-medium text-gray-900 dark:text-white">
              {nft?.collection.owners}
            </p>
          </div>

          <div className="rounded-lg bg-gray-50 p-4 dark:bg-gray-800">
            <p className="text-sm text-gray-500 dark:text-gray-400">Total</p>
            <p className="mt-1 text-lg font-medium text-gray-900 dark:text-white">
              {nft?.collection.totalItems}
            </p>
          </div>
        </div>

        {nft?.attributes && (
          <div className="space-y-2">
            <h4 className="text-sm font-medium text-gray-900 dark:text-white">
              Attributes
            </h4>
            <div className="rounded-lg border border-gray-200 dark:border-gray-700">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">
                      Type
                    </th>
                    <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {modifiedAttributes?.map((attr) => (
                    <tr key={attr.trait_type}>
                      <td className="px-4 py-2 text-sm text-gray-900 dark:text-white">
                        {attr.trait_type}
                      </td>
                      <td className="px-4 py-2 text-sm text-gray-900 dark:text-white">
                        {String(attr.value)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div className="space-y-2">
          <h4 className="text-sm font-medium text-gray-900 dark:text-white">
            Description
          </h4>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            <ExpandableText noOfLines={1}>
              {nft?.description || "This Item has no description."}
            </ExpandableText>
          </div>
        </div>

        {nft?.userDescription && (
          <div className="space-y-2">
            <h4 className="text-sm font-medium text-gray-900 dark:text-white">
              Additional Info Provided by Seller
            </h4>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {nft.userDescription}
            </p>
          </div>
        )}

        {nft?.marketplace?.url && (
          <div className="pt-4">
            <a
              href={nft.marketplace.url}
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center gap-2 text-primary hover:text-primary-dark"
            >
              See on Marketplace
              <IconLink className="h-4 w-4" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(DetailsTab);