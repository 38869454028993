// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Text,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useRoom } from "../../contexts/roomContext";

function TimesUpModal({ timeLeft }: { timeLeft: number }) {
  const { roomState } = useRoom();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLDivElement>(null);

  const [stage, setStage] = useState("solving");

  useEffect(() => {
    // if (timeLeft > 0) return;
    let timesUp = timeLeft <= 0 && (roomState === "listing" || roomState === "bidding");
    if (
      timesUp ||
      roomState === "solving"
    ) {
      onOpen();
      if (roomState === "solving") {
        setStage("finding");
      }
    } else {
      onClose();
    }
  }, [timeLeft, roomState]);

  return (
    <AlertDialog
      id="times-up-modal"
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={false}
      zIndex={99999999}
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        maxWidth="500px"
        className="ns-dialog-content"
        bg={"#091824"}
      >
        <AlertDialogBody py={"30px"}>
          <Box textAlign={"center"}>
            <Text as={"h2"} fontSize={"3xl"} mb={"15px"}>
              ⏰ Time's Up!
            </Text>
            <Box>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="#6C60FF"
                size="xl"
              />
            </Box>

            <Text as={"p"} mt={"10px"}>
              {stage === "solving" ? "Solving..." : null}
              {stage === "finding" ? "Finding trade.. this may take a few minutes because we are verifying balances on chain before solving." : null}
            </Text>
          </Box>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default TimesUpModal;
