import { ReactElement, memo, useEffect, useId, useReducer, useState } from "react";
import { XMarkIcon, MagnifyingGlassIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { orderBy } from "lodash";
import { FixedSizeGrid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { DateTime } from "luxon";
import HandleTime from "../../components/HandleTime";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { BiddableNft, Nft, useRoom } from "../../contexts/roomContext";
import { filterAndSort, SortOptions } from "../../utils/filterAndSort";
import IconGrid from "../Icons/IconGrid";
import IconRows from "../Icons/IconRows";
import GroupCard from "./GroupCard";
import { CollectionTable } from "./Table";
import NeoSelect from "../NeoSelect";
import ListModal from "../ListingDataTable/ListModal";
import BiddingModal from "../BiddingModal";
import ContactMethodModal from "../ContactMethodModal";
import GridLoader from "../GridLoader";
import TokenGated from "../TokenGated";
import { useAppContext } from "../../contexts/appContext";
import SmartAuctions from "../SmartAuctions";

interface Props {
  title?: string | JSX.Element | null;
  data: Nft[] | BiddableNft[];
  GridComponent: ({ nft }: { nft: Nft | BiddableNft }) => JSX.Element;
  ModalComponent?: ({ nfts }: { nfts: Nft[] | BiddableNft[] }) => JSX.Element;
  columns?: any;
  indexOptions: string[];
  groupBy?: string;
  children?: JSX.Element;
  emptyMessage: string;
  additionalSortOptions?: SortOpt[];
  collectionType?: "listing" | "bidding" | "myNfts";
  isLoading: boolean;
  defaultSort?: SortOptions;
  breakPoints?: { [key: string]: number };
  preferredNfts?: BiddableNft[];
  showGating?: boolean;
  hasJoined?: boolean;
}

enum ActionType {
  UPDATE = "UPDATE",
  SEARCH = "SEARCH",
  SORT = "SORT",
}

type State = {
  data: Nft[] | BiddableNft[];
  isGrid: boolean;
  isGrouped: boolean;
  selectedGroups: string[];
  results: Nft[] | BiddableNft[];
  query?: string;
  indexOptions: string[];
  sortedBy?: SortOptions;
  groups: string[];
  groupBy?: string;
};

type Action = {
  type: ActionType;
  payload: Partial<State>;
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case ActionType.SORT:
      return {
        ...state,
        sortedBy: action.payload.sortedBy,
        ...filterAndSort(
          state.data,
          state.indexOptions,
          state.query!,
          action.payload.sortedBy,
          state.groupBy
        ),
      };
    case ActionType.SEARCH:
      return {
        ...state,
        ...filterAndSort(
          state.data,
          state.indexOptions,
          action.payload.query!,
          state.sortedBy,
          state.groupBy
        ),
      };

    default:
      return state;
  }
};

type SortOpt = {
  label: string;
  value: {
    key: string;
    direction: "asc" | "desc";
  };
};

const defaultSortOptions: SortOpt[] = [
  {
    label: "Recently Added",
    value: { key: "timestamp", direction: "desc" },
  },
  {
    label: "Name (A-Z)",
    value: { key: "name", direction: "asc" },
  },
  {
    label: "Name (Z-A)",
    value: { key: "name", direction: "desc" },
  },
  {
    label: "Price (Low to High)",
    value: { key: "price", direction: "asc" },
  },
  {
    label: "Price (High to Low)",
    value: { key: "price", direction: "desc" },
  },
  {
    label: "Rarity (Common First)",
    value: { key: "rarity", direction: "asc" },
  },
  {
    label: "Rarity (Rare First)",
    value: { key: "rarity", direction: "desc" },
  }
];

const useWindowSize = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
};

const IsLiveOverlay = ({ children }: { children: ReactElement }) => {
  const { room, roomId } = useRoom();

  const calulateIsLive = () => {
    return (room?.info?.startAt || 0) < Date.now() / 1000;
  };

  const [isLive, setisLive] = useState(calulateIsLive());

  useEffect(() => {
    const timer = setTimeout(() => {
      setisLive(calulateIsLive());
    }, 1000);

    return () => clearTimeout(timer);
  });

  if (!room?.info) return children;

  const start = room.info.startAt || 0;
  const end = start + (room.info.partyDuration || 0);
  const DTstart = DateTime.fromSeconds(start);
  const DTend = DateTime.fromSeconds(end);
  if (!isLive) {
    return (
      <div className="relative w-full h-full flex p-6 min-h-[600px]">
        <div className="absolute inset-0 z-50 bg-[#041520] bg-opacity-80 flex items-center justify-center">
          <div>

            <div className="flex flex-col gap-4 items-center">
              <h1 className="text-5xl font-bold">Starting In</h1>
              <div className="time-wrapper [&_.val]:text-4xl">
                <HandleTime time={room?.info?.startAt} />
              </div>
              <AddToCalendarButton
                name={room?.info?.name}
                options={["Apple", "Google"]}
                startDate={DTstart.toFormat("yyyy-MM-dd")}
                endDate={DTend.toFormat("yyyy-MM-dd")}
                startTime={DTstart.toFormat("HH:mm")}
                endTime={DTend.toFormat("HH:mm")}
                location={window.location.href}
                timeZone="UTC"
                uid={roomId}
                size="8|8|8"
                styleLight="--btn-background: #6C60FF;
           --btn-border: #6C60FF;
           --btn-text: #fffff;
               --btn-background-hover: #062438"
              />
            </div>
          </div>
        </div>
        {children}
      </div>
    );
  }
  return children;
};

const OverLay = ({
  children,
  showIsLive,
}: {
  children: ReactElement;
  showIsLive?: boolean;
}) => {
  if (showIsLive) {
    return <IsLiveOverlay>{children}</IsLiveOverlay>;
  }
  return children;
};

const NftCollection = memo(
  ({
    GridComponent,
    data,
    indexOptions,
    groupBy,
    title,
    columns,
    children,
    emptyMessage,
    additionalSortOptions,
    collectionType,
    isLoading,
    defaultSort,
    breakPoints,
    preferredNfts = [],
    showGating,
    hasJoined,
  }: Props) => {
    const { roomId, isPassing } = useRoom();
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const { width } = useWindowSize();
    const columnCount = (() => {
      if (width > 1600) return breakPoints?.xxxxLarge || 3;
      if (width > 1400) return breakPoints?.xxxLarge || 3;
      if (width > 1200) return breakPoints?.xl || 2;
      if (width > 992) return breakPoints?.lg || 2;
      if (width > 768) return breakPoints?.md || 2;
      return breakPoints?.base || 2;
    })();
    const [state, dispatch] = useReducer(
      reducer,
      {
        sortedBy: defaultSort || {
          key: "collection.floorPrice.floorPrice.valueConverted",
          direction: "asc",
        },
      } as State,
      (state: State) => {
        const initialOptions = filterAndSort(
          data,
          indexOptions,
          "",
          state.sortedBy,
          groupBy
        );
        return {
          ...state,
          ...initialOptions,
          isGrid: true,
          groupBy,
          indexOptions,
          data,
          selectedGroups: [],
        };
      }
    );

    useEffect(() => {
      dispatch({
        type: ActionType.UPDATE,
        payload: {
          data,
        },
      });
      dispatch({
        type: ActionType.SEARCH,
        payload: {
          query: state.query || "",
        },
      });
    }, [data]);

    const toggleGroup = () => {
      dispatch({
        type: ActionType.UPDATE,
        payload: {
          selectedGroups: [],
          isGrouped: !state.isGrouped,
        },
      });
    };

    const addOrRemoveGroup = (groupName: string) =>
      dispatch({
        type: ActionType.UPDATE,
        payload: {
          selectedGroups: state.selectedGroups.includes(groupName)
            ? state.selectedGroups.filter((group) => group !== groupName)
            : [...state.selectedGroups, groupName],
        },
      });

    const handleSort = (sortedBy: SortOptions) =>
      dispatch({
        type: ActionType.SORT,
        payload: {
          sortedBy,
        },
      });

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch({
        type: ActionType.SEARCH,
        payload: {
          query: e.currentTarget.value || "",
        },
      });

    const setView = (mode: string) =>
      dispatch({
        type: ActionType.UPDATE,
        payload: { isGrid: mode === "grid" ? true : false },
      });

    const clearSearch = () =>
      dispatch({
        type: ActionType.SEARCH,
        payload: { query: "" },
      });

    const clearGroups = () =>
      dispatch({
        type: ActionType.UPDATE,
        payload: { selectedGroups: [] },
      });

    let nfts = state.results;

    if (state.selectedGroups.length > 0) {
      nfts = nfts.filter((nft) => {
        if (state.selectedGroups.includes("null"))
          return !nft?.collection?.name;
        return state.selectedGroups.includes(nft?.collection?.name);
      });
    }

    const sortOptions: SortOpt[] = [
      ...(additionalSortOptions || []),
      {
        label: "Collection Floor Price (ASC)",
        value: {
          key: "collection.floorPrice.floorPrice.valueConverted",
          direction: "asc",
        },
      },
      {
        label: "Collection Floor Price (DESC)",
        value: {
          key: "collection.floorPrice.floorPrice.valueConverted",
          direction: "desc",
        },
      },
    ];
    return (
      <>
        {collectionType === "listing" && (
          <ListModal nfts={nfts.filter((nft) => !nft?.locks?.length)} />
        )}
        {collectionType === "bidding" && (
          <BiddingModal
            nfts={
              nfts
                .concat(preferredNfts)
                .filter((nft) => !nft?.locks?.length) as BiddableNft[]
            }
          />
        )}
        <ContactMethodModal
          open={infoModalOpen}
          handleClose={() => setInfoModalOpen(false)}
        />
        <div className={`grid-view listing-selection pb-${isPassing ? '0' : '100px'}`}>
          <div className="grid-view-header">
            {title && (
              <h2 className="text-lg font-semibold">
                {title}
              </h2>
            )}
            <div>
              <div className="search-div">
                <div className="relative w-full">
                  <input
                    className="w-full px-4 py-2 border border-[#6C60FF] rounded-lg pl-10"
                    placeholder="Search"
                    onChange={handleSearch}
                    value={state.query}
                  />

                  {state?.query ? (
                    <button
                      onClick={clearSearch}
                      className="absolute right-3 top-1/2 -translate-y-1/2"
                    >
                      <XMarkIcon className="h-5 w-5 text-gray-400" />
                    </button>
                  ) : (
                    <MagnifyingGlassIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                  )}
                </div>
              </div>
              <div className="filters">
                <NeoSelect
                  placeholder="Select collection"
                  className="filter-input collection"
                  id="input-color"
                  value={null}
                  onChange={(val: any) => {
                    if (!val?.value) return;
                    addOrRemoveGroup(val.value);
                  }}
                  options={orderBy(
                    state.groups
                      .filter((group) => !state.selectedGroups.includes(group))
                      .map((group) => {
                        return {
                          label:
                            group === "null" ? "Unknown Collection" : group,
                          value: group,
                        };
                      }),
                    ["label"],
                    ["asc"]
                  )}
                />
                <NeoSelect
                  placeholder="Sort by"
                  isSearchable={false}
                  id="input-color"
                  className="filter-input sort"
                  onChange={(val: any) => {
                    if (!val?.value) return;
                    handleSort(val.value);
                  }}
                  options={sortOptions}
                />
              </div>
            </div>
          </div>
          {state?.selectedGroups.length > 0 && (
            <div className="flex justify-end gap-2 overflow-auto py-2">
              {state.selectedGroups.map((group) => (
                <span
                  key={group}
                  className="inline-flex items-center px-3 py-1 rounded-full bg-blue-500 text-white"
                >
                  <span>{group === "null" ? "Unknown Collection" : group}</span>
                  <button
                    onClick={() => addOrRemoveGroup(group)}
                    className="ml-2"
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </button>
                </span>
              ))}

              {state.selectedGroups.length > 1 && (
                <button
                  onClick={clearGroups}
                  className="text-sm text-blue-500 hover:underline"
                >
                  Clear All
                </button>
              )}
            </div>
          )}

          <div className="block mt-8">
            <OverLay showIsLive={showGating}>
              <div className="flex flex-col w-full">
                <div className="grid-view-wrapper">
                  {isLoading && (
                    <GridLoader
                      count={9}
                      minH={collectionType === "myNfts" ? "250px" : "300px"}
                    />
                  )}
                  {!isLoading &&
                    state.isGrouped &&
                    state.selectedGroups.length < 1 &&
                    state.groups?.length > 0 && (
                      <div className="grid-view-wrapper h-750px">
                        <AutoSizer>
                          {({ height, width }: { height: number, width: number }) => (
                            <FixedSizeGrid
                              columnCount={columnCount}
                              rowCount={Math.ceil(
                                state.groups.length / columnCount
                              )}
                              columnWidth={width / columnCount}
                              rowHeight={350}
                              height={height}
                              width={width}
                              style={{ overflowX: "hidden" }}
                            >
                              {({ columnIndex, rowIndex, style }) => {
                                const index =
                                  rowIndex * columnCount + columnIndex;
                                const group = state.groups[index];
                                const first = nfts.find(
                                  (ele) => ele?.collection?.name === group
                                );

                                const image =
                                  first?.collection?.image || first?.image;
                                const name =
                                  first?.collection?.name ||
                                  "Unknown Collection";
                                if (!group) return null;
                                return (
                                  <div style={{ ...style, padding: "10px" }}>
                                    <GroupCard
                                      key={`${group}_${index}`}
                                      name={name}
                                      image={image}
                                      onClick={() => addOrRemoveGroup(group)}
                                    />
                                  </div>
                                );
                              }}
                            </FixedSizeGrid>
                          )}
                        </AutoSizer>
                      </div>
                    )}
                </div>

                {!isLoading &&
                  (!state.isGrouped || state.selectedGroups.length > 0) &&
                  state.isGrid &&
                  nfts?.length > 0 && (
                    <div className={`grid-view-wrapper h-[${isPassing ? '400px' : '750px'}]`}>
                      <AutoSizer>
                        {({ height, width }: { height: number, width: number }) => (
                          <FixedSizeGrid
                            columnCount={columnCount}
                            rowCount={Math.ceil(nfts.length / columnCount)}
                            columnWidth={width / columnCount}
                            rowHeight={
                              collectionType === "myNfts"
                                ? columnCount < 3
                                  ? columnCount < 2
                                    ? 450
                                    : 300
                                  : 250
                                : 500
                            }
                            height={height}
                            width={width}
                            style={{ overflowX: "hidden" }}
                          >
                            {({ columnIndex, rowIndex, style }) => {
                              const index =
                                rowIndex * columnCount + columnIndex;
                              const nft = nfts[index];
                              if (!nft) return null;
                              return (
                                <div style={{ ...style, padding: "10px" }}>
                                  <GridComponent key={nft.itemId} nft={nft} />
                                </div>
                              );
                            }}
                          </FixedSizeGrid>
                        )}
                      </AutoSizer>
                    </div>
                  )}
                {!isLoading && nfts?.length < 1 && (
                  <div className="box">{emptyMessage}</div>
                )}
                {!isLoading && columns &&
                  (!state.isGrouped || state.selectedGroups.length > 0) &&
                  !state.isGrid &&
                  nfts?.length > 0 && (
                    <CollectionTable data={nfts} columns={columns} />
                  )}
              </div>
            </OverLay>
          </div>

          <div className="flex flex-row gap-4 listing-section">
            <SmartAuctions
              chain={"solana"}
              onlyLive={true}
              exclude={[roomId]}
              hideHeader={true}
              customHeader={"Related Events"}
              isRelated={true}
            />
          </div>
        </div>
        {children}
      </>
    );
  }
);

export default NftCollection;
