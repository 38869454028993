export default function IconCustomDuration() {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            xmlSpace="preserve"
        >
            <path
                d="M15,0C6.7,0,0,6.7,0,15s6.7,15,15,15s15-6.7,15-15S23.3,0,15,0z M15,27.3C8.2,27.3,2.7,21.8,2.7,15S8.2,2.7,15,2.7
        S27.3,8.2,27.3,15C27.3,21.8,21.8,27.3,15,27.3z"
                fill="currentColor"
            />
            <path
                d="M20.5,14.6h-4.1V7.9c0-0.8-0.6-1.4-1.4-1.4s-1.4,0.6-1.4,1.4v8.1c0,0.8,0.6,1.4,1.4,1.4h5.5c0.8,0,1.4-0.6,1.4-1.4
        S21.3,14.6,20.5,14.6z"
                fill="currentColor"
            />
            <path
                d="M15,19.6c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S15.8,19.6,15,19.6z"
                fill="currentColor"
            />
            <path
                d="M19.6,15c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4S19.6,14.2,19.6,15z"
                fill="currentColor"
            />
            <path
                d="M7.6,15c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4S7.6,14.2,7.6,15z"
                fill="currentColor"
            />
            <path
                d="M15,7.6c0.8,0,1.4-0.6,1.4-1.4S15.8,4.8,15,4.8s-1.4,0.6-1.4,1.4S14.2,7.6,15,7.6z"
                fill="currentColor"
            />
        </svg>
    );
} 