import { useState } from "react";
import { NavLink, Link as RouterLink } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import LogoWhite from "../../assets/logo-white.png";
import { useAppContext } from "../../contexts/appContext";
import SignInButton from "../SignInButton";
import { FaBars } from "react-icons/fa";
import { useChain } from "../../contexts/chainsContext";
import { ThemeToggle } from '../ThemeToggle';
import { useTheme } from '../../contexts/ThemeContext';
import IconSolana from "../Icons/IconSolana";
import IconStacks from "../Icons/IconStacks";
import IconBitcoin from "../Icons/IconBitcoin";
import { ChevronDownIcon } from "@chakra-ui/icons";

export const ChainSwitcher = ({ type }: { type?: string }) => {
  const { chains, chain, setChain } = useChain();
  const [isOpen, setIsOpen] = useState(false);

  if (type === "tile") {
    return (
      <div className="flex gap-5 justify-between flex-wrap">
        {chains?.map((theChain) => (
          <div
            key={theChain.name}
            className="flex flex-col items-center gap-2.5 cursor-pointer"
            onClick={() => setChain(theChain.name)}
          >
            <div className={`flex items-center justify-center transition-all duration-200 rounded-xl 
              lg:w-24 lg:h-24 w-[70px] h-[70px] 
              ${chain?.name === theChain.name ? 'border-[3px] border-primary' : 'border-[3px] border-transparent'}`}
            >
              {theChain.name === "Solana" && <IconSolana className="lg:w-12 lg:h-12 w-8 h-8" />}
              {theChain.name === "Stacks" && <IconStacks className="lg:w-12 lg:h-12 w-8 h-8" />}
              {theChain.name === "Bitcoin" && <IconBitcoin className="lg:w-12 lg:h-12 w-8 h-8" />}
            </div>
            <span>{theChain.name}</span>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-4 py-2 rounded-lg bg-gray-200 dark:bg-white/10 hover:bg-gray-300 dark:hover:bg-white/15 text-black dark:text-white transition-colors"
      >
        <div className="flex items-center gap-2">
          {chain?.name === "Bitcoin" && <IconBitcoin />}
          {chain?.name === "Solana" && <IconSolana />}
          {chain?.name === "Stacks" && <IconStacks />}
          <span>{chain?.name || "Select Chain"}</span>
        </div>
        <ChevronDownIcon />
      </button>

      {isOpen && (
        <div className="absolute top-full mt-2 w-48 bg-light-bg-secondary dark:bg-dark-bg-secondary rounded-lg shadow-lg py-1 z-50 text-black dark:text-white">
          {chains?.map((chain) => (
            <button
              key={chain.name}
              onClick={() => {
                setChain(chain.name);
                setIsOpen(false);
              }}
              className="w-full px-4 py-2 text-left hover:bg-light-menu-hover dark:hover:bg-dark-menu-hover flex items-center gap-2 text-black dark:text-white"
            >
              {chain.name === "Bitcoin" && <IconBitcoin />}
              {chain.name === "Solana" && <IconSolana />}
              {chain.name === "Stacks" && <IconStacks />}
              {chain.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default function Navbar() {
  const { uid, token } = useAppContext();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { isDarkMode } = useTheme();

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 backdrop-blur-md transition-colors
      ${isDarkMode ? 'bg-dark-bg-primary/80 border-b border-white/10' : 'bg-white/80 border-b border-black/10'}`}
    >
      <div className="max-w-7xl mx-auto h-16 flex items-center px-6 gap-8">
        <RouterLink to="/" className="flex items-center h-full py-4">
          <img src={isDarkMode ? LogoWhite : Logo} alt="Tulle" className="h-8 w-auto object-contain" />
        </RouterLink>

        <div className={`hidden md:flex items-center gap-6 ml-auto ${isNavOpen ? 'flex' : ''}`}>
          {/* Add your nav items here */}
        </div>

        <div className="flex items-center gap-4 ml-auto">
          {!(token || uid) && <ChainSwitcher />}
          <SignInButton />
          <ThemeToggle />

          <button
            className="md:hidden p-2 rounded-lg bg-white/10 hover:bg-white/15 transition-colors"
            onClick={() => setIsNavOpen(!isNavOpen)}
          >
            <FaBars className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      {isNavOpen && (
        <div className="md:hidden absolute top-full left-0 right-0 bg-light-bg-primary dark:bg-dark-bg-primary border-b border-light-border dark:border-dark-border">
          <div className="p-4 flex flex-col gap-2">
            {/* Add your mobile nav items here */}
          </div>
        </div>
      )}
    </nav>
  );
}
