import { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { addRating, getRating } from "../services/room.service";
import { RiEmotionSadFill, RiEmotionHappyLine } from "react-icons/ri";
import { HiEmojiSad, HiEmojiHappy } from "react-icons/hi";
import { BsFillEmojiNeutralFill, BsFillEmojiHeartEyesFill } from "react-icons/bs";

interface RatingButtonProps {
  roomId: string;
  uid: string;
}

const RatingButton = ({ roomId, uid }: RatingButtonProps) => {
  const [rating, setRating] = useState<number | undefined>(undefined);
  const [hasRated, setHasRated] = useState(false);
  const [hoveredRating, setHoveredRating] = useState<number | null>(null);
  const toast = useToast();

  useEffect(() => {
    const checkIfRated = async () => {
      const userRating = await getRating(roomId, uid);
      if (userRating) {
        setHasRated(true);
      }
    };
    checkIfRated();
  }, [roomId, uid]);

  const handleRating = async (value: number) => {
    if (hasRated) {
      toast({
        title: "Already rated",
        description: "You have already provided your rating.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await addRating(roomId, uid, value);
      toast({
        title: "Rating sent, thank you!",
        duration: 3000,
        isClosable: true,
      });
      setHasRated(true);
    } catch (error) {
      console.error("Error adding rating:", error);
      toast({
        title: "Error adding rating!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getEmoticonColor = (value: number) => {
    const isHovered = hoveredRating !== null && value <= hoveredRating;
    const baseColors = {
      1: "text-red-500 dark:text-red-400",
      2: "text-orange-500 dark:text-orange-400",
      3: "text-yellow-500 dark:text-yellow-400",
      4: "text-green-500 dark:text-green-400",
      5: "text-green-500 dark:text-green-400"
    };

    return `${baseColors[value as keyof typeof baseColors]} ${isHovered ? "scale-110" : "scale-100"}`;
  };

  return (
    <div className="w-full max-w-md mx-auto">
      {hasRated ? (
        <div className="text-center py-3 px-6 bg-green-50 dark:bg-green-900/20 rounded-xl">
          <p className="text-green-600 dark:text-green-400 font-medium">
            Rating sent, thank you!
          </p>
        </div>
      ) : (
        <div className="space-y-4">
          <p className="text-center text-gray-700 dark:text-gray-300 font-medium">
            How satisfied are you with this trade?
          </p>

          <div className="flex justify-center items-center gap-4">
            <button
              className={`transition-all duration-200 hover:scale-110 ${getEmoticonColor(1)}`}
              onClick={() => handleRating(1)}
              onMouseEnter={() => setHoveredRating(1)}
              onMouseLeave={() => setHoveredRating(null)}
              title="Very Dissatisfied"
            >
              <RiEmotionSadFill size={32} />
            </button>

            <button
              className={`transition-all duration-200 hover:scale-110 ${getEmoticonColor(2)}`}
              onClick={() => handleRating(2)}
              onMouseEnter={() => setHoveredRating(2)}
              onMouseLeave={() => setHoveredRating(null)}
              title="Dissatisfied"
            >
              <HiEmojiSad size={32} />
            </button>

            <button
              className={`transition-all duration-200 hover:scale-110 ${getEmoticonColor(3)}`}
              onClick={() => handleRating(3)}
              onMouseEnter={() => setHoveredRating(3)}
              onMouseLeave={() => setHoveredRating(null)}
              title="Neutral"
            >
              <BsFillEmojiNeutralFill size={28} />
            </button>

            <button
              className={`transition-all duration-200 hover:scale-110 ${getEmoticonColor(4)}`}
              onClick={() => handleRating(4)}
              onMouseEnter={() => setHoveredRating(4)}
              onMouseLeave={() => setHoveredRating(null)}
              title="Satisfied"
            >
              <HiEmojiHappy size={32} />
            </button>

            <button
              className={`transition-all duration-200 hover:scale-110 ${getEmoticonColor(5)}`}
              onClick={() => handleRating(5)}
              onMouseEnter={() => setHoveredRating(5)}
              onMouseLeave={() => setHoveredRating(null)}
              title="Very Satisfied"
            >
              <BsFillEmojiHeartEyesFill size={28} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RatingButton;
