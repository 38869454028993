import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

{
  /* <meta name="twitter:card" content="summary_large_image">
<meta name="twitter:site" content="@nytimes">
<meta name="twitter:creator" content="@SarahMaslinNir">
<meta name="twitter:title" content="Parade of Fans for Houston’s Funeral">
<meta name="twitter:description" content="NEWARK - The guest list and parade of limousines with celebrities emerging from them seemed more suited to a red carpet event in Hollywood or New York than than a gritty stretch of Sussex Avenue near the former site of the James M. Baxter Terrace public housing project here.">
<meta name="twitter:image" content="http://graphics8.nytimes.com/images/2012/02/19/us/19whitney-span/19whitney-span-articleLarge.jpg"> */
}

export default function ImagePage() {
  const { imageUrl } = useParams();

  console.log(atob(imageUrl!));

  type MetaTag = {
    property?: string;
    name?: string;
    content?: string;
  };

  useEffect(() => {
    const metaTags: MetaTag[] = [
      {
        name: "twitter:image",
        content:
          "https://www.simplilearn.com/ice9/free_resources_article_thumb/Types_of_Artificial_Intelligence.jpg",
      },
      {
        name: "twitter:title",
        content: "Awesome AI Trade",
      },
      {
        name: "twitter:description",
        content: "The best AI trade ever",
      },
      {
        name: "twitter:url",
        content: "https://neoswap.xyz",
      },
      {
        property: "og:title",
        content: "Awesome AI Trade",
      },
      {
        property: "og:description",
        content: "The best AI trade ever",
      },
      {
        property: "og:image",
        content:
          "https://www.simplilearn.com/ice9/free_resources_article_thumb/Types_of_Artificial_Intelligence.jpg",
      },
    ];

    const head = document.querySelector("head");

    metaTags.forEach((tag) => {
      const meta = document.createElement("meta");

      if (tag.property) {
        meta.setAttribute("property", tag.property);
      }

      if (tag.name) {
        meta.setAttribute("name", tag.name);
      }

      if (tag.content) {
        meta.setAttribute("content", tag.content);
      }

      head?.insertBefore(meta, head.firstChild);
    });
  }, []);

  return (
    <>
      <Helmet prioritizeSeoTags>
        {/* <meta property="og:site_name" content="NeoSwap" />
        <meta property="og:url" content="https://neoswap.xyz" />
        <meta property="og:title" content="NeoSwap OG Title" />
        <meta property="og:description" content="A Cool Trade" />
        <meta
          property="og:image"
          content="https://www.simplilearn.com/ice9/free_resources_article_thumb/Types_of_Artificial_Intelligence.jpg"
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="neoswap" />
        <meta name="twitter:url" content="https://neoswap.xyz" />
        <meta name="twitter:title" content="NeoSwap AI Trades" />
        <meta
          name="twitter:image"
          content="https://www.simplilearn.com/ice9/free_resources_article_thumb/Types_of_Artificial_Intelligence.jpg"
        />
        <meta
          name="twitter:description"
          content="Neoswap is an AI-powered economic engine that supports Item and Ordinals smart auctions, swaps, and OTC trades across multiple chains."
        />
        <meta name="twitter:card" content="summary_large_image" />

        <meta name="title" content="NeoSwap" /> */}
        {/* <title>
          Tulle | Item Smart Auctions, Smart Swaps, and OTC Trades on ETH, SOL,
          MATIC, and STX
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="title"
          content="Tulle | Trade Like a Degen"
        />
        <meta name="twitter:title" content="Tulle | Trade Like a Degen" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://neoswap.xyz" />
        <meta name="twitter:site" content="@NeoSwap_ai" />
        <meta
          property="twitter:description"
          content="An AI-powered economic engine that supports smart auctions, collection swap, and starter packs."
        />
        <meta property="twitter:image" content={imageUrl} /> */}
      </Helmet>
    </>
  );
}
