import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Switch,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
  useBreakpointValue,
  useDisclosure,
  Spinner,
  Text,
} from "@chakra-ui/react";

import {
  removeAllListings,
  removeListing,
  saveProgress,
} from "../../services/room.service";
import BottomBar from "./BottomBar";
import { createFloor, filterCompleted } from "../../utils";
import ListingComplete from "./ListingComplete";
import { useRoom, Nft, BiddableNft } from "../../contexts/roomContext";
import { useUA } from "../../contexts/userTracking";
import { useAppContext } from "../../contexts/appContext";
import ListModal from "../../components/ListingDataTable/ListModal";
import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import PendingImg from "../../assets/pending.jpg";
import { FaLock } from "react-icons/fa";

export default function Listing() {
  const { uid } = useAppContext();
  const { gaRemoveNft, gaFinishListing } = useUA();
  const {
    room,
    roomId,
    stepProgress,
    listings,
    availableToList,
    nftsLoading,
    getAvailableToList,
    setModalId,
    modalId,
    decimals,
    abbr,
  } = useRoom();

  console.log("Available to list:", availableToList);
  console.log("Available to list:", listings);

  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("name_asc");
  const [selectedCollection, setSelectedCollection] = useState("all");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [filteredNfts, setFilteredNfts] = useState<Nft[]>([]);

  console.log("Listing component state:", {
    hasNFTs: !!availableToList?.length,
    nftsCount: availableToList?.length,
    isLoading: nftsLoading,
    roomStatus: room?.status
  });

  const hasJoined = room?.users && room?.users.includes(uid || "") ? true : false;

  useEffect(() => {
    if (uid) {
      console.log("Triggering getAvailableToList from Listing component");
      getAvailableToList();
    }
  }, [uid]);

  // Get unique collections for filter dropdown
  const collections = ["all", ...new Set(availableToList?.filter(nft => nft?.collection?.name).map(nft => nft.collection.name) || [])];

  // Get collection image helper
  const getCollectionImage = (collectionName: string) => {
    const firstNftInCollection = availableToList?.find(nft => nft?.collection?.name === collectionName);
    return firstNftInCollection?.collection?.image;
  };

  useEffect(() => {
    // Filter out any undefined or null NFTs first
    let result = [...(availableToList || [])].filter(nft => nft !== undefined && nft !== null);

    // Apply collection filter
    if (selectedCollection !== "all") {
      result = result.filter(nft => nft?.collection?.name === selectedCollection);
    }

    // Apply search filter
    if (searchQuery) {
      result = result.filter(nft =>
        (nft?.name || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
        (nft?.collection?.name || "").toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply sorting
    result.sort((a, b) => {
      switch (sortBy) {
        case "name_asc":
          return (a?.name || "").localeCompare(b?.name || "");
        case "name_desc":
          return (b?.name || "").localeCompare(a?.name || "");
        case "collection_asc":
          return (a?.collection?.name || "").localeCompare(b?.collection?.name || "");
        case "collection_desc":
          return (b?.collection?.name || "").localeCompare(a?.collection?.name || "");
        default:
          return 0;
      }
    });

    setFilteredNfts(result);
  }, [availableToList, searchQuery, sortBy, selectedCollection]);

  const handleSaveProgress = () => {
    saveProgress(roomId, "listings", uid!, true);
    gaFinishListing(roomId);
  };

  const handleRemove = (id: string) => {
    removeListing(roomId, uid!, id);
    gaRemoveNft(roomId, id);
  };

  const removeAll = () => {
    removeAllListings(roomId, uid!);
    gaRemoveNft(roomId, Object.keys(listings));
  };

  const createBottomNfts = () => {
    return Object.keys(listings || {}).sort((a, b) => a.localeCompare(b));
  };

  const part = filterCompleted(stepProgress);

  if (part.includes(uid!)) {
    return <ListingComplete />;
  }

  const isOrdinals = room?.info?.isOrdinals || room.blockchain.name === "bitcoin";

  return (
    <>
      <div className="flex flex-col gap-6 mb-36">
        {/* Listed Items Section */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
          <div className="flex items-center justify-between mb-4 pb-3 border-b border-gray-200 dark:border-gray-700">
            <div>
              <h3 className="font-semibold text-gray-900 dark:text-white text-lg">Your Listed Items</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {Object.keys(listings || {}).length} items listed
              </p>
            </div>
            {Object.keys(listings || {}).length > 0 && (
              <button
                onClick={removeAll}
                className="text-sm text-red-500 hover:text-red-600 transition-colors"
              >
                Remove All
              </button>
            )}
          </div>

          {Object.keys(listings || {}).length === 0 ? (
            <div className="text-center py-4">
              <p className="text-gray-500 dark:text-gray-400">
                No items listed yet
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-10 xl:grid-cols-12 gap-2">
              {Object.entries(listings || {}).map(([itemId, listing]) => {
                const nft = availableToList?.find(n => n.itemId === itemId);
                if (!nft) return null;

                return (
                  <div key={itemId}
                    className="bg-gray-50 dark:bg-gray-700/50 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                  >
                    <div className="relative pt-[100%]">
                      <img
                        src={nft.thumbnail || nft.image}
                        alt={nft.name}
                        className="absolute inset-0 w-full h-full object-cover rounded-t"
                        onError={(e) => {
                          e.currentTarget.src = PendingImg;
                        }}
                      />
                    </div>
                    <div className="p-1.5">
                      <h4 className="font-medium text-gray-900 dark:text-white text-[10px] leading-tight truncate">
                        {nft.name}
                      </h4>
                      <p className="text-gray-500 dark:text-gray-400 text-[10px] leading-tight truncate">
                        {nft.collection?.name || "Unknown Collection"}
                      </p>
                      <div className="flex items-center justify-between mt-0.5 mb-1">
                        <span className="text-[10px] text-primary font-medium">
                          {listing.listingPrice / (10 ** decimals)} {abbr}
                        </span>
                        <button
                          onClick={() => handleRemove(itemId)}
                          className="text-red-500 hover:text-red-600 transition-colors"
                        >
                          <XMarkIcon className="w-3 h-3" />
                        </button>
                      </div>
                      <button
                        onClick={() => setModalId(itemId)}
                        className="w-full px-1.5 py-0.5 text-[10px] leading-none bg-primary hover:bg-primary-dark
                          text-white rounded transition-colors"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {/* Main Content with Sidebar */}
        <div className="flex gap-6 relative">
          {/* Collections Sidebar - Desktop */}
          <aside className="hidden lg:block w-64 flex-shrink-0">
            <div className="sticky top-20">
              <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-4">
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Search items..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full h-10 px-4 rounded-lg 
                      border border-gray-200 dark:border-gray-700
                      bg-white dark:bg-gray-700 
                      text-gray-900 dark:text-white
                      focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>

                <div className="space-y-2 overflow-y-auto max-h-[calc(100vh-180px)]">
                  <h3 className="font-semibold text-gray-900 dark:text-white mb-2">Collections</h3>
                  <button
                    onClick={() => setSelectedCollection("all")}
                    className={`w-full text-left px-3 py-2 rounded-lg transition-colors ${selectedCollection === "all"
                      ? "bg-primary text-white"
                      : "text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                      }`}
                  >
                    All Collections
                  </button>
                  {collections
                    .filter(c => c !== "all")
                    .map(collection => (
                      <button
                        key={collection}
                        onClick={() => setSelectedCollection(collection)}
                        className={`w-full text-left px-3 py-2 rounded-lg transition-colors flex items-center gap-3 ${selectedCollection === collection
                          ? "bg-primary text-white"
                          : "text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                          }`}
                      >
                        <div className="w-8 h-8 flex-shrink-0 rounded-full overflow-hidden bg-gray-100 dark:bg-gray-700">
                          <img
                            src={getCollectionImage(collection)}
                            alt={collection}
                            className="w-full h-full object-cover"
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = PendingImg;
                            }}
                          />
                        </div>
                        <span className="truncate text-gray-800 dark:text-gray-200">
                          {collection}
                        </span>
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </aside>

          {/* Collections Sidebar - Mobile */}
          <div className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity lg:hidden
            ${isSidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
            onClick={() => setIsSidebarOpen(false)}
          >
            <div className={`fixed inset-y-0 left-0 w-64 bg-white dark:bg-gray-800 transform transition-transform
              ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
              onClick={e => e.stopPropagation()}
            >
              <div className="p-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="font-semibold text-gray-900 dark:text-white">Collections</h3>
                  <button onClick={() => setIsSidebarOpen(false)} className="text-gray-500">
                    <XMarkIcon className="w-6 h-6" />
                  </button>
                </div>
                <div className="space-y-2">
                  <button
                    onClick={() => {
                      setSelectedCollection("all");
                      setIsSidebarOpen(false);
                    }}
                    className={`w-full text-left px-3 py-2 rounded-lg transition-colors ${selectedCollection === "all"
                      ? "bg-primary text-white"
                      : "text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                      }`}
                  >
                    All Collections
                  </button>
                  {collections
                    .filter(c => c !== "all")
                    .map(collection => (
                      <button
                        key={collection}
                        onClick={() => {
                          setSelectedCollection(collection);
                          setIsSidebarOpen(false);
                        }}
                        className={`w-full text-left px-3 py-2 rounded-lg transition-colors flex items-center gap-3 ${selectedCollection === collection
                          ? "bg-primary text-white"
                          : "text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                          }`}
                      >
                        <div className="w-8 h-8 flex-shrink-0 rounded-full overflow-hidden bg-gray-100 dark:bg-gray-700">
                          <img
                            src={getCollectionImage(collection)}
                            alt={collection}
                            className="w-full h-full object-cover"
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = PendingImg;
                            }}
                          />
                        </div>
                        <span className="truncate text-gray-800 dark:text-gray-200">
                          {collection}
                        </span>
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>

          {/* Main NFT Grid */}
          <main className="flex-1 min-w-0">
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
              <div className="flex justify-between items-center mb-6">
                <div className="flex items-center gap-4">
                  {/* Mobile filter button */}
                  <button
                    className="lg:hidden px-4 py-2 rounded-lg bg-gray-100 dark:bg-gray-700
                      text-gray-800 dark:text-gray-200
                      hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                    onClick={() => setIsSidebarOpen(true)}
                  >
                    <span className="flex items-center gap-2">
                      <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                      </svg>
                      Collections
                    </span>
                  </button>
                  <select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className="w-[180px] px-4 py-2 rounded-lg
                      border border-gray-200 dark:border-gray-700
                      bg-white dark:bg-gray-700 text-gray-900 dark:text-white
                      focus:ring-2 focus:ring-primary focus:border-transparent"
                  >
                    <option value="name_asc">Name (A-Z)</option>
                    <option value="name_desc">Name (Z-A)</option>
                    <option value="collection_asc">Collection (A-Z)</option>
                    <option value="collection_desc">Collection (Z-A)</option>
                  </select>
                </div>
              </div>

              {nftsLoading ? (
                <div className="flex flex-col items-center justify-center min-h-[200px] w-full gap-4">
                  <div className="w-10 h-10 border-4 border-primary border-t-transparent rounded-full animate-[spin_1s_linear_infinite]"></div>
                  <p className="text-lg font-medium text-gray-800 dark:text-white">Loading {isOrdinals ? "Ordinals" : "items"}...</p>
                </div>
              ) : filteredNfts.length === 0 ? (
                <div className="flex justify-center items-center min-h-[200px]">
                  <p className="text-lg text-gray-500 dark:text-gray-400">
                    {`Sorry you don't have any ${isOrdinals ? "Ordinals" : "items"} to list`}
                  </p>
                </div>
              ) : (
                <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-8 gap-3">
                  {filteredNfts.map((nft) => {
                    const listing = listings?.[nft.itemId];
                    const isListed = !!listing;
                    const isLocked = nft.locks.length > 0;

                    return (
                      <div key={nft.itemId}
                        className={`bg-gray-50 dark:bg-gray-700/50 rounded-lg 
                          shadow-sm hover:shadow-md transition-shadow
                          ${isListed ? 'ring-2 ring-primary' : ''}`}
                      >
                        <div className="relative pt-[100%]">
                          {isLocked && (
                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 rounded-t-lg">
                              <FaLock className="w-4 h-4 text-white" />
                            </div>
                          )}
                          <img
                            src={nft.thumbnail || nft.image}
                            alt={nft.name}
                            className={`absolute inset-0 w-full h-full object-cover rounded-t-lg
                              ${isLocked ? 'opacity-50' : ''}`}
                            onError={(e) => {
                              e.currentTarget.src = PendingImg;
                            }}
                          />
                        </div>

                        <div className="p-2">
                          <div className="mb-1">
                            <h3 className="font-medium text-gray-900 dark:text-white text-xs truncate">
                              {nft.name}
                            </h3>
                            <p className="text-gray-500 dark:text-gray-400 text-xs truncate">
                              {nft.collection?.name || "Unknown Collection"}
                            </p>
                          </div>

                          <div className="flex flex-col gap-1 text-xs">
                            <div className="flex justify-between items-center px-2 py-1 bg-gray-100 dark:bg-gray-600 rounded text-[10px]">
                              <span className="text-gray-600 dark:text-gray-300">Floor</span>
                              <span className="font-medium text-gray-900 dark:text-white">
                                {createFloor(nft)}
                              </span>
                            </div>
                            {isListed && (
                              <div className="flex justify-between items-center px-2 py-1 bg-primary/10 rounded text-[10px]">
                                <span className="text-primary">Listed</span>
                                <span className="font-medium text-primary">
                                  {listing.listingPrice / (10 ** decimals)} {abbr}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="mt-1">
                            <button
                              onClick={() => setModalId(nft.itemId)}
                              className={`w-full px-2 py-1 text-[10px]
                                ${isListed
                                  ? 'bg-primary hover:bg-primary-dark text-white'
                                  : 'border border-primary text-primary hover:bg-primary hover:text-white'
                                } rounded transition-colors`}
                              disabled={isLocked}
                            >
                              {isListed ? 'Edit Listing' : 'List Item'}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </main>
        </div>
      </div>

      {/* List Modal */}
      <ListModal nfts={filteredNfts.filter((nft) => !nft?.locks?.length)} />

      {/* Bottom Bar */}
      <BottomBar
        nfts={createBottomNfts()}
        handleRemove={handleRemove}
        saveProgress={handleSaveProgress}
        removeAll={removeAll}
        finishLabel="Finish Listing"
      />
    </>
  );
}
