import React from 'react';
import potatocat from "../assets/dapps/potatocat.jpg";
import staratlas from "../assets/dapps/staratlas.jpg";
import genopets from "../assets/dapps/genopets.png";
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

export default function DAppsCards() {
    const navigate = useNavigate();
    const toast = useToast();

    const handleGenopetsClick = () => {
        toast({
            title: "Coming Soon",
            description: "The Genopets app is not available yet",
            status: "info",
            duration: 3000,
            isClosable: true,
        });
    };

    const cardsData = [

        {
            title: "Star Atlas Starter Packs",
            description: "Choose a starter pack for your adventure, or create custom bundles to buy and sell items.",
            image: staratlas,
            link: "/star-atlas",
            buttonText: "Launch App"
        },
        {
            title: "Fleet Rentals Blinks",
            description: "View and manage your Star Atlas fleet rentals with detailed blink information.",
            image: staratlas,
            link: "/fleet-rentals",
            buttonText: "Coming Soon",
            disabled: true
        },
        {
            title: "Star Atlas Blinks Viewer",
            description: "View your Star Atlas Solana blinks.",
            image: staratlas,
            link: "/blinks",
            buttonText: "Launch App"
        },
        {
            title: "Genopets Starter Packs",
            description: "Get started on Genopets game with your favorite pack",
            image: genopets,
            link: "/genopets",
            buttonText: "Coming Soon",
            disabled: true
        }
    ];

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-6 w-full">
            {cardsData.map((card, index) => (
                <a
                    key={index}
                    href={card.disabled ? "#" : card.link}
                    onClick={card.disabled ? (e) => {
                        e.preventDefault();
                        handleGenopetsClick();
                    } : undefined}
                    target={card.link.startsWith('http') ? '_blank' : undefined}
                    rel={card.link.startsWith('http') ? 'noopener noreferrer' : undefined}
                    className="block h-full group"
                >
                    <div className={`flex flex-col h-full border border-gray-200 dark:border-gray-700 
                                  rounded-lg overflow-hidden packs-widget 
                                  shadow-sm hover:shadow-xl transition-all duration-300 ease-in-out
                                  transform hover:scale-[1.02] hover:-translate-y-1
                                  bg-white dark:bg-gray-800 ${card.disabled ? 'opacity-75' : ''}`}>
                        <img
                            src={card.image}
                            alt={card.title}
                            className="object-cover h-[200px] w-full"
                        />
                        <div className="p-6 w-full flex-grow">
                            <h3 className="text-xl font-bold mb-3 text-gray-900 dark:text-white">
                                {card.title}
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                {card.description}
                            </p>
                        </div>
                        <button className={`w-full py-4 text-center font-semibold
                                         ${card.disabled ? 'bg-gray-500' : 'bg-primary hover:bg-primary-hover'} 
                                         text-white transition-colors duration-200 ease-in-out`}>
                            {card.buttonText}
                        </button>
                    </div>
                </a>
            ))}
        </div>
    );
}