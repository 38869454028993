import { useState, useEffect, useCallback } from "react";
import { useRoom } from "../contexts/roomContext";

type Props = {
  align?: "left" | "center" | "right";
};

export default function LastRun({ align = "right" }: Props) {
  const [countdownTime, setCountdownTime] = useState("60");
  const [isSpinning, setSpinning] = useState(false);
  const { timeDiff } = useRoom();

  const calculateTargetTime = useCallback(() => {
    const currentTime = new Date().getTime() + timeDiff * 1000;
    return Math.ceil(currentTime / 1000 / 60) * 1000 * 60;
  }, [timeDiff]);

  const [targetTime, setTargetTime] = useState(calculateTargetTime);

  useEffect(() => {
    const updateCountdown = () => {
      const currentTime = new Date().getTime() + timeDiff * 1000;
      const remainingTime = targetTime - currentTime;

      if (remainingTime <= 0) {
        setSpinning(true);
        setTargetTime(calculateTargetTime());

        setTimeout(() => {
          setSpinning(false);
        }, 2000);
        return;
      }

      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      const formattedSeconds = String(Math.max(0, seconds)).padStart(2, "0");
      setCountdownTime(formattedSeconds);
    };

    // Initial update
    updateCountdown();

    // Set up interval
    const interval = setInterval(updateCountdown, 1000);

    // Cleanup
    return () => {
      clearInterval(interval);
    };
  }, [targetTime, timeDiff, calculateTargetTime]);

  return (
    <div className={`text-${align} font-medium`}>
      {isSpinning ? (
        <div className="inline-flex items-center gap-2 px-4 py-2 text-sm text-indigo-600 bg-indigo-50 rounded-full">
          <span>Running</span>
          <span className="w-1.5 h-1.5 bg-indigo-600 rounded-full animate-pulse"></span>
          <span className="w-1.5 h-1.5 bg-indigo-600 rounded-full animate-pulse delay-150"></span>
          <span className="w-1.5 h-1.5 bg-indigo-600 rounded-full animate-pulse delay-300"></span>
        </div>
      ) : (
        <div className="inline-flex items-center gap-2 px-4 py-2 text-sm text-gray-600 bg-gray-50 rounded-full">
          <span>Next prediction in:</span>
          <span className="font-mono font-bold text-indigo-600">
            {countdownTime}s
          </span>
        </div>
      )}
    </div>
  );
}
