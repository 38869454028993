import { useEffect, useState } from "react";
import {
    Button,
    Center,
    Container,
    Flex,
    Heading,
    Text,
    Spinner,
    useToast,
    Skeleton,
    Image,
    Box,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Alert,
    AlertDescription,
    AlertIcon,
} from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { Helmet } from "react-helmet-async";
import Slider from "react-slick";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useChain } from "../contexts/chainsContext";
import EmailInput from "../components/Inputs/EmailInput";
import { useUA } from "../contexts/userTracking";
import { useAppContext } from "../contexts/appContext";
import Firebase from "../services/firebase";
import { generateAiRoom } from "../services/room.service";
import { aiGetUserInfo, joinAiWaitlist } from "../services/user.service";
import { createFloor, validateEmail } from "../utils";
import { useQuery } from "@tanstack/react-query";
import { getNftsMetaData } from "./AiOffers";
import { ChainDetails } from "../components/AiTradesV2/types";
import TradesForDayComplete from "../components/AiTradesV2/TradesForDayComplete";
import DownTime from "../components/AiTradesV2/Downtime";
import { getAiTradeRoomInfo, getNftShowcaseCarousels } from "../services/strapi";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkRehype from "remark-rehype";
import remarkBreaks from "remark-breaks";
import rehypeRaw from "rehype-raw";
import IconUSDC from "../components/Icons/iconUSDC";
import IconStacks from "../components/Icons/IconStacks";
import { ChainSwitcher } from "../components/navbar";
import { ChevronDownIcon } from "@chakra-ui/icons";
import IconBitcoin from "../components/Icons/IconBitcoin";
import IconSolana from "../components/Icons/IconSolana";
import IconATLAS from "../components/Icons/IconATLAS";

const Bold = ({ children }: { children: any }) => <span className="bold">{children}</span>;

// const Text = ({ children }: { children: any }) => (
//   <p className="align-center">{children}</p>
// );

const options = {
    renderMark: {
        [MARKS.BOLD]: (text: any) => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
    },
};

const getTitle = (data: any) => {
    if (!data?.top_title || data?.top_title === " ") return;
    return (
        <div className="self-stretchflex-col justify-center items-center gap-4 flex">
            <h1 className="text-center text-5xl sm:text-6xl font-bold gradient-text">
                {data.top_title}
            </h1>
        </div>
    );
};

const getButtonText = (data: any) => {
    if (!data?.button_text) return "Generate Trade";
    return data.button_text;
};

const getBodyText = (data: any, canGetTrade?: boolean) => {
    if (!data?.body_text || data?.body_text === " ") return;

    if (data.is_whitelist && !canGetTrade && data?.contenterror?.[0]?.rich_text) {
        return (
            <div className="text-center">
                <h1>jjjj</h1>
                <span className="text-gray-200 text-xl font-medium leading-snug">
                    <Markdown
                        remarkPlugins={[remarkGfm, remarkRehype, remarkBreaks, rehypeRaw]}
                        children={data?.contenterror?.[0]?.rich_text.replace(/\n/g, "<br />")}
                        components={{
                            // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
                            a(props) {
                                const { node, ...rest } = props;
                                return (
                                    <a
                                        className="text-[#3DFF99] underline decoration-solid"
                                        {...rest}
                                    />
                                );
                            },
                        }}
                    />
                </span>
            </div>
        );
    }

    return <div dangerouslySetInnerHTML={{ __html: data.body_text }} />;
};

const getTopImage = (data: any) => {
    if (!data?.top_image?.data?.attributes?.url) return null;
    return (
        <div className="self-stretch flex-col justify-center items-center gap-8 flex">
            <Image src={data.top_image.data.attributes.url} w="300px" />
        </div>
    );
};

const getViewtradeLink = (data: any) => {
    if (data?.hide_view_trade_link) return;
    return (
        <>
            or{" "}
            <Link className="text-[#3DFF99] underline decoration-solid" to="/ai-history">
                View your AI Trades
            </Link>
        </>
    );
};

const getBottomNotice = (data: any) => {
    if (data?.hide_bottom_notice) return;

    const btmNoticeText = () => {
        if (data?.bottom_notice_text?.[0]?.rich_text) {
            return (
                <Markdown remarkPlugins={[remarkGfm]}>
                    {data?.bottom_notice_text?.[0]?.rich_text}
                </Markdown>
            );
        }
        return "We've opened up a limited number of AI Trades per day. First come, first served, 1 accepted trade per day, up to 3 proposals.";
    };

    return (
        <div className="px-10 py-6 bg-black bg-opacity-30 rounded-2xl backdrop-blur-3xl flex-col justify-start items-start gap-2.5 flex">
            <div className="self-stretch justify-start items-start gap-8 inline-flex">
                <div className="w-9 relative rounded-2xl shadow">
                    <svg
                        width="37"
                        height="36"
                        viewBox="0 0 37 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18.5 0C14.9399 0 11.4598 1.05568 8.49974 3.03355C5.53966 5.01141 3.23255 7.82263 1.87018 11.1117C0.507799 14.4008 0.151339 18.02 0.845873 21.5116C1.54041 25.0033 3.25474 28.2106 5.77209 30.7279C8.28943 33.2453 11.4967 34.9596 14.9884 35.6541C18.48 36.3487 22.0992 35.9922 25.3883 34.6298C28.6774 33.2674 31.4886 30.9603 33.4665 28.0003C35.4443 25.0402 36.5 21.5601 36.5 18C36.5 13.2261 34.6036 8.64773 31.2279 5.27208C27.8523 1.89642 23.2739 0 18.5 0V0ZM16.925 7.5375C16.925 7.11978 17.0909 6.71918 17.3863 6.42381C17.6817 6.12844 18.0823 5.9625 18.5 5.9625C18.9177 5.9625 19.3183 6.12844 19.6137 6.42381C19.9091 6.71918 20.075 7.11978 20.075 7.5375V21.0375C20.075 21.4552 19.9091 21.8558 19.6137 22.1512C19.3183 22.4466 18.9177 22.6125 18.5 22.6125C18.0823 22.6125 17.6817 22.4466 17.3863 22.1512C17.0909 21.8558 16.925 21.4552 16.925 21.0375V7.5375ZM18.5 29.8125C18.0995 29.8125 17.708 29.6937 17.375 29.4712C17.042 29.2487 16.7824 28.9324 16.6291 28.5624C16.4759 28.1924 16.4358 27.7852 16.5139 27.3924C16.5921 26.9996 16.7849 26.6388 17.0681 26.3556C17.3513 26.0724 17.7121 25.8795 18.1049 25.8014C18.4978 25.7233 18.9049 25.7634 19.2749 25.9166C19.645 26.0699 19.9612 26.3295 20.1837 26.6625C20.4062 26.9955 20.525 27.387 20.525 27.7875C20.525 28.3246 20.3117 28.8396 19.9319 29.2194C19.5521 29.5991 19.0371 29.8125 18.5 29.8125Z"
                            fill="#6C60FF"
                        />
                    </svg>
                </div>
                <div className="grow shrink basis-0 self-stretch text-white text-base font-normal leading-tight">
                    {btmNoticeText()}
                </div>
            </div>
        </div>
    );
};

const LeftSlider = ({
    settings,
    aiTradesImageIds,
    slug,
}: {
    settings: any;
    aiTradesImageIds: string[];
    slug?: string;
}) => {
    const { getChainDetails } = useChain();
    const chain = getChainDetails("solana") as ChainDetails;

    const { isFetching, data } = useQuery({
        queryKey: ["aiTradesSlider", slug],
        queryFn: () => getNftsMetaData({ ids: aiTradesImageIds }),
        staleTime: 5000,
        refetchOnWindowFocus: false,
        enabled: !!aiTradesImageIds,
    });

    return (
        <div className="w-[400px] max-w-full pt-px flex-col justify-start items-center gap-8 inline-flex">
            <div className="w-96 text-center text-indigo-500 text-xl font-medium leading-loose">
                Recently Traded With NeoSwap AI
            </div>
            <div className="w-full">
                <div className="justify-start items-center inline-flex w-full relative">
                    <Slider {...settings} className="w-full relative">
                        {isFetching && (
                            <div>
                                <div className="rounded-2xl w-full overflow-hidden">
                                    <Skeleton height="500px" width="100%" />
                                </div>
                            </div>
                        )}
                        {data?.map((img) => {
                            const name = img.name;
                            const src = img.image || img.thumbnail || "";
                            const collectionFloorPrice = createFloor(img, chain.truncate);
                            const collectionName = img.collection.name;
                            return (
                                <div key={img.itemId}>
                                    <div className="rounded-2xl w-full overflow-hidden">
                                        <img src={src} alt={name} />
                                    </div>
                                    <div className="p-4 bg-black bg-opacity-30 rounded-2xl backdrop-blur-3xl flex-col justify-start items-start gap-2 flex w-full mt-4">
                                        <div className="text-white text-xl font-medium leading-loose">
                                            {img.name}
                                        </div>
                                        <div className="flex justify-between w-full">
                                            <div className="text-white text-base font-medium leading-tight">
                                                {collectionName}
                                            </div>
                                            <div className="self-stretch text-right text-white text-base font-medium leading-tight">
                                                {collectionFloorPrice}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default function AiTrades() {
    const { gaGenerateSwap } = useUA();
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [joining, setJoining] = useState(false);
    const [token, setToken] = useState("atlas");
    // const [token, setToken] = useState("usdc");
    const [sequenceMessage, setSequenceMessage] = useState("");
    const { uid } = useAppContext();
    const navigate = useNavigate();
    const theChain = uid!.split("-")[0];

    const { slug = `ai-trades-${theChain}` } = useParams();

    const { chain } = useChain();

    const [emailData] = useDocument(doc(Firebase.getDBApp(), `users`, uid!, "contact", "email"));

    const email = emailData?.data();

    const [chainAvailableTradeData, chainLoading] = useDocument(
        doc(Firebase.getDBApp(), "blockchain", theChain || "")
    );

    const [waitlistData] = useDocument(doc(Firebase.getDBApp(), "aiWaitlist", uid!));

    const waitlist = waitlistData?.data();
    const chainAvailableTrade = chainAvailableTradeData?.data();
    const ai = chainAvailableTrade?.ai;

    const { data, isFetched } = useQuery({
        queryKey: ["getAiTradeRoomInfo", slug],
        queryFn: () => getAiTradeRoomInfo(slug),
        staleTime: 5000,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        select: (res) => res?.[0]?.attributes,
    });

    useEffect(() => {
        if (data === undefined && isFetched) {
            navigate("/", { replace: true });
        }
    }, [data, navigate, isFetched]);

    const campaign = data?.campaign;

    const { updateState } = useAppContext();

    // Update activeChainBalance when campaign changes
    useEffect(() => {
        if (campaign === "sa-starter-pack") {
            updateState({ activeChainBalance: "atlas" });
            // updateState({ activeChainBalance: 'usdc' });
        }
        return () => {
            updateState({ activeChainBalance: "native" });
        };
    }, [campaign, updateState]);

    const { data: aiUserInfo, isLoading: aiGetUserInfoLoading } = useQuery({
        queryKey: ["ai-getUserInfo", uid, campaign],
        queryFn: () => aiGetUserInfo(uid!, campaign),
        staleTime: 5000,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        enabled: !!uid && isFetched,
        select: (data) => data.data,
    });

    const { data: aiTradesImageIds } = useQuery({
        queryKey: ["getAiTradeRoomImages", data?.nft_showcase_carousel?.data.attributes?.slug],
        queryFn: () => getNftShowcaseCarousels(data?.nft_showcase_carousel?.data.attributes?.slug),
        staleTime: 5000,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        enabled: !!data?.nft_showcase_carousel?.data.attributes?.slug,
        select: (res) => res?.[0]?.attributes.nftids.map((ele: any) => ele.nftid),
    });

    console.log("SLUG", slug, data, aiTradesImageIds);

    const handleGenerateTrade = async () => {
        try {
            setLoading(true);
            loadingSequence(0);
            const res = await generateAiRoom({ userId: uid!, campaign, token: token });
            if (!res.success) {
                throw new Error("Failed to fetch data");
            }
            gaGenerateSwap();
            navigate(`/ai-trades/${res.aiRoomId}`, { replace: true });
        } catch (e) {
            setLoading(false);
            setSequenceMessage("");
            const isNoNftError =
                (e as Error).message.replace(/^FirebaseError:\s*/, "") === "No offers found";

            toast({
                title: isNoNftError ? "No trade found" : "Error",
                description: isNoNftError
                    ? `No offers found. We recommend adding more ${chain?.abbr}s and Items to your wallet, and then try again to increase your likelihood of success.`
                    : (e as Error).message.replace(/^FirebaseError:\s*/, ""),
                status: "error",
                duration: 5000,
                isClosable: true,
                styleConfig: {
                    zIndex: 999999,
                },
            });
        }
    };

    const sequenceSteps = [
        { message: "Reading your wallet", delay: 4000 },
        { message: "Finding packs", delay: 8000 },
        { message: "Solving" }, // No delay for the last step
    ];

    const loadingSequence = (index: number) => {
        if (index >= sequenceSteps.length) {
            // Exit the recursion when all messages have been shown
            return;
        }

        const { message, delay } = sequenceSteps[index];

        // Update the state with the current message
        setSequenceMessage(message);

        setTimeout(() => {
            // Call loadingSequence again with the next index after the specified delay
            loadingSequence(index + 1);
        }, delay || 0); // Use 0 as the default delay if not specified
    };

    const handleJoinWaitlist = async () => {
        try {
            setJoining(true);
            if (!validateEmail(email?.value)) {
                throw new Error("Invalid email");
            }
            await joinAiWaitlist(uid!, email?.value);
            toast({
                title: "Congratulations!",
                description: "You were added to the waitlist.",
                status: "success",
                duration: 9000,
                isClosable: true,
                styleConfig: {
                    zIndex: 999999,
                },
            });
        } catch (e: any) {
            toast({
                title: "Something went wrong adding you to the waitlist",
                description: e?.message || "",
                status: "error",
                duration: 9000,
                isClosable: true,
                styleConfig: {
                    zIndex: 999999,
                },
            });
        } finally {
            setJoining(false);
        }
    };

    if (aiGetUserInfoLoading || chainLoading) {
        return (
            <div className="nft-loader">
                <Spinner color="#ed6cd8" />
                <p> Loading, please wait... </p>
            </div>
        );
    }

    if (chain?.downtime) {
        return <DownTime />;
    }

    if (!aiUserInfo?.active) {
        const hasJoined = !!waitlist;

        if (hasJoined) {
            return (
                <>
                    <Helmet>
                        <title>Tulle | AI Trades Waitlist</title>
                    </Helmet>
                    <Center>
                        <Container maxW="xl" textAlign="center" gap={8}>
                            <Heading className="wait-title" as="h2" size="lg">
                                You're on the list
                            </Heading>
                            <p className="sub-waitlist">
                                We'll let you know when new proposals open up.
                            </p>
                            <p>
                                {" "}
                                In the meantime, you can switch your wallet to Solana or Stacks to
                                try AI Trades.{" "}
                            </p>
                        </Container>
                    </Center>
                </>
            );
        }

        return (
            <>
                <Helmet>
                    <title>Tulle | AI Trades Waitlist</title>
                </Helmet>
                <Center>
                    <Container maxW="xl" textAlign="center" gap={8}>
                        <Heading className="wait-title" as="h2" size="lg">
                            Join AI Trades Waitlist
                        </Heading>
                        <p className="sub-waitlist">
                            {/* We are currently live on Stacks and Solana, with one trade
              available per user each day. */}
                            We're pausing AI Trades to focus on enhancing smart swaps and smart
                            auctions in the short term.
                        </p>

                        <p className="sub-waitlist">
                            Click 'Join Waitlist' to get early access notifications.
                        </p>
                        <Container maxW="xl">
                            <EmailInput />
                            <Button
                                width="100%"
                                className="grad-btn"
                                mt="30px"
                                onClick={handleJoinWaitlist}
                                isDisabled={!!waitlist}
                                isLoading={joining}
                            >
                                Join Waitlist
                            </Button>
                        </Container>
                    </Container>
                </Center>
            </>
        );
    }

    if ((aiUserInfo?.tradesLeft.value || 0) < 1 && aiUserInfo?.nextTradeUnlock) {
        return (
            <>
                <Helmet>
                    <title>Tulle | AI Trades Complete</title>
                </Helmet>
                <TradesForDayComplete nextTradeAt={aiUserInfo?.nextTradeUnlock} />
            </>
        );
    }

    type CustomArrowProps = {
        onClick: () => void; // assuming onClick is a function
    };

    const CustomNextArrow = (props: CustomArrowProps) => {
        const { onClick } = props;
        return (
            <div
                className="w-16 h-16 absolute -right-10 top-44 z-10 bg-black bg-opacity-30 rounded-full backdrop-blur-3xl flex items-center justify-center cursor-pointer"
                onClick={onClick}
            >
                <svg
                    width="16"
                    height="28"
                    viewBox="0 0 16 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.5 2L13.5 14L1.5 26"
                        stroke="white"
                        strokeWidth="3"
                        strokeLinecap="round"
                    />
                </svg>
            </div>
        );
    };

    const CustomPrevArrow = (props: CustomArrowProps) => {
        const { onClick } = props;
        return (
            <div
                className="w-16 h-16 absolute -left-10 top-44 z-10 bg-black bg-opacity-30 rounded-full backdrop-blur-3xl flex items-center justify-center cursor-pointer"
                onClick={onClick}
            >
                <svg
                    width="16"
                    height="28"
                    viewBox="0 0 16 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14.5 2L2.5 14L14.5 26"
                        stroke="white"
                        strokeWidth="3"
                        strokeLinecap="round"
                    />
                </svg>
            </div>
        );
    };
    const settings = {
        customPaging: function (i: number) {
            return <div className="w-2 h-2 rounded-full opacity-30 bg-white" />;
        },
        dots: true,
        dotsClass: "justify-center items-center gap-2.5 !flex w-full mt-4 aitradeslider",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        nextArrow: (
            <CustomNextArrow
                onClick={function (): void {
                    throw new Error("Function not implemented.");
                }}
            />
        ),
        prevArrow: (
            <CustomPrevArrow
                onClick={function (): void {
                    throw new Error("Function not implemented.");
                }}
            />
        ),
    };

    const RightContentSection = () => (
        <div className="flex-col  w-full justify-center items-center inline-flex">
            {getTopImage(data)}
            {(!data?.top_title || data?.top_title === " ") &&
                (!data?.body_text || data?.body_text === " ") ? null : (
                <div className="self-stretch flex-col justify-center items-center gap-8 flex">
                    {getTitle(data)}
                    {getBodyText(data, aiUserInfo?.canGetTrade)}
                </div>
            )}

            <Flex
                className="font-poppins"
                direction={"column"}
                gap={4}
                justifyContent="center"
                alignItems={"center"}
                background={"transparent"}
                rounded={"28px"}
                p={8}
                w={"fit-content"}
                ml={"auto"}
                mr={"auto"}
            >
                <Text mb={{ md: 5, base: 0 }} fontSize={"md"}>
                    Select a token to get started
                </Text>
                <Flex justifyContent="center" mb="10px">
                    <Flex
                        gap={"20px"}
                        justifyContent={{
                            base: "space-between",
                            md: "space-between",
                            sm: "center",
                        }}
                        flexWrap={"wrap"}
                    >
                        <Flex
                            direction={"column"}
                            alignItems={"center"}
                            gap={"10px"}
                            cursor={"pointer"}
                            data-group
                            onClick={() => {
                                setToken("native")
                                updateState({ activeChainBalance: 'native' });
                            }}
                            key={"native"}
                        >
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                transition={"all .2s ease-in-out"}
                                background="rgba(255,255,255,.1)"
                                _groupHover={{ background: "rgba(255,255,255,.2)" }}
                                rounded="xl"
                                w={{ lg: "95px", base: "70px" }}
                                h={{ lg: "95px", base: "70px" }}
                                border={
                                    token === "native"
                                        ? "3px solid #6C60FF"
                                        : "3px solid transparent"
                                }
                            >
                                <IconSolana
                                    w={{ lg: "47px", base: "30px" }}
                                    h={{ lg: "47px", base: "30px" }}
                                />

                            </Flex>
                            <Text >SOL</Text>
                        </Flex>
                        <Flex
                            direction={"column"}
                            alignItems={"center"}
                            gap={"10px"}
                            cursor={"pointer"}
                            data-group
                            onClick={() => {
                                setToken("usdc")
                                updateState({ activeChainBalance: 'usdc' });
                            }}
                            key={"usdc"}
                        >
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                transition={"all .2s ease-in-out"}
                                background="rgba(255,255,255,.1)"
                                _groupHover={{ background: "rgba(255,255,255,.2)" }}
                                rounded="xl"
                                w={{ lg: "95px", base: "70px" }}
                                h={{ lg: "95px", base: "70px" }}
                                border={
                                    token === "usdc"
                                        ? "3px solid #6C60FF"
                                        : "3px solid transparent"
                                }
                            >
                                <IconUSDC
                                    w={{ lg: "47px", base: "30px" }}
                                    h={{ lg: "47px", base: "30px" }}
                                />

                            </Flex>
                            <Text >USDC</Text>
                        </Flex>

                        <Flex
                            direction={"column"}
                            alignItems={"center"}
                            gap={"10px"}
                            cursor={"pointer"}
                            data-group
                            onClick={() => {
                                setToken("atlas");
                                updateState({ activeChainBalance: "atlas" });
                            }}
                            key={"atlas"}
                        >
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                transition={"all .2s ease-in-out"}
                                background="rgba(255,255,255,.1)"
                                _groupHover={{ background: "rgba(255,255,255,.2)" }}
                                rounded="xl"
                                w={{ lg: "95px", base: "70px" }}
                                h={{ lg: "95px", base: "70px" }}
                                border={
                                    token === "atlas"
                                        ? "3px solid #6C60FF"
                                        : "3px solid transparent"
                                }
                            >
                                <IconATLAS
                                    w={{ lg: "47px", base: "30px" }}
                                    h={{ lg: "47px", base: "30px" }}
                                />
                            </Flex>
                            <Text>ATLAS</Text>
                        </Flex>
                    </Flex>
                </Flex>
                {aiUserInfo?.canGetTrade && (
                    <div className="self-stretchpy-2.5 flex-col justify-center items-center gap-2.5 flex">
                        {sequenceMessage && loading ? (
                            <Flex
                                p={10}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={4}
                                flexDir="column"
                            >
                                <Spinner color="#ed6cd8" size={"xl"} emptyColor="#666666" />{" "}
                                {sequenceMessage}
                            </Flex>
                        ) : (
                            <div
                                className="px-10 py-6 bg-gradient-to-l from-sky-500 via-blue-600 to-indigo-500 rounded-md justify-end items-center gap-2 inline-flex cursor-pointer"
                                onClick={loading ? () => null : handleGenerateTrade}
                            >
                                <div className="text-center text-white text-base font-medium capitalize">
                                    {loading ? "Generating Trade" : getButtonText(data)}
                                </div>
                            </div>
                        )}
                    </div>
                )}


                <div className="text-center text-xs">
                    <br></br>
                    <Flex
                        maxWidth={"600px"}>
                        <Alert
                            status="info"
                            className="info-alert"
                            background={
                                "linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"

                            }
                            borderRadius={"4px"}
                        >
                            <AlertIcon color={"#6C60FF"} />
                            <AlertDescription>
                                {" For accurate pack pricing, please select and confirm quickly as prices and quantities may change. If signing fails, try generating a new pack."}
                            </AlertDescription>
                        </Alert>
                    </Flex>
                </div>
            </Flex>

            <div>
                {/* <Link
          className="text-[#3DFF99] underline decoration-solid"
          target="_blank"
          to="https://magiceden.io/u/6DNxxtkJZTNERmk7qJ9gzQwa4LTxJwadrgYwDnfcjn1G"
        >
          View ai-treasury wallet
        </Link>{" "} */}
                {getViewtradeLink(data)}
            </div>
            {getBottomNotice(data)}
        </div>
    );

    return (
        <>
            <Helmet>
                <title>Tulle | Star Atlas</title>
            </Helmet>
            <Center>
                <Container maxW="5xl">
                    <div className="flex flex-col-reverse lg:flex-row justify-center items-center gap-20 lg:gap-0 lg:justify-between">
                        {/* <LeftSlider
              settings={settings}
              aiTradesImageIds={aiTradesImageIds}
              slug={slug}
            /> */}
                        <RightContentSection />
                    </div>
                </Container>
            </Center>
        </>
    );
}
