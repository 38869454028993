import { memo, useCallback, useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { addBid, cancelBid, setBudget } from "../../services/room.service";
import useTimeLeft from "../../hooks/useTimeLeft";
import { roundValue, checkCanSubmitVal } from "../../utils";
import { useUA } from "../../contexts/userTracking";
import { BiddableNft, getHighestBid, useRoom } from "../../contexts/roomContext";
import { useAppContext } from "../../contexts/appContext";
import TopBids from "./TopBids";
import ModalImages from "./ModalImages";
import DetailsTab from "./DetailsTab";
import BiddingTab from "./BiddingTab";
import FooterButtons from "./FooterButtons";
import Tabs from "./Tabs";
import { useChain } from "../../contexts/chainsContext";
import TransactionModal from "../ui/TransactionModal";

interface Props {
  nfts: BiddableNft[];
}

const createInitialBidVal = (
  reservePrice: number,
  decimals: number,
  truncate: number,
  bid?: number
) => {
  if (bid) {
    return bid / 10 ** decimals;
  }
  return roundValue(reservePrice / 10 ** decimals, truncate, 'up');
};

const BiddingModal = memo(({ nfts }: Props) => {
  const { uid, balance } = useAppContext();
  const {
    bids,
    roomId,
    step,
    abbr,
    handlePrev,
    handleNext,
    modalId,
    setModalId,
    highestBids,
    truncate,
    isSilentAuction,
    budgets,
    handlePass,
    availableForBid,
    canList,
    room,
    incrementToken,
    incrementPct,
    decimals,
    isAdmin,
    budgetMargin,
    isPassing
  } = useRoom();

  const isOrdinals = room?.info?.isOrdinals || room.blockchain.name === "bitcoin";
  const suggestedPrice = room?.info?.suggestedPrice || "Floor";
  const isSeller = isSilentAuction && canList;
  const budget = budgets?.budget;

  const { gaSelectNft, gaSetBidPrice, gaSetBudget } = useUA();
  const toast = useToast();
  const [error, setError] = useState<string | undefined>();
  const [fetching, setFetching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const nft = nfts?.find((nft) => nft?.itemId === modalId);
  const id = nft?.itemId;
  const itemIndex = nfts?.findIndex((nft) => nft?.itemId === modalId) + 1;
  const bid = bids?.[id || ""]?.bidPrice;
  const hasBid = typeof bid === "number";

  const [currentTab, setCurrentTab] = useState(() => {
    if (isPassing) {
      return "details";
    } else if ((isSeller || isAdmin) && hasBid) {
      return "top-bids";
    } else {
      return "bidding";
    }
  });

  useEffect(() => {
    if (isPassing) {
      setCurrentTab("details");
    } else if ((isSeller || isAdmin) && hasBid) {
      setCurrentTab("top-bids");
    } else {
      setCurrentTab("bidding");
    }
  }, [isPassing, isSeller, isAdmin, hasBid]);

  const reservePrice = nft?.reservePrice?.value || 0;
  const startingBid = nft?.startingBid;

  const min = roundValue(
    Number(startingBid) / 10 ** decimals,
    truncate,
    "up"
  );

  const [bidVal, setBidVal] = useState<number | string>();

  const floor = nft?.collection?.floorPrice?.floorPrice?.valueConverted || 0;

  const { isHighBidder, highestBidAmount, isTied, place } = getHighestBid(
    highestBids,
    id,
    uid
  );

  const isSecretReserve = room.info.secretReserve;

  const isBidReserveMet =
    room.info.secretReserve &&
    highestBidAmount >=
    (typeof reservePrice !== "undefined" ? reservePrice : 0);

  const { getChainDetails } = useChain();
  const tokenChain = getChainDetails(room.blockchain.name, room.info.token);
  let margin = (tokenChain.budgetMargin !== null && tokenChain.budgetMargin !== undefined)
    ? tokenChain.budgetMargin / 10 ** decimals
    : 0.05;

  const max =
    !balance || balance < margin
      ? 0
      : roundValue(Number(balance - margin || 0), decimals, "down") *
      10 ** decimals;

  useEffect(() => {
    setError(undefined);

    setBidVal(
      isSecretReserve && !bid
        ? 0
        : createInitialBidVal(reservePrice, decimals, truncate, bid)
    );
  }, [nft?.itemId]);

  const cancel = async () => {
    try {
      if (!id) return;
      if (fetching) return;
      setFetching(true);
      await cancelBid(roomId, uid!, id);
    } catch (e) {
      return toast({
        title: "Something went wrong!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setFetching(false);
    }
  };

  const handleKeyPress = useCallback(
    (event: any) => {
      if (nfts.length < 2) return;
      if (event.key === "ArrowRight") {
        handleNext(nfts);
      } else if (event.key === "ArrowLeft") {
        handlePrev(nfts);
      }
    },
    [handleNext, handlePrev]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleBid = async (
    value: string | number | undefined,
    ignoreCheck?: boolean
  ) => {
    try {
      if (!id) return;

      const theValue = roundValue(Number(value), truncate, "down");

      setBidVal(theValue);
      let submissionVal = Number(theValue);
      submissionVal = submissionVal * 10 ** decimals;
      submissionVal = Math.floor(submissionVal);

      if (fetching) return;
      setFetching(true);

      // Special handling for zero bids when reserve price is 0
      if (reservePrice === 0 && submissionVal === 0) {
        await addBid(roomId, uid!, id, 0);
        gaSelectNft(roomId, id);
        gaSetBidPrice(roomId, id, 0);
        toast({
          title: "Bid Set!",
          description: `Bid Set for 0 ${abbr}`,
          status: "success",
          duration: 1000,
          isClosable: true,
        });
        setError(undefined);
        setFetching(false);
        return;
      }

      const { canSubmit, reason } = checkCanSubmitVal({
        theValue,
        min,
        max: ignoreCheck ? submissionVal : max,
        reservePrice,
        decimals,
        isSecretReserve,
        isSilentAuction,
        currentHighestBid: highestBidAmount,
        incrementToken: incrementToken,
        incrementPct: incrementPct,
        abbr,
        truncate,
        roomType: room.info.roomType,
        maxbalance: budget,
      });

      if (!canSubmit && reason[0] === "Bid is more than spending limit!") {
        onOpen();
        setCurrentTab("bidding");
      } else if (!canSubmit) {
        setError("lowbid");
      }

      if (!canSubmit) throw new Error(reason[0]);

      await addBid(roomId, uid!, id, Math.ceil(submissionVal));
      gaSelectNft(roomId, id);
      gaSetBidPrice(roomId, id, submissionVal);
      toast({
        title: "Bid Set!",
        description: `Bid Set for ${Number(theValue)} ${abbr}`,
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      setError(undefined);
    } catch (e: any) {
      if (e.message) {
        setError(e.message);
      }
    } finally {
      setFetching(false);
    }
  };

  const setAsIncrementAboveHighestBid = () => {
    const value =
      (highestBidAmount +
        Math.max(
          room.info.incrementToken || incrementToken,
          highestBidAmount * room.info.incrementPct || incrementPct
        )) /
      10 ** decimals;
    setBidVal(value);
    handleBid(value);
  };

  const handleClose = () => setModalId(undefined);

  const bidMaxBudget = async () => {
    try {
      if (max === 0) {
        toast({
          title: "Insufficient balance",
          description: `Sorry! You need a little more ${abbr}.`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        await setBudget(roomId, uid!, { budget: max });
        gaSetBudget(roomId, max);
        toast({
          title: "Spending Limit Updated",
          description: "Spending Limit Set Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (e: any) {
      if (e.message) {
        toast({
          title: "Error Updating Spending Limit",
          description: e.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const handleSetBudget = async () => {
    try {
      if (max === 0)
        return toast({
          title: "Insufficient balance",
          description: `Sorry! You need a little more ${abbr}.`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });

      await setBudget(roomId, uid!, { budget: max });
      gaSetBudget(roomId, max);
      toast({
        title: "Spending Limit Updated",
        description: "Spending Limit Set Successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
      handleBid(bidVal, true);
    } catch (e: any) {
      if (e.message) {
        toast({
          title: "Error Updating Spending Limit",
          description: e.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const timeLeft = useTimeLeft();
  const finalMinute = timeLeft < 60;

  const canBid = !isPassing && (!isSeller || room.info.roomType === "smartAuction");

  const setBudgetAndBid = async (value: number) => {
    const humanValue = value / 10 ** decimals;
    try {
      setBidVal(humanValue);
      await setBudget(roomId, uid!, { budget: value });
      gaSetBudget(roomId, max);
      toast({
        title: "Spending Limit Updated",
        description: "Spending Limit Set Successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
      await addBid(roomId, uid!, id!, value);
      gaSelectNft(roomId, id!);
      gaSetBidPrice(roomId, id!, value);
      toast({
        title: "Bid Set!",
        description: `Bid Set for ${humanValue} ${abbr}`,
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      setError(undefined);
    } catch (e: any) {
      if (e.message) {
        toast({
          title: "Error Updating Spending Limit and Bid",
          description: e.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <TransactionModal
      isOpen={!!nft}
      onClose={handleClose}
      size="4xl"
      className="dark:bg-gray-900 max-h-[90vh] overflow-hidden"
      headerClassName="dark:bg-gray-800/50"
      contentClassName="dark:bg-gray-900/50"
      title="Bid Item"
      footer={
        <FooterButtons
          isSeller={isSeller}
          isAdmin={isAdmin}
          roomType={room.info.roomType}
          hasBid={hasBid}
          handlePass={() => handlePass(id!)}
          handleClose={handleClose}
          handleBid={() => handleBid(bidVal)}
          isPassing={isPassing}
        />
      }
    >
      <div className="flex gap-6 h-[calc(90vh-12rem)] overflow-hidden">
        {/* Left side - Image and Navigation */}
        <div className="w-1/3 flex-shrink-0 flex flex-col">
          <div className="relative flex-1 overflow-hidden">
            <div className={`h-full rounded-lg ${hasBid ? 'ring-2 ring-primary' : ''}`}>
              <ModalImages
                nft={nft}
                hasBid={hasBid}
                nftIndex={itemIndex}
                total={availableForBid?.length ?? 0}
                handleNext={() => handleNext(nfts)}
                handlePrev={() => handlePrev(nfts)}
              />
            </div>
          </div>

          {!isPassing && nfts.length > 1 && (
            <div className="mt-4 flex items-center justify-between">
              <button
                className="flex items-center gap-2 rounded-lg border border-gray-300 
                  bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 
                  disabled:opacity-50 dark:border-gray-600 dark:bg-gray-700 
                  dark:text-white dark:hover:bg-gray-600"
                onClick={() => handlePrev(nfts)}
                disabled={nfts.length < 2}
              >
                <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                </svg>
                <span>Previous</span>
              </button>

              <span className="text-sm text-gray-500 dark:text-gray-400">
                Viewing [{itemIndex}/{availableForBid?.length ?? 0}]
              </span>

              <button
                className="flex items-center gap-2 rounded-lg border border-gray-300 
                  bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 
                  disabled:opacity-50 dark:border-gray-600 dark:bg-gray-700 
                  dark:text-white dark:hover:bg-gray-600"
                onClick={() => handleNext(nfts)}
                disabled={nfts.length < 2}
              >
                <span>Next</span>
                <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          )}
        </div>

        {/* Right side - Content */}
        <div className="w-2/3 flex-1 overflow-y-auto pr-2">
          <div className="flex flex-col gap-4">
            <Tabs
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              isSeller={isSeller}
              isAdmin={isAdmin}
              roomType={room.info.roomType}
              isPassing={isPassing}
            />

            {currentTab === "details" && nft && (
              <DetailsTab
                nft={nft}
                abbr={abbr}
                truncate={truncate}
                isOrdinals={isOrdinals}
                suggestedPrice={suggestedPrice}
              />
            )}

            {currentTab === "bidding" &&
              ((!isSeller && !isAdmin) || room.info.roomType === "smartAuction") &&
              !isPassing &&
              nft && (
                <BiddingTab
                  nft={nft}
                  finalMinute={finalMinute}
                  min={min}
                  max={max}
                  budget={budget}
                  isHighBidder={isHighBidder}
                  isTied={isTied}
                  hasBid={hasBid}
                  place={place}
                  highestBidAmount={highestBidAmount}
                  total={availableForBid?.length ?? 0}
                  abbr={abbr}
                  truncate={truncate}
                  isSilentAuction={isSilentAuction}
                  decimals={decimals}
                  isSecretReserve={isSecretReserve}
                  isBidReserveMet={isBidReserveMet}
                  incrementPct={incrementPct}
                  incrementToken={incrementToken}
                  startingBid={startingBid ?? 0}
                  handleBid={handleBid}
                  floor={floor}
                  setAsIncrementAboveHighestBid={setAsIncrementAboveHighestBid}
                  bidVal={bidVal}
                  canBid={canBid}
                  onClose={onClose}
                  cancel={async () => {
                    await cancel();
                    return;
                  }}
                  step={step.toString()}
                  isOpen={isOpen}
                  setBidVal={setBidVal}
                  bidMaxBudget={bidMaxBudget}
                  error={error}
                  setBudgetAndBid={setBudgetAndBid}
                  isOrdinals={isOrdinals}
                  suggestedPrice={suggestedPrice}
                />
              )}

            {currentTab === "top-bids" && uid && nft && !isPassing && (
              <TopBids
                uid={uid}
                nft={nft}
                highestBids={highestBids}
                abbr={abbr}
                truncate={truncate}
                isSecretReserve={isSecretReserve}
                isSeller={isSeller}
                isAdmin={isAdmin}
                handleBid={handleBid}
              />
            )}
          </div>
        </div>
      </div>
    </TransactionModal>
  );
});

export default BiddingModal;
