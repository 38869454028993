import { Button, Flex } from "@chakra-ui/react";
import PassButton from "./PassButton";
import { memo } from "react";

interface Props {
  isSeller: boolean;
  isAdmin: boolean;
  roomType: string;
  hasBid: boolean;
  handlePass: () => void;
  handleClose: () => void;
  handleBid: () => void;
  isPassing?: boolean;
}

function FooterButtons({
  isSeller,
  isAdmin,
  roomType,
  hasBid,
  handlePass,
  handleClose,
  handleBid,
  isPassing = false,
}: Props) {
  if ((!isSeller && !isAdmin) || roomType === "smartAuction") {
    return (
      <Flex justifyContent="flex-end" className="footer-buttons" gap="6px">
        <PassButton handlePass={() => handlePass()} hasBid={hasBid} />
        {!hasBid && !isPassing ? (
          <Button
            colorScheme="blue"
            backgroundColor="#6C60FF"
            color="white"
            _hover={{ backgroundColor: "#5550c7" }}
            onClick={() => handleBid()}
            autoFocus
          >
            Set Bid
          </Button>
        ) : (
          <Button
            colorScheme="blue"
            backgroundColor="#6C60FF"
            color="white"
            _hover={{ backgroundColor: "#5550c7" }}
            onClick={() => handleClose()}
          >
            Done
          </Button>
        )}
      </Flex>
    );
  }
  return (
    <Button
      colorScheme="blue"
      backgroundColor="#6C60FF"
      color="white"
      _hover={{ backgroundColor: "#5550c7" }}
      mt="10px"
      float="right"
      onClick={() => handleClose()}
    >
      Done
    </Button>
  );
}

export default memo(FooterButtons);
