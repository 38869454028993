import {
  AvatarGroup,
  Box,
  Flex,
  Heading,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useAppContext } from "../contexts/appContext";
import { useRoom } from "../contexts/roomContext";
import { saveProgress } from "../services/room.service";
import NFTThumb from "./NftThumb";

export default function ListedNfts() {
  const { uid } = useAppContext();
  const { roomId, listings, availableToList } = useRoom();
  const listed = Object.keys(listings || {});

  const goBack = () => {
    saveProgress(roomId, "listings", uid!, false);
  };

  const max = useBreakpointValue({ base: 1, md: 5 });

  return (
    <Box className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
          Your Listed Items ({listed?.length})
        </h2>
        <button
          onClick={goBack}
          className="px-4 py-2 text-sm font-medium text-primary hover:text-primary-dark
                   border border-primary hover:border-primary-dark
                   rounded-lg transition-colors"
        >
          Edit Listings
        </button>
      </div>

      <div className="flex gap-4 overflow-x-auto pb-2">
        {listed
          ?.sort()
          ?.slice(0, max)
          ?.map((id) => {
            const nft = availableToList?.find((ele) => ele.itemId === id)!;
            return <NFTThumb key={id} nft={nft} />;
          })}
        {listed.length > max! && (
          <div className="flex justify-center items-center min-w-[64px] h-16
                        bg-gray-50 dark:bg-gray-700 rounded-lg
                        text-gray-600 dark:text-gray-400 text-sm font-medium">
            +{listed.length - max!}
          </div>
        )}
        {listed.length === 0 && (
          <p className="text-gray-500 dark:text-gray-400">Nothing Listed Yet</p>
        )}
      </div>
    </Box>
  );
}
