import { Image } from "@chakra-ui/react";
import InteractiveTrade from "./InteractiveTrade";
import PendingImg from "../assets/pending.jpg";

interface Props {
  gifUrl?: string;
  gifData?: any;
}

export default function TradeGif({ gifUrl, gifData }: Props) {
  // If neither gifUrl nor gifData is provided, don't render anything
  if (!gifUrl && !gifData) {
    return null;
  }

  return (
    <div className="trade-gif">
      {gifData ? (
        <InteractiveTrade data={gifData} />
      ) : gifUrl ? (
        <Image
          src={`https://${gifUrl}`}
          alt="Animated Gif"
          fallbackSrc={PendingImg}
          height="500px"
          width="100%"
          objectFit="cover"
        />
      ) : null}
    </div>
  );
}
