import { useState, useEffect } from "react";
import { useAppContext } from "../contexts/appContext";
import { useRoom } from "../contexts/roomContext";
import { setBudget } from "../services/room.service";
import IconPurpleEye from "./Icons/IconPurpleEye";

export default function NumItemsLimit() {
  const { uid } = useAppContext();
  const { availableForBid, budgets, roomId, passed } = useRoom();
  const [inputValue, setInputValue] = useState('');

  // Add proper dependencies to useEffect
  useEffect(() => {
    const defaultValue = budgets?.numItemsLimitForBids ||
      Math.max(1, (availableForBid?.length || 0) - (passed?.length || 0));
    setInputValue(defaultValue.toString());
  }, [budgets?.numItemsLimitForBids, availableForBid?.length, passed?.length]);

  const handleMax = () => {
    const maxValue = (availableForBid?.length || 0) - (passed?.length || 0);
    if (maxValue <= 0) {
      alert("No items to bid yet.");
      return;
    }
    setInputValue(maxValue.toString());
    setBudget(roomId, uid!, { numItemsLimitForBids: maxValue });
  };

  const handleBlur = () => {
    const val = Number(inputValue);
    if (isNaN(val) || val < 0 || inputValue === "") {
      alert("Please enter a valid number.");
      return;
    }
    setBudget(roomId, uid!, { numItemsLimitForBids: val });
  };

  const handleChange = (value: string) => {
    const numValue = parseInt(value);
    if (!isNaN(numValue) && numValue >= 0) {
      setInputValue(value);
    }
  };

  return (
    <>
      <div className="flex items-center gap-2 mb-4">
        <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
          Item Limit
        </h3>
        <div className="cursor-pointer text-gray-400 hover:text-gray-600 dark:hover:text-gray-300">
          <IconPurpleEye />
        </div>
      </div>

      <div className="flex items-center gap-2">
        <div className="relative flex-1">
          <input
            type="number"
            value={inputValue}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={handleBlur}
            min="0"
            step="1"
            className="w-full h-10 px-4 bg-gray-50 dark:bg-gray-700 
                     text-gray-900 dark:text-white 
                     border border-gray-200 dark:border-gray-600 
                     rounded-l-lg
                     focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent
                     placeholder-gray-400 dark:placeholder-gray-500 pr-16"
          />
          <span className="absolute right-8 top-1/2 -translate-y-1/2 text-gray-400 dark:text-gray-500">
            Items
          </span>
          <div className="absolute right-2 top-1/2 -translate-y-1/2 flex flex-col">
            <button
              className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 h-4 flex items-center justify-center"
              onClick={() => handleChange((parseInt(inputValue) + 1).toString())}
            >
              ▲
            </button>
            <button
              className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 h-4 flex items-center justify-center"
              onClick={() => handleChange((Math.max(0, parseInt(inputValue) - 1)).toString())}
            >
              ▼
            </button>
          </div>
        </div>

        <button
          onClick={handleMax}
          className="h-10 px-4 bg-primary hover:bg-primary-dark text-white
                   rounded-r-lg transition-colors duration-200"
        >
          Max
        </button>
      </div>
    </>
  );
}


