import { memo } from "react";

interface Props {
  currentTab: string;
  setCurrentTab: (tab: string) => void;
  isSeller: boolean;
  isAdmin: boolean;
  roomType: string;
  isPassing: boolean;
}

const Tabs = ({ currentTab, setCurrentTab, isSeller, isAdmin, roomType, isPassing }: Props) => {
  const tab = (name: string, slug: string, isNew?: boolean) => {
    return (
      <button
        className={`px-5 py-2.5 text-sm font-medium cursor-pointer transition-colors
          ${currentTab === slug
            ? 'bg-[#052032] text-white'
            : 'text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700/50'}`}
        onClick={() => setCurrentTab(slug)}
      >
        <div className="flex items-center gap-2">
          {name}
          {isNew && (
            <span className="inline-flex items-center rounded-md bg-primary/10 px-2 py-1 text-xs font-medium text-primary">
              new
            </span>
          )}
        </div>
      </button>
    );
  };

  return (
    <div className="flex border-b border-gray-200 dark:border-gray-700">
      {tab("Details", "details")}
      {!isPassing && tab("Bidding", "bidding")}
      {!isPassing && tab("Top Bids", "top-bids", true)}
    </div>
  );
};

export default memo(Tabs);
