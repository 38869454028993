export default function IconQuickDuration() {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            xmlSpace="preserve"
        >
            <path
                d="M15,0C6.7,0,0,6.7,0,15s6.7,15,15,15s15-6.7,15-15S23.3,0,15,0z M15,27.3C8.2,27.3,2.7,21.8,2.7,15S8.2,2.7,15,2.7
        S27.3,8.2,27.3,15C27.3,21.8,21.8,27.3,15,27.3z"
                fill="currentColor"
            />
            <path
                d="M15,7.5c-0.8,0-1.4,0.6-1.4,1.4v5.7l-2.9,2.9c-0.5,0.5-0.5,1.4,0,1.9s1.4,0.5,1.9,0l3.3-3.3c0.3-0.3,0.5-0.7,0.5-1.1V8.9
        C16.4,8.1,15.8,7.5,15,7.5z"
                fill="currentColor"
            />
            <path
                d="M21.1,15c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1s2.7-6.1,6.1-6.1S21.1,11.6,21.1,15z M11.8,15c0,1.8,1.4,3.2,3.2,3.2
        s3.2-1.4,3.2-3.2s-1.4-3.2-3.2-3.2S11.8,13.2,11.8,15z"
                fill="currentColor"
            />
        </svg>
    );
} 