import { Heading, HStack, Image, Tooltip } from "@chakra-ui/react";
import { useEffect } from "react";
import PendingImg from "../../assets/pending.jpg";
import { saveProgress } from "../../services/room.service";
// import { BiddingDataTable } from "../../components/BiddingDataTable";
import { useAppContext } from "../../contexts/appContext";
import { Nft, useRoom } from "../../contexts/roomContext";

const NFTThumb = ({ nft }: { nft: Nft }) => {
  const thumbnail = nft?.thumbnail;
  const name = nft?.name;
  return (
    <Tooltip label={name}>
      <Image
        minWidth="64px"
        boxSize="64px"
        objectFit="cover"
        src={thumbnail}
        fallbackSrc={PendingImg}
        alt={name}
      />
    </Tooltip>
  );
};

export default function ListingComplete() {
  const { uid } = useAppContext();
  const {
    roomId,
    listings,
    availableForBid,
    getAvailableForBid,
    availableToList,
    biddingDiff,
  } = useRoom();

  const goBack = () => {
    saveProgress(roomId, "listings", uid!, false);
  };

  useEffect(() => {
    getAvailableForBid();
  }, []);
  // const { units, abbr } = useRoom();

  // const [reservePrices] = useDocument(doc(db, "rooms", roomId!, "bids", uid!), {
  //   snapshotListenOptions: { includeMetadataChanges: true },
  // });

  const listed = Object.keys(listings || {});

  return (
    <div className="space-y-6">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
            Listed Items ({listed?.length})
          </h2>
          <button
            onClick={goBack}
            className="px-4 py-2 text-sm font-medium text-primary hover:text-primary-dark
                     border border-primary hover:border-primary-dark
                     rounded-lg transition-colors"
          >
            Edit Listings
          </button>
        </div>

        <div className="flex gap-4 overflow-x-auto pb-2">
          {listed?.map((id) => {
            const nft = availableToList?.find((ele) => ele.itemId === id)!;
            return <NFTThumb key={id} nft={nft} />;
          })}
          {listed.length === 0 && (
            <p className="text-gray-500 dark:text-gray-400">Nothing Listed Yet</p>
          )}
        </div>
      </div>
      <Heading
        className="section-title"
        size="md"
        marginTop={7}
        justifyContent={"space-between"}
      >
        Listed By Others
        {biddingDiff && (
          <button onClick={getAvailableForBid} className="small-link">
            Get Latest
          </button>
        )}
      </Heading>
      {/* <div className="listing-section box">
        {availableForBid?.length > 0 && <BiddingDataTable />}
      </div> */}
    </div>
  );
}
