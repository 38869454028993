import { Helmet } from "react-helmet-async";
import ProfileForm from "../components/ProfileForm";

export default function Profile() {
  return (
    <>
      <Helmet>
        <title>Tulle | Profile</title>
      </Helmet>
      <div className="p-6 mt-20">
        <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white text-center">Profile</h2>
        <ProfileForm />
      </div>
    </>
  );
}
