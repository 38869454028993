import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '../contexts/ThemeContext';
import { FaTwitter, FaCopy, FaShareAlt } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';

// Define TypeScript interfaces
interface RentalHistory {
  date: string;
  duration: number;
  renter: string;
  amount: number;
}

interface FleetStats {
  crew: number;
  speed: number;
  health: number;
  armor: number;
  cargo: number;
}

interface FleetEarnings {
  daily: number;
  weekly: number;
  monthly: number;
}

interface Fleet {
  id: string;
  name: string;
  image: string;
  description: string;
  stats: FleetStats;
  earnings: FleetEarnings;
  status: string;
  lastRented: string;
  rentalHistory: RentalHistory[];
}

interface FleetCardProps {
  fleet: Fleet;
  isSelected: boolean;
  onClick: () => void;
}

interface BlinkPanelProps {
  fleet: Fleet;
  onClose: () => void;
}

// Mock data for fleets
const mockFleets: Fleet[] = [
  {
    id: 'fleet-1',
    name: 'Pearce X4',
    image: 'https://storage.googleapis.com/neoswap-assets/star-atlas/ships/pearce-x4.png',
    description: 'A small but versatile exploration vessel, perfect for solo pilots.',
    stats: {
      crew: 1,
      speed: 4.5,
      health: 1200,
      armor: 800,
      cargo: 25
    },
    earnings: {
      daily: 0.45,
      weekly: 3.15,
      monthly: 13.5
    },
    status: 'active',
    lastRented: '2023-12-15T14:30:00Z',
    rentalHistory: [
      { date: '2023-12-15T14:30:00Z', duration: 7, renter: '0x123...abc', amount: 3.15 },
      { date: '2023-12-01T10:15:00Z', duration: 3, renter: '0x456...def', amount: 1.35 }
    ]
  },
  {
    id: 'fleet-2',
    name: 'Calico Guardian',
    image: 'https://storage.googleapis.com/neoswap-assets/star-atlas/ships/calico-guardian.png',
    description: 'A medium-sized combat vessel with balanced offensive and defensive capabilities.',
    stats: {
      crew: 3,
      speed: 3.8,
      health: 2500,
      armor: 1800,
      cargo: 15
    },
    earnings: {
      daily: 0.75,
      weekly: 5.25,
      monthly: 22.5
    },
    status: 'active',
    lastRented: '2023-12-10T09:45:00Z',
    rentalHistory: [
      { date: '2023-12-10T09:45:00Z', duration: 14, renter: '0x789...ghi', amount: 10.5 }
    ]
  },
  {
    id: 'fleet-3',
    name: 'Opal Jetjet',
    image: 'https://storage.googleapis.com/neoswap-assets/star-atlas/ships/opal-jetjet.png',
    description: 'A fast and agile scout ship, designed for reconnaissance missions.',
    stats: {
      crew: 2,
      speed: 5.2,
      health: 1000,
      armor: 600,
      cargo: 10
    },
    earnings: {
      daily: 0.6,
      weekly: 4.2,
      monthly: 18
    },
    status: 'inactive',
    lastRented: '2023-11-28T16:20:00Z',
    rentalHistory: [
      { date: '2023-11-28T16:20:00Z', duration: 5, renter: '0xabc...123', amount: 3 },
      { date: '2023-11-15T11:30:00Z', duration: 10, renter: '0xdef...456', amount: 6 }
    ]
  },
  {
    id: 'fleet-4',
    name: 'Fimbul ECOS',
    image: 'https://storage.googleapis.com/neoswap-assets/star-atlas/ships/fimbul-ecos.png',
    description: 'A large cargo vessel with significant storage capacity for resource transport.',
    stats: {
      crew: 5,
      speed: 2.8,
      health: 3000,
      armor: 2200,
      cargo: 150
    },
    earnings: {
      daily: 1.2,
      weekly: 8.4,
      monthly: 36
    },
    status: 'active',
    lastRented: '2023-12-12T08:15:00Z',
    rentalHistory: [
      { date: '2023-12-12T08:15:00Z', duration: 30, renter: '0xghi...789', amount: 36 }
    ]
  }
];

// Fleet Card Component
const FleetCard = ({ fleet, isSelected, onClick }: FleetCardProps) => {
  const { isDarkMode } = useTheme();

  return (
    <div
      className={`relative flex flex-col rounded-lg overflow-hidden cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-[1.02] hover:-translate-y-1 border ${isSelected
        ? 'border-primary shadow-lg'
        : isDarkMode
          ? 'border-gray-700 hover:border-gray-600'
          : 'border-gray-200 hover:border-gray-300'
        } ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}
      onClick={onClick}
    >
      <div className="relative h-48">
        <img
          src={fleet.image}
          alt={fleet.name}
          className="w-full h-full object-cover"
        />
        <div className={`absolute top-2 right-2 px-2 py-1 rounded-full text-xs font-medium ${fleet.status === 'active'
          ? 'bg-green-500 text-white'
          : 'bg-gray-500 text-white'
          }`}>
          {fleet.status === 'active' ? 'Active' : 'Inactive'}
        </div>
      </div>
      <div className="p-4 flex-grow">
        <h3 className={`text-lg font-bold mb-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {fleet.name}
        </h3>
        <p className={`text-sm mb-3 line-clamp-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          {fleet.description}
        </p>
        <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
          <div className="flex justify-between mb-1">
            <span>Daily Earnings:</span>
            <span className="font-medium">{fleet.earnings.daily} ATLAS</span>
          </div>
          <div className="flex justify-between">
            <span>Last Rented:</span>
            <span className="font-medium">
              {new Date(fleet.lastRented).toLocaleDateString()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

// Blink Panel Component
const BlinkPanel = ({ fleet, onClose }: BlinkPanelProps) => {
  const { isDarkMode } = useTheme();
  const [activeTab, setActiveTab] = useState('info');

  const handleCopyLink = () => {
    const link = `${window.location.origin}/fleet-rentals/${fleet.id}`;
    navigator.clipboard.writeText(link);
    toast.success('Link copied to clipboard!');
  };

  const handleShareOnX = () => {
    const text = `Check out my ${fleet.name} fleet on Star Atlas! #StarAtlas #FleetRentals`;
    const url = `${window.location.origin}/fleet-rentals/${fleet.id}`;
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`, '_blank');
  };

  return (
    <div className={`h-full flex flex-col ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
      <div className="p-4 border-b flex justify-between items-center">
        <h2 className="text-xl font-bold">{fleet.name} Blink</h2>
        <button
          onClick={onClose}
          className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="relative h-64">
        <img
          src={fleet.image}
          alt={fleet.name}
          className="w-full h-full object-cover"
        />
      </div>

      <div className="p-4 border-b">
        <div className="flex space-x-4">
          <button
            className={`px-4 py-2 rounded-lg transition-colors ${activeTab === 'info'
              ? 'bg-primary text-white'
              : isDarkMode
                ? 'hover:bg-gray-700'
                : 'hover:bg-gray-100'
              }`}
            onClick={() => setActiveTab('info')}
          >
            Info
          </button>
          <button
            className={`px-4 py-2 rounded-lg transition-colors ${activeTab === 'stats'
              ? 'bg-primary text-white'
              : isDarkMode
                ? 'hover:bg-gray-700'
                : 'hover:bg-gray-100'
              }`}
            onClick={() => setActiveTab('stats')}
          >
            Stats
          </button>
          <button
            className={`px-4 py-2 rounded-lg transition-colors ${activeTab === 'history'
              ? 'bg-primary text-white'
              : isDarkMode
                ? 'hover:bg-gray-700'
                : 'hover:bg-gray-100'
              }`}
            onClick={() => setActiveTab('history')}
          >
            History
          </button>
        </div>
      </div>

      <div className="flex-grow overflow-y-auto p-4">
        {activeTab === 'info' && (
          <div>
            <p className="mb-4">{fleet.description}</p>
            <div className="grid grid-cols-2 gap-4">
              <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                <div className="text-sm text-gray-500 dark:text-gray-400">Daily Earnings</div>
                <div className="text-xl font-bold">{fleet.earnings.daily} ATLAS</div>
              </div>
              <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                <div className="text-sm text-gray-500 dark:text-gray-400">Weekly Earnings</div>
                <div className="text-xl font-bold">{fleet.earnings.weekly} ATLAS</div>
              </div>
              <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                <div className="text-sm text-gray-500 dark:text-gray-400">Monthly Earnings</div>
                <div className="text-xl font-bold">{fleet.earnings.monthly} ATLAS</div>
              </div>
              <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                <div className="text-sm text-gray-500 dark:text-gray-400">Status</div>
                <div className={`text-xl font-bold ${fleet.status === 'active' ? 'text-green-500' : 'text-gray-500'}`}>
                  {fleet.status === 'active' ? 'Active' : 'Inactive'}
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'stats' && (
          <div className="space-y-4">
            <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-500 dark:text-gray-400">Crew</span>
                <span className="font-bold">{fleet.stats.crew}</span>
              </div>
              <div className="w-full bg-gray-300 dark:bg-gray-600 rounded-full h-2">
                <div
                  className="bg-primary h-2 rounded-full"
                  style={{ width: `${(fleet.stats.crew / 5) * 100}%` }}
                ></div>
              </div>
            </div>

            <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-500 dark:text-gray-400">Speed</span>
                <span className="font-bold">{fleet.stats.speed}</span>
              </div>
              <div className="w-full bg-gray-300 dark:bg-gray-600 rounded-full h-2">
                <div
                  className="bg-primary h-2 rounded-full"
                  style={{ width: `${(fleet.stats.speed / 6) * 100}%` }}
                ></div>
              </div>
            </div>

            <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-500 dark:text-gray-400">Health</span>
                <span className="font-bold">{fleet.stats.health}</span>
              </div>
              <div className="w-full bg-gray-300 dark:bg-gray-600 rounded-full h-2">
                <div
                  className="bg-primary h-2 rounded-full"
                  style={{ width: `${(fleet.stats.health / 3000) * 100}%` }}
                ></div>
              </div>
            </div>

            <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-500 dark:text-gray-400">Armor</span>
                <span className="font-bold">{fleet.stats.armor}</span>
              </div>
              <div className="w-full bg-gray-300 dark:bg-gray-600 rounded-full h-2">
                <div
                  className="bg-primary h-2 rounded-full"
                  style={{ width: `${(fleet.stats.armor / 2500) * 100}%` }}
                ></div>
              </div>
            </div>

            <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-500 dark:text-gray-400">Cargo</span>
                <span className="font-bold">{fleet.stats.cargo}</span>
              </div>
              <div className="w-full bg-gray-300 dark:bg-gray-600 rounded-full h-2">
                <div
                  className="bg-primary h-2 rounded-full"
                  style={{ width: `${(fleet.stats.cargo / 150) * 100}%` }}
                ></div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'history' && (
          <div className="space-y-4">
            {fleet.rentalHistory.length > 0 ? (
              fleet.rentalHistory.map((rental, index) => (
                <div
                  key={index}
                  className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}
                >
                  <div className="flex justify-between mb-1">
                    <span className="text-gray-500 dark:text-gray-400">Date</span>
                    <span className="font-medium">{new Date(rental.date).toLocaleDateString()}</span>
                  </div>
                  <div className="flex justify-between mb-1">
                    <span className="text-gray-500 dark:text-gray-400">Duration</span>
                    <span className="font-medium">{rental.duration} days</span>
                  </div>
                  <div className="flex justify-between mb-1">
                    <span className="text-gray-500 dark:text-gray-400">Renter</span>
                    <span className="font-medium">{rental.renter}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-500 dark:text-gray-400">Amount</span>
                    <span className="font-medium">{rental.amount} ATLAS</span>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center py-8 text-gray-500 dark:text-gray-400">
                No rental history available
              </div>
            )}
          </div>
        )}
      </div>

      <div className="p-4 border-t">
        <div className="flex space-x-2">
          <button
            onClick={handleShareOnX}
            className="flex items-center justify-center gap-2 px-4 py-2 bg-[#1DA1F2] text-white rounded-lg hover:bg-[#1a91da] transition-colors"
          >
            <FaTwitter />
            <span>Share on X</span>
          </button>
          <button
            onClick={handleCopyLink}
            className="flex items-center justify-center gap-2 px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
          >
            <FaCopy />
            <span>Copy Link</span>
          </button>
        </div>
      </div>
    </div>
  );
};

// Main FleetRentalsBlinks Component
const FleetRentalsBlinks = () => {
  const { isDarkMode } = useTheme();
  const [fleets, setFleets] = useState<Fleet[]>([]);
  const [selectedFleet, setSelectedFleet] = useState<Fleet | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showBlink, setShowBlink] = useState(false);

  // Load fleet data
  useEffect(() => {
    // Simulate API call
    setTimeout(() => {
      setFleets(mockFleets);
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleFleetClick = (fleet: Fleet) => {
    setSelectedFleet(fleet);
    setShowBlink(true);
  };

  const handleCloseBlink = () => {
    setShowBlink(false);
  };

  return (
    <>
      <Helmet>
        <title>Fleet Rentals Blinks | Tulle</title>
        <meta name="description" content="View and manage your Star Atlas fleet rentals and blinks" />
      </Helmet>

      <Toaster position="top-right" />

      <div className="container mx-auto px-4 py-8 mt-16">
        <div className="mb-8">
          <h1 className={`text-3xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Fleet Rentals Blinks
          </h1>
          <p className={`text-lg ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            View and manage your Star Atlas fleet rentals
          </p>
        </div>

        <div className="flex flex-col lg:flex-row gap-6">
          <div className={`w-full ${showBlink ? 'lg:w-2/3' : 'lg:w-full'}`}>
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
              </div>
            ) : (
              fleets.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {fleets.map((fleet) => (
                    <FleetCard
                      key={fleet.id}
                      fleet={fleet}
                      isSelected={selectedFleet?.id === fleet.id}
                      onClick={() => handleFleetClick(fleet)}
                    />
                  ))}
                </div>
              ) : (
                <div className={`text-center py-16 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'} rounded-lg`}>
                  <FaShareAlt className="mx-auto h-12 w-12 text-gray-400 mb-4" />
                  <h3 className="text-xl font-medium mb-2">No Fleets Found</h3>
                  <p className="text-gray-500 dark:text-gray-400">
                    You don't have any fleets in your collection yet.
                  </p>
                </div>
              )
            )}
          </div>

          {showBlink && selectedFleet && (
            <div className="w-full lg:w-1/3 lg:sticky lg:top-20 h-[calc(100vh-8rem)] overflow-hidden rounded-lg border border-gray-200 dark:border-gray-700 shadow-lg">
              <BlinkPanel
                fleet={selectedFleet}
                onClose={handleCloseBlink}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FleetRentalsBlinks;
